import React from 'react';
import { getIn } from 'formik';

import { DateInput } from 'components/shared/DateInput';
import { FormControl } from 'components/shared/FormControl';

const DateField = ({ label, field, form, formClassName, ...restProps }) => {
  const error = getIn(form.errors, field.name);
  const touched = form.touched[field.name];

  const hasError = touched && !!error;

  return (
    <FormControl
      label={label}
      error={error}
      className={formClassName}
      touched={touched}
    >
      <DateInput
        {...field}
        {...restProps}
        variant={hasError ? 'error' : 'default'}
      />
    </FormControl>
  );
};

export default React.memo(DateField);
