export const getRadioItems = (t) => [
  {
    title: t('queueLabel'),
    key: 'QUEUE',
  },
  {
    title: t('inProgressLabel'),
    key: 'IN_PROCESS',
  },
  {
    title: t('doneLabel'),
    key: 'DONE',
  },
];
