import React from 'react';
import cn from 'classnames';

import { isUrl } from 'helpers/isUrl';

import { Typography } from 'components/shared/Typography';

import styles from './Radio.styles.scss';

const Radio = ({
  className,
  iconClassName,
  labelClassName,
  checked,
  label,
  name,
  ...restProps
}) => (
  <div className={cn(styles.radio, className)}>
    <input
      type="radio"
      checked={checked}
      name={name}
      className={styles.input}
      {...restProps}
    />

    <div
      className={cn(styles.iconWrapper, {
        [styles.iconActiveWrapper]: checked,
      })}
    >
      {checked && <div className={cn(styles.icon, iconClassName)} />}
    </div>
    {typeof label === 'string' ? (
      <Typography
        className={cn(styles.label, labelClassName)}
        weight="regular"
        variant="subtitle"
      >
        {isUrl(label) ? <img src={label} /> : label}
      </Typography>
    ) : (
      label
    )}
  </div>
);

export default React.memo(Radio);
