import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './AnalyticsCard.styles.scss';

const AnalyticsCard = ({ children, name, onCardClick }) => (
  <div className={styles.card} onClick={onCardClick}>
    <span>{children}</span>
    <Typography className={styles.name} variant="body">
      {name}
    </Typography>
  </div>
);

export default React.memo(AnalyticsCard);
