import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showToastMessage } from 'helpers/showToastMessage';

import { useTimer } from 'hooks/useTimer';

import { AUTH_CODE_LENGTH } from 'constants/common';

import CodeStep from './CodeStep';

const CodeStepContainer = ({
  variant,
  changeRequestData,
  verifyRequestData,
  verifyRequest,
  changeRequest,
  onClose,
  onSendCodeAgainClick,
  ...restProps
}) => {
  const { t } = useTranslation('toast');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeSubmitted, setCodeSubmitted] = useState(false);

  const [errorCounter, setErrorCounter] = useState(0);
  const [resetTrigger, setResetTrigger] = useState(new Date());

  const [timerSecondsLeft, { startTimer }] = useTimer({ defaultSeconds: 0 });

  const handleCodeChange = (code) => {
    if (code.filter((number) => !!number).length === AUTH_CODE_LENGTH) {
      setLoading(true);

      setErrorCounter(errorCounter + 1);

      verifyRequest(code.join(''), verifyRequestData)
        .then((res) => {
          console.log('!', res);
          setResetTrigger(new Date());

          setErrorCounter(0);

          setCodeSubmitted(true);
          setLoading(false);
          setError(false);

          showToastMessage({
            variant: 'success',
            title: t(
              variant === 'password' ? 'passwordChanged' : 'emailChanged',
            ),
          });

          setTimeout(() => {
            setCodeSubmitted(false);

            onClose();
          }, 1000);
        })
        .catch(({ status }) => {
          setResetTrigger(new Date());

          setError(true);
          setLoading(false);

          setTimeout(() => {
            setError(false);
          }, 2000);

          if (errorCounter > 1) {
            setTimeout(() => {
              setResetTrigger(new Date());

              setErrorCounter(0);

              setError(false);

              handleSendCodeAgainClick();
            }, 3000);
          }
        });
    }
  };

  const handleSendCodeAgainClick = () => {
    if (timerSecondsLeft > 0) {
      return;
    }

    startTimer(30);

    changeRequest(changeRequestData)
      .then(() => {
        setErrorCounter(0);

        setError(false);
      })
      .catch(({ status }) => {
        setLoading(false);

        showToastMessage({ variant: 'error', status, t });
      });
  };

  return (
    <CodeStep
      {...restProps}
      loading={loading}
      variant={variant}
      error={error}
      errorCounter={errorCounter}
      timerSecondsLeft={timerSecondsLeft}
      resetTrigger={resetTrigger}
      codeSubmitted={codeSubmitted}
      onClose={onClose}
      onCodeChange={handleCodeChange}
      onSendCodeAgainClick={handleSendCodeAgainClick}
    />
  );
};

export default React.memo(CodeStepContainer);
