import React from 'react';

import * as api from 'api/methods';

import { useModal } from 'hooks/useModal';

import { GuideModal } from '../GuideModal';

import NotificationModal from './NotificationModal';

const NotificationModalContainer = ({
  isOpen,
  boardId,
  analyticNotification,
  onClose,
  ...restProps
}) => {
  const [guideModalOpen, openGuideModal, closeGuideModall] = useModal({});

  const handleUpdateAnalyticNotification = () => {
    if (!analyticNotification.timelineNotified) {
      api.updateAnalyticNotification(boardId, { timelineNotified: true });
    }
  };

  const handleConfigureClick = () => {
    handleUpdateAnalyticNotification();

    onClose();

    openGuideModal();
  };

  const handleClose = () => {
    handleUpdateAnalyticNotification();

    onClose();
  };

  return (
    <>
      <NotificationModal
        isOpen={isOpen}
        onConfigureClick={handleConfigureClick}
        onClose={handleClose}
        {...restProps}
      />
      <GuideModal isOpen={guideModalOpen} onClose={closeGuideModall} />
    </>
  );
};

export default React.memo(NotificationModalContainer);
