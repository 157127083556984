import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as api from 'api/methods';
import { showToastMessage } from 'helpers/showToastMessage';
import { userSelector } from 'store/auth/selectors';
import { getScrollHeight, getAdmin, getMembers } from './MembersModal.helpers';
import MembersModal from './MembersModal';

const MembersModalContainer = ({
  members = [],
  boardId,
  onMemberDeleted,
  ...restProps
}) => {
  const { t } = useTranslation('toast');
  const user = useSelector(userSelector);

  const handleDeleteMemberClick = React.useCallback(
    (memberId) => {
      api
        .deleteBoardUser(boardId, memberId)
        .then(() => {
          onMemberDeleted(memberId);
        })
        .catch(({ status }) => {
          showToastMessage({ variant: 'error', status, t });
        });
    },
    [boardId, onMemberDeleted, t],
  );

  const admin = getAdmin(members);
  const membersList = getMembers(members);
  const isAdmin = admin ? user.id === admin.id : false;
  const scrollHeight = getScrollHeight(membersList);

  return (
    <MembersModal
      {...restProps}
      isAdmin={isAdmin}
      members={membersList}
      scrollHeight={scrollHeight}
      admin={admin}
      onDeleteMemberClick={handleDeleteMemberClick}
    />
  );
};

export default React.memo(MembersModalContainer);
