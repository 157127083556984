import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './Header.styles.scss';

const Header = ({
  modulesRef,
  welcomeRef,
  applicationRef,
  functionsRef,
  //currentLanguage,
  onLogoClick,
  onModuleClick,
  onApplicationClick,
  //onLanguageChange,
  onFunctionClick,
  onTryForFreeClick,
  onSignInClick,
}) => {
  const { t } = useTranslation('homePage');

  return (
    <div className={styles.container}>
      <Icon
        name="logo"
        className={styles.icon}
        onClick={() => {
          onLogoClick(welcomeRef);
        }}
      />
      <div className={styles.menu}>
        <Typography
          className={styles.menuItem}
          onClick={() => {
            onModuleClick(modulesRef);
          }}
          variant="body"
          weight="medium"
        >
          {t('modulesLabel')}
        </Typography>
        <Typography
          className={styles.menuItem}
          onClick={() => {
            onApplicationClick(applicationRef);
          }}
          variant="body"
          weight="medium"
        >
          {t('caseLabel')}
        </Typography>
        <Typography
          className={styles.menuItem}
          onClick={() => {
            onFunctionClick(functionsRef);
          }}
          variant="body"
          weight="medium"
        >
          {t('functionsLabel')}
        </Typography>
      </div>
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          variant="outline"
          onClick={onSignInClick}
        >
          {t('signInButton')}
        </Button>
        <Button
          className={styles.button}
          size="small"
          onClick={onTryForFreeClick}
        >
          {t('headerButton')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Header);
