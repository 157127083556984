import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';

import styles from './NewBoardCard.styles.scss';

const NewBoardCard = ({ className, ...restProps }) => {
  const { t } = useTranslation('boardsPage');

  return (
    <div className={cn(styles.card, className)} {...restProps}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <Typography
            className={styles.text}
            component="div"
            variant="h2"
            weight="medium"
          >
            {t('createButton')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NewBoardCard);
