import React from 'react';

import { TabButton } from './components/TabButton';

import styles from './Header.styles.scss';

const Header = ({
  tabsRef,
  tabBarRef,
  loading,
  checkedButtonPosition,
  selectedTimePeriod,
  tabButtons,
  onTabClick,
  onDownloadXLS,
}) => (
  <div className={styles.header}>
    <div className={styles.tabBar} ref={tabBarRef}>
      <div
        className={styles.selectedTab}
        style={{ left: checkedButtonPosition }}
      />
      {tabButtons.map((tab, index) => (
        <TabButton
          key={tab.type}
          index={index}
          rootRef={tabsRef.current[index]}
          name={tab.name}
          checked={selectedTimePeriod === tab.type}
          onClick={() => onTabClick(tab)}
        />
      ))}
    </div>

    <div className={styles.tabBar}>
      <TabButton
        loading={loading}
        name="XLS"
        startIconName="download2"
        onClick={onDownloadXLS}
      />
    </div>
  </div>
);

export default React.memo(Header);
