import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PAGE_CONSTANTS } from '../../RegisterPage.constants';

import { checkPasswordValue } from 'helpers/checkPasswordValue';

import PasswordStep from './PasswordStep';

const PasswordStepContainer = ({ loading, error, passwordConfig }) => {
  const { t } = useTranslation('password');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordInfo, setPasswordInfo] = useState('');

  const handleAfterPasswordChange = (event) => {
    setPasswordInfo(
      checkPasswordValue({
        value: event.target.value,
        config: passwordConfig,
        t: t,
      }),
    );
  };

  const handleEyeClick = (name) => {
    switch (name) {
      case PAGE_CONSTANTS.PASSWORD:
        setShowPassword(!showPassword);
        break;

      case PAGE_CONSTANTS.CONFIRM_PASSWORD:
        setShowConfirmPassword(!showConfirmPassword);
        break;

      default:
        break;
    }
  };

  return (
    <PasswordStep
      loading={loading}
      showPassword={showPassword}
      showConfirmPassword={showConfirmPassword}
      passwordInfo={passwordInfo}
      error={error}
      onEyeClick={handleEyeClick}
      onAfterPasswordChange={handleAfterPasswordChange}
    />
  );
};

export default React.memo(PasswordStepContainer);
