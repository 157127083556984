import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared/Modal';
import { ModalControls } from 'components/ModalControls';
import { Typography } from 'components/shared/Typography';

import styles from './DeleteModal.styles.scss';

const DeleteModal = ({
  loading,
  title,
  subtitle,
  isOpen,
  onClose,
  onDeleteClick,
}) => {
  const { t, i18n } = useTranslation('deleteModal');

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <Typography
        className={styles.title}
        variant="h2"
        weight={i18n.language === 'ru' ? 'regular' : 'medium'}
      >
        {title}
      </Typography>
      <Typography className={styles.subtitle}>{subtitle}</Typography>
      <div className={styles.controls}>
        <ModalControls
          loading={loading}
          submitTitle={t('deleteButton')}
          onConfirmClick={onDeleteClick}
          onCancelClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default React.memo(DeleteModal);
