import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Tab.styles.scss';

const Tab = ({ className, active, label, onClick }) => (
  <div className={cn(styles.tab, className)} onClick={onClick}>
    <Typography
      variant="h3"
      className={cn(styles.title, { [styles.activeTitle]: active })}
    >
      {label}
    </Typography>
    {active && <div className={styles.underline} />}
  </div>
);

export default React.memo(Tab);
