import React from 'react';

import { Typography } from 'components/shared/Typography';
import { Checkbox } from 'components/shared/Checkbox';

import styles from './ItemSettings.styles.scss';

const ItemSettings = ({ params }) => (
  <div className={styles.settings}>
    {params.map((param) => (
      <div className={styles.filterItem}>
        <Typography>{param}</Typography>
        <Checkbox />
      </div>
    ))}
  </div>
);

export default React.memo(ItemSettings);
