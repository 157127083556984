import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Container, Draggable } from 'react-smooth-dnd';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import { PageLayout } from 'components/Layout';
import { CreatingInput } from 'components/CreatingInput';

import { List } from './components/List';

import styles from './BoardPage.styles.scss';

const BoardPage = ({
  loading,
  movingDisabledTo,
  movingDisabledFrom,
  boardFavorite,
  boardName,
  user,
  taskIdUrl,
  reserveTaskData,
  boardAdmin,
  lists,
  getCurrentList,
  getListPayload,
  setMovingTask,
  setMovingDisabledTo,
  setMovingDisabledFrom,
  checkListTitleUniq,
  onAnalyticsClick,
  onListRenamed,
  onListDeleted,
  onListDrop,
  onListCreate,
  onListCreated,
  onShareClick,
  onMembersClick,
  onSettingsClick,
  onFavoriteClick,
  onSaveReserveTaskData,
  onBackButtonClick,
}) => {
  const { t } = useTranslation('boardPage');

  return (
    <>
      {loading ? (
        <Loading className={styles.loading} size={30} />
      ) : (
        <PageLayout
          withActions
          backButton
          onBackButtonClick={onBackButtonClick}
          title={boardName}
          actions={
            <div className={styles.actions}>
              {boardAdmin && boardAdmin.id === user.id && (
                <Button
                  className={styles.button}
                  startIcon={
                    <Icon className={styles.shareIcon} name="persons" />
                  }
                  size="small"
                  onClick={onShareClick}
                >
                  {t('shareButton')}
                </Button>
              )}

              <div className={styles.analiticsAction}>
                <Icon
                  className={styles.analiticsIcon}
                  name="analyticsLine"
                  onClick={onAnalyticsClick}
                />
              </div>

              <div className={styles.moreActions}>
                <Icon
                  className={styles.moreActionsIcon}
                  name="person"
                  onClick={onMembersClick}
                />
                <Icon
                  className={cn(styles.moreActionsIcon, {
                    [styles.favoriteIcon]: boardFavorite,
                  })}
                  name={boardFavorite ? 'star' : 'emptyStar'}
                  onClick={onFavoriteClick}
                />
                <Icon
                  className={styles.moreActionsIcon}
                  name="settings"
                  onClick={onSettingsClick}
                />
              </div>
            </div>
          }
        >
          <ScrollWrapper noScrollY contentClassName={styles.scrollContent}>
            <div className={styles.inner}>
              {!!lists.length && (
                <Container
                  groupName="lists"
                  orientation="horizontal"
                  getChildPayload={getListPayload}
                  onDrop={onListDrop}
                  dropPlaceholder={{
                    className: styles.dropPlaceholder,
                    animationDuration: 150,
                    showOnTop: true,
                  }}
                >
                  {lists.map((list, index) => (
                    <Draggable key={list.id}>
                      <List
                        {...list}
                        reserveTaskData={reserveTaskData}
                        movingDisabledTo={movingDisabledTo}
                        movingDisabledFrom={movingDisabledFrom}
                        type={list.type}
                        tasks={list.tasks}
                        taskIdUrl={taskIdUrl}
                        checkListTitleUniq={checkListTitleUniq}
                        getCurrentList={getCurrentList}
                        setMovingTask={setMovingTask}
                        setMovingDisabledTo={setMovingDisabledTo}
                        setMovingDisabledFrom={setMovingDisabledFrom}
                        onListRenamed={onListRenamed}
                        onListDeleted={onListDeleted}
                        onSaveReserveTaskData={onSaveReserveTaskData}
                      />
                    </Draggable>
                  ))}
                </Container>
              )}

              <CreatingInput
                creatingClassName={styles.creatingClassName}
                fontWeight={'medium'}
                checkValueUniq={checkListTitleUniq}
                onItemCreate={onListCreate}
                onItemCreated={onListCreated}
                title={t('listPlaceholder')}
                errorMessage={t('uniqueError')}
              />
            </div>
          </ScrollWrapper>
        </PageLayout>
      )}
    </>
  );
};

export default React.memo(BoardPage);
