import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { FormControl } from 'components/shared/FormControl';
import { ErrorMessage } from 'components/shared/ErrorMessage';

import { ModalControls } from 'components/ModalControls';
import { InputField } from 'components/FormikFields';

import formConfig from './ChangeEmailModal.formConfig';

import styles from './ChangeEmailModal.styles.scss';

const ChangeEmailModal = ({
  isOpen,
  validationSchema,
  user,
  onClose,
  onConfirmClick,
}) => {
  const { t, i18n } = useTranslation('changeEmailModal');

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <Typography
        className={styles.title}
        variant="h2"
        weight={i18n.language === 'ru' ? 'regular' : 'medium'}
      >
        {t('title')}
      </Typography>
      <div className={styles.inner}>
        <FormControl label={t('currentEmailLabel')}>
          <Typography variant="main">{user.email}</Typography>
        </FormControl>
        <Formik
          {...formConfig}
          validationSchema={validationSchema}
          validateOnMount
          enableReinitialize
          onSubmit={onConfirmClick}
        >
          {({ errors }) => (
            <Form>
              <Field
                name="email"
                label={t('emailLabel')}
                placeholder={t('emailPlaceholder')}
                component={InputField}
              />
              {errors.form && (
                <ErrorMessage className={styles.error}>
                  {errors.form}
                </ErrorMessage>
              )}
              <ModalControls
                className={styles.controls}
                confirmButtonClassName={styles.confirmButton}
                submitType="submit"
                submitTitle={t('confirmButton')}
                onCancelClick={onClose}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default React.memo(ChangeEmailModal);
