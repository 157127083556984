import React from 'react';
import cn from 'classnames';

import { TextEditor } from 'components/shared/TextEditor';

import styles from './TextEditorField.styles.scss';

const TextEditorField = ({
  rootClassName,
  haveError,
  error,
  field,
  form,
  ...restProps
}) => {
  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.value);
  };

  return (
    <div className={cn(styles.root, rootClassName)}>
      <TextEditor
        {...field}
        {...restProps}
        haveError={haveError}
        onChange={handleChange}
      />
    </div>
  );
};

export default React.memo(TextEditorField);
