import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';
import { useTranslation } from 'react-i18next';

import { convertDate } from 'helpers/convertDate';
import { getTagColor } from 'helpers/getTagColor';
import { calculateProgress } from 'helpers/calculateProgress';

import { replaceMonthFormat } from './TaskCard.helpers';

import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';

import { TypePopover } from '../../../TypePopover';
import { POPOVER_TYPES } from '../TaskModal/TaskModal.constants';

import styles from './TaskCard.styles.scss';

const TaskCard = ({
  currentPopover,
  togglePopover,
  closePopover,
  loading,
  taskIndex,
  listLength,
  memberList = [],
  boardId,
  tagList,
  checklists,
  dueDate,
  description,
  title,
  type,
  taskTypes,
  commentCount,
  attachmentCount,
  coverPreview,
  onClick,
  onTypeCardClick,
  onAddTaskTypeClick,
  onTypesSettingsCardClick,
}) => {
  const { t, i18n } = useTranslation('boardPage');
  const language = i18n.language;

  // Handle popover toggle and close
  const handlePopoverToggle = useCallback(() => {
    togglePopover(POPOVER_TYPES.TYPE_CARD);
  }, [togglePopover]);

  const handlePopoverClose = useCallback(() => {
    closePopover(POPOVER_TYPES.TYPE_CARD);
  }, [closePopover]);

  const handleTypeCardClick = useCallback(
    (event) => {
      onTypeCardClick(event);
    },
    [onTypeCardClick],
  );

  const handleAddTaskTypeClick = useCallback(() => {
    onAddTaskTypeClick();
  }, [onAddTaskTypeClick]);

  const handleTypesSettingsCardClick = useCallback(() => {
    onTypesSettingsCardClick();
  }, [onTypesSettingsCardClick]);

  const cardClasses = useMemo(
    () =>
      cn(styles.card, {
        [styles.lastCard]: taskIndex === listLength - 1,
      }),
    [taskIndex, listLength],
  );

  const innerClasses = useMemo(
    () =>
      cn(styles.inner, {
        [styles.innerPreview]: !!coverPreview || type,
      }),
    [coverPreview, type],
  );

  const tagsContent = useMemo(
    () =>
      tagList.map((tag) => (
        <div
          key={tag.id}
          style={{ backgroundColor: getTagColor(tag.color) }}
          className={styles.tag}
        >
          <Typography className={styles.tagName} variant="subtitle">
            {tag.name}
          </Typography>
        </div>
      )),
    [tagList],
  );

  const checklistsContent = useMemo(
    () =>
      checklists.map((checklist) => {
        const progress = calculateProgress(checklist.itemList);
        return (
          <div key={checklist.id} className={styles.checkList}>
            <div className={styles.checkListHeader}>
              <div className={styles.checkListMain}>
                <Icon name="checkList" className={styles.checkListIcon} />
                <Typography variant="subtitle" className={styles.checkListName}>
                  {checklist.name}
                </Typography>
              </div>
              <div className={styles.checkListInfo}>
                <Typography variant="subtitle" className={styles.checkListText}>
                  {
                    checklist.itemList.filter(
                      (item) => item.completeItem.completed,
                    ).length
                  }
                  /{checklist.itemList.length}
                </Typography>
                <Typography variant="subtitle" className={styles.checkListText}>
                  {progress} %
                </Typography>
              </div>
            </div>
            <LinearProgressBar
              className={styles.checklistProgressBar}
              progress={progress}
            />
          </div>
        );
      }),
    [checklists],
  );

  const membersContent = useMemo(
    () => (
      <Tooltip
        trigger="mouseenter"
        position="top"
        html={
          <div className={styles.tippy}>
            {memberList.map((member, index) => (
              <Typography
                key={member.id}
                className={styles.tippyText}
                variant="subtitle"
              >
                {member.fullName}
                {index !== memberList.length - 1
                  ? index !== memberList.length - 2
                    ? ','
                    : ` ${t('and')}`
                  : ` ${t(
                      memberList.length === 1
                        ? 'assignedMember'
                        : 'assignedMembers',
                    )}`}
              </Typography>
            ))}
          </div>
        }
        placement="bottom"
      >
        <span className={styles.infoPerson}>
          <Icon className={styles.personIcon} name="person" />
          <Typography className={styles.personText} variant="subtitle">
            {memberList.length}
          </Typography>
        </span>
      </Tooltip>
    ),
    [memberList, t],
  );

  return (
    <div className={cardClasses} onClick={onClick}>
      {coverPreview && (
        <div className={styles.previewWrapper}>
          {loading ? (
            <Loading size={17} />
          ) : (
            <img
              className={styles.preview}
              src={coverPreview}
              alt="Cover Preview"
            />
          )}
        </div>
      )}

      <TypePopover
        open={currentPopover === POPOVER_TYPES.TYPE_CARD}
        boardId={boardId}
        taskTypes={taskTypes}
        taskType={type}
        togglePopover={handlePopoverToggle}
        onAddTaskTypeClick={handleAddTaskTypeClick}
        onRequestClose={handlePopoverClose}
        onSettingsClick={handleTypesSettingsCardClick}
      >
        <div
          className={cn(styles.typePlaceholder, {
            [styles.activeTypePlaceholder]:
              !type && currentPopover === POPOVER_TYPES.TYPE_CARD,
            [styles.typeLabelUpper]: !coverPreview,
            [styles.hoverTypePlaceholder]: type,
          })}
          style={type ? { backgroundColor: type.color } : undefined}
          onClick={handleTypeCardClick}
        />
      </TypePopover>

      <div className={innerClasses}>
        <Typography className={styles.title}>{title}</Typography>

        {tagList.length > 0 && (
          <div
            className={cn(styles.tags, {
              [styles.tagsWihInfo]:
                description || memberList.length || commentCount || dueDate,
            })}
          >
            {tagsContent}
          </div>
        )}

        {checklists.length > 0 && (
          <div className={styles.checkLists}>{checklistsContent}</div>
        )}

        <div className={styles.main}>
          <div className={styles.cardInfoPanel}>
            {description && (
              <div className={styles.infoItem}>
                <div className={styles.description}>
                  <Icon className={styles.descriptionIcon} name="description" />
                </div>
              </div>
            )}
            {memberList.length > 0 && (
              <div className={styles.infoItem}>{membersContent}</div>
            )}
            {(commentCount > 0 || attachmentCount > 0) && (
              <div className={styles.info}>
                {commentCount > 0 && (
                  <div className={styles.infoItem}>
                    <Icon className={styles.infoIcon} name="chat" />
                    <Typography className={styles.infoText} variant="subtitle">
                      {commentCount}
                    </Typography>
                  </div>
                )}
                {attachmentCount > 0 && (
                  <div className={styles.infoItem}>
                    <Icon className={styles.infoIcon} name="paperClip" />
                    <Typography className={styles.infoText} variant="subtitle">
                      {attachmentCount}
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>

          {dueDate && (
            <div className={styles.dueDate}>
              <Icon className={styles.dueDateIcon} name="calendar" />
              <Typography className={styles.dueDateText} variant="subtitle">
                {replaceMonthFormat(
                  convertDate(dueDate, 'dd MMM', language),
                  language,
                )}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TaskCard);
