import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as api from 'api/methods';

import { systemMetaSelector } from 'store/system/selectors';

import { showToastMessage } from 'helpers/showToastMessage';
import { checkPasswordValue } from 'helpers/checkPasswordValue';

import { userSelector } from 'store/auth/selectors';

import { useModal } from 'hooks/useModal';

import { CodeStep } from 'components/CodeStep';

import { getValidationSchema } from './ChangePasswordModal.formConfig';

import ChangePasswordModal from './ChangePasswordModal';

const ChangePasswordModalContainer = ({ onClose, ...props }) => {
  const { t: t_toast } = useTranslation('toast');
  const { t: t_password } = useTranslation('password');
  const { t: t_validation } = useTranslation('validation');

  const user = useSelector(userSelector);
  const meta = useSelector(systemMetaSelector);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordInfo, setPasswordInfo] = useState('');

  const [
    codeStepModalOpen,
    openCodeStepModal,
    closeCodeStepModal,
    codeStepModalData,
  ] = useModal({
    defaultOpen: false,
  });

  const verifyRequest = (code, data) => api.verifyPassword(code, data);
  const changeRequest = (data) => api.changePassword(data);

  const handleEyeClick = (name) => {
    switch (name) {
      case 'password': {
        setShowPassword(!showPassword);

        break;
      }

      case 'confirmPassword': {
        setShowConfirmPassword(!showConfirmPassword);

        break;
      }
    }
  };

  const handleConfirmClick = (values) => {
    setLoading(true);

    const data = {
      confirmNewPassword: values.confirmPassword,
      newPassword: values.password,
    };

    api
      .changePassword(data)
      .then(() => {
        setLoading(false);

        onClose();

        openCodeStepModal({
          changeRequestData: data,
          verifyRequestData: { email: user.email },
        });
      })
      .catch(({ status }) => {
        showToastMessage({ variant: 'error', status, t: t_toast });

        setLoading(false);
      });
  };

  const handleAfterPasswordChange = (event) => {
    setPasswordInfo(
      checkPasswordValue({
        value: event.target.value,
        config: passwordPattern,
        t: t_password,
      }),
    );
  };

  const passwordPattern = meta ? meta.passwordPattern : '';

  const validationSchema =
    meta && getValidationSchema(passwordPattern, t_validation);

  return (
    <>
      <ChangePasswordModal
        {...props}
        showConfirmPassword={showConfirmPassword}
        showPassword={showPassword}
        validationSchema={validationSchema}
        user={user}
        passwordInfo={passwordInfo}
        onClose={onClose}
        onEyeClick={handleEyeClick}
        onConfirmClick={handleConfirmClick}
        onAfterPasswordChange={handleAfterPasswordChange}
      />
      <CodeStep
        {...codeStepModalData}
        email={user.email}
        loading={loading}
        verifyRequest={verifyRequest}
        changeRequest={changeRequest}
        variant="password"
        isOpen={codeStepModalOpen}
        onClose={closeCodeStepModal}
      />
    </>
  );
};

export default React.memo(ChangePasswordModalContainer);
