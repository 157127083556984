import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { UserMenu } from '../UserMenu';

import styles from './Header.styles.scss';

const Header = ({ className }) => {
  const { t } = useTranslation('boardsPage');

  return (
    <div className={cn(styles.header, className)}>
      <div className={styles.side}>
        <NavLink className={styles.logoWrapper} to="/boards">
          <Typography className={styles.logoText} variant="body">
            X-CORE
          </Typography>
        </NavLink>
        <div className={styles.menu}>
          <Tooltip
            trigger="mouseenter"
            position="bottom"
            html={
              <div className={styles.tippy}>
                <Typography className={styles.tippyText} variant="subtitle">
                  {t('boardPopover')}
                </Typography>
              </div>
            }
            placement="bottom"
          >
            <span>
              <NavLink
                to="/boards"
                className={styles.menuItem}
                activeClassName={styles.menuItemActive}
              >
                <Icon name="boards" className={styles.menuItemIcon} />
              </NavLink>
            </span>
          </Tooltip>
          <Tooltip
            trigger="mouseenter"
            position="bottom"
            html={
              <div className={styles.tippy}>
                <Typography className={styles.tippyText} variant="subtitle">
                  {t('dashboardPopover')}
                </Typography>
              </div>
            }
            placement="bottom"
          >
            <span>
              <NavLink
                to="/dashboard"
                className={styles.menuItem}
                activeClassName={styles.menuItemActive}
              >
                <Icon name="dashboard" className={styles.menuItemIcon} />
              </NavLink>
            </span>
          </Tooltip>
          <Tooltip
            trigger="mouseenter"
            position="bottom"
            html={
              <div className={styles.tippy}>
                <Typography className={styles.tippyText} variant="subtitle">
                  {t('dashboardPopover')}
                </Typography>
              </div>
            }
            placement="bottom"
          >
            <span>
              <NavLink
                to="/info"
                className={styles.menuItem}
                activeClassName={styles.menuItemActive}
              >
                <Icon name="dashboard" className={styles.menuItemIcon} />
              </NavLink>
            </span>
          </Tooltip>
        </div>
      </div>
      <div className={styles.inner}>
        <UserMenu />
      </div>
    </div>
  );
};

export default React.memo(Header);
