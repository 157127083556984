import React, { useState, useRef, createRef, useEffect } from 'react';

import { AUTH_CODE_LENGTH } from 'constants/common';

import CodeInput from './CodeInput';

const CodeInputContainer = ({ onChange, resetTrigger, ...restProps }) => {
  const numbersRef = useRef(
    Array.from(Array(AUTH_CODE_LENGTH)).map(() => createRef('')),
  );

  const [value, setValue] = useState(
    Array.from(Array(AUTH_CODE_LENGTH)).map(() => ''),
  );

  useEffect(() => {
    setValue(Array.from(Array(AUTH_CODE_LENGTH)).map(() => ''));
    numbersRef.current[AUTH_CODE_LENGTH - 1].current.blur();
  }, [resetTrigger]);

  let numberValue;

  const handleChange = (event, index) => {
    if (event.target.value === ' ') {
      return;
    }

    numberValue = event.target.value.toUpperCase();
    const codeArray = numberValue.split('');

    if (index === 0 && numberValue.length === AUTH_CODE_LENGTH) {
      setValue(codeArray);
      numbersRef.current[AUTH_CODE_LENGTH - 1].current.focus();
      onChange(codeArray);
      return;
    }

    if (numberValue.length > 1 && index + 1 === AUTH_CODE_LENGTH) {
      return;
    }

    if (numberValue === '') {
      if (index > 0) {
        numbersRef.current[index - 1].current.focus();
      }

      const nextValue = [...value];

      nextValue[index] = numberValue;

      setValue(nextValue);

      if (onChange) {
        onChange(nextValue);
      }

      return;
    }

    if (numberValue.length > 1) {
      numbersRef.current[index + 1].current.focus();

      const nextValue = [...value];

      nextValue[index + 1] = numberValue[numberValue.length - 1];

      setValue(nextValue);

      if (onChange) {
        onChange(nextValue);
      }

      return;
    }

    if (index + 1 < AUTH_CODE_LENGTH) {
      numbersRef.current[index + 1].current.focus();
    }

    const nextValue = [...value];

    nextValue[index] = numberValue;

    setValue(nextValue);

    if (index + 1 === AUTH_CODE_LENGTH) {
      numbersRef.current[index].current.blur();
    }

    if (onChange) {
      onChange(nextValue);
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      if (index > 0 && !event.target.value) {
        event.stopPropagation();
        event.preventDefault();

        numbersRef.current[index - 1].current.focus();
      }
    }
  };

  return (
    <CodeInput
      {...restProps}
      numbersRef={numbersRef}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default React.memo(CodeInputContainer);
