import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import { SLIDE_TYPES } from './TypesSettingsModal.constants';

import { sliderSettings } from './TypesSettingsModal.config';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import { Type } from './components/Type';
import { EditType } from './components/EditType';
import { AddNewType } from './components/AddNewType';

import styles from './TypesSettingsModal.styles.scss';

const TypesSettingsModal = ({
  isOpen,
  showSettings,
  sliderRef,
  slide,
  popoverType,
  boardId,
  editingType,
  taskTypes,
  onAddNewTypeClick,
  onEditTypeClick,
  onOpenTypePopover,
  onPrevSlideClick,
  onTaskTypeEdited,
  onDeleteTypeClick,
  onClose,
}) => {
  const { t, i18n } = useTranslation('typePopover');

  return (
    <Modal className={styles.modal} withClose isOpen={isOpen} onClose={onClose}>
      <Slider {...sliderSettings} ref={sliderRef}>
        {showSettings && (
          <div className={styles.modalInner}>
            <Typography
              className={styles.title}
              variant="h2"
              weight={i18n.language === 'ru' ? 'regular' : 'medium'}
            >
              {t('settingsTitle')}
            </Typography>
            <ScrollWrapper
              className={styles.scrollWrapper}
              trackClassName={styles.track}
            >
              {taskTypes.length ? (
                taskTypes.map((type) => (
                  <Type
                    withDelete
                    key={type.id}
                    type={type}
                    onTypeClick={() => onEditTypeClick(type.id)}
                    onDeleteClick={onDeleteTypeClick}
                  />
                ))
              ) : (
                <Typography className={styles.typeSubtitle} variant="body">
                  {t('noTypesSubtitle')}
                </Typography>
              )}
            </ScrollWrapper>
            <div className={styles.controls}>
              <Button className={styles.button} onClick={onAddNewTypeClick}>
                {t('addNewType')}
              </Button>
            </div>
          </div>
        )}
        <div className={styles.modalInner}>
          {slide === SLIDE_TYPES.ADD_NEW_TYPE && (
            <AddNewType
              boardId={boardId}
              popoverType={popoverType}
              taskTypes={taskTypes}
              onOpenTypePopover={onOpenTypePopover}
              onClose={onPrevSlideClick}
            />
          )}

          {slide === SLIDE_TYPES.EDIT_TYPE && (
            <EditType
              boardId={boardId}
              popoverType={popoverType}
              editingType={editingType}
              taskTypes={taskTypes}
              onOpenTypePopover={onOpenTypePopover}
              onTaskTypeEdited={onTaskTypeEdited}
              onClose={onPrevSlideClick}
            />
          )}
        </div>
      </Slider>
    </Modal>
  );
};

export default React.memo(TypesSettingsModal);
