import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './ShareModal.styles.scss';

const ShareModal = ({ loading, link, isOpen, onClose, onShowToastClick }) => {
  const { t, i18n } = useTranslation('shareModal');

  return (
    <Modal className={styles.modal} withClose isOpen={isOpen} onClose={onClose}>
      <Typography
        className={styles.title}
        variant="h2"
        weight={i18n.language === 'ru' ? 'regular' : 'medium'}
      >
        {t('title')}
      </Typography>
      <Typography className={styles.subtitle}>{t('subtitle')}</Typography>
      <CopyToClipboard text={link}>
        <div className={styles.controls}>
          <Button
            className={styles.copyButton}
            loading={loading}
            onClick={onShowToastClick}
          >
            {t('copyButton')}
          </Button>
          <Button
            className={styles.cancelButton}
            variant="outline"
            color="alert"
            onClick={onClose}
          >
            {t('cancelButton')}
          </Button>
        </div>
      </CopyToClipboard>
    </Modal>
  );
};

export default React.memo(ShareModal);
