import * as Yup from 'yup';

export const getValidationSchema = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t('requiredTitleError')),
  });

const initialValues = {
  name: '',
};

export default {
  initialValues,
};
