import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'react-tippy';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import styles from './WelcomePopover.styles.scss';

const WelcomePopover = ({ children, isOpen, onClose }) => {
  const { t } = useTranslation('welcomePopover');

  return (
    <Tooltip
      interactive
      open={isOpen}
      onRequestClose={onClose}
      position="bottom-end"
      distance="45"
      html={
        <div className={styles.popover}>
          <Icon className={styles.closeIcon} name="close" onClick={onClose} />
          <Icon className={styles.icon} name="firstBoard" />
          <Typography variant="h4" weight="medium" className={styles.title}>
            {t('title')}
          </Typography>
          <Typography variant="body" className={styles.text}>
            {t('text')}
          </Typography>
        </div>
      }
    >
      <span className={styles.content}>{children}</span>
    </Tooltip>
  );
};

export default React.memo(WelcomePopover);
