import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import { preloadComponent } from 'helpers/preloadComponent';

import { useSocket } from 'hooks/useSocket';
import { withOnlyDesktopView } from 'hoc/withOnlyDesktopView';

import { AuthRoute } from 'components/AuthRoute';
import { PrivateRoute } from 'components/PrivateRoute';
import { SessionProvider } from 'components/SessionProvider';
import { Loading } from 'components/shared/Loading';
import { PolicyPage } from 'components/pages/PolicyPage';
import { RedirectPage } from 'components/pages/RedirectPage';
import { NotFoundPage } from 'components/pages/NotFoundPage';
import { TimelinePage } from 'components/pages/TimelinePage';
import { AnalyticsPage } from 'components/pages/AnalyticsPage';
import { RestrictedPage } from 'components/pages/RestrictedPage';
import { UnsubscribePage } from 'components/pages/UnsubscribePage';
import { AcceptInvitePage } from 'components/pages/AcceptInvitePage';

// loadable DEV
import { StartPage } from 'components/pages/StartPage';
import { RegisterPage } from 'components/pages/RegisterPage';
import { LoginPage } from 'components/pages/LoginPage';
import { ResetPasswordPage } from 'components/pages/ResetPasswordPage';
import { DashboardPage } from 'components/pages/DashboardPage';
import { InfoPage } from 'components/pages/InfoPage';
import { BoardsPage } from 'components/pages/BoardsPage';
import { BoardPage } from 'components/pages/BoardPage';
import { ExpiredLinkPage } from 'components/pages/ExpiredLinkPage';

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    preloadComponent(RegisterPage);
    preloadComponent(LoginPage);
    preloadComponent(ResetPasswordPage);

    if (navigator.language.indexOf('en') > -1 && i18n.language !== 'en') {
      i18n.changeLanguage('en');
    }

    if (navigator.language.indexOf('ru') > -1 && i18n.language !== 'ru') {
      i18n.changeLanguage('ru');
    }
  }, []);

  useSocket();

  return (
    <Suspense fallback={<Loading />}>
      <SessionProvider>
        <Switch>
          <Route exact path="/" component={StartPage} />

          <AuthRoute
            exact
            path={ROUTES.LOGIN}
            component={withOnlyDesktopView(LoginPage)}
          />
          <AuthRoute
            exact
            path={ROUTES.REGISTER}
            component={withOnlyDesktopView(RegisterPage)}
          />
          <AuthRoute
            exact
            path={ROUTES.RESET_PASSWORD}
            component={withOnlyDesktopView(ResetPasswordPage)}
          />

          <AuthRoute
            exact
            path={ROUTES.UNSUBSCRIBE_PAGE}
            component={withOnlyDesktopView(UnsubscribePage)}
          />

          <AuthRoute
            exact
            path={ROUTES.EXPIRED_LINK}
            component={withOnlyDesktopView(ExpiredLinkPage)}
          />

          <PrivateRoute
            exact
            path={ROUTES.BOARDS}
            component={withOnlyDesktopView(BoardsPage)}
          />
          <PrivateRoute
            exact
            path={ROUTES.BOARD}
            component={withOnlyDesktopView(BoardPage)}
          />
          <PrivateRoute
            exact
            path={ROUTES.BOARDTASK}
            component={withOnlyDesktopView(BoardPage)}
          />
          <PrivateRoute
            exact
            path={ROUTES.DASHBOARD}
            component={withOnlyDesktopView(DashboardPage)}
          />
          <PrivateRoute
            exact
            path={ROUTES.INFO}
            component={withOnlyDesktopView(InfoPage)}
          />
          <PrivateRoute
            exact
            path={ROUTES.ANALYTICS}
            component={withOnlyDesktopView(AnalyticsPage)}
          />
          <PrivateRoute
            exact
            path={ROUTES.TIMELINE}
            component={withOnlyDesktopView(TimelinePage)}
          />
          <PrivateRoute
            exact
            path={ROUTES.ACCEPT_INVITE}
            component={withOnlyDesktopView(AcceptInvitePage)}
          />
          <AuthRoute
            exact
            path={ROUTES.REDIRECT}
            component={withOnlyDesktopView(RedirectPage)}
          />

          <Route exact path={ROUTES.POLICY} component={PolicyPage} />
          <Route exact path={ROUTES.RESTRICTED} component={RestrictedPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>

        <ToastContainer hideProgressBar closeOnClick newestOnTop={false} />
      </SessionProvider>
    </Suspense>
  );
};

export default React.memo(App);
