import React, { useState, useRef } from 'react';

import * as api from 'api/methods';

import CheckListPopover from './CheckListPopover';

const CheckListPopoverContainer = ({
  boardId,
  taskId,
  onRequestClose,
  onCheckListCreated,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);

  const resetFormRef = useRef();

  const handleRequestClose = () => {
    resetFormRef.current.resetForm();

    onRequestClose();
  };

  const handleSaveClick = (values, { resetForm }) => {
    setLoading(true);

    api
      .createCheckList({ boardId, taskId, data: { name: values.name } })
      .then((checkList) => {
        setLoading(false);
        onRequestClose();

        resetForm();

        if (onCheckListCreated) {
          onCheckListCreated(checkList);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <CheckListPopover
      {...restProps}
      loading={loading}
      resetFormRef={resetFormRef}
      onRequestClose={handleRequestClose}
      onSaveClick={handleSaveClick}
    />
  );
};

export default React.memo(CheckListPopoverContainer);
