import React, { useEffect } from 'react';

import * as api from 'api/methods';

import { useModal } from 'hooks/useModal';

import { AttachmentModal } from '../AttachmentModal';

import {
  useUpload,
  useUploadAttachmentPreviews,
} from './AttachmentsSection.hooks';

import AttachmentsSection from './AttachmentsSection';

const AttachmentsSectionContainer = ({
  attachments,
  boardId,
  taskId,
  uploadingAttachment,
  onUploadCancelled,
  onUploadFinished,
  onUploadError,
  onAttachmentRemoved,
  onAttachmentCoverUpdated,
  ...restProps
}) => {
  const [
    attachmentModalOpen,
    openAttachmentModal,
    closeAttachmentModal,
    attachmentModalData,
  ] = useModal({ defaultOpen: false });

  const [percentage, onUploadStart, onUploadCancel] = useUpload({
    boardId,
    taskId,
    onError: onUploadError,
    onUploaded: onUploadFinished,
  });

  const [attachmentPreviews, setAttachmentPreviews] =
    useUploadAttachmentPreviews({
      attachments,
      boardId,
      taskId,
    });

  useEffect(() => {
    if (uploadingAttachment) {
      onUploadStart(uploadingAttachment);
    }
  }, [uploadingAttachment]);

  const handleCancelUploadClick = () => {
    onUploadCancel();

    if (onUploadCancelled) {
      onUploadCancelled();
    }
  };

  const handleAttachmentActionClick = (action, attachment) => {
    switch (action.key) {
      case 'save': {
        api
          .getAttachment({ boardId, taskId, attachmentId: attachment.id })
          .then((attachmentBlob) => {
            return new Promise((resolve, reject) => {
              const reader = new window.FileReader();
              reader.readAsDataURL(attachmentBlob);

              reader.onload = function () {
                resolve(reader.result);
              };
            });
          })
          .then((attachmentFile) => {
            const linkElememt = document.createElement('a');

            linkElememt.href = attachmentFile;
            linkElememt.download = attachment.name;

            document.body.appendChild(linkElememt);

            linkElememt.click();

            document.body.removeChild(linkElememt);
          })
          .catch(() => {});

        break;
      }
      case 'cover': {
        if (attachment.isCover) {
          api
            .removeTaskCover({ boardId, taskId, attachmentId: attachment.id })
            .then((updatedAttachment) => {
              if (onAttachmentCoverUpdated) {
                onAttachmentCoverUpdated(updatedAttachment);
              }
            })
            .catch(() => {});
        } else {
          api
            .setTaskCover({ boardId, taskId, attachmentId: attachment.id })
            .then((updatedAttachment) => {
              if (onAttachmentCoverUpdated) {
                onAttachmentCoverUpdated(updatedAttachment);
              }
            })
            .catch(() => {});
        }

        break;
      }
      case 'delete': {
        api
          .removeAttachment({ boardId, taskId, attachmentId: attachment.id })
          .then(({ taskVersion }) => {
            if (onAttachmentRemoved) {
              onAttachmentRemoved(attachment.id, taskVersion);
            }

            if (attachmentModalOpen) {
              closeAttachmentModal();
            }

            setAttachmentPreviews((prevPreviews) => {
              const nextPreviews = { ...prevPreviews };

              delete nextPreviews[attachment.id];

              return nextPreviews;
            });
          })
          .catch(() => {});
        break;
      }
    }
  };

  const handleAttachmentClick = (attachment) => {
    openAttachmentModal({
      boardId,
      taskId,
      attachment,
      onAttachmentActionClick: handleAttachmentClick,
    });
  };

  return (
    <>
      <AttachmentsSection
        {...restProps}
        attachments={attachments}
        attachmentPreviews={attachmentPreviews}
        uploadingAttachment={uploadingAttachment}
        percentage={percentage}
        onCancelUploadClick={handleCancelUploadClick}
        onAttachmentClick={handleAttachmentClick}
        onAttachmentActionClick={handleAttachmentActionClick}
      />
      <AttachmentModal
        {...attachmentModalData}
        isOpen={attachmentModalOpen}
        onClose={closeAttachmentModal}
        onAttachmentActionClick={handleAttachmentActionClick}
      />
    </>
  );
};

export default React.memo(AttachmentsSectionContainer);
