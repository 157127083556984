import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './Info.styles.scss';

const Info = ({ part, text, image: ImageComponent }) => (
  <div className={styles.info}>
    <div className={styles.inner}>
      <div className={styles.partWrapper}>
        <Typography className={styles.part} variant="body" weight="medium">
          {part}
        </Typography>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
    <ImageComponent className={styles.image} />
  </div>
);

export default React.memo(Info);
