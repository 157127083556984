import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import { showToastMessage } from 'helpers/showToastMessage';

import { setFormError } from './MoveTaskModal.helpers';

import MoveTaskModal from './MoveTaskModal';

const MoveTaskModalContainer = ({
  isOpen,
  boardId,
  listId,
  orderNumber,
  taskId,
  taskVersion,
  onTaskMoved,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation('moveTasksModal');
  const { t: t_toast } = useTranslation('toast');

  const [loading, setLoading] = useState(false);

  const { data: lists } = useFetch(
    {
      defaultData: [],
      fetcher: api.getBoardList,
      immediately: false,
      stopRequest: !isOpen,
    },
    boardId,
    [isOpen],
  );

  const handleSubmit = (values, actions) => {
    setLoading(true);

    const data = {
      fromColumnId: listId,
      orderNumber: orderNumber,
      toColumnId: values.list.value,
      version: taskVersion,
    };

    api
      .moveTask(boardId, taskId, data)
      .then((response) => {
        api
          .getTask({ boardId, listId: values.list.value, taskId })
          .then((taskResponse) => {
            setLoading(false);

            showToastMessage({
              variant: 'success',
              title: t_toast('taskMoved'),
            });

            onClose();

            if (onTaskMoved) {
              onTaskMoved({
                fromListId: listId,
                toListId: values.list.value,
                nextTask: taskResponse,
              });
            }
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(({ status }) => {
        setLoading(false);

        setFormError({ status, setFieldError: actions.setFieldError, t });
      });
  };

  const filteredLists = lists.filter((list) => list.id !== listId);

  const options = filteredLists.map((list) => {
    return {
      label: list.name,
      value: list.id,
    };
  });

  return (
    <MoveTaskModal
      loading={loading}
      isOpen={isOpen}
      options={options}
      onClose={onClose}
      onSubmit={handleSubmit}
      {...restProps}
    />
  );
};

export default React.memo(MoveTaskModalContainer);
