import React from 'react';
import { getIn } from 'formik';

import { Input } from 'components/shared/Input';
import { FormControl } from 'components/shared/FormControl';

const InputField = ({
  classNames = {},
  label,
  field,
  form,
  formControlProps = {},
  onBlur,
  ...restProps
}) => {
  const error = getIn(form.errors, field.name);
  const touched = form.touched[field.name];

  const hasError = touched && !!error;

  const handleBlur = (event) => {
    field.onBlur(event);
    onBlur?.(event, field.value);
  };

  return (
    <FormControl
      className={classNames.formControl}
      {...formControlProps}
      label={label}
      error={error}
      touched={touched}
    >
      <Input
        {...field}
        {...restProps}
        variant={hasError ? 'error' : 'default'}
        onBlur={handleBlur}
      />
    </FormControl>
  );
};

export default React.memo(InputField);
