import React from 'react';
import ReactCollapsible from 'react-collapsible';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './Collapsible.styles.scss';

const Collapsible = ({
  className,
  iconClassName,
  withArrow = false,
  headerComponent,
  innerComponent,
  iconSize = 22,
  onOpen,
  onClose,
  open = false,
  transitionTime = 0,
  ...restProps
}) => (
  <ReactCollapsible
    trigger={
      <div className={cn(styles.header, className)}>
        {headerComponent}
        {withArrow && (
          <Icon
            name="arrowDown"
            className={cn(styles.icon, iconClassName)}
            style={{ width: iconSize, height: iconSize }}
          />
        )}
      </div>
    }
    onOpen={onOpen}
    onClose={onClose}
    openedClassName={styles.expanded}
    open={open}
    transitionTime={transitionTime}
    {...restProps}
  >
    {innerComponent}
  </ReactCollapsible>
);

export default React.memo(Collapsible);
