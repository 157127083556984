import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useBoard } from 'components/pages/BoardPage/components/BoardPageContext/BoardPage.context';

import { BOARD_PROVIDER_TYPES } from 'components/pages/BoardPage/components/BoardPageContext/BoardPageProvider.constants';

import * as api from 'api/methods';

import { convertDataToApi } from './EditType.helpers';
import { showToastMessage } from 'helpers/showToastMessage';

import EditType from './EditType';

const EditTypeContainer = ({
  boardId,
  editingType,
  taskTypes,
  popoverType,
  onClose,
  onTaskTypeEdited,
  onOpenTypePopover,
  ...restProps
}) => {
  const { t } = useTranslation('toast');

  const [loading, setLoading] = useState(false);

  const { dispatch } = useBoard();

  const handleSubmit = (values) => {
    const data = convertDataToApi(values);

    if (data.color === editingType.color && data.name === editingType.name) {
      onClose();

      return;
    }

    setLoading(true);

    api
      .updateType({ boardId, typeId: editingType.id, data })
      .then((updatedTaskType) => {
        setLoading(false);

        if (onTaskTypeEdited) {
          onTaskTypeEdited(updatedTaskType);
        }

        dispatch({
          type: BOARD_PROVIDER_TYPES.TASKS_TYPE_EDITED,
          payload: { updatedType: updatedTaskType },
        });

        onClose();

        if (onOpenTypePopover) {
          onOpenTypePopover(popoverType);
        }
      })
      .catch(({ status }) => {
        setLoading(false);

        showToastMessage({ variant: 'error', status, t });
      });
  };

  return (
    <EditType
      {...restProps}
      loading={loading}
      editingType={editingType}
      taskTypes={taskTypes}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default React.memo(EditTypeContainer);
