import React from 'react';
import cn from 'classnames';

import { Input } from 'components/shared/Input';
import { Icon } from 'components/shared/Icon';

import styles from './SearchInput.styles.scss';

const SearchInput = ({ placeholder, className }) => (
  <div className={cn(styles.search, className)}>
    <Input
      placeholder={placeholder}
      className={styles.inputWrapper}
      inputClassName={styles.input}
      startAdornment={<Icon name="search" className={styles.icon} />}
    />
  </div>
);

export default React.memo(SearchInput);
