export const TYPES_COLORS = [
  {
    color: '#EF8D6B',
    activeColor: '#F9CFC1',
    value: '#EF8D6B',
  },
  {
    color: '#9EB5EE',
    activeColor: '#C0CFF4',
    value: '#9EB5EE',
  },
  {
    color: '#F09999',
    activeColor: '#F5BDBD',
    value: '#F09999',
  },
  {
    color: '#C78DE2',
    activeColor: '#DBB5EC',
    value: '#C78DE2',
  },
  {
    color: '#FFE1A6',
    activeColor: '#FFEBC5',
    value: '#FFE1A6',
  },
  {
    color: '#85E4DF',
    activeColor: '#B0EEEA',
    value: '#85E4DF',
  },
  {
    color: '#6A49B0',
    activeColor: '#9E89CC',
    value: '#6A49B0',
  },
  {
    color: '#409686',
    activeColor: '#83BBB1',
    value: '#409686',
  },
  {
    color: '#607EE7',
    activeColor: '#98ABEF',
    value: '#607EE7',
  },
  {
    color: '#CD4D4D',
    activeColor: '#DE8B8B',
    value: '#CD4D4D',
  },
  {
    color: '#6A6A6A',
    activeColor: '#9E9E9E',
    value: '#6A6A6A',
  },
  {
    color: '#E195AF',
    activeColor: '#ECBACB',
    value: '#E195AF',
  },
  {
    color: '#79C68B',
    activeColor: '#A8DAB3',
    value: '#79C68B',
  },
  {
    color: '#B199F0',
    activeColor: '#CCBDF5',
    value: '#B199F0',
  },
];
