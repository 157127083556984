import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'components/shared/Avatar';
import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Member.styles.scss';

const Member = ({
  canRemove,
  active,
  underline,
  deleted,
  className,
  id: memberId,
  fullName,
  avatarColor,
  email,
  onDeleteMemberClick,
}) => {
  const { t } = useTranslation('memberModal');

  return (
    <div
      className={cn(
        styles.member,
        { [styles.highlightMemberActive]: active },
        { [styles.underlineMemberActive]: underline },
        className,
      )}
    >
      <div className={styles.inner}>
        <Avatar
          variant="medium"
          fullName={fullName}
          color={deleted ? 'grey' : avatarColor}
        />
        <div className={styles.info}>
          <Typography
            className={cn(styles.name, { [styles.deletedName]: deleted })}
            variant="body"
          >
            {fullName}
          </Typography>
          <Typography className={styles.email} variant="subtitle">
            {deleted ? t('deletedUser') : email}
          </Typography>
        </div>
      </div>
      {!deleted && canRemove && (
        <div
          className={styles.iconWrapper}
          onClick={() => onDeleteMemberClick(memberId)}
        >
          <Icon className={styles.icon} name="close" />
        </div>
      )}
    </div>
  );
};

export default React.memo(Member);
