import React, { useState } from 'react';

import FilterItem from './FilterItem';

const FilterItemContainer = ({ title, params }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOuterAction = () => {
    setIsOpen(false);
  };

  return (
    <FilterItem
      isOpen={isOpen}
      title={title}
      params={params}
      onFilterClick={handleFilterClick}
      onOuterAction={handleOuterAction}
    />
  );
};

export default React.memo(FilterItemContainer);
