import React from 'react';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { calculateProgress } from 'helpers/calculateProgress';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Checkbox } from 'components/shared/Checkbox';
import { Typography } from 'components/shared/Typography';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';

import { ActionsPanel } from '../ActionsPanel';
import { CreatingInput } from '../CreatingInput';

import { getActionsPanelItems } from './CheckListSection.helpers';

import { EndAdornmentContent } from './components/EndAdornmentContent';

import styles from './CheckListSection.styles.scss';

const CheckListSection = ({
  openAssignMemberPopover,
  creating,
  editing,
  checklists,
  boardId,
  taskDueDate,
  setOpenAssignMemberPopover,
  onTitleInputBlur,
  onItemInputFocus,
  onItemInputBlur,
  onCreatingItemInputBlur,
  onCompletedChange,
  onItemDueDateChanged,
  onItemAssigneeChanged,
  onSaveItemClick,
  onUpdateItemClick,
  onCheckListItemClick,
  onAddItemClick,
  onCancelItemClick,
  onCancelCreatingItemClick,
  onDeleteCheckListClick,
  onItemClick,
  ...restProps
}) => {
  const { t } = useTranslation('taskModal');

  return (
    <div className={styles.section}>
      {checklists.map((checklist) => (
        <Formik
          key={checklist.id}
          validateOnMount
          enableReinitialize
          initialValues={{
            checkListId: checklist.id,
            checkListName: checklist.name,
            items: checklist.itemList,
            creatingText: '',
          }}
        >
          {({ values, setFieldValue }) => (
            <div className={styles.checklist}>
              <div className={styles.header}>
                <Field name="checkListName">
                  {({ field }) => (
                    <input
                      {...field}
                      className={styles.titleField}
                      onBlur={() =>
                        onTitleInputBlur({
                          checkListId: values.checkListId,
                          title: checklist.name,
                          currentTitle: field.value,
                          setFieldValue,
                        })
                      }
                    />
                  )}
                </Field>
                <div
                  className={styles.action}
                  onClick={() => onDeleteCheckListClick(checklist.id)}
                >
                  <Icon name="bucket" className={styles.actionIcon} />
                  <Typography variant="body" className={styles.actionText}>
                    {t('deleteLabel')}
                  </Typography>
                </div>
              </div>

              <div className={styles.inner}>
                <div className={styles.progress}>
                  <Typography
                    variant="body"
                    weight="medium"
                    className={styles.progressText}
                  >
                    {t('progressLabel')}
                  </Typography>
                  <LinearProgressBar
                    progress={calculateProgress(values.items)}
                    className={styles.progressBar}
                  />
                  <Typography variant="body" className={styles.progressText}>
                    {calculateProgress(values.items)} %
                  </Typography>
                </div>

                <div className={styles.items}>
                  {values.items.map((item, index) => (
                    <div key={item.id} className={styles.item}>
                      <Field name={`items[${index}].completed`}>
                        {({ field }) => (
                          <Checkbox
                            {...field}
                            value={item.completeItem.completed}
                            className={styles.itemCompleted}
                            onChange={(event) =>
                              onCompletedChange(
                                event,
                                item,
                                item.completeItem.completed,
                                { values, field },
                              )
                            }
                          />
                        )}
                      </Field>
                      <CreatingInput
                        className={styles.creatingInput}
                        controlsClassName={styles.controlsClassName}
                        buttonClassName={styles.button}
                        name={`items[${index}].name`}
                        confirmText={t('saveButton')}
                        cancelText={t('cancelButton')}
                        editing={editing === item.id}
                        actionsContent={
                          (item.assignee ||
                            item.dueDate ||
                            item.completeItem.completed) && (
                            <ActionsPanel
                              items={getActionsPanelItems(item, t)}
                              onItemClick={(action) =>
                                onCheckListItemClick(action, item)
                              }
                            />
                          )
                        }
                        endAdornment={
                          <EndAdornmentContent
                            {...restProps}
                            openAssignMemberPopover={openAssignMemberPopover}
                            item={item}
                            boardId={boardId}
                            values={values}
                            taskDueDate={taskDueDate}
                            setFieldValue={setFieldValue}
                            setOpenAssignMemberPopover={
                              setOpenAssignMemberPopover
                            }
                            onItemClick={onItemClick}
                            onItemDueDateChanged={onItemDueDateChanged}
                            onItemAssigneeChanged={onItemAssigneeChanged}
                          />
                        }
                        onInputBlur={onItemInputBlur}
                        onInputFocus={(event) => onItemInputFocus(event, item)}
                        onConfirmClick={() =>
                          onUpdateItemClick(item, {
                            values,
                          })
                        }
                        onCancelClick={() =>
                          onCancelItemClick(item.id, checklist.items, {
                            values,
                            setFieldValue,
                          })
                        }
                      />
                    </div>
                  ))}
                  {creating === values.checkListId && (
                    <div className={styles.item}>
                      <Field name="creatingCompleted">
                        {({ field }) => (
                          <Checkbox
                            {...field}
                            disabled
                            className={styles.itemCompleted}
                          />
                        )}
                      </Field>
                      <CreatingInput
                        className={styles.creatingInput}
                        controlsClassName={styles.controlsClassName}
                        buttonClassName={styles.button}
                        editing
                        autoFocus
                        name="creatingName"
                        placeholder={t('addSubtaskPlaceholder')}
                        confirmText={t('addSubtaskButton')}
                        cancelText={t('cancelButton')}
                        onInputBlur={onCreatingItemInputBlur}
                        onConfirmClick={() =>
                          onSaveItemClick(values, { setFieldValue })
                        }
                        onCancelClick={() =>
                          onCancelCreatingItemClick({ setFieldValue })
                        }
                      />
                    </div>
                  )}
                </div>
                <Button
                  size="small"
                  variant="outline"
                  endIcon={<Icon className={styles.icon} name="plus" />}
                  className={styles.button}
                  onClick={() => onAddItemClick(values.checkListId)}
                >
                  {t('addSubtaskButton')}
                </Button>
              </div>
            </div>
          )}
        </Formik>
      ))}
    </div>
  );
};

export default React.memo(CheckListSection);
