import React from 'react';
import Popover from 'react-popover';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'components/shared/Avatar';
import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './UserMenu.styles.scss';

const UserMenu = ({
  isOpen,
  user,
  onAvatarClick,
  onSettingsClick,
  onLogoutClick,
  onOuterAction,
  className,
  ...restProps
}) => {
  const { t } = useTranslation('userMenu');

  return (
    <Popover
      className={styles.popover}
      isOpen={isOpen}
      body={
        <div className={styles.dropdownMenu}>
          <div className={styles.itemUser}>
            <Avatar
              className={styles.avatar}
              fullName={user.fullName}
              color={user.avatarColor}
              onClick={onAvatarClick}
            />
            <Typography className={styles.itemText}>{user.fullName}</Typography>
          </div>
          <div className={styles.item} onClick={onSettingsClick}>
            <Icon className={styles.itemIcon} name="settings" />
            <Typography className={styles.itemText}>
              {t('settingsLabel')}
            </Typography>
          </div>
          <div className={styles.item} onClick={onLogoutClick}>
            <Icon className={styles.itemIcon} name="exit" />
            <Typography className={styles.itemText}>
              {t('signOutLabel')}
            </Typography>
          </div>
        </div>
      }
      place="below"
      onOuterAction={onOuterAction}
    >
      <div className={styles.menu} onClick={onAvatarClick}>
        <Avatar
          className={cn(styles.avatar, {
            [styles.avatarMenuOpened]: isOpen,
          })}
          fullName={user.fullName}
          color={user.avatarColor}
          onClick={onAvatarClick}
        />
      </div>
    </Popover>
  );
};

export default React.memo(UserMenu);
