import React, { useEffect, useState } from 'react';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import AssignMembersModal from './AssignMembersModal';

const AssignMembersModalContainer = ({
  isOpen,
  members,
  boardId,
  onClose,
  onMembersChanged,
  ...restProps
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [assignedMembers, setAssignedMembers] = useState(members || []);

  const { data: boardMembers } = useFetch(
    {
      defaultData: [],
      fetcher: api.getBoardMembers,
      immediately: false,
      stopRequest: !isOpen,
    },
    boardId,
    [isOpen],
  );

  useEffect(() => {
    if (members) {
      setAssignedMembers(members);
    }
  }, [members]);

  const handleMembersChange = (memberOption) => {
    setAssignedMembers((prevMembers) => [...prevMembers, memberOption.data]);
  };

  const handleDeleteMemberClick = (memberId) => {
    const nextMembers = assignedMembers.filter(
      (member) => member.id !== memberId,
    );

    setAssignedMembers(nextMembers);
  };

  const handleSaveClick = () => {
    setSaveLoading(true);

    setSaveLoading(false);

    onClose();

    if (onMembersChanged) {
      onMembersChanged(assignedMembers);
    }
  };

  const activeBoardMembers = boardMembers.filter((member) => !member.deleted);

  const membersToAssign = activeBoardMembers.filter((member) =>
    !member.deleted && assignedMembers
      ? !assignedMembers.some(
          (assignedMember) => assignedMember.id === member.id,
        )
      : true,
  );

  return (
    <AssignMembersModal
      {...restProps}
      isOpen={isOpen}
      saveLoading={saveLoading}
      assignedMembers={assignedMembers}
      membersToAssign={membersToAssign}
      onClose={onClose}
      onMembersChange={handleMembersChange}
      onDeleteMemberClick={handleDeleteMemberClick}
      onSaveClick={handleSaveClick}
    />
  );
};

export default React.memo(AssignMembersModalContainer);
