import { useState } from 'react';

export const usePopover = () => {
  const [currentPopover, setCurrentPopover] = useState(null);

  const togglePopover = (popoverName) => {
    if (popoverName === currentPopover) {
      setCurrentPopover(null);
    } else {
      setCurrentPopover(popoverName);
    }
  };

  const closePopover = (popoverName) => {
    if (popoverName !== currentPopover) {
      return;
    }

    setCurrentPopover(null);
  };

  return { currentPopover, togglePopover, closePopover };
};
