import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Tabs } from 'components/shared/Tabs';
import { Typography } from 'components/shared/Typography';

import { SearchInput } from 'components/SearchInput';
import { Filter } from 'components/Filter';

import styles from './PageLayout.styles.scss';

const PageLayout = ({
  children,
  withTabs,
  withActions,
  withSearch,
  withFilter,
  backButton,
  title,
  controlTitle,
  label,
  className,
  currentTab,
  tabs,
  actions,
  onTabChange,
  onBackButtonClick,
}) => (
  <div className={cn(styles.layout, className)}>
    <div className={styles.header}>
      <div className={styles.headerBackInner}>
        {backButton && (
          <div className={styles.headerBackButton}>
            <div className={styles.backButton}>
              <Icon
                className={styles.backButtonActionsIcon}
                name="arrowHeaderLeft"
                onClick={onBackButtonClick}
              />
            </div>
          </div>
        )}
        {title && (
          <Typography variant="h1" weight="bold">
            {title}
          </Typography>
        )}
      </div>
      {controlTitle}
      <div className={styles.headerActions}>
        {withSearch && (
          <SearchInput
            className={styles.searchInput}
            placeholder="Search for board"
          />
        )}
        {withActions && <div className={styles.actions}>{actions}</div>}
      </div>
    </div>
    {(withTabs || withFilter) && (
      <div className={cn({ [styles.filterWrapper]: withTabs || withFilter })}>
        {withTabs && <Tabs tabs={tabs} onTabChange={onTabChange} />}
        {withFilter && <Filter className={styles.filter} />}
      </div>
    )}

    <div className={cn(styles.inner, { [styles.tabsInner]: withTabs })}>
      {currentTab ? currentTab.content : children}
    </div>
  </div>
);

export default React.memo(PageLayout);
