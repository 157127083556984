import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { EMITTER_TYPES } from 'constants/emitterTypes';

import { updateUserInfo } from 'store/auth/actions';

import Emitter from 'services/eventEmitter';

import { userSelector } from 'store/auth/selectors';

import OnboardingModal from './OnboardingModal';

const OnboardingModalContainer = ({ onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const [loading, setLoading] = useState(false);

  const handleGetStartedClick = (values) => {
    setLoading(true);

    dispatch(
      updateUserInfo({
        fullName: values.name,
        avatarColor: values.color,
        language: user.language,
        shortName: user.shortName,
        state: 'ACTIVE',
        tz: user.tz,
      }),
    )
      .then(() => {
        Emitter.emit(EMITTER_TYPES.SET_INVITED_MEMBER, {
          id: user.id,
          fullName: values.name,
          avatarColor: values.color,
        });

        setLoading(false);

        onClose();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <OnboardingModal
      {...restProps}
      loading={loading}
      onGetStartedClick={handleGetStartedClick}
    />
  );
};

export default React.memo(OnboardingModalContainer);
