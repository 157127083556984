import React from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import createDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { Input } from 'components/shared/Input';

const datePipe = createDatePipe('dd/mm/yyyy');
const mask = [
  /[0-3]/,
  /[0-9]/,
  '.',
  /[0-1]/,
  /[0-9]/,
  '.',
  /[1-2]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

const DateInput = ({
  className,
  isDatePickerOpen,
  value,
  onClickOutside,
  onDayClick,
  onIconClick,
  disabledDays,
  initialMonth,
  inputRef,
  iconRef,
  selectedDays = null, // parseDate(value, true) ? value : null,
  month,
  fromMonth,
  toMonth,
  disabled,
  ...restProps
}) => {
  const { t } = useTranslation('dateInput');
  return (
    <MaskedInput
      className={className}
      keepCharPositions
      pipe={datePipe}
      mask={mask}
      guide={false}
      placeholder={t('placeholder')}
      value={value}
      disabled={disabled}
      {...restProps}
      render={(ref, props) => (
        <Input
          inputRef={(el) => {
            ref(el);
            if (inputRef) {
              inputRef.current = el;
            }
          }}
          {...props}
        />
      )}
    />
  );
};

export default React.memo(DateInput);
