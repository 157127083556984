import React from 'react';
import cn from 'classnames';
import isEqual from 'date-fns/isEqual';
import startOfMonth from 'date-fns/startOfMonth';

import { TIME_PERIOD } from 'components/pages/TimelinePage/TimelinePage.constants';

import { getMonthshWidth } from 'components/pages/TimelinePage/TimelinePage.helpers';

import { Typography } from 'components/shared/Typography';

import styles from './TimelineHeader.styles.scss';

const TimelineHeader = ({ period, topArray, bottomArray }) => (
  <div className={styles.header} style={{ width: getMonthshWidth(topArray) }}>
    <div className={styles.monthsWrapper}>
      {topArray.map((month) => (
        <Typography
          key={month.text}
          className={styles.month}
          style={{ width: month.width }}
          variant="body"
          weight="light"
        >
          {month.text}
        </Typography>
      ))}
    </div>

    <div className={styles.daysWrapper}>
      {bottomArray.map((day, index) => (
        <div key={day.position}>
          <div className={styles.dayWrapper} style={{ width: day.width }}>
            <Typography
              className={cn(styles.day, {
                [styles.startMonthBorder]:
                  day.withBorder &&
                  isEqual(day.fullDate, startOfMonth(day.fullDate)),
                [styles.today]: period === TIME_PERIOD.DAY && day.today,
              })}
              variant="body"
              weight="light"
            >
              {day.text}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default React.memo(TimelineHeader);
