import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { showToastMessage } from 'helpers/showToastMessage';

import { useFetch } from 'hooks/useFetch';

import CreateBoardModal from './CreateBoardModal';

const CreateBoardModalContainer = ({ isOpen, onClose, onBoardCreated }) => {
  const { t } = useTranslation('toast');

  const [loading, setLoading] = useState(false);

  const { loading: templatesLoading, data: templates } = useFetch(
    {
      defaultData: [],
      fetcher: api.getTemplates,
      immediately: true,
    },
    {},
    [],
  );
  console.log(templates);

  const handleSubmit = (values) => {
    setLoading(true);

    api
      .createBoard(values)
      .then((board) => {
        if (onBoardCreated) {
          onBoardCreated(board);
        }

        setLoading(false);

        onClose();
      })
      .catch(({ status }) => {
        showToastMessage({ variant: 'error', status, t });
      });
  };

  return (
    <CreateBoardModal
      loading={loading}
      templatesLoading={templatesLoading}
      isOpen={isOpen}
      templates={templates}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default React.memo(CreateBoardModalContainer);
