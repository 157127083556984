import React from 'react';
import cn from 'classnames';
import { components } from 'react-select';
import { Typography } from 'components/shared/Typography';

import styles from './ValueContainer.styles.scss';

const VARIANTS = {
  searchable: styles.searchable,
  default: styles.default,
};

const ValueContainer = ({
  children,
  variant = 'default',
  error,
  className,
  ...props
}) => (
  <components.SingleValue
    {...props}
    className={cn(styles.value, VARIANTS[variant], className)}
  >
    <Typography>{children}</Typography>
  </components.SingleValue>
);

export default React.memo(ValueContainer);
