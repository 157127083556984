import { LANGUAGE_OPTIONS } from './SettingsModal.constants';

export const convertDataToForm = (user, t) => {
  const currentLanguage = LANGUAGE_OPTIONS.find(
    (language) => language.value === user.language,
  );

  return {
    name: user.fullName,
    email: user.email,
    color: user.avatarColor,
    language: currentLanguage
      ? {
          ...currentLanguage,
          label: t(currentLanguage.translationKey),
        }
      : null,
    state: user.state,
  };
};
