import React from 'react';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';
import { Icon } from 'components/shared/Icon';
import { Radio } from 'components/shared/Radio';
import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';

import styles from './ContextMenuPopover.styles.scss';

const VARIANTS = {
  default: styles.default,
  alert: styles.alert,
};

const ContextMenuPopover = ({
  open,
  checked,
  wipLimit,
  items = [],
  radioItems = [],
  children,
  position = 'top',
  offset = 10,
  popoverClassName,
  setOpen,
  setChecked,
  onItemClick,
  onCheckClick,
  onRequestClose,
}) => (
  <Tooltip
    open={open}
    onRequestClose={onRequestClose}
    interactive
    animation="shift"
    offset={offset}
    position={position}
    html={
      <div className={cn(styles.popover, popoverClassName)}>
        {items.map((item) => {
          if (item.key === 'settingsLimit') {
            return (
              <div
                key={item.key}
                className={cn(styles.itemWrapper, VARIANTS[item.variant])}
                onClick={() =>
                  onItemClick({
                    key: item.key,
                    open,
                    openPopover: setOpen,
                  })
                }
              >
                <Icon className={styles.icon} name={item.icon} />
                <Typography className={styles.title} variant="subtitle">
                  {wipLimit ? item.settingsTitle : item.title}
                </Typography>
              </div>
            );
          }

          if (item.key === 'columnType') {
            return (
              <Collapsible
                key={item.key}
                withArrow
                iconClassName={styles.arrowIcon}
                iconSize={16}
                transitionTime={150}
                headerComponent={
                  <div
                    className={cn(
                      styles.itemWrapper,
                      styles.header,
                      VARIANTS[item.variant],
                    )}
                  >
                    <Icon className={styles.icon} name={item.icon} />
                    <Typography className={styles.title} variant="subtitle">
                      {item.title}
                    </Typography>
                  </div>
                }
                innerComponent={radioItems.map((radioItem) => (
                  <Radio
                    className={styles.collapsItems}
                    labelClassName={styles.collapsTitle}
                    key={radioItem.key}
                    name="radioItems"
                    checked={radioItem.key === checked}
                    label={radioItem.title}
                    onChange={() => onCheckClick(radioItem.key)}
                  />
                ))}
              />
            );
          }

          return (
            <div
              className={cn(styles.itemWrapper, VARIANTS[item.variant])}
              key={item.key}
              onClick={() =>
                onItemClick({
                  key: item.key,
                  open,
                  openPopover: setOpen,
                })
              }
            >
              <Icon className={styles.icon} name={item.icon} />
              <Typography className={styles.title} variant="subtitle">
                {item.title}
              </Typography>
            </div>
          );
        })}
      </div>
    }
  >
    <span>{children({ open, openPopover: setOpen })}</span>
  </Tooltip>
);

export default React.memo(ContextMenuPopover);
