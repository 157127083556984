import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BOARD_PROVIDER_TYPES } from 'components/pages/BoardPage/components/BoardPageContext/BoardPageProvider.constants';

import * as api from 'api/methods';

import { setFormError } from './MoveTasksModal.helpers';

import { useBoard } from 'components/pages/BoardPage/components/BoardPageContext/BoardPage.context';

import MoveTasksModal from './MoveTasksModal';

const MoveTasksModalContainer = ({
  withDeleteList,
  isOpen,
  boardId,
  listId,
  tasks,
  getCurrentList,
  onDeleteList,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation('moveTasksModal');
  const [loading, setLoading] = useState(false);

  const { dispatch, board } = useBoard();

  const handleSubmit = (values, actions) => {
    setLoading(true);

    const data = {
      boardColumnId: values.list.value,
      taskIdList: tasks.map((task) => task.id),
    };

    const currentList = getCurrentList(values.list.value);

    api
      .moveTasks(boardId, listId, data)
      .then((movedTasks) => {
        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_TASKS_MOVED,
          payload: {
            fromColumnId: listId,
            toColumnId: values.list.value,
            fromColumnTaskList: [],
            toColumnTaskList: [...movedTasks, ...currentList.tasks],
          },
        });

        if (withDeleteList && onDeleteList) {
          onDeleteList();
        }

        setLoading(false);

        onClose();
      })
      .catch(({ status }) => {
        setLoading(false);
        setFormError({ status, setFieldError: actions.setFieldError, t });
      });
  };

  const filteredLists = board.lists.filter((list) => list.id !== listId);

  const options = filteredLists.map((list) => {
    return {
      label: list.name,
      value: list.id,
    };
  });

  return (
    <MoveTasksModal
      loading={loading}
      isOpen={isOpen}
      options={options}
      onClose={onClose}
      onSubmit={handleSubmit}
      {...restProps}
    />
  );
};

export default React.memo(MoveTasksModalContainer);
