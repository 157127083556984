import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import * as api from 'api/methods';

import { EMITTER_TYPES } from 'constants/emitterTypes';
import { BOARD_PROVIDER_TYPES } from './components/BoardPageContext/BoardPageProvider.constants';

import Emitter from 'services/eventEmitter';

import { userSelector } from 'store/auth/selectors';

import { updateBoard } from './BoardPage.helpers';
import { showToastMessage } from 'helpers/showToastMessage';

import { useModal } from 'hooks/useModal';
import { useBoard } from './components/BoardPageContext/BoardPage.context';

import { ShareModal } from './components/ShareModal';
import { MembersModal } from './components/MembersModal';
import { SettingsModal } from './components/SettingsModal';

import { BoardProvider } from './components/BoardPageContext/BoardPage.context';

import { OnboardingModal } from 'components/OnboardingModal';

import BoardPage from './BoardPage';

const BoardPageContainer = () => {
  const { t } = useTranslation('toast');

  const { id, taskId: taskIdUrl } = useParams();

  const history = useHistory();

  const user = useSelector(userSelector);

  const reserveTaskData = useRef();

  const { board, dispatch } = useBoard();

  const [movingDisabledFrom, setMovingDisabledFrom] = useState(false);
  const [movingDisabledTo, setMovingDisabledTo] = useState(false);
  const [movingTask, setMovingTask] = useState(false);

  useEffect(() => {
    if (!movingTask && movingDisabledFrom && movingDisabledTo) {
      setMovingDisabledFrom(false);
      setMovingDisabledTo(false);
    }
  }, [board, movingTask]);

  const [shareModalOpen, openShareModal, closeShareModal] = useModal({});

  const [onboardingModalOpen, openOnboardingModal, closeOnboardingModal] =
    useModal({});

  useEffect(() => {
    if (user.fullName === null) {
      openOnboardingModal();
    }
  }, []);

  const [
    membersModalOpen,
    openMembersModal,
    closeMembersModal,
    membersModalData,
    setMembersModalData,
  ] = useModal({});

  const [
    settingsModalOpen,
    openSettingsModal,
    closeSettingsModal,
    settingsModalData,
  ] = useModal({});

  useEffect(() => {
    api.recentBoard(id);
  }, []);

  useEffect(() => {
    Emitter.on(EMITTER_TYPES.SET_INVITED_MEMBER, (data) => {
      dispatch({
        type: BOARD_PROVIDER_TYPES.SET_INVITED_MEMBER,
        payload: data,
      });
    });

    Emitter.on(EMITTER_TYPES.UPDATE, (data) => {
      if (id === data.board.id) {
        updateBoard({ id, data, dispatch });
      }
    });

    return () => {
      Emitter.removeListener(EMITTER_TYPES.UPDATE);
    };
  }, []);

  const handleSaveReserveTaskData = ({ isSource, payload, willAcceptDrop }) => {
    reserveTaskData.current = payload;
  };

  const getListPayload = (index) => board.lists[index];

  const getCurrentList = (columnId) =>
    board.lists.find((list) => list.id === columnId);

  const handleListCreate = (listTitle) =>
    api.createBoardList(id, { name: listTitle });

  const checkListTitleUniq = (listTitle, listId) =>
    board.lists
      .filter((list) => list.id !== listId)
      .some((list) => list.name === listTitle.trim());

  const handleMemberDeleted = (memberId) => {
    const nextMembers = board.memberList.map((member) =>
      member.id === memberId ? { ...member, deleted: true } : member,
    );

    setMembersModalData({ members: nextMembers });

    dispatch({
      type: BOARD_PROVIDER_TYPES.UPDATE_BOARD_DATA,
      payload: { memberList: nextMembers },
    });
  };

  const handleListCreated = (list) => {
    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_LIST_CREATED,
      payload: list,
    });
  };

  const handleListDrop = ({ removedIndex, addedIndex, payload }) => {
    const nextLists = [...board.lists];

    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = nextLists.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      nextLists.splice(addedIndex, 0, itemToAdd);
    }

    dispatch({
      type: BOARD_PROVIDER_TYPES.SET_BOARD_LISTS,
      payload: nextLists,
    });

    api
      .moveList(id, payload.id, { columnNumber: addedIndex + 1 })
      .then((response) => {
        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_LIST_MOVED,
          payload: { nextLists: response },
        });
      })
      .catch(({ status }) => {
        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_LIST_MOVED,
          payload: { nextLists: board.lists },
        });

        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleFavoriteClick = () => {
    dispatch({
      type: BOARD_PROVIDER_TYPES.UPDATE_BOARD_DATA,
      payload: { favorite: !board.favorite },
    });

    api
      .setFavoriteBoard(id, { favorite: !board.favorite })
      .catch(({ status }) => {
        dispatch({
          type: BOARD_PROVIDER_TYPES.UPDATE_BOARD_DATA,
          payload: { favorite: board.favorite && true },
        });

        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleSettingsClick = () => {
    openSettingsModal({ board });
  };

  const handleMembersClick = () => {
    openMembersModal({ members: board.memberList });
  };

  const handleAnalyticsClick = () => {
    history.push(`/boards/${board.id}/analytics`);
  };

  const handleBackButtonClick = () => {
    if (window.location.pathname !== `/boards/${board.id}`) {
      history.push(`/boards/${board.id}/analytics`);
    } else {
      history.push('/boards');
    }
  };

  const loading = !board || !board.lists;
  const boardName = !loading ? board.name : '';
  const boardFavorite = !loading && board.favorite;
  const boardAdmin =
    board.memberList &&
    board.memberList.find((member) => member.role === 'ADMIN');

  return (
    <>
      <BoardPage
        loading={loading}
        movingDisabledTo={movingDisabledTo}
        movingDisabledFrom={movingDisabledFrom}
        boardFavorite={boardFavorite}
        boardName={boardName}
        boardAdmin={boardAdmin}
        user={user}
        taskIdUrl={taskIdUrl}
        reserveTaskData={reserveTaskData}
        lists={board.lists}
        getCurrentList={getCurrentList}
        getListPayload={getListPayload}
        setMovingTask={setMovingTask}
        setMovingDisabledTo={setMovingDisabledTo}
        setMovingDisabledFrom={setMovingDisabledFrom}
        checkListTitleUniq={checkListTitleUniq}
        onAnalyticsClick={handleAnalyticsClick}
        onBackButtonClick={handleBackButtonClick}
        onListDrop={handleListDrop}
        onListCreate={handleListCreate}
        onListCreated={handleListCreated}
        onShareClick={openShareModal}
        onMembersClick={handleMembersClick}
        onSettingsClick={handleSettingsClick}
        onFavoriteClick={handleFavoriteClick}
        onSaveReserveTaskData={handleSaveReserveTaskData}
      />
      <ShareModal id={id} isOpen={shareModalOpen} onClose={closeShareModal} />
      <MembersModal
        {...membersModalData}
        isOpen={membersModalOpen}
        boardId={board.id}
        onMemberDeleted={handleMemberDeleted}
        onClose={closeMembersModal}
      />
      <SettingsModal
        {...settingsModalData}
        isOpen={settingsModalOpen}
        board={board}
        user={user}
        openSettingsModal={openSettingsModal}
        onClose={closeSettingsModal}
      />
      <OnboardingModal
        isOpen={onboardingModalOpen}
        onClose={closeOnboardingModal}
      />
    </>
  );
};

const BoardPageProvider = (props) => {
  const { id } = useParams();

  return (
    <BoardProvider boardId={id}>
      <BoardPageContainer {...props} />
    </BoardProvider>
  );
};

export default React.memo(BoardPageProvider);
