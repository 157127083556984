import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './Checkbox.styles.scss';

const Checkbox = ({
  className,
  inputClassName,
  iconWrapperClassName,
  iconClassName,
  name,
  value,
  labelElement,
  ...restProps
}) => (
  <div className={cn(styles.checkbox, className)}>
    <input
      type="checkbox"
      name={name}
      className={cn(styles.input, inputClassName)}
      checked={value}
      {...restProps}
    />
    <div className={cn(styles.iconWrapper, iconWrapperClassName)}>
      <Icon name="check" className={cn(styles.checkIcon, iconClassName)} />
    </div>
    {labelElement}
  </div>
);

export default React.memo(Checkbox);
