import React from 'react';
import cn from 'classnames';

import { TASK_WIDTH } from 'components/pages/TimelinePage/TimelinePage.constants';

import {
  getLineWidth,
  getMonthshWidth,
  getStartPosition,
  getTaskPeriodWidth,
  getTodayLinePosition,
} from 'components/pages/TimelinePage/TimelinePage.helpers';

import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import DueDate from '../DueDate/DueDate';
import TimelineHeader from '../TimelineHeader/TimelineHeader';

import styles from './TimelineInner.styles.scss';

const TimelineInner = ({
  lineRef,
  timelineRef,
  lineContainerRef,
  hovering,
  dragging,
  language,
  period,
  tasks,
  topArray,
  bottomArray,
  onSyncScroll,
  onTaskAndLineHover,
}) => (
  <ScrollWrapper
    scrollbarRef={timelineRef}
    contentClassName={styles.scrollContent}
    trackClassName={styles.trackClassName}
    scrollThumbSize={4}
    onScroll={onSyncScroll}
  >
    <TimelineHeader
      period={period}
      topArray={topArray}
      bottomArray={bottomArray}
    />
    <div className={styles.linesWrapper}>
      {!!tasks.length &&
        tasks.map((task, index) => (
          <div
            ref={lineRef}
            key={task.taskId}
            className={cn(styles.line, {
              [styles.hovering]: hovering === task.taskId,
            })}
            style={{
              top: task.taskPosition,
              width: getLineWidth(period),
            }}
          >
            <div
              className={styles.lineInner}
              style={{
                width: getTaskPeriodWidth({
                  period,
                  endDate: task.endDate,
                  startDate: task.startDate,
                }),
                left: getStartPosition({
                  period,
                  bottomArray,
                  startDate: task.startDate,
                }),
              }}
              onMouseOver={() => onTaskAndLineHover(task.taskId)}
              onMouseOut={() => onTaskAndLineHover(null)}
            />

            {task.endDate && (
              <DueDate
                key={task.taskId}
                language={language}
                dueDate={task.endDate}
                period={period}
                taskPosition={task.taskPosition}
                bottomArray={bottomArray}
              />
            )}
          </div>
        ))}
    </div>

    <div
      className={styles.columns}
      style={{
        width: getMonthshWidth(topArray),
        height: tasks.length && tasks.length * TASK_WIDTH,
      }}
    >
      {!!bottomArray.length &&
        bottomArray.map((day) => (
          <div key={day.position}>
            <div
              className={styles.column}
              style={{ left: day.position, width: day.width }}
            />
            {day.today && (
              <div
                className={styles.todayLine}
                style={{
                  left:
                    day.position +
                    getTodayLinePosition({
                      period,
                      tasks,
                      startDayOfWeek: day.startDayOfWeek,
                    }),
                }}
              />
            )}
          </div>
        ))}
    </div>
  </ScrollWrapper>
);

export default React.memo(TimelineInner);
