import { getRegex } from 'helpers/getRegex';

export const emailValidation = (value, emailPattern) => {
  if (!value || value.length < 3) {
    return true;
  }

  if (value.length >= 3 && getRegex(emailPattern).test(value)) {
    return false;
  }

  return true;
};
