import { useEffect, useState } from 'react';
import axios from 'axios';

import * as api from 'api/methods';

import { getAttachmentFormat } from './AttachmentSection.helpers';

export const useUploadAttachmentPreviews = ({
  attachments,
  boardId,
  taskId,
}) => {
  const [attachmentPreviews, setAttachmentPreviews] = useState({});

  useEffect(() => {
    const currentAttachmentPreviews = Object.values(attachmentPreviews);
    const imageAttachments = attachments.filter(
      (attachment) =>
        getAttachmentFormat(attachment) === 'JPEG' ||
        getAttachmentFormat(attachment) === 'PNG',
    );

    if (imageAttachments.length > currentAttachmentPreviews.length) {
      const attachmentPreviewsToRequest = imageAttachments.filter(
        (attachment) =>
          !currentAttachmentPreviews.some(
            (attachmentPreview) => attachmentPreview.id === attachment.id,
          ),
      );

      const attachmentPreviewsToRequestPromises =
        attachmentPreviewsToRequest.map((attachmentPreviewToRequest) =>
          api.getAttachmentPreview({
            boardId,
            taskId,
            attachmentId: attachmentPreviewToRequest.id,
          }),
        );

      if (!currentAttachmentPreviews.length) {
        setAttachmentPreviews(
          imageAttachments.reduce((previews, attachment, index) => {
            previews[attachment.id] = {
              id: attachment.id,
              loading: true,
              preview: null,
            };

            return previews;
          }, {}),
        );
      } else {
        setAttachmentPreviews(
          imageAttachments.reduce((previews, attachment, index) => {
            if (!attachmentPreviews[attachment.id]) {
              previews[attachment.id] = {
                id: attachment.id,
                loading: true,
                preview: null,
              };

              return previews;
            }

            previews[attachment.id] = attachmentPreviews[attachment.id];

            return previews;
          }, {}),
        );
      }

      Promise.all(attachmentPreviewsToRequestPromises).then((previewBlobs) => {
        const previewsBlobsPromises = previewBlobs.map(
          (previewBlob, previewBlobIndex) =>
            new Promise((resolve, reject) => {
              const reader = new window.FileReader();
              reader.readAsDataURL(previewBlob);

              reader.onload = function () {
                const attachmentPreviewToRequest =
                  attachmentPreviewsToRequest[previewBlobIndex];

                resolve({
                  id: attachmentPreviewToRequest.id,
                  preview: reader.result,
                });
              };
            }),
        );

        return Promise.all(previewsBlobsPromises).then((previews) => {
          setAttachmentPreviews((prevPreviews) => {
            return Object.values(prevPreviews).reduce(
              (nextPreviews, prevPreview) => {
                if (!prevPreview.loading) {
                  nextPreviews[prevPreview.id] = prevPreview;

                  return nextPreviews;
                }

                const preview = previews.find(
                  (prv) => prv.id === prevPreview.id,
                );

                nextPreviews[prevPreview.id] = {
                  ...prevPreview,
                  loading: false,
                  preview: preview.preview,
                };

                return nextPreviews;
              },
              {},
            );
          });
        });
      });
    }
  }, [attachments]);

  return [attachmentPreviews, setAttachmentPreviews];
};

export const useUpload = ({
  boardId,
  taskId,
  multiple,
  onUploaded,
  onError,
}) => {
  const [percentage, setPercentage] = useState(0);
  const [source] = useState(axios.CancelToken.source());

  const handleStartUpload = (file) => {
    const data = new FormData();
    data.append('multipartFile', file);

    const config = {
      cancelToken: source.token,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );

        setPercentage(percentCompleted);
      },
    };

    api
      .createAttachment({ boardId, taskId }, data, config)
      .then((response) => {
        if (onUploaded) {
          onUploaded(response);
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  };

  const handleRemove = (fileIndex) => {
    setPercentage(0);
  };

  const handleCancelUpload = () => {
    source.cancel();

    setPercentage(0);
  };

  return [
    percentage,
    handleStartUpload,
    handleCancelUpload,
    handleRemove,
    setPercentage,
  ];
};
