export const getPAGE_CONSTANTS = (t) => {
  return {
    WEAK_PASSWORD: t('weakPassword'),
    NORMAL_PASSWORD: t('normalPassword'),
    STRONG_PASSWORD: t('strongPassword'),
  };
};

export const PAGE_CONSTANTS = {
  MAX_ERROR_COUNTER: 3,
  PASSWORD: 'PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
};
