import React from 'react';
import cn from 'classnames';
import { Resizable } from 're-resizable';
import { useTranslation } from 'react-i18next';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './TasksSection.styles.scss';

const SortableTask = sortableElement(
  ({ hovering, dragging, item, onTaskAndLineHover }) => (
    <div
      className={cn(styles.task, {
        [styles.hovering]: hovering === item.taskId,
      })}
      onMouseEnter={() => onTaskAndLineHover(item.taskId)}
      onMouseLeave={() => onTaskAndLineHover(null)}
    >
      <Icon className={styles.dragDropIcon} name="drag" />

      <div
        className={styles.taskColor}
        style={{
          backgroundColor: item.taskType && item.taskType.color,
        }}
      />
      <Typography className={styles.taskName} variant="body">
        {item.taskName}
      </Typography>
    </div>
  ),
);

const SortableContainer = sortableContainer(({ children }) => (
  <div className={styles.tasks}>{children}</div>
));

const TasksSection = ({
  tasksSectionRef,
  dragging,
  hovering,
  resizing,
  tasks,
  setResizing,
  onSortEnd,
  onSortStart,
  onSyncScroll,
  onTaskAndLineHover,
}) => {
  const { t } = useTranslation('timelinePage');

  return (
    <Resizable
      className={cn(styles.resizable, { [styles.resizing]: resizing })}
      defaultSize={{
        width: 224,
        height: '100%',
      }}
      minWidth={150}
      maxWidth={600}
      enable={{
        right: true,
        top: false,
        left: false,
        bottom: false,
        topLeft: false,
        topRight: false,
        bottomLeft: false,
        bottomRight: false,
      }}
      as={'div'}
      handleComponent={{
        right: (
          <div className={styles.resizeHandle}>
            <Icon className={styles.arrow} name="arrowLeft" />
            <Icon className={styles.arrow} name="arrowRight" />
          </div>
        ),
      }}
      onResizeStart={() => setResizing(true)}
      onResizeStop={() => setResizing(false)}
    >
      <div className={styles.section}>
        <div
          className={styles.inner}
          ref={tasksSectionRef}
          onScroll={(event) => onSyncScroll(event)}
        >
          <div className={styles.header}>
            <Typography className={styles.title} variant="body" weight="medium">
              {t('tasks')}
            </Typography>
          </div>

          <SortableContainer
            helperClass={cn(styles.dragging, dragging)}
            onSortEnd={onSortEnd}
            onSortStart={onSortStart}
          >
            {!!tasks.length &&
              tasks.map((task, index) => (
                <SortableTask
                  key={task.taskId}
                  index={index}
                  item={task}
                  hovering={hovering}
                  dragging={dragging}
                  onTaskAndLineHover={onTaskAndLineHover}
                />
              ))}
          </SortableContainer>
        </div>
      </div>
    </Resizable>
  );
};

export default React.memo(TasksSection);
