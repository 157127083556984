import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './NotFoundPage.styles.scss';

const NotFoundPage = ({ onButtonClick }) => {
  const { t } = useTranslation('notFoundPage');

  return (
    <div className={styles.page}>
      <div className={styles.inner}>
        <Typography className={styles.title} weight="medium">
          {t('title')}
        </Typography>
        <Button
          className={styles.button}
          variant="contained"
          onClick={onButtonClick}
        >
          {t('titleButton')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(NotFoundPage);
