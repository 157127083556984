import React from 'react';

import { Loading } from 'components/shared/Loading';

import { Header } from './components/Header';
import { Timeline } from './components/Timeline';
import { TasksSection } from './components/TasksSection';

import styles from './TimelinePage.styles.scss';

const TimelinePage = ({
  lineRef,
  timelineRef,
  tasksSectionRef,
  lineContainerRef,
  loading,
  hovering,
  dragging,
  boardId,
  selectedTimePeriod,
  tasks,
  onSortEnd,
  onSortStart,
  onTaskScroll,
  onSelectPeriod,
  onTimelineScroll,
  onTaskAndLineHover,
  onChangeTaskPosition,
}) => (
  <>
    {loading ? (
      <Loading className={styles.loading} size={30} />
    ) : (
      <div className={styles.page}>
        <Header
          boardId={boardId}
          selectedTimePeriod={selectedTimePeriod}
          onSelectPeriod={onSelectPeriod}
        />

        <div className={styles.inner}>
          <TasksSection
            tasksSectionRef={tasksSectionRef}
            hovering={hovering}
            dragging={dragging}
            tasks={tasks}
            onSortEnd={onSortEnd}
            onSortStart={onSortStart}
            onSyncScroll={onTaskScroll}
            onTaskAndLineHover={onTaskAndLineHover}
          />

          <Timeline
            lineRef={lineRef}
            timelineRef={timelineRef}
            lineContainerRef={lineContainerRef}
            hovering={hovering}
            dragging={dragging}
            selectedTimePeriod={selectedTimePeriod}
            tasks={tasks}
            onSyncScroll={onTimelineScroll}
            onTaskAndLineHover={onTaskAndLineHover}
          />
        </div>
      </div>
    )}
  </>
);

export default React.memo(TimelinePage);
