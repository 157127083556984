import React from 'react';
import { useTranslation } from 'react-i18next';

import { CODE_CONSTANTS } from './CodeStepInner.constants';

import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { ErrorMessage } from 'components/shared/ErrorMessage';
import { CodeInput } from 'components/CodeInput';

import styles from './CodeStepInner.styles.scss';

const InfoRenderer = ({
  loading,
  codeSubmitted,
  error,
  errors,
  errorCounter,
  t,
}) => {
  if (loading) {
    return (
      <div className={styles.loading}>
        <Loading color="#B9B9B9" />
        <Typography className={styles.text}>
          {t('codeCheckingLoading')}
        </Typography>
      </div>
    );
  }

  if (error) {
    if (errorCounter < CODE_CONSTANTS.MAX_ERROR_COUNTER) {
      return (
        <div className={styles.info}>
          <Icon className={styles.errorIcon} name="close" />
          <Typography className={styles.errorText}>
            {`${t('invalidCodeErrorStart')} (${errorCounter} ${t(
              'invalidCodeErrorFinish',
            )})`}
          </Typography>
        </div>
      );
    }
    if (errorCounter === CODE_CONSTANTS.MAX_ERROR_COUNTER) {
      return (
        <Typography className={styles.errorText}>
          {t('codeCounterLimitError')}
        </Typography>
      );
    }
  }

  if (codeSubmitted) {
    return (
      <div className={styles.info}>
        <Icon className={styles.successIcon} name="success" />
        <Typography className={styles.successText}>
          {t('successText')}
        </Typography>
      </div>
    );
  }

  if (errors.code) {
    return <ErrorMessage className={styles.error}>{errors.code}</ErrorMessage>;
  }

  return null;
};

const CodeStepInner = ({
  loading = false,
  withCancel = false,
  error = '',
  title = '',
  subtitle = '',
  email = '',
  variant = 'email',
  timerSecondsLeft = 0,
  resetTrigger = false,
  onClose = () => {},
  onCodeChange = () => {},
  onSendCodeAgainClick = () => {},
  errors = {},
  codeSubmitted = false,
  ...restProps
}) => {
  const { t } = useTranslation('codeStepInner');
  const { t: t_codeStepErrors } = useTranslation('codeStepErrors');

  const VARIANTS = {
    email: t('subtitle2Email'),
    password: t('subtitle2Password'),
  };

  return (
    <>
      <Typography className={styles.title} variant="h1">
        {title}
      </Typography>
      <Typography className={styles.subtitle} variant="main">
        {subtitle || `${t('subtitle1')} ${email}. ${VARIANTS[variant]}`}
      </Typography>
      <CodeInput
        className={styles.codeInput}
        error={error}
        resetTrigger={resetTrigger}
        onChange={onCodeChange}
      />
      <div className={styles.infoWrapper}>
        <InfoRenderer
          loading={loading}
          codeSubmitted={codeSubmitted}
          error={error}
          errors={errors}
          errorCounter={restProps.errorCounter}
          t={t_codeStepErrors}
        />
      </div>
      <div className={styles.linkWrapper}>
        <Link
          className={styles.resendLink}
          type={timerSecondsLeft > 0 ? 'disabled' : 'default'}
          onClick={onSendCodeAgainClick}
        >
          {t('link')}
        </Link>
        {timerSecondsLeft > 0 && (
          <Typography className={styles.timer}>
            {`( ${timerSecondsLeft} )`}
          </Typography>
        )}
      </div>
      {withCancel && (
        <Typography className={styles.cancel} variant="body" onClick={onClose}>
          {t('label')}
        </Typography>
      )}
    </>
  );
};

export default React.memo(CodeStepInner);
