import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import { formatActions } from './ActionsSection.helpers';

import ActionsSection from './ActionsSection';

const ActionsSectionContainer = ({
  triggerActionsUpdate,
  boardId,
  taskId,
  ...restProps
}) => {
  const { t, i18n } = useTranslation('taskModal');

  const language = i18n.language;

  const [open, setOpen] = useState(false);

  const {
    loading,
    data: actions,
    setState: setActions,
  } = useFetch(
    {
      defaultData: [],
      fetcher: api.getTaskActions,
      immediately: false,
    },
    { boardId, taskId },
    [],
  );

  useEffect(() => {
    if (triggerActionsUpdate) {
      api
        .getTaskActions({ boardId, taskId })
        .then((response) => {
          setActions((prevState) => ({
            ...prevState,
            data: response,
          }));
        })
        .catch(() => {});
    }
  }, [triggerActionsUpdate]);

  const handleToggleClick = () => {
    setOpen(!open);
  };

  return (
    <ActionsSection
      {...restProps}
      open={open}
      loading={loading}
      actions={formatActions(actions, t, language)}
      onToggleClick={handleToggleClick}
    />
  );
};

export default React.memo(ActionsSectionContainer);
