import React from 'react';
import { useSelector } from 'react-redux';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import { ROUTES } from 'constants/routes';

import { useResponsive } from 'hooks/useResponsive';

import ExpiredLinkPage from './ExpiredLinkPage';

const ExpiredLinkPageContainer = ({ history }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useResponsive();

  const handleButtonClick = () => {
    if (isAuthenticated) {
      history.push(ROUTES.BOARDS);
    } else {
      history.push('/');
    }
  };

  return <ExpiredLinkPage onButtonClick={handleButtonClick} />;
};

export default React.memo(ExpiredLinkPageContainer);
