import React from 'react';
import Markdown from 'react-remarkable';
import TextareaAutosize from 'react-textarea-autosize';

import cn from 'classnames';

import styles from './TextEditor.styles.scss';

const TextEditor = ({
  textareaRef,
  editing,
  value,
  placeholder,
  onBlur,
  onFocus,
  onChange,
  onMarkdownClick,
}) => (
  <>
    <TextareaAutosize
      ref={textareaRef}
      className={cn(styles.textarea, {
        [styles.textareaEditing]: editing || !value,
      })}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />

    <div
      onClick={onMarkdownClick}
      className={cn(styles.markdown, styles.listStyle, {
        [styles.markdownActive]: !editing,
      })}
    >
      <Markdown source={value} />
    </div>
  </>
);

export default React.memo(TextEditor);
