import React, { useEffect, useState } from 'react';

import * as api from 'api/methods';

import AttachmentModal from './AttachmentModal';

const AttachmentModalContainer = ({
  boardId,
  taskId,
  attachment,
  ...restProps
}) => {
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (attachment) {
      api
        .getAttachment({ boardId, taskId, attachmentId: attachment.id })
        .then((attachmentBlob) => {
          return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.readAsDataURL(attachmentBlob);

            reader.onload = function () {
              resolve(reader.result);
            };
          });
        })
        .then((attachmentPreview) => {
          setLoading(false);
          setPreview(attachmentPreview);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [attachment]);

  return (
    <AttachmentModal
      {...restProps}
      loading={loading}
      attachment={attachment}
      preview={preview}
    />
  );
};

export default React.memo(AttachmentModalContainer);
