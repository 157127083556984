import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';
import { ErrorMessage } from 'components/shared/ErrorMessage';

import styles from './FormControl.styles.scss';

const FormControl = ({
  className,
  error,
  touched,
  children,
  label,
  endAdornment,
  ...restProps
}) => (
  <div className={cn(styles.control, className)} {...restProps}>
    {label && (
      <div className={styles.header}>
        <Typography variant="link" weight="medium" className={styles.label}>
          {label}
        </Typography>
        {endAdornment && (
          <div className={styles.endAdornment}>{endAdornment}</div>
        )}
      </div>
    )}
    <div className={styles.inner}>
      {children}
      {!!error && touched && (
        <ErrorMessage className={styles.error}>{error}</ErrorMessage>
      )}
    </div>
  </div>
);

export default React.memo(FormControl);
