import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import * as api from 'api/methods';

import { userSelector } from 'store/auth/selectors';

import CommentsSection from './CommentsSection';

const CommentsSectionContainer = ({
  boardId,
  taskId,
  onCommentCreated,
  onCommentUpdated,
  onCommentRemoved,
  ...restProps
}) => {
  const user = useSelector(userSelector);

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(null);

  const handleCreatingInputFocus = () => {
    setCreating(true);
  };

  const handleCreatingInputBlur = () => {
    if (loading) {
      return;
    }

    setCreating(false);
  };

  const handleEditingInputBlur = () => {
    if (loading) {
      return null;
    }

    setEditing(null);
  };

  const handleToggleClick = () => {
    setOpen(!open);
  };

  const handleActionItemClick = (actionItem, comment) => {
    switch (actionItem.key) {
      case 'edit': {
        setEditing(comment.id);

        break;
      }
      case 'delete': {
        api
          .removeComment({ boardId, taskId, commentId: comment.id })
          .then(() => {
            if (onCommentRemoved) {
              onCommentRemoved(comment.id);
            }
          })
          .catch(() => {
            setLoading(false);
          });
        break;
      }
    }
  };

  const handleCreateItemClick = ({ values, setFieldValue }) => {
    setLoading(true);

    api
      .createComment({ boardId, taskId }, { text: values.creatingText })
      .then((createdComment) => {
        setLoading(false);
        setFieldValue('creatingText', '');
        setCreating(false);

        if (onCommentCreated) {
          onCommentCreated(createdComment);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleUpdateItemClick = ({ comment }) => {
    setLoading(true);
    setEditing(null);

    api
      .updateComment(
        { boardId, taskId, commentId: comment.id },
        {
          text: comment.text,
        },
      )
      .then((updatedComment) => {
        setLoading(false);

        if (onCommentUpdated) {
          onCommentUpdated(updatedComment);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancelCreatingItemClick = ({ setFieldValue }) => {
    setCreating(false);

    setFieldValue('creatingText', '');
  };

  const handleCancelEditingItemClick = ({
    commentId,
    comments,
    currentComments,
    setFieldValue,
  }) => {
    const prevComment = comments.find((comment) => comment.id === commentId);

    setFieldValue(
      'comments',
      currentComments.map((comment) => {
        if (comment.id === prevComment.id) {
          return {
            ...comment,
            text: prevComment.text,
          };
        }

        return comment;
      }),
    );
  };

  return (
    <CommentsSection
      {...restProps}
      open={open}
      creating={creating}
      editing={editing}
      user={user}
      onCreatingInputFocus={handleCreatingInputFocus}
      onCreatingInputBlur={handleCreatingInputBlur}
      onEditingInputBlur={handleEditingInputBlur}
      onToggleClick={handleToggleClick}
      onCreateItemClick={handleCreateItemClick}
      onUpdateItemClick={handleUpdateItemClick}
      onActionItemClick={handleActionItemClick}
      onCancelCreatingItemClick={handleCancelCreatingItemClick}
      onCancelEditingItemClick={handleCancelEditingItemClick}
    />
  );
};

export default React.memo(CommentsSectionContainer);
