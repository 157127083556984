import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';

import styles from './DeleteListModal.styles.scss';

const DeleteListModal = ({ loading, isOpen, onClose, onDelete, onMove }) => {
  const { t, i18n } = useTranslation('deleteListModal');

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <div className={styles.close} onClick={onClose}>
        <Icon className={styles.closeIcon} name="close" />
      </div>

      <Typography
        className={styles.title}
        variant="h2"
        weight={i18n.language === 'ru' ? 'regular' : 'medium'}
      >
        {t('title')}
      </Typography>
      <Typography className={styles.subtitle}>{t('subtitle')}</Typography>
      <div className={styles.controls}>
        <div className={styles.controls}>
          <Button className={styles.button} loading={loading} onClick={onMove}>
            {t('moveButton')}
          </Button>
          <Button
            className={styles.button}
            variant="outline"
            color="alert"
            onClick={onDelete}
          >
            {t('deleteButton')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(DeleteListModal);
