import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { getPAGE_CONSTANTS } from '../../ResetPassword.constants';
import { PAGE_CONSTANTS } from '../../ResetPassword.constants';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { FormikControl } from 'components/shared/FormikControl';

import styles from './PasswordStep.styles.scss';

const PasswordStep = ({
  loading,
  showPassword,
  showConfirmPassword,
  error,
  passwordInfo,
  onEyeClick,
  onAfterPasswordChange,
}) => {
  const { t } = useTranslation(['password', 'resetPasswordPasswordPage']);

  const passwordStatusStyle = {
    [styles.weak]: passwordInfo === getPAGE_CONSTANTS(t).WEAK_PASSWORD,
    [styles.normal]: passwordInfo === getPAGE_CONSTANTS(t).NORMAL_PASSWORD,
    [styles.strong]: passwordInfo === getPAGE_CONSTANTS(t).STRONG_PASSWORD,
  };

  return (
    <div className={styles.step}>
      <Typography className={styles.title} variant="h1">
        {t('resetPasswordPasswordPage:title')}
      </Typography>
      <Typography className={styles.subtitle} variant="main">
        {t('resetPasswordPasswordPage:subtitle')}
      </Typography>
      <div className={styles.passwordWrapper}>
        <FormikControl
          formClassName={styles.inputForm}
          inputClassName={cn({ [styles.input]: !error }, passwordStatusStyle)}
          component="input"
          label={t('resetPasswordPasswordPage:passwordLabel')}
          name="password"
          placeholder={t('resetPasswordPasswordPage:passwordPlaceholder')}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <Icon
              className={styles.showPasswordIcon}
              name={showPassword ? 'showEye' : 'hideEye'}
              onClick={() => {
                onEyeClick(PAGE_CONSTANTS.PASSWORD);
              }}
            />
          }
          onAfterChange={onAfterPasswordChange}
        />
        <Typography
          className={cn(styles.passwordInfo, passwordStatusStyle)}
          variant="subtitle"
        >
          {!error && passwordInfo}
        </Typography>
      </div>
      <FormikControl
        formClassName={styles.inputForm}
        component="input"
        label={t('resetPasswordPasswordPage:confirmPasswordLabel')}
        name="confirmPassword"
        placeholder={t('resetPasswordPasswordPage:confirmPasswordPlaceholder')}
        type={showConfirmPassword ? 'text' : 'password'}
        endAdornment={
          <Icon
            className={styles.showPasswordIcon}
            name={showConfirmPassword ? 'showEye' : 'hideEye'}
            onClick={() => {
              onEyeClick(PAGE_CONSTANTS.CONFIRM_PASSWORD);
            }}
          />
        }
      />

      <Button className={styles.button} type="submit" loading={loading}>
        {t('resetPasswordPasswordPage:nextButton')}
      </Button>
    </div>
  );
};

export default React.memo(PasswordStep);
