import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import { Formik, Form, Field } from 'formik';
import { sliderSettings } from './LoginPage.config';
import formConfig from './LoginPage.formConfig';

import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { ErrorMessage } from 'components/shared/ErrorMessage';

import { InputField } from 'components/FormikFields';

import { CodeStep } from './components/CodeStep';

import styles from './LoginPage.styles.scss';

const LoginPage = ({
  loading,
  loginSliderRef,
  validationSchema,
  showPassword,
  onSubmit,
  onEyeClick,
}) => {
  const { t } = useTranslation('signInPage');

  return (
    <div className={styles.page}>
      <Formik
        {...formConfig}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, errors }) => (
          <Form>
            <Slider {...sliderSettings} ref={loginSliderRef}>
              <div>
                <Typography variant="h1" className={styles.title}>
                  {t('title')}
                </Typography>
                <Field
                  classNames={{
                    formControl: styles.formInput,
                  }}
                  label={t('emailLabel')}
                  name="email"
                  placeholder={t('emailPlaceholder')}
                  component={InputField}
                />
                <Field
                  label={t('passwordLabel')}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('passwordPlaceholder')}
                  component={InputField}
                  endAdornment={
                    <Icon
                      name={showPassword ? 'showEye' : 'hideEye'}
                      className={styles.showPasswordIcon}
                      onClick={onEyeClick}
                    />
                  }
                  inputClassName={styles.input}
                />
                <div className={styles.actions}>
                  {errors.form && (
                    <ErrorMessage className={styles.error}>
                      {errors.form}
                    </ErrorMessage>
                  )}
                  <Button
                    type="submit"
                    loading={loading}
                    className={styles.button}
                  >
                    {t('signInButton')}
                  </Button>
                </div>
                <div className={styles.links}>
                  <Link to="/register">{t('signUpLink')}</Link>
                  <Link to="/reset-password">{t('forgotPasswordLink')}</Link>
                </div>
              </div>
              <CodeStep errors={errors} />
            </Slider>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(LoginPage);
