import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';

import { getFilterConfig } from '../../BoardPage.config';

import styles from './Filter.styles.scss';

const Filter = ({ className, activeFilter, onFilterChange }) => {
  const { t } = useTranslation('boardsPage');

  return (
    <div className={cn(styles.root, className)}>
      {getFilterConfig(t).map((filter) => (
        <div
          className={cn(styles.filter, {
            [styles.active]: activeFilter === filter.value,
          })}
          key={filter.value}
          onClick={() => onFilterChange(filter.value)}
        >
          <Typography className={styles.text}>{filter.label}</Typography>
        </div>
      ))}
    </div>
  );
};

export default React.memo(Filter);
