import React from 'react';
import cn from 'classnames';
import { uniqBy } from 'lodash';
import { Tooltip } from 'react-tippy';
import { useTranslation } from 'react-i18next';
import { Container, Draggable } from 'react-smooth-dnd';

import { TASK_MAX_NAME, LIST_MAX_NAME } from 'constants/common';
import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';
import { CreatingInput } from 'components/CreatingInput';
import { ContextMenuPopover } from 'components/ContextMenuPopover';
import { TaskCard } from './components/TaskCard';
import { getItems } from './List.constants';

import styles from './List.styles.scss';

const List = ({
  onSaveReserveTaskData,
  error,
  editing,
  loading,
  tasksLoading,
  type,
  wipLimit,
  listId,
  boardId,
  value,
  taskTypes,
  tasks = [],
  getTaskCardPayload,
  onTaskDrop,
  onMouseUp,
  onInputListChange,
  onInputListBlur,
  onInputListKeyDown,
  onTaskCreate,
  onTaskCreated,
  onTaskClick,
  onMoreItemClick,
}) => {
  const { t } = useTranslation('boardPage');

  return (
    <div className={styles.list}>
      <div className={styles.header}>
        <div className={styles.inputWrapper} onMouseUp={onMouseUp}>
          {editing ? (
            <>
              {error && (
                <Typography className={styles.errorText} variant="subtitle">
                  {error}
                </Typography>
              )}
              <input
                className={cn(styles.input, { [styles.error]: error })}
                autoFocus
                value={value}
                maxLength={LIST_MAX_NAME}
                onChange={onInputListChange}
                onBlur={onInputListBlur}
                onKeyDown={onInputListKeyDown}
              />
            </>
          ) : (
            <>
              <Tooltip
                trigger="mouseenter"
                position="top"
                html={
                  <div className={styles.tippy}>
                    <Typography className={styles.tippyText} variant="subtitle">
                      {value}
                    </Typography>
                  </div>
                }
              >
                <div className={styles.nameWrapper}>
                  <Typography
                    variant="body"
                    weight="medium"
                    className={styles.name}
                  >
                    {value}
                  </Typography>
                </div>
              </Tooltip>
              {wipLimit || tasks.length > 0 ? (
                <Tooltip
                  trigger="mouseenter"
                  position="bottom"
                  html={
                    <div
                      className={cn(styles.tippy, styles.limitTippy, {
                        [styles.empty]: !wipLimit,
                      })}
                    >
                      <Typography
                        className={styles.tippyText}
                        variant="subtitle"
                      >
                        {`${t('limitLabel')} ${tasks.length}/${wipLimit}`}
                      </Typography>
                    </div>
                  }
                >
                  <div
                    className={cn(styles.counterWrapper, {
                      [styles.limitCounter]: wipLimit,
                      [styles.warningLimitCounter]: wipLimit === tasks.length,
                      [styles.errorLimitCounter]:
                        wipLimit && wipLimit < tasks.length,
                    })}
                  >
                    <Typography
                      className={styles.taskCounter}
                      variant="subtitle"
                      weight="medium"
                    >
                      {wipLimit ? `${tasks.length}/${wipLimit}` : tasks.length}
                    </Typography>
                  </div>
                </Tooltip>
              ) : null}
            </>
          )}
          {loading && <Loading className={styles.loading} size={17} />}
        </div>

        <ContextMenuPopover
          type={type}
          listId={listId}
          boardId={boardId}
          offset="10"
          position="bottom-start"
          wipLimit={wipLimit}
          items={getItems(t)}
          onItemClick={onMoreItemClick}
        >
          {({ open, openPopover }) => (
            <div
              className={cn(styles.more, { [styles.moreActive]: open })}
              onClick={() => openPopover(!open)}
            >
              <Icon className={styles.moreIcon} name="dots" />
            </div>
          )}
        </ContextMenuPopover>
      </div>

      <div className={styles.inner}>
        {tasksLoading ? (
          <Loading className={styles.tasksLoading} size={17} />
        ) : (
          <ScrollWrapper
            contentClassName={styles.scrollContent}
            thumbClassName={styles.track}
          >
            <Container
              groupName="tasks"
              orientation="vertical"
              onDrop={onTaskDrop}
              getChildPayload={getTaskCardPayload}
              onDragStart={onSaveReserveTaskData}
              dropPlaceholder={{
                className: styles.dropPlaceholder,
                animationDuration: 150,
                showOnTop: true,
              }}
              style={{ height: '100%' }}
            >
              {tasks.map((task, index) => (
                <Draggable key={task.id}>
                  <TaskCard
                    {...task}
                    taskIndex={index}
                    listLength={tasks.length}
                    taskTypes={taskTypes}
                    boardId={boardId}
                    memberList={uniqBy(task.memberList || [], 'id')}
                    onClick={() => onTaskClick(task.id, task.orderNumber)}
                  />
                </Draggable>
              ))}

              <CreatingInput
                className={cn(styles.creatingInput, {
                  [styles.creatingInputEmpty]: tasks.length === 0,
                })}
                withSubmit={false}
                title={t('addTaskPlaceholder')}
                placeholder={t('taskNamePlaceholder')}
                onItemCreate={onTaskCreate}
                onItemCreated={onTaskCreated}
                maxLength={TASK_MAX_NAME}
              />
            </Container>
          </ScrollWrapper>
        )}
      </div>
    </div>
  );
};

export default React.memo(List);
