import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './ColorRadio.styles.scss';

const VARIANTS = {
  square: styles.square,
  circle: styles.circle,
};

const ColorRadio = ({
  hover,
  withIcon,
  className,
  inputClassName,
  iconWrapperClassName,
  iconClassName,
  size = 34,
  color,
  activeColor,
  checked,
  onMouseOver,
  onMouseOut,
  variant = 'circle',
  ...restProps
}) => (
  <div
    className={cn(styles.radio, VARIANTS[variant], className)}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
  >
    <input
      className={cn(
        styles.input,
        { [styles.checked]: checked },
        inputClassName,
      )}
      type="radio"
      {...restProps}
    />
    <div
      style={
        hover
          ? { background: activeColor, width: size + 2, height: size + 2 }
          : { background: color, width: size + 2, height: size + 2 }
      }
      className={cn(styles.iconWrapper, iconWrapperClassName)}
    >
      {withIcon && checked ? (
        <Icon className={styles.checkedIcon} name="check" />
      ) : (
        <div
          className={cn(styles.checkIcon, iconClassName)}
          style={{
            width: size,
            height: size,
          }}
        />
      )}
    </div>
  </div>
);

export default React.memo(ColorRadio);
