import * as Yup from 'yup';

import { checkUniqType } from './EditType.helpers';

export const getValidationSchema = (t, taskTypes, typeId) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('requiredTitleError'))
      .max(30, t('shortTitleError'))
      .test('uniq', t('uniqTypeName'), (value) =>
        checkUniqType({ taskTypes, typeId, value }),
      ),

    color: Yup.string().required(t('requiredColorError')),
  });

export const getInitialValues = (type) => ({
  color: type.color || '',
  name: type.name || '',
});
