import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import AnalyticsPage from './AnalyticsPage';

const AnalyticsPageContainer = () => {
  const history = useHistory();
  const { id: boardId } = useParams();

  const handleTimelineClick = () => {
    history.push(`/boards/${boardId}/analytics/timeline`);
  };

  const handleBackButtonClick = () => {
    if (window.location.pathname !== `/boards/${boardId}/analytics`) {
      history.push(`/boards/${boardId}`);
    } else {
      history.push(`/boards/${boardId}`);
    }
  };

  return (
    <AnalyticsPage
      onTimelineClick={handleTimelineClick}
      onBackButtonClick={handleBackButtonClick}
    />
  );
};

export default React.memo(AnalyticsPageContainer);
