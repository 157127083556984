import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import * as api from 'api/methods';

import { getErrorAction } from 'helpers/getErrorAction';

import AcceptInvitePage from './AcceptInvitePage';

const AcceptInvitePageContainer = () => {
  const history = useHistory();

  const { code } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    api
      .acceptInvite(code)
      .then((board) => {
        setLoading(false);

        history.replace(`/boards/${board.id}`);
      })
      .catch(({ status }) => {
        setLoading(false);

        getErrorAction({ status, history });
      });
  }, []);

  return <AcceptInvitePage loading={loading} />;
};

export default React.memo(AcceptInvitePageContainer);
