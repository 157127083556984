import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { Loading } from 'components/shared/Loading';

import styles from './UnsubscribePage.styles.scss';

const UnsubscribePage = ({ loading, onUnsubscribe }) => {
  const { t } = useTranslation('unsubscribePage');

  return loading ? (
    <Loading className={styles.loading} size={30} color="#623CEA" />
  ) : (
    <div className={styles.page}>
      <Typography className={styles.title} variant="h1" weight="regular">
        {t('title')}
      </Typography>

      <Typography className={styles.subtitle} variant="main" weight="regular">
        {t('subtitleStart')}{' '}
        <span className={styles.highlightedText}>X-CORE</span>
        <span>.ru?</span>
        {t('subtitleFinish')}
      </Typography>

      <Button onClick={onUnsubscribe}>{t('title')}</Button>
    </div>
  );
};

export default React.memo(UnsubscribePage);
