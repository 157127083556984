import React, { useCallback } from 'react';
import cn from 'classnames';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { convertDate } from 'helpers/convertDate';

import { Link } from 'components/shared/Link';
import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';
import { Avatar } from 'components/shared/Avatar';

import { CreatingInput } from '../CreatingInput';
import { ActionsPanel } from '../ActionsPanel';

import { getActionItems } from './CommentsSection.constants';

import styles from './CommentsSection.styles.scss';

const CommentsSection = ({
  open,
  creating = false,
  editing = null,
  comments = [],
  user,
  onCreatingInputFocus,
  onCreatingInputBlur,
  onEditingInputBlur,
  onCreateItemClick,
  onUpdateItemClick,
  onActionItemClick,
  onCancelCreatingItemClick,
  onCancelEditingItemClick,
  onToggleClick,
}) => {
  const { t } = useTranslation('taskModal');

  const handleCreateItemClick = useCallback(
    (values, setFieldValue) => () =>
      onCreateItemClick({ values, setFieldValue }),
    [onCreateItemClick],
  );

  const handleCancelCreatingItemClick = useCallback(
    (setFieldValue) => () => onCancelCreatingItemClick({ setFieldValue }),
    [onCancelCreatingItemClick],
  );

  const handleUpdateItemClick = useCallback(
    (comment, setFieldValue) => () =>
      onUpdateItemClick({ comment, setFieldValue }),
    [onUpdateItemClick],
  );

  const handleCancelEditingItemClick = useCallback(
    (comment, values, setFieldValue) => () =>
      onCancelEditingItemClick({
        commentId: comment.id,
        comments,
        currentComments: values.comments,
        setFieldValue,
      }),
    [onCancelEditingItemClick, comments],
  );

  return (
    <div className={styles.section}>
      <Collapsible
        open={open}
        transitionTime={150}
        headerComponent={
          <div
            className={cn(styles.collapsedHeader, {
              [styles.expandedHeader]: open,
            })}
          >
            <Typography variant="body" weight="medium" className={styles.title}>
              {t('commentsLabel')} ({comments.length})
            </Typography>
            <Link onClick={onToggleClick}>
              {open ? t('hideTrigger') : t('showTrigger')}
            </Link>
          </div>
        }
        innerComponent={
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={{ comments, creatingText: '' }}
          >
            {({ values, setFieldValue }) => (
              <div className={styles.inner}>
                <div
                  className={cn(styles.creatingComment, {
                    [styles.creatingWithComment]: comments.length,
                  })}
                >
                  <Avatar
                    variant="medium"
                    className={styles.avatar}
                    fullName={user.fullName}
                    color={user.avatarColor}
                  />
                  <CreatingInput
                    className={styles.creatingInput}
                    controlsClassName={styles.controlsClassName}
                    buttonClassName={styles.button}
                    editing={creating}
                    name="creatingText"
                    placeholder={t('commentsPlaceholder')}
                    confirmText={t('addCommentButton')}
                    cancelText={t('cancelButton')}
                    onInputFocus={onCreatingInputFocus}
                    onInputBlur={onCreatingInputBlur}
                    onConfirmClick={handleCreateItemClick(
                      values,
                      setFieldValue,
                    )}
                    onCancelClick={handleCancelCreatingItemClick(setFieldValue)}
                  />
                </div>
                <div className={styles.comments}>
                  {values.comments.map((comment, index) => (
                    <div key={comment.id} className={styles.comment}>
                      <Avatar
                        variant="medium"
                        className={styles.commentAvatar}
                        fullName={comment.boardMember.fullName}
                        color={comment.boardMember.avatarColor}
                      />
                      <div className={styles.commentInner}>
                        <div className={styles.commentHeader}>
                          <Typography
                            variant="body"
                            weight="medium"
                            className={styles.commentUserName}
                          >
                            {comment.boardMember.fullName}
                          </Typography>
                          <Typography
                            variant="body"
                            className={styles.commentDate}
                          >
                            {comment.updatedDate
                              ? `${t('commentEdited')} ${convertDate(
                                  comment.updatedDate,
                                  'dd.MM HH:mm',
                                )}`
                              : convertDate(comment.createdAt, 'dd.MM HH:mm')}
                          </Typography>
                        </div>
                        {editing === comment.id ? (
                          <CreatingInput
                            className={styles.editingInput}
                            editing
                            autoFocus
                            name={`comments[${index}].text`}
                            confirmText={t('addCommentButton')}
                            cancelText={t('cancelButton')}
                            onInputBlur={onEditingInputBlur}
                            onConfirmClick={handleUpdateItemClick(
                              comment,
                              setFieldValue,
                            )}
                            onCancelClick={handleCancelEditingItemClick(
                              comment,
                              values,
                              setFieldValue,
                            )}
                          />
                        ) : (
                          <>
                            <Typography
                              variant="body"
                              className={styles.commentText}
                            >
                              {comment.text}
                            </Typography>
                            {comment.boardMember.id === user.id && (
                              <ActionsPanel
                                className={styles.commentActions}
                                items={getActionItems(t)}
                                onItemClick={(item) =>
                                  onActionItemClick(item, comment)
                                }
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default React.memo(CommentsSection);
