import React from 'react';
import { components } from 'react-select';

import styles from './SingleValue.styles.scss';

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props} className={styles.singleValue}>
    {children}
  </components.SingleValue>
);

export default React.memo(SingleValue);
