import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/auth/selectors';

import { showToastMessage } from 'helpers/showToastMessage';

import { getTimeDistance } from './TaskInfo.helpers';

import TaskInfo from './TaskInfo';

const TaskInfoContainer = ({ identifier, lastHistory, ...restProps }) => {
  const { t } = useTranslation('toast');
  const { t: t_taskInfo } = useTranslation('taskInfo');

  const user = useSelector(userSelector);

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(identifier);

    showToastMessage({
      variant: 'success',
      title: t('copyId'),
    });
  };

  const timeDistance = getTimeDistance(lastHistory, user.language, t_taskInfo);

  return (
    <TaskInfo
      {...restProps}
      identifier={identifier}
      timeDistance={timeDistance}
      onCopyLinkClick={handleCopyLinkClick}
    />
  );
};

export default React.memo(TaskInfoContainer);
