import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import styles from './AttachementButton.styles.scss';

const AttachementButton = ({
  getFileRootProps,
  getFileInputProps,
  onClick,
}) => {
  const { t } = useTranslation('taskModal');

  return (
    <div className={styles.root} {...getFileRootProps()}>
      <Button
        className={styles.button}
        variant="outline"
        size="small"
        onClick={onClick}
      >
        <Icon className={styles.icon} name="paperClip" />
        {t('attachmentButton')}
      </Button>
    </div>
  );
};

export default React.memo(AttachementButton);
