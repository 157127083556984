import React, { useState } from 'react';

import TasksSection from './TasksSection';

const TasksSectionContainer = ({ onChangeTaskPosition, ...restProps }) => {
  const [resizing, setResizing] = useState(false);

  const handleResizing = (state) => {
    setResizing(state);
  };

  return (
    <TasksSection
      {...restProps}
      resizing={resizing}
      setResizing={setResizing}
      onResizing={handleResizing}
    />
  );
};

export default React.memo(TasksSectionContainer);
