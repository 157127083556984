import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import * as api from 'api/methods';

import { setFormError } from './EmailStep.helpers';

import EmailStep from './EmailStep';

const EmailStepContainer = ({ sliderRef, errors }) => {
  const { t } = useTranslation('resetPasswordEmailPage');
  const { values, setFieldError } = useFormikContext();

  const [loading, setLoading] = useState(false);

  const handleNextStepClick = () => {
    if (!errors) {
      setLoading(true);
      api
        .forgotPassword({ email: values.email })
        .then(() => {
          setLoading(false);
          sliderRef.current.slickNext();
        })
        .catch(({ status }) => {
          setFormError({ status, setFieldError, t });
          setLoading(false);
        });
    }
  };

  return <EmailStep loading={loading} onNextStepClick={handleNextStepClick} />;
};

export default React.memo(EmailStepContainer);
