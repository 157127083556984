import React from 'react';
import cn from 'classnames';
import Popover from 'react-popover';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { ItemSettings } from '../ItemSettings';

import styles from './FilterItem.styles.scss';

const FilterItem = ({
  isOpen,
  title,
  params,
  onFilterClick,
  onOuterAction,
}) => (
  <Popover
    isOpen={isOpen}
    body={<ItemSettings params={params} />}
    preferPlace="below"
    onOuterAction={onOuterAction}
    className={styles.popover}
  >
    <div className={styles.item} onClick={onFilterClick}>
      <Typography className={styles.text}>{title}</Typography>
      <Icon
        name="arrowDown"
        className={cn(styles.icon, { [styles.openIcon]: isOpen })}
      />
    </div>
  </Popover>
);

export default React.memo(FilterItem);
