import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/Button';

import styles from './ModalControls.styles.scss';

const ModalControls = ({
  className,
  confirmButtonClassName,
  loading,
  submitType,
  onConfirmClick,
  onCancelClick,
  ...restProps
}) => {
  const { t } = useTranslation('modalControls');

  const submitTitle = restProps.submitTitle || t('submitButton');
  const cancelTitle = restProps.cancelTitle || t('cancelButton');

  return (
    <div className={cn(styles.controls, className)}>
      <Button
        className={cn(styles.button, confirmButtonClassName)}
        type={submitType}
        loading={loading}
        onClick={onConfirmClick}
      >
        {submitTitle}
      </Button>
      <Button
        className={styles.button}
        variant="outline"
        color="alert"
        onClick={onCancelClick}
      >
        {cancelTitle}
      </Button>
    </div>
  );
};

export default React.memo(ModalControls);
