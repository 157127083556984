import React, { useState, useRef } from 'react';

import TextEditor from './TextEditor';

const TextEditorContainer = ({ onBlur, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const textareaRef = useRef();

  const handleMarkdownClick = () => {
    setEditing(true);

    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 0);
  };

  const handleInputBlur = () => {
    setEditing(false);

    onBlur();
  };

  const handleInputFocus = () => {
    setEditing(true);
  };

  return (
    <TextEditor
      {...restProps}
      textareaRef={textareaRef}
      editing={editing}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      onMarkdownClick={handleMarkdownClick}
    />
  );
};

export default React.memo(TextEditorContainer);
