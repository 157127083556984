import React, {
  createContext,
  useEffect,
  useContext,
  useReducer,
  useState,
} from 'react';
import _flatten from 'lodash/flatten';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { ERROR_TYPES } from 'constants/errorTypes';
import { BOARD_PROVIDER_TYPES } from './BoardPageProvider.constants';

import { boardReducer } from './BoardPageReducer';

import * as api from 'api/methods';

import { Loading } from 'components/shared/Loading';

const BoardContext = createContext();

const convertResponseData = ({ boardData, boardLists, boardTasks }) => ({
  ...boardData,
  lists: boardLists.map((list) => ({
    ...list,
    tasks: boardTasks.filter((task) => task.columnId === list.id),
  })),
});

const BoardProvider = ({ boardId, children }) => {
  const [state, dispatch] = useReducer(boardReducer, { board: {} });
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const value = { state, dispatch };

  useEffect(() => {
    setLoading(true);

    Promise.all([api.getBoard(boardId), api.getBoardList(boardId)])
      .then(([board, boardLists]) => {
        Promise.all(
          boardLists.map((list) =>
            api.getListTasks({ boardId, listId: list.id }),
          ),
        ).then((response) => {
          dispatch({
            type: BOARD_PROVIDER_TYPES.SET_BOARD_DATA,
            payload: convertResponseData({
              boardData: board,
              boardLists,
              boardTasks: _flatten(response),
            }),
          });
        });

        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);

        if (status === ERROR_TYPES.BOARD_DOES_NOT_EXIST) {
          history.replace(ROUTES.BOARDS);
        }
      });
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <BoardContext.Provider value={value}>{children}</BoardContext.Provider>
  );
};

const useBoard = () => {
  const context = useContext(BoardContext);

  if (context === undefined) {
    throw new Error('useBoard must be used within a BoardProvider');
  }

  return {
    board: context.state.board,
    dispatch: context.dispatch,
  };
};

export { BoardProvider, useBoard };
