import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { getColors, getUsedColors } from './EditType.helpers';
import { getValidationSchema, getInitialValues } from './EditType.formConfig';

import { Typography } from 'components/shared/Typography';
import { InputField } from 'components/FormikFields';
import { ModalControls } from 'components/ModalControls';
import { ColorRadioField } from 'components/FormikFields';

import styles from './EditType.styles.scss';

const EditType = ({
  loading,
  editingType,
  taskTypes = {},
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation('typePopover');
  const { t: t_validation } = useTranslation('validation');

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={getValidationSchema(
        t_validation,
        taskTypes,
        editingType.id,
      )}
      initialValues={getInitialValues(editingType)}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className={styles.form}>
          <Typography className={styles.title} variant="h2" weight="medium">
            {t('settingsTitle')}
          </Typography>

          <div className={styles.inner}>
            <Field
              name="name"
              label={t('labelTitle')}
              placeholder={t('addTypePlaceholder')}
              component={InputField}
            />
            <Field
              className={styles.colorRadio}
              formControlClassName={styles.formControl}
              colorsWrapperClassName={styles.colorsWrapper}
              withIcon
              name="color"
              variant="square"
              label={t('labelColor')}
              colors={getColors(taskTypes)}
              component={ColorRadioField}
            />
            {!!taskTypes.length && (
              <Field
                className={styles.colorRadio}
                formControlClassName={styles.formControl}
                colorsWrapperClassName={styles.colorsWrapper}
                withIcon
                name="color"
                variant="square"
                label={t('labelUsedColors')}
                colors={getUsedColors(taskTypes)}
                component={ColorRadioField}
              />
            )}
          </div>
          <ModalControls
            loading={loading}
            submitType="submit"
            onCancelClick={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(EditType);
