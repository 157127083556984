import React from 'react';
import cn from 'classnames';

import { Header } from './components/Header';

import styles from './MainLayout.styles.scss';

const MainLayout = ({ className, children }) => (
  <div className={cn(styles.layout, className)}>
    <div className={styles.inner}>
      <Header />
      {children}
    </div>
  </div>
);

export default React.memo(MainLayout);
