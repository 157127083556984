import React, { useState } from 'react';

import { PARTS_TYPE } from './GuideModal.constants';

import GuideModal from './GuideModal';

const GuideModalContainer = ({ isOpen, onClose, ...restProps }) => {
  const [visibleChapter, setVisibleChapter] = useState(PARTS_TYPE.CONFIGURE);

  const handleControlClick = (name) => {
    setVisibleChapter(name);
  };

  return (
    <GuideModal
      isOpen={isOpen}
      visibleChapter={visibleChapter}
      onControlClick={handleControlClick}
      onClose={onClose}
      {...restProps}
    />
  );
};

export default React.memo(GuideModalContainer);
