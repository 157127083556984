import { ERROR_TYPES } from 'constants/errorTypes';

export const setFormError = ({ status, setFieldError, errorCounter, t }) => {
  switch (status) {
    case ERROR_TYPES.EMAIL_IS_NOT_VALID:
    case ERROR_TYPES.PASSWORD_IS_INVALID: {
      setFieldError('code', t('incorrectValueError'));

      break;
    }
    case ERROR_TYPES.CONFIRMATION_CODE_IS_EMPTY: {
      setFieldError('code', t('emptyCodeError'));

      break;
    }
    case ERROR_TYPES.REQUEST_COUNT_LIMIT_EXPIRED: {
      setFieldError('code', t('codeCounterLimitError'));

      break;
    }
    case ERROR_TYPES.USER_NOT_FOUND: {
      setFieldError('code', t('notFoundUserError'));

      break;
    }
    case ERROR_TYPES.CONFIRMATION_CODE_IS_NULL: {
      setFieldError('code', t('codeIsNullError'));

      break;
    }
    case ERROR_TYPES.CODE_EXPIRED: {
      setFieldError('code', t('expiredCodeError'));

      break;
    }
    case ERROR_TYPES.ACCOUNT_DELETED: {
      setFieldError('code', t('deletedAccountError'));

      break;
    }
    case ERROR_TYPES.CODE_DOES_NOT_MATCH:
    case ERROR_TYPES.CONFIRMATION_NOT_VALID: {
      setFieldError('code', t('notValidCodeError'));

      break;
    }

    default: {
      console.log(t('defaultError'));
    }
  }
};
