import { TIME_PERIOD } from '../../TimelinePage.constants';

export const getTabButton = (t) => {
  return [
    {
      type: TIME_PERIOD.MONTH,
      name: t('month'),
    },
    {
      type: TIME_PERIOD.WEEK,
      name: t('week'),
    },
    {
      type: TIME_PERIOD.DAY,
      name: t('day'),
    },
  ];
};
