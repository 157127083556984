import React from 'react';

import { FormControl } from 'components/shared/FormControl';
import { Switch } from 'components/shared/Switch';

import styles from './SwitchField.styles.scss';

const SwitchField = ({ field, form, onChange, ...restProps }) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.checked, field.name, form);
    }

    form.setFieldValue(field.name, event.target.checked);
  };

  return (
    <FormControl className={styles.formControl}>
      <Switch {...field} {...restProps} onChange={handleChange} />
    </FormControl>
  );
};

export default React.memo(SwitchField);
