import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from 'constants/routes';

import * as api from 'api/methods';

import { systemMetaSelector } from 'store/system/selectors';

import { showToastMessage } from 'helpers/showToastMessage';

import { convertDataToApi, setFormError } from './ResetPassword.helpers';

import { getValidationSchema } from './ResetPassword.formConfig';

import ResetPasswordPage from './ResetPasswordPage';

const ResetPasswordPageContainer = () => {
  const { t } = useTranslation(['validation', 'toast']);

  const sliderRef = useRef();
  const history = useHistory();
  const meta = useSelector(systemMetaSelector);

  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');

  const handleSubmit = (values, actions) => {
    setLoading(true);

    const data = convertDataToApi(values);

    api
      .resetPassword(data, confirmationCode.join(''))
      .then(() => {
        setLoading(false);

        showToastMessage({
          variant: 'success',
          title: t('toast:passwordChanged'),
        });

        setTimeout(() => {
          history.push(ROUTES.LOGIN);
        }, 1000);
      })
      .catch(({ status }) => {
        setFormError({ status, setFieldError: actions.setFieldError, t });
        setLoading(false);
      });
  };

  const handleSaveConfirmationCode = (code) => {
    setConfirmationCode(code);
  };

  const passwordPattern = meta ? meta.passwordPattern : '';
  const emailPattern = meta ? meta.emailPattern : '';

  const validationSchema =
    meta && getValidationSchema(passwordPattern, emailPattern, t);

  return (
    <ResetPasswordPage
      loading={loading}
      sliderRef={sliderRef}
      passwordConfig={passwordPattern}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onSaveConfirmationCode={handleSaveConfirmationCode}
    />
  );
};

export default React.memo(ResetPasswordPageContainer);
