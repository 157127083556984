import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { BoardCard } from 'components/BoardCard';
import { PageLayout } from 'components/Layout';
import { WelcomePopover } from 'components/OnboardingModal/WelcomePopover';

import { Filter } from './components/Filter';
import { NewBoardCard } from './components/NewBoardCard';

import styles from './BoardsPage.styles.scss';

const BoardsPage = ({
  isWelcomePopoverOpen,
  loading,
  user,
  activeFilter,
  boards,
  onFilterChange,
  onBoardDeleted,
  onCreateBoardClick,
  onFavoriteClick,
  onWelcomePopoverClose,
}) => {
  const { t } = useTranslation('boardsPage');

  return (
    <PageLayout
      title={t('title')}
      withActions
      //withSearch
      //withFilter
      actions={
        <WelcomePopover
          isOpen={isWelcomePopoverOpen}
          onClose={onWelcomePopoverClose}
        >
          <Button
            className={styles.button}
            endIcon={<Icon className={styles.icon} name="plus" />}
            onClick={onCreateBoardClick}
          >
            {t('createButton')}
          </Button>
        </WelcomePopover>
      }
    >
      <Filter
        className={styles.filter}
        activeFilter={activeFilter}
        onFilterChange={onFilterChange}
      />
      {loading ? (
        <Loading className={styles.loading} size={30} />
      ) : (
        <div className={styles.inner}>
          {boards.length ? (
            <div className={styles.boards}>
              {boards.map((board) => (
                <BoardCard
                  key={board.id}
                  canDelete={
                    board.memberList.find((member) => member.id === user.id)
                      .role === 'ADMIN'
                  }
                  favorite={board.favorite}
                  members={board.memberList}
                  id={board.id}
                  title={board.name}
                  color={board.color}
                  onFavoriteClick={onFavoriteClick}
                  onBoardDeleted={onBoardDeleted}
                />
              ))}
              <NewBoardCard onClick={onCreateBoardClick} />
            </div>
          ) : (
            <Typography
              className={styles.emptyText}
              variant="main"
              weight="regular"
            >
              {t('subtitle')}
            </Typography>
          )}
        </div>
      )}
    </PageLayout>
  );
};

export default React.memo(BoardsPage);
