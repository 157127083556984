import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Controls.styles.scss';

const Controls = ({ name, onClick, active }) => {
  return (
    <div
      className={cn(styles.buttons, { [styles.active]: active })}
      onClick={onClick}
    >
      <Typography className={styles.name} variant="body" weight="regular">
        {name}
      </Typography>
    </div>
  );
};

export default React.memo(Controls);
