import * as Yup from 'yup';

import { checkUniqType } from './AddNewType.helpers';

export const getValidationSchema = (t, taskTypes) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('requiredTitleError'))
      .max(30, t('shortTitleError'))
      .test('uniq', t('uniqTypeName'), (value) =>
        checkUniqType({ taskTypes, value }),
      ),

    color: Yup.string().required(t('requiredColorError')),
  });

export const initialValues = {
  name: '',
  color: '',
};
