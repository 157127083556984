import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { BOARD_COLORS } from 'constants/board/colors';

import { convertDataToForm } from './CreateBoardModal.helpers';

import { getValidationSchema } from './CreateBoardModal.formConfig';

import { Modal } from 'components/shared/Modal';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { InputField } from 'components/FormikFields';
import { ColorRadioField } from 'components/FormikFields';
import { ModalControls } from 'components/ModalControls';

import { Template } from './Template';

import styles from './CreateBoardModal.styles.scss';

const CreateBoardModal = ({
  loading,
  templatesLoading,
  isOpen,
  templates,
  onSubmit,
  onClose,
}) => {
  const { t, i18n } = useTranslation(['validation', 'createBoardModal']);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
    >
      {templatesLoading ? (
        <Loading className={styles.loading} size={30} />
      ) : (
        <Formik
          validationSchema={getValidationSchema(t)}
          initialValues={convertDataToForm(templates, BOARD_COLORS)}
          enableReinitialize
          validateOnMount
          onSubmit={onSubmit}
        >
          {() => (
            <Form className={styles.form}>
              <Typography
                className={styles.title}
                variant="h2"
                weight={i18n.language === 'ru' ? 'regular' : 'medium'}
              >
                {t('createBoardModal:title')}
              </Typography>
              <Field
                label={t('createBoardModal:titleLabel')}
                name="name"
                placeholder={t('createBoardModal:placeholder')}
                component={InputField}
              />
              <Field
                label={t('createBoardModal:colorLabel')}
                name="color"
                variant="circle"
                colors={BOARD_COLORS}
                component={ColorRadioField}
              />

              <Template templates={templates} />

              <ModalControls
                className={styles.controls}
                confirmButtonClassName={styles.confirmButton}
                submitType="submit"
                loading={loading}
                submitTitle={t('createBoardModal:saveButton')}
                onCancelClick={onClose}
              />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
export default React.memo(CreateBoardModal);
