import React from 'react';
import { Tooltip } from 'react-tippy';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Loading } from 'components/shared/Loading';

import { Member } from 'components/Member';

import styles from './AssignOwnerPopover.styles.scss';

const AssignOwnerPopover = ({
  children,
  loading,
  open,
  title,
  currentOwner,
  members,
  setOpen,
  onRequestClose,
  onMemberClick,
}) => (
  <Tooltip
    trigger="click"
    open={open}
    onRequestClose={onRequestClose}
    interactive
    animation="shift"
    position="bottom-start"
    html={
      <div className={styles.popover}>
        <Icon name="close" className={styles.icon} onClick={onRequestClose} />
        <Typography variant="h2" weight="medium" className={styles.title}>
          {title}
        </Typography>
        <div className={styles.inner}>
          {loading ? (
            <Loading size={17} className={styles.loading} />
          ) : (
            members.map(
              (member) =>
                !member.deleted && (
                  <div
                    key={member.id}
                    className={cn(styles.member, {
                      [styles.memberActive]: member.id === currentOwner.id,
                    })}
                    onClick={() => onMemberClick(member)}
                  >
                    <Member
                      {...member}
                      active={member.id === currentOwner.id}
                    />
                  </div>
                ),
            )
          )}
        </div>
      </div>
    }
  >
    {children({ open, openPopover: setOpen })}
  </Tooltip>
);

export default React.memo(AssignOwnerPopover);
