import React from 'react';
import cn from 'classnames';

import { DEFAULT_PROGRESS } from './LinearProgressBar.constants';

import styles from './LinearProgressBar.styles.scss';

const VARIANTS = {
  info: styles.info,
  error: styles.error,
  success: styles.success,
  warning: styles.warning,
};

const LinearProgressBar = ({
  className = '',
  trackClassName = '',
  thumbClassName = '',
  variant = 'success',
  progress = DEFAULT_PROGRESS,
}) => {
  const variantClass = VARIANTS[variant] || VARIANTS.success;

  const progressWidth = Math.max(progress, DEFAULT_PROGRESS);

  return (
    <div className={cn(styles.bar, className)}>
      <div className={cn(styles.thumb, thumbClassName)}>
        <div
          className={cn(styles.track, variantClass, trackClassName)}
          style={{ width: `${progressWidth}%` }}
        />
      </div>
    </div>
  );
};

export default React.memo(LinearProgressBar);
