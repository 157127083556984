import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Popover from 'react-popover';
import LinesEllipsis from 'react-lines-ellipsis';
import { Tooltip } from 'react-tippy';

import { getUserRole } from 'helpers/getUserRole';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Avatar } from 'components/shared/Avatar';

import styles from './BoardCard.styles.scss';

const BoardCard = ({
  className,
  isOpen,
  canDelete,
  favorite,
  members,
  id,
  title,
  color,
  variant,
  onFavoriteClick,
  onContextMenuClick,
  onOuterAction,
  onDeleteBoardClick,
  onBoardClick,
  ...props
}) => {
  const { t } = useTranslation('boardPage');
  return (
    <Popover
      className={styles.itemSettings}
      isOpen={isOpen}
      body={
        <div className={styles.menu} onClick={onDeleteBoardClick}>
          <div className={styles.menuItem}>
            <Icon className={styles.deleteIcon} name="bucket" />
            <Typography className={styles.text}>
              {t('deleteBoardTitle')}
            </Typography>
          </div>
        </div>
      }
      preferPlace="right"
      onOuterAction={onOuterAction}
    >
      <div
        className={cn(styles.card, className)}
        onClick={() => onBoardClick(id)}
        onContextMenuCapture={
          canDelete
            ? onContextMenuClick
            : (event) => {
                event.preventDefault();
              }
        }
        {...props}
      >
        <div className={styles.line} style={{ backgroundColor: color }} />
        <div className={styles.inner}>
          <div className={styles.content}>
            <Typography component="div" variant="h2" weight="medium">
              <LinesEllipsis
                className={styles.title}
                text={title || ''}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="words"
              />
            </Typography>
            <Icon
              className={cn(styles.icon, { [styles.favoriteIcon]: favorite })}
              name={favorite ? 'star' : 'emptyStar'}
              onClick={(event) => {
                onFavoriteClick(event, id);
              }}
            />
          </div>
          <div className={styles.members}>
            {members
              .map((member) => {
                return (
                  <Tooltip
                    trigger="mouseenter"
                    position="bottom"
                    html={
                      <div className={styles.tippy}>
                        <Typography
                          className={styles.tippyText}
                          variant="subtitle"
                        >
                          {member.fullName}
                        </Typography>
                        <Typography
                          className={styles.tippyText}
                          variant="subtitle"
                        >
                          {getUserRole(member.role, t)}
                        </Typography>
                      </div>
                    }
                    placement="bottom"
                    key={member.id}
                  >
                    <div>
                      <Avatar
                        className={styles.avatar}
                        variant="extraSmall"
                        fullName={member.fullName}
                        color={member.avatarColor}
                      />
                    </div>
                  </Tooltip>
                );
              })
              .splice(0, 6)}
            {members.length - 6 > 0 && (
              <div className={styles.count}>
                <Typography className={styles.countText}>
                  +{members.length - 6}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default React.memo(BoardCard);
