export const SOCKET_EVENT_TYPES = {
  BOARD_UPDATED: 'BOARD_UPDATED',

  BOARD_COLUMN_CREATED: 'BOARD_COLUMN_CREATED',
  BOARD_COLUMN_UPDATED: 'BOARD_COLUMN_UPDATED',
  BOARD_COLUMN_DELETED: 'BOARD_COLUMN_DELETED',
  BOARD_COLUMN_MOVED: 'BOARD_COLUMN_MOVED',

  BOARD_TASK_CREATED: 'BOARD_TASK_CREATED',
  BOARD_TASK_UPDATED: 'BOARD_TASK_UPDATED',
  BOARD_TASK_DELETED: 'BOARD_TASK_DELETED',
  BOARD_TASK_MOVED: 'BOARD_TASK_MOVED',

  BOARD_TASKS_UPDATED: 'BOARD_TASKS_UPDATED',
};
