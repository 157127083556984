import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { setFormError } from './EmailStep.helpers';

import EmailStep from './EmailStep';

const EmailStepContainer = ({ sliderRef }) => {
  const { t } = useTranslation('signUpEmailPage');
  const { values, errors, setFieldError } = useFormikContext();

  const [loading, setLoading] = useState(false);

  const handleNextStepClick = () => {
    if (!errors.email) {
      setLoading(true);

      api
        .checkUser(values.email)
        .then(() => {
          setLoading(false);

          sliderRef.current.slickNext();
        })
        .catch(({ status }) => {
          setFormError({ status, setFieldError, t });

          setLoading(false);
        });
    }
  };

  return <EmailStep loading={loading} onNextStepClick={handleNextStepClick} />;
};

export default React.memo(EmailStepContainer);
