export const POPOVER_TYPES = {
  TYPE_VALUE: 'TYPE_VALUE',
  TYPE_BUTTON: 'TYPE_BUTTON',
  TYPE_CARD: 'TYPE_CARD',

  TAG_VALUE: 'TAG_VALUE',
  TAG_BUTTON: 'TAG_BUTTON',
  TAG_EXTRA_BUTTON: 'TAG_EXTRA_BUTTON',

  DATE_VALUE: 'DATE_VALUE',
  DATE_BUTTON: 'DATE_BUTTON',

  CHECK_LIST_BUTTON: 'CHECK_LIST_BUTTON',
};
