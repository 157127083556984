import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';

import { ModalControls } from 'components/ModalControls';

import styles from './NotificationModal.styles.scss';

const NotificationModal = ({ isOpen, onConfigureClick, onClose }) => {
  const { t, i18n } = useTranslation('notificationModal');

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <Typography
        className={styles.title}
        variant="h2"
        weight={i18n.language === 'ru' ? 'regular' : 'medium'}
      >
        {t('title')}
      </Typography>

      <div className={styles.inner}>
        <Typography className={styles.subtitle} variant="body" weight="regular">
          {t('subtitleStart')}
        </Typography>
        <Typography
          className={styles.highlightedSubtitle}
          variant="body"
          weight="regular"
        >
          {t('subtitleFinish')}
        </Typography>
      </div>
      <ModalControls
        submitTitle={t('submitTitle')}
        cancelTitle={t('cancelTitle')}
        onConfirmClick={onConfigureClick}
        onCancelClick={onClose}
      />
    </Modal>
  );
};

export default React.memo(NotificationModal);
