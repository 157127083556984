import * as Yup from 'yup';

export const getValidationSchema = (t) =>
  Yup.object().shape({
    list: Yup.object().required(t('requiredFieldError')),
  });

const initialValues = {
  list: '',
};

export default {
  initialValues,
};
