import React from 'react';
import cn from 'classnames';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';

import { TASK_MAX_NAME } from 'constants/common';

import { convertDate } from 'helpers/convertDate';
import { getTagColor } from 'helpers/getTagColor';

import { Link } from 'components/shared/Link';
import { Icon } from 'components/shared/Icon';
import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Avatar } from 'components/shared/Avatar';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { FormControl } from 'components/shared/FormControl';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import { TextEditorField } from 'components/FormikFields';
import { Barcode } from 'components/Barcode';

import { TaskInfo } from './components/TaskInfo';
import { TagsPopover } from './components/TagsPopover';
import { TypePopover } from '../../../TypePopover';
import { DatePopover } from './components/DatePopover';
import { ActionsSection } from './components/ActionsSection';
import { CommentsSection } from './components/CommentsSection';
import { CheckListPopover } from './components/CheckListPopover';
import { CheckListSection } from './components/CheckListSection';
import { AttachementButton } from './components/AttachementButton';
import { AttachmentsSection } from './components/AttachmentsSection';
import { AssignOwnerPopover } from './components/AssignOwnerPopover';

import { POPOVER_TYPES } from './TaskModal.constants';

import { convertDataToForm } from './TaskModal.helpers';

import styles from './TaskModal.styles.scss';

const TaskModal = ({
  currentPopover,
  togglePopover,
  closePopover,
  triggerActionsUpdate,
  loading,
  isOpen,
  boardId,
  listId,
  task,
  taskTypes,
  uploadingAttachment,
  listName,
  getFileRootProps,
  getFileInputProps,
  setTriggerActionsUpdate,
  openFileDialog,
  onClose,
  onTaskTypeEdited,
  onTaskTypeRemoved,
  onTypesSettingsClick,
  onAddTaskTypeClick,
  onTitleInputBlur,
  onDescriptionInputBlur,
  onOwnerChanged,
  onDueDateChanged,
  onTagsChanged,
  onTagDelete,
  onCheckListItemCreated,
  onCheckListItemUpdated,
  onCheckListItemRemoved,
  onCheckListCreated,
  onCheckListUpdated,
  onCheckListRemoved,
  onCommentCreated,
  onCommentUpdated,
  onCommentRemoved,
  onAttachmentUploadFinished,
  onAttachmentUploadCancelled,
  onAttachmentUploadError,
  onAttachmentRemoved,
  onAttachmentCoverUpdated,
  onDeleteTaskClick,
  onAssignMembersClick,
  onMoveTaskClick,
}) => {
  const { t, i18n } = useTranslation('taskModal');

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <Icon className={styles.closeIcon} name="close" onClick={onClose} />

      <div className={styles.actions}>
        <div className={styles.section}>
          <Typography
            className={styles.sectionTitleMain}
            variant="body"
            weight={i18n.language === 'ru' ? 'regular' : 'medium'}
          >
            {t('qrLabel')}
          </Typography>
          <div className={styles.sectionInner}>
            <Barcode value={task.identifier} />
          </div>
        </div>

        <div className={styles.section}>
          <Typography
            className={styles.sectionTitleMain}
            variant="body"
            weight={i18n.language === 'ru' ? 'regular' : 'medium'}
          >
            {t('addLabel')}
          </Typography>

          <div className={styles.sectionInner}>
            <TypePopover
              open={currentPopover === POPOVER_TYPES.TYPE_BUTTON}
              boardId={boardId}
              taskTypes={taskTypes}
              taskType={task.type}
              togglePopover={() => {
                togglePopover(POPOVER_TYPES.TYPE_BUTTON);
              }}
              onAddTaskTypeClick={onAddTaskTypeClick}
              onSettingsClick={() =>
                onTypesSettingsClick(POPOVER_TYPES.TYPE_BUTTON)
              }
              onRequestClose={() => closePopover(POPOVER_TYPES.TYPE_BUTTON)}
            >
              <Button
                className={styles.button}
                onClick={() => {
                  togglePopover(POPOVER_TYPES.TYPE_BUTTON);
                }}
                variant="outline"
                size="small"
              >
                <Icon className={styles.buttonIcon} name="bookmark" />
                {t('typeButton')}
              </Button>
            </TypePopover>

            <TagsPopover
              open={currentPopover === POPOVER_TYPES.TAG_BUTTON}
              tags={task.tagList}
              boardId={boardId}
              onTagsChanged={onTagsChanged}
              onRequestClose={() => closePopover(POPOVER_TYPES.TAG_BUTTON)}
            >
              <Button
                className={styles.button}
                onClick={() => {
                  togglePopover(POPOVER_TYPES.TAG_BUTTON);
                }}
                size="small"
                variant="outline"
              >
                <Icon className={styles.buttonIcon} name="tag" />
                {t('tagButton')}
              </Button>
            </TagsPopover>

            <CheckListPopover
              open={currentPopover === POPOVER_TYPES.CHECK_LIST_BUTTON}
              boardId={boardId}
              taskId={task.id}
              onCheckListCreated={onCheckListCreated}
              currentPopover={currentPopover}
              togglePopover={togglePopover}
              onRequestClose={() =>
                closePopover(POPOVER_TYPES.CHECK_LIST_BUTTON)
              }
            >
              <Button
                className={styles.button}
                variant="outline"
                size="small"
                onClick={() => togglePopover(POPOVER_TYPES.CHECK_LIST_BUTTON)}
              >
                <Icon className={styles.buttonIcon} name="checkList" />
                {t('checkButton')}
              </Button>
            </CheckListPopover>

            <AttachementButton
              getFileRootProps={getFileRootProps}
              getFileInputProps={getFileInputProps}
              onClick={openFileDialog}
            />

            <DatePopover
              open={currentPopover === POPOVER_TYPES.DATE_BUTTON}
              disabledDays={{
                before: new Date(),
              }}
              boardId={boardId}
              listId={listId}
              value={task.dueDate}
              onChanged={onDueDateChanged}
              onRequestClose={() => closePopover(POPOVER_TYPES.DATE_BUTTON)}
            >
              <Button
                className={styles.button}
                variant="outline"
                size="small"
                onClick={() => togglePopover(POPOVER_TYPES.DATE_BUTTON)}
              >
                <Icon className={styles.buttonIcon} name="calendar" />
                {t('dateButton')}
              </Button>
            </DatePopover>
          </div>
          <Typography
            className={styles.sectionTitle}
            variant="body"
            weight={i18n.language === 'ru' ? 'regular' : 'medium'}
          >
            {t('actionsLabel')}
          </Typography>
          <div className={styles.sectionInner}>
            <Button
              className={styles.button}
              variant="outline"
              size="small"
              onClick={() => {
                onMoveTaskClick(task.id);
              }}
            >
              <Icon className={styles.buttonIcon} name="fullArrow" />
              {t('moveButton')}
            </Button>
            <Button
              className={styles.button}
              variant="outline"
              color="alert"
              size="small"
              onClick={onDeleteTaskClick}
            >
              <Icon className={styles.buttonIcon} name="deleteTask" />
              {t('deleteButton')}
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <Loading className={styles.loading} size={20} />
      ) : (
        <Formik
          initialValues={convertDataToForm(task)}
          enableReinitialize
          validateOnMount
        >
          {({ values }) => (
            <Form className={styles.inner}>
              <ScrollWrapper trackClassName={styles.track}>
                <TypePopover
                  open={currentPopover === POPOVER_TYPES.TYPE_VALUE}
                  boardId={boardId}
                  taskTypes={taskTypes}
                  taskType={task.type}
                  togglePopover={() => {
                    togglePopover(POPOVER_TYPES.TYPE_VALUE);
                  }}
                  onTaskTypeRemoved={onTaskTypeRemoved}
                  onTaskTypeEdited={onTaskTypeEdited}
                  onAddTaskTypeClick={onAddTaskTypeClick}
                  onSettingsClick={() =>
                    onTypesSettingsClick(POPOVER_TYPES.TYPE_VALUE)
                  }
                  onRequestClose={() => closePopover(POPOVER_TYPES.TYPE_VALUE)}
                >
                  <div
                    className={cn(styles.typePlaceholder, {
                      [styles.activeTypePlaceholder]:
                        !task.type &&
                        currentPopover === POPOVER_TYPES.TYPE_VALUE,
                      [styles.type]: task.type,
                    })}
                    style={task.type && { backgroundColor: task.type.color }}
                    onClick={() => {
                      togglePopover(POPOVER_TYPES.TYPE_VALUE);
                    }}
                  >
                    {task.type && (
                      <Typography className={styles.typeName} variant="body">
                        {task.type.name}
                      </Typography>
                    )}
                  </div>
                </TypePopover>

                <div className={styles.content}>
                  <Field name="title">
                    {({ field, form }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.titleField}
                        maxLength={TASK_MAX_NAME}
                        onBlur={(event) => onTitleInputBlur(event, field, form)}
                      />
                    )}
                  </Field>
                  <TaskInfo
                    identifier={task.identifier}
                    lastHistory={task.lastHistoryRecord}
                  />
                  <div className={styles.formGroup}>
                    <div
                      className={cn(styles.formControl, styles.formControlRow)}
                    >
                      <Typography className={styles.formLabel} variant="body">
                        {t('ownerLabel')}
                      </Typography>

                      <AssignOwnerPopover
                        boardId={boardId}
                        title={t('assignOwnerLabel')}
                        currentOwner={task.customer}
                        onChanged={onOwnerChanged}
                      >
                        {({ open, openPopover }) => (
                          <div
                            className={cn(styles.field, styles.ownerField, {
                              [styles.ownerFieldActive]: open,
                            })}
                            onClick={() => openPopover(!open)}
                          >
                            <Typography
                              className={styles.fieldValue}
                              variant="body"
                            >
                              {task.customer.fullName}
                            </Typography>
                          </div>
                        )}
                      </AssignOwnerPopover>
                    </div>

                    <div
                      className={cn(styles.formControl, styles.formControlRow)}
                    >
                      <Typography className={styles.formLabel} variant="body">
                        {t('listLabel')}
                      </Typography>
                      <div className={styles.field} onClick={onMoveTaskClick}>
                        <Typography
                          className={cn(styles.fieldValue, styles.listName)}
                          variant="body"
                        >
                          {listName}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {task.dueDate && (
                    <div className={styles.dueDate}>
                      <Typography
                        className={styles.dueDateLabel}
                        variant="body"
                        weight="medium"
                      >
                        {t('dueDateLabel')}
                      </Typography>

                      <DatePopover
                        open={currentPopover === POPOVER_TYPES.DATE_VALUE}
                        disabledDays={{
                          before: new Date(),
                        }}
                        value={task.dueDate}
                        onChanged={onDueDateChanged}
                        onRequestClose={() =>
                          closePopover(POPOVER_TYPES.DATE_VALUE)
                        }
                      >
                        <div
                          className={styles.dueDateInner}
                          onClick={() =>
                            togglePopover(POPOVER_TYPES.DATE_VALUE)
                          }
                        >
                          <Icon
                            className={styles.dueDateIcon}
                            name="calendar"
                          />
                          <Typography
                            className={styles.dueDateValue}
                            variant="body"
                          >
                            {convertDate(task.dueDate, 'dd.MM.yyyy')}
                          </Typography>
                        </div>
                      </DatePopover>
                    </div>
                  )}
                  <FormControl
                    className={styles.formControl}
                    label={t('assignedLabel')}
                    endAdornment={
                      !!task.memberList.length && (
                        <Link onClick={onAssignMembersClick}>
                          {t('editMembersLabel')}
                        </Link>
                      )
                    }
                  >
                    {!task.memberList.length ? (
                      <Button
                        className={styles.formButton}
                        size="small"
                        variant="outline"
                        endIcon={<Icon className={styles.icon} name="add" />}
                        onClick={onAssignMembersClick}
                      >
                        {t('membersLabel')}
                      </Button>
                    ) : (
                      <div className={styles.members}>
                        {task.memberList.map((member) => (
                          <Avatar
                            className={styles.member}
                            key={member.id}
                            variant="medium"
                            fullName={member.fullName}
                            color={member.avatarColor}
                          />
                        ))}
                      </div>
                    )}
                  </FormControl>
                  {!!task.tagList.length && (
                    <FormControl
                      className={styles.formTags}
                      label={t('tagsLabel')}
                    >
                      <div className={styles.tags}>
                        <TagsPopover
                          open={currentPopover === POPOVER_TYPES.TAG_VALUE}
                          tags={task.tagList}
                          boardId={boardId}
                          onTagsChanged={onTagsChanged}
                          onRequestClose={() =>
                            closePopover(POPOVER_TYPES.TAG_VALUE)
                          }
                        >
                          <span className={styles.tagsPopoverArea}>
                            {task.tagList.map((tag) =>
                              tag.name ? (
                                <span
                                  key={tag.id}
                                  className={styles.tagWrapper}
                                >
                                  <div
                                    className={styles.tag}
                                    style={{
                                      backgroundColor: getTagColor(tag.color),
                                    }}
                                    onClick={() => {
                                      togglePopover(POPOVER_TYPES.TAG_VALUE);
                                    }}
                                  >
                                    <Typography
                                      className={styles.tagName}
                                      variant="body"
                                    >
                                      {tag.name}
                                    </Typography>
                                    <Icon
                                      className={styles.deleteTagIcon}
                                      name="close"
                                      onClick={(event) => {
                                        onTagDelete(event, tag.id);
                                      }}
                                    />
                                  </div>
                                </span>
                              ) : (
                                <span
                                  key={tag.id}
                                  className={styles.tagWrapper}
                                >
                                  <div
                                    className={styles.emptyTag}
                                    style={{
                                      backgroundColor: getTagColor(tag.color),
                                    }}
                                    onClick={() => {
                                      togglePopover(POPOVER_TYPES.TAG_VALUE);
                                    }}
                                  >
                                    <Icon
                                      className={styles.deleteTagIcon}
                                      name="close"
                                      onClick={(event) => {
                                        onTagDelete(event, tag.id);
                                      }}
                                    />
                                  </div>
                                </span>
                              ),
                            )}

                            {task.tagList.length !== 5 && (
                              <TagsPopover
                                open={
                                  currentPopover ===
                                  POPOVER_TYPES.TAG_EXTRA_BUTTON
                                }
                                tags={task.tagList}
                                boardId={boardId}
                                onTagsChanged={onTagsChanged}
                                onRequestClose={() =>
                                  closePopover(POPOVER_TYPES.TAG_EXTRA_BUTTON)
                                }
                              >
                                <Button
                                  className={styles.addTag}
                                  variant="outline"
                                  size="small"
                                  onClick={() => {
                                    togglePopover(
                                      POPOVER_TYPES.TAG_EXTRA_BUTTON,
                                    );
                                  }}
                                >
                                  <Icon
                                    className={styles.addTagIcon}
                                    name="plus"
                                  />
                                </Button>
                              </TagsPopover>
                            )}
                          </span>
                        </TagsPopover>
                      </div>
                    </FormControl>
                  )}
                  <FormControl
                    className={styles.description}
                    label={t('descriptionLabel')}
                  >
                    <Field
                      name="description"
                      placeholder={t('descriptionPlaceholder')}
                      className={styles.selectField}
                      component={TextEditorField}
                      onBlur={() => {
                        onDescriptionInputBlur(values);
                      }}
                    />
                  </FormControl>
                  <CheckListSection
                    boardId={boardId}
                    taskId={task.id}
                    taskDueDate={task.dueDate}
                    checklists={task.checklists}
                    onCheckListItemCreated={onCheckListItemCreated}
                    onCheckListItemUpdated={onCheckListItemUpdated}
                    onCheckListItemRemoved={onCheckListItemRemoved}
                    onCheckListUpdated={onCheckListUpdated}
                    onCheckListRemoved={onCheckListRemoved}
                  />
                  <AttachmentsSection
                    boardId={boardId}
                    taskId={task.id}
                    attachments={task.attachmentList}
                    uploadingAttachment={uploadingAttachment}
                    getFileInputProps={getFileInputProps}
                    getFileRootProps={getFileRootProps}
                    openFileDialog={openFileDialog}
                    onUploadCancelled={onAttachmentUploadCancelled}
                    onUploadFinished={onAttachmentUploadFinished}
                    onUploadError={onAttachmentUploadError}
                    onAttachmentRemoved={onAttachmentRemoved}
                    onAttachmentCoverUpdated={onAttachmentCoverUpdated}
                  />
                  <CommentsSection
                    boardId={boardId}
                    taskId={task.id}
                    comments={task.commentList}
                    onCommentCreated={onCommentCreated}
                    onCommentUpdated={onCommentUpdated}
                    onCommentRemoved={onCommentRemoved}
                  />
                  <ActionsSection
                    triggerActionsUpdate={triggerActionsUpdate}
                    boardId={boardId}
                    taskId={task.id}
                    setTriggerActionsUpdate={setTriggerActionsUpdate}
                  />
                </div>
              </ScrollWrapper>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default React.memo(TaskModal);
