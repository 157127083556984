import _orderBy from 'lodash/orderBy';

import { BOARD_PROVIDER_TYPES } from './BoardPageProvider.constants';

export function boardReducer(state, action) {
  switch (action.type) {
    case BOARD_PROVIDER_TYPES.SET_BOARD_DATA: {
      return { ...state, board: action.payload };
    }

    case BOARD_PROVIDER_TYPES.SET_BOARD_LISTS: {
      return {
        ...state,
        board: { ...state.board, lists: action.payload },
      };
    }

    case BOARD_PROVIDER_TYPES.SET_BOARD_LISTS_SOCKET: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: _orderBy(
            action.payload.map((list) => {
              if (
                state.board.lists.find((prevList) => prevList.id === list.id)
              ) {
                return {
                  ...list,
                  tasks: state.board.lists.find(
                    (prevList) => prevList.id === list.id,
                  ).tasks,
                };
              }
            }),
            ['columnNumber'],
            ['asc'],
          ),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASKS_MOVED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (action.payload.fromColumnId === list.id) {
              return { ...list, tasks: action.payload.fromColumnTaskList };
            }
            if (action.payload.toColumnId === list.id) {
              return {
                ...list,
                tasks: _orderBy(
                  action.payload.toColumnTaskList,
                  ['orderNumber'],
                  ['asc'],
                ),
              };
            }
            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.SET_BOARD_TASKS: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (list.id === action.payload.listId) {
              return { ...list, tasks: action.payload.tasks };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.SET_BOARD_TASK: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (list.id === action.payload.listId) {
              return {
                ...list,
                tasks: list.tasks.map((task) => {
                  if (task.id === action.payload.nextTask.id) {
                    return action.payload.nextTask;
                  }

                  return task;
                }),
              };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.SET_INVITED_MEMBER: {
      const nextMemberList = state.board.memberList.map((member) => {
        if (member.id === action.payload.id) {
          return {
            ...member,
            avatarColor: action.payload.avatarColor,
            fullName: action.payload.fullName,
          };
        }

        return member;
      });

      return {
        ...state,
        board: state.board.memberList.some(
          (member) => member.id === action.payload.id,
        )
          ? { ...state.board, memberList: nextMemberList }
          : state.board,
      };
    }

    case BOARD_PROVIDER_TYPES.UPDATE_BOARD_DATA: {
      return { ...state, board: { ...state.board, ...action.payload } };
    }

    case BOARD_PROVIDER_TYPES.BOARD_LIST_CREATED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: [...state.board.lists, { ...action.payload, tasks: [] }],
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_LIST_UPDATED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (list.id === action.payload.listId) {
              return { ...list, ...action.payload.data };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_LIST_MOVED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: _orderBy(
            state.board.lists.map((list) => {
              return {
                ...list,
                columnNumber: action.payload.nextLists.find(
                  (nextList) => nextList.id === list.id,
                ).columnNumber,
              };
            }),
            ['columnNumber'],
            ['asc'],
          ),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_LIST_DELETED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.filter(
            (list) => list.id !== action.payload.listId,
          ),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASKS_UPDATE: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (
              action.payload.changedTasks.some(
                (task) => task.columnId === list.id,
              )
            ) {
              return {
                ...list,
                tasks: list.tasks.map((task) => {
                  if (
                    action.payload.changedTasks.some(
                      (changedTask) => changedTask.id === task.id,
                    )
                  ) {
                    return action.payload.changedTasks.find(
                      (changedTask) => changedTask.id === task.id,
                    );
                  }

                  return task;
                }),
              };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASKS_UPDATE_TAG: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            return {
              ...list,
              tasks: list.tasks.map((task) => {
                if (task.tagList.length) {
                  return {
                    ...task,
                    tagList: task.tagList.map((tag) => {
                      if (
                        action.payload.nextTags.some(
                          (nextTag) => nextTag.id === tag.id,
                        )
                      ) {
                        return action.payload.nextTags.find(
                          (updatedTag) => updatedTag.id === tag.id,
                        );
                      }

                      return tag;
                    }),
                  };
                }

                return task;
              }),
            };
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.TASKS_TYPE_EDITED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => ({
            ...list,
            tasks: list.tasks.map((task) => {
              if (task.type && task.type.id === action.payload.updatedType.id) {
                return { ...task, type: action.payload.updatedType };
              }

              return task;
            }),
          })),
          taskTypeList: state.board.taskTypeList.map((type) => {
            if (type && type.id === action.payload.updatedType.id) {
              return action.payload.updatedType;
            }

            return type;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASKS_MOVED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (action.payload.fromColumnId === list.id) {
              return { ...list, tasks: action.payload.fromColumnTaskList };
            }

            if (action.payload.toColumnId === list.id) {
              return { ...list, tasks: action.payload.toColumnTaskList };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.TYPE_CREATED: {
      return {
        ...state,
        board: {
          ...state.board,
          taskTypeList: [
            ...state.board.taskTypeList,
            action.payload.createdTaskType,
          ],
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TYPE_REMOVED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => ({
            ...list,
            tasks: list.tasks.map((task) => {
              if (task.type && task.type.id === action.payload.typeId) {
                const updatedTaskVersion =
                  action.payload.updatedTasksVersion.find(
                    (updatedVersion) => updatedVersion.taskId === task.id,
                  );

                return {
                  ...task,
                  type: null,
                  version: updatedTaskVersion.taskVersion,
                };
              }

              return task;
            }),
          })),
          taskTypeList: state.board.taskTypeList.filter(
            (type) => type.id !== action.payload.typeId,
          ),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASK_CREATED: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (list.id === action.payload.listId) {
              return { ...list, tasks: [...list.tasks, action.payload.task] };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (list.id === action.payload.task.columnId) {
              return {
                ...list,
                tasks: list.tasks.map((task) => {
                  if (task.id === action.payload.task.id) {
                    return {
                      ...action.payload.task,
                      attachmentCount:
                        action.payload.task.attachmentCount ||
                        action.payload.task.attachmentList.length,
                      commentCount:
                        action.payload.task.commentCount ||
                        action.payload.task.commentList.length,
                    };
                  }

                  return task;
                }),
              };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASK_MOVE: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (action.payload.fromListId === list.id) {
              return {
                ...list,
                tasks: list.tasks.filter(
                  (task) => action.payload.nextTask.id !== task.id,
                ),
              };
            }

            if (action.payload.toListId === list.id) {
              return {
                ...list,
                tasks: _orderBy(
                  [action.payload.nextTask, ...list.tasks],
                  ['orderNumber'],
                  ['asc'],
                ),
              };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASK_MOVED_SOCKET: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (action.payload.lists.some((column) => column.id === list.id)) {
              return {
                ...list,
                tasks: _orderBy(
                  action.payload.tasks
                    .filter((task) => task.columnId === list.id)
                    .map((task) => {
                      if (task.attachmentCount && task.commentCount) {
                        return task;
                      }

                      return {
                        ...task,
                        attachmentCount: task.attachmentList.length,
                        commentCount: task.commentList.length,
                      };
                    }),
                  ['orderNumber'],
                  ['asc'],
                ),
              };
            }

            return list;
          }),
        },
      };
    }

    case BOARD_PROVIDER_TYPES.BOARD_TASK_DELETE: {
      return {
        ...state,
        board: {
          ...state.board,
          lists: state.board.lists.map((list) => {
            if (list.id === action.payload.listId) {
              return {
                ...list,
                tasks: list.tasks.filter(
                  (task) => task.id !== action.payload.taskId,
                ),
              };
            }

            return list;
          }),
        },
      };
    }
  }
}
