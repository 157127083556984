import React from 'react';

import { FormControl } from 'components/shared/FormControl';
import { ColorPicker } from 'components/shared/ColorPicker';

const ColorPickerField = () => {
  return (
    <FormControl>
      <ColorPicker />
    </FormControl>
  );
};

export default React.memo(ColorPickerField);
