import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';

import { registerUser } from 'store/auth/actions';

import { useTimer } from 'hooks/useTimer';

import * as api from 'api/methods';

import { AUTH_CODE_LENGTH } from 'constants/common';

import { setFormError } from './CodeStep.helpers';

import CodeStep from './CodeStep';

const CodeStepContainer = ({ errors }) => {
  const { t, i18n } = useTranslation('signUpCodeStep');

  const history = useHistory();
  const dispatch = useDispatch();

  const { values, setFieldError } = useFormikContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [codeSubmitted, setCodeSubmitted] = useState(false);

  const [errorCounter, setErrorCounter] = useState(0);
  const [resetTrigger, setResetTrigger] = useState(new Date());

  const [timerSecondsLeft, { startTimer }] = useTimer({ defaultSeconds: 0 });

  const handleCodeChange = (code, value) => {
    if (code.filter((number) => !!number).length === AUTH_CODE_LENGTH) {
      setLoading(true);
      dispatch(
        registerUser(
          {
            email: values.email,
            password: values.password,
          },
          code.join(''),
        ),
      )
        .then(({ value: user }) => {
          i18n.changeLanguage(user.language);

          setLoading(false);
          setError(false);
          setCodeSubmitted(true);

          const inviteLink = localStorage.getItem('inviteLink');

          if (inviteLink) {
            localStorage.removeItem('inviteLink');

            setLoading(false);
            history.replace(inviteLink);
          } else {
            setLoading(false);
            history.push(ROUTES.BOARDS);
          }
        })
        .catch(({ status: registerStatus }) => {
          setLoading(false);
          setError(true);

          setFormError({ status: registerStatus, setFieldError, t });

          if (errorCounter < 2) {
            setErrorCounter(errorCounter + 1);

            setTimeout(() => {
              setResetTrigger(new Date());

              setError(false);
            }, 2000);
          }

          if (errorCounter > 1) {
            setErrorCounter(errorCounter + 1);

            api
              .requestVerifyCode({ email: values.email })
              .then(() => {
                setTimeout(() => {
                  setResetTrigger(new Date());

                  setErrorCounter(0);
                  setError(false);
                }, 3000);
              })
              .catch(({ status: codeStatus }) => {
                setFormError({ status: codeStatus, setFieldError, t });
              });
          }

          setError(true);
        });
    }
  };

  const handleSendCodeAgainClick = () => {
    if (timerSecondsLeft > 0) {
      return;
    }

    startTimer(30);

    api
      .requestVerifyCode({ email: values.email })
      .then(() => {
        setErrorCounter(0);
        setError(false);
      })
      .catch(({ status }) => {
        setFormError({ status, setFieldError, t });
      });
  };

  return (
    <CodeStep
      loading={loading}
      codeSubmitted={codeSubmitted}
      error={error}
      errors={errors}
      currentEmail={values.email}
      errorCounter={errorCounter}
      timerSecondsLeft={timerSecondsLeft}
      resetTrigger={resetTrigger}
      onCodeChange={handleCodeChange}
      onSendCodeAgainClick={handleSendCodeAgainClick}
    />
  );
};

export default React.memo(CodeStepContainer);
