import React, { useState } from 'react';

import ColorPicker from './ColorPicker';

const ColorPickerContainer = () => {
  const [color, setColor] = useState('#fff');
  const [hidden, setHidden] = useState(false);

  const handleColorChange = (clr) => {
    setColor(clr.hex);
  };

  const handleOpenPallete = () => {
    setHidden(!hidden);
  };

  return (
    <ColorPicker
      color={color}
      hidden={hidden}
      onColorChange={handleColorChange}
      onOpenPallete={handleOpenPallete}
    />
  );
};

export default React.memo(ColorPickerContainer);
