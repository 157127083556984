import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Emitter from 'services/eventEmitter';

import * as api from 'api/methods';

import { EMITTER_TYPES } from 'constants/emitterTypes';
import { SOCKET_EVENT_TYPES } from 'constants/socketConstants';
import { BOARD_PROVIDER_TYPES } from '../../../BoardPageContext/BoardPageProvider.constants';

import { convertDate } from 'helpers/convertDate';
import { showToastMessage } from 'helpers/showToastMessage';

import { useModal } from 'hooks/useModal';
import { useFetch } from 'hooks/useFetch';
import { usePopover } from './TaskModal.hooks';
import { useBoard } from '../../../BoardPageContext/BoardPage.context';

import { DeleteModal } from 'components/DeleteModal';

import { MoveTaskModal } from './components/MoveTaskModal';
import { AssignMembersModal } from './components/AssignMembersModal';
import { TypesSettingsModal } from 'components/pages/BoardPage/components/TypesSettingsModal';

import { POPOVER_TYPES } from './TaskModal.constants';

import { convertDataToApi } from './TaskModal.helpers';

import TaskModal from './TaskModal';

const TaskModalContainer = ({
  isOpen,
  listId,
  taskId,
  listName,
  orderNumber,
  taskTypes,
  onTaskMoved,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation(['toast', 'taskModal']);

  const history = useHistory();

  const { id: boardId } = useParams();

  const { dispatch } = useBoard();

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [uploadingAttachment, setUploadingAttachment] = useState(null);
  const [triggerActionsUpdate, setTriggerActionsUpdate] = useState(null);

  const {
    getRootProps: getFileRootProps,
    getInputProps: getFileInputProps,
    open: openFileDialog,
    acceptedFiles,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      setUploadingAttachment(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    Emitter.on(EMITTER_TYPES.UPDATE, (data) => {
      if (boardId === data.board.id) {
        switch (data.state) {
          case SOCKET_EVENT_TYPES.BOARD_TASK_DELETED: {
            onClose();

            break;
          }

          case SOCKET_EVENT_TYPES.BOARD_TASK_UPDATED:
          case SOCKET_EVENT_TYPES.BOARD_TASK_MOVED: {
            setTask((prevState) => {
              if (prevState.data.id === data.changedTasks[0].id) {
                return { ...prevState, data: data.changedTasks[0] };
              }

              return prevState;
            });

            dispatch({
              type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
              payload: { task: data.changedTasks[0] },
            });

            setTriggerActionsUpdate(new Date());

            break;
          }
          default: {
            console.log(data.state, 'not handled for now');

            break;
          }
        }
      }
    });
  }, []);

  const [deleteTaskModalOpen, openDeleteTaskModal, closeDeleteTaskModal] =
    useModal({ defaultOpen: false });

  const [
    assignMembersModalOpen,
    openAssignMembersModal,
    closeAssignMembersModal,
    assignMembersModalData,
  ] = useModal({ defaultOpen: false });

  const [
    moveTaskModalOpen,
    openMoveTaskModal,
    closeMoveTaskModal,
    moveTaskModalData,
  ] = useModal({
    defaultOpen: false,
  });

  const [
    typesSettingsModalOpen,
    openTypesSettingsModal,
    closeTypesSettingsModal,
    typesSettingsModalData,
  ] = useModal({});

  const {
    data: task,
    loading,
    setState: setTask,
    error,
  } = useFetch(
    {
      defaultData: {},
      fetcher: api.getTask,
      immediately: false,
      stopRequest: !isOpen,
      onError: () => {
        history.replace('/*');
      },
    },
    { boardId, listId, taskId },
    [isOpen],
  );

  const popoverProps = usePopover();

  const updateTask = (data) => {
    api
      .updateTask(
        { boardId, listId: task.columnId, taskId: task.id },
        convertDataToApi({ ...task, ...data }),
      )
      .then((updatedTask) => {
        setTask((prevData) => ({ ...prevData, data: updatedTask }));

        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
          payload: { task: updatedTask },
        });

        setTriggerActionsUpdate(new Date());
      })
      .catch(({ status }) => {
        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleTaskMoved = ({ fromListId, toListId, nextTask }) => {
    setTask((prevState) => ({ ...prevState, data: nextTask }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_MOVE,
      payload: { fromListId, toListId, nextTask },
    });

    if (onTaskMoved) {
      onTaskMoved(toListId);
    }

    setTriggerActionsUpdate(new Date());
  };

  const handleTitleInputBlur = (event, field, { setFieldValue }) => {
    if (field.value === task.title) {
      return;
    }

    if (field.value === '') {
      setFieldValue(field.name, task.title);

      return;
    }

    setTask((prevState) => ({
      ...prevState,
      data: { ...task, title: field.value },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, title: field.value } },
    });

    updateTask({ title: field.value });
  };

  const handleDescriptionInputBlur = (values) => {
    if (values.description === task.description) {
      return;
    }

    setTask((prevState) => ({
      ...prevState,
      data: { ...task, description: values.description },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, description: values.description } },
    });

    updateTask({ description: values.description });
  };

  const handleConfirmDeleteTaskClick = () => {
    setDeleteLoading(true);

    api
      .deleteTask(boardId, task.id)
      .then(() => {
        setDeleteLoading(false);

        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_TASK_DELETE,
          payload: { listId: task.columnId, taskId: task.id },
        });

        closeDeleteTaskModal();
        onClose();
      })
      .catch(({ status }) => {
        setDeleteLoading(false);

        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleAddTaskTypeClick = (typeId) => {
    popoverProps.togglePopover();

    (task.type && task.type.id === typeId) || !typeId
      ? updateTask({ type: { id: null } })
      : updateTask({ type: { id: typeId } });
  };

  const handleTaskTypeEdited = (updatedType) => {
    if (task.type && task.type.id === updatedType.id) {
      setTask((prevState) => ({
        ...prevState,
        data: { ...task, type: updatedType },
      }));

      updateTask({ type: { id: updatedType.id } });
    }
  };

  const handleTaskTypeRemoved = (updatedTasksVersion) => {
    const updatedTaskVersion = updatedTasksVersion.find(
      (updatedVersion) => updatedVersion.taskId === task.id,
    );

    setTask((prevState) => ({
      ...prevState,
      data: { ...task, type: null, version: updatedTaskVersion.taskVersion },
    }));
  };

  const handleTypesSettingsClick = (popoverType) => {
    openTypesSettingsModal({ popoverType });
  };

  const openTypePopover = (popoverType) => {
    if (typesSettingsModalOpen) {
      closeTypesSettingsModal();
    }

    if (popoverType) {
      popoverProps.togglePopover(popoverType);
    }
  };

  const handleAssignMemberClick = () => {
    openAssignMembersModal({
      customer: task.customer,
      members: task.memberList,
    });
  };

  const handleMoveTaskClick = () => {
    openMoveTaskModal();
  };

  const handleMembersChanged = (nextMembers) => {
    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, memberList: nextMembers },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, memberList: nextMembers } },
    });

    updateTask({ memberList: nextMembers });
  };

  const handleDueDateChanged = (nextDueDate) => {
    const updatedDueDate = nextDueDate
      ? format(nextDueDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
      : null;

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, dueDate: updatedDueDate },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, dueDate: updatedDueDate } },
    });

    updateTask({
      dueDate: updatedDueDate,
    });
  };

  const handleOwnerChanged = (nextOwner) => {
    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, customer: nextOwner },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, customer: nextOwner } },
    });

    updateTask({ customer: nextOwner });
  };

  const handleTagsChanged = (nextTags) => {
    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, tagList: nextTags },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASKS_UPDATE_TAG,
      payload: { listId: task.columnId, nextTags },
    });

    updateTask({ tagList: nextTags });
  };

  const handleTagDelete = (event, tagId) => {
    event.stopPropagation();

    if (
      popoverProps.currentPopover === POPOVER_TYPES.TAG_VALUE ||
      POPOVER_TYPES.TAG_BUTTON ||
      POPOVER_TYPES.TAG_EXTRA_BUTTON
    ) {
      popoverProps.closePopover(popoverProps.currentPopover);
    }

    const nextTags = task.tagList.filter((tag) => tag.id !== tagId);

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, tagList: nextTags },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, tagList: nextTags } },
    });

    updateTask({ tagList: nextTags });
  };

  const handleCheckListCreated = (createdCheckList) => {
    const updatedTaskData = {
      checklists: [...task.checklists, createdCheckList],
      version: createdCheckList.taskVersionVO.taskVersion,
    };

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, ...updatedTaskData },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, ...updatedTaskData } },
    });

    setTriggerActionsUpdate(new Date());
  };

  const handleCheckListUpdated = (updatedCheckList) => {
    const nextCheckLists = task.checklists.map((checklist) => {
      if (checklist.id === updatedCheckList.id) {
        return updatedCheckList;
      }

      return checklist;
    });

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, checklists: nextCheckLists },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, checklists: nextCheckLists } },
    });

    setTriggerActionsUpdate(new Date());
  };

  const handleCheckListRemoved = (removedCheckListId, taskVersion) => {
    const updatedTaskData = {
      checklists: task.checklists.filter(
        (checkList) => checkList.id !== removedCheckListId,
      ),
      version: taskVersion,
    };

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, ...updatedTaskData },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, ...updatedTaskData } },
    });

    setTriggerActionsUpdate(new Date());
  };

  const handleCheckListItemCreated = (createdItem, checkListId) => {
    const nextCheckLists = task.checklists.map((checklist) => {
      if (checklist.id === checkListId) {
        return {
          ...checklist,
          itemList: [...checklist.itemList, createdItem],
        };
      }

      return checklist;
    });

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, checklists: nextCheckLists },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, checklists: nextCheckLists } },
    });
  };

  const handleCheckListItemUpdated = (updatedItem, checkListId) => {
    const nextCheckLists = task.checklists.map((checklist) => {
      if (checklist.id === checkListId) {
        return {
          ...checklist,
          itemList: checklist.itemList.map((item) =>
            item.id === updatedItem.id ? updatedItem : item,
          ),
        };
      }

      return checklist;
    });

    const nextDueDate = !task.dueDate
      ? updatedItem.dueDate
        ? convertDate(updatedItem.dueDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
        : null
      : task.dueDate;

    setTask((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        checklists: nextCheckLists,
        dueDate: nextDueDate,
      },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: {
        task: {
          ...task,
          checklists: nextCheckLists,
          dueDate: nextDueDate,
        },
      },
    });
  };

  const handleCheckListItemRemoved = (removedItemId, checkListId) => {
    const nextCheckLists = task.checklists.map((checklist) => {
      if (checklist.id === checkListId) {
        return {
          ...checklist,
          itemList: checklist.itemList.filter(
            (item) => item.id !== removedItemId,
          ),
        };
      }

      return checklist;
    });

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, checklists: nextCheckLists },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, checklists: nextCheckLists } },
    });
  };

  const handleCommentCreated = (createdComment) => {
    const nextCommentList = [...task.commentList, createdComment];

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, commentList: nextCommentList },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, commentList: nextCommentList } },
    });
  };

  const handleCommentUpdated = (createdComment) => {
    const nextCommentList = task.commentList.map((comment) => {
      if (comment.id === createdComment.id) {
        return createdComment;
      }

      return comment;
    });

    setTask((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        commentList: nextCommentList,
      },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, commentList: nextCommentList } },
    });
  };

  const handleCommentRemoved = (removedCommentId) => {
    const nextCommentList = task.commentList.filter(
      (comment) => comment.id !== removedCommentId,
    );

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, commentList: nextCommentList },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, commentList: nextCommentList } },
    });
  };

  const handleAttachmentUploadFinished = (createdAttachment) => {
    const updatedTaskData = {
      attachmentList: [...task.attachmentList, createdAttachment],
      version: createdAttachment.taskVersionVO.taskVersion,
    };

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, ...updatedTaskData },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, ...updatedTaskData } },
    });

    setTriggerActionsUpdate(new Date());

    setUploadingAttachment(null);
  };

  const handleAttachmentRemoved = (removedAttacmentId, taskVersion) => {
    const updatedTaskData = {
      coverAttachmentId: null,
      attachmentList: task.attachmentList.filter(
        (attachment) => attachment.id !== removedAttacmentId,
      ),
      version: taskVersion,
    };

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, ...updatedTaskData },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, ...updatedTaskData } },
    });

    setTriggerActionsUpdate(new Date());
  };

  const handleAttachmentCoverUpdated = (updatedAttachment) => {
    const nextAttachmentList = task.attachmentList.map((attachment) => {
      if (attachment.id === updatedAttachment.id) {
        return updatedAttachment;
      }

      return {
        ...attachment,
        isCover: false,
      };
    });

    const coverAttachment = nextAttachmentList.find(
      (attachment) => attachment.isCover,
    );

    const updatedTaskData = {
      coverAttachmentId: coverAttachment ? coverAttachment.id : null,
      attachmentList: nextAttachmentList,
      version: updatedAttachment.taskVersionVO.taskVersion,
    };

    setTask((prevState) => ({
      ...prevState,
      data: { ...prevState.data, ...updatedTaskData },
    }));

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
      payload: { task: { ...task, ...updatedTaskData } },
    });
  };

  const handleAttachmentUploadError = () => {
    setUploadingAttachment(null);
  };

  const handleAttachmentUploadCancelled = () => {
    setUploadingAttachment(null);
  };

  if (error) {
    return null;
  }

  return (
    <>
      <TaskModal
        {...restProps}
        {...popoverProps}
        triggerActionsUpdate={triggerActionsUpdate}
        isOpen={isOpen}
        loading={loading}
        boardId={boardId}
        listId={listId}
        uploadingAttachment={uploadingAttachment}
        listName={listName}
        task={task}
        taskTypes={taskTypes}
        getFileRootProps={getFileRootProps}
        getFileInputProps={getFileInputProps}
        setTriggerActionsUpdate={setTriggerActionsUpdate}
        openFileDialog={openFileDialog}
        onClose={onClose}
        onAddTaskTypeClick={handleAddTaskTypeClick}
        onTaskTypeEdited={handleTaskTypeEdited}
        onTaskTypeRemoved={handleTaskTypeRemoved}
        onTypesSettingsClick={handleTypesSettingsClick}
        onTitleInputBlur={handleTitleInputBlur}
        onDescriptionInputBlur={handleDescriptionInputBlur}
        onOwnerChanged={handleOwnerChanged}
        onDueDateChanged={handleDueDateChanged}
        onTagsChanged={handleTagsChanged}
        onTagDelete={handleTagDelete}
        onCheckListItemCreated={handleCheckListItemCreated}
        onCheckListItemUpdated={handleCheckListItemUpdated}
        onCheckListItemRemoved={handleCheckListItemRemoved}
        onCheckListCreated={handleCheckListCreated}
        onCheckListUpdated={handleCheckListUpdated}
        onCheckListRemoved={handleCheckListRemoved}
        onCommentCreated={handleCommentCreated}
        onCommentUpdated={handleCommentUpdated}
        onCommentRemoved={handleCommentRemoved}
        onAttachmentUploadFinished={handleAttachmentUploadFinished}
        onAttachmentUploadCancelled={handleAttachmentUploadCancelled}
        onAttachmentRemoved={handleAttachmentRemoved}
        onAttachmentCoverUpdated={handleAttachmentCoverUpdated}
        onAttachmentUploadError={handleAttachmentUploadError}
        onDeleteTaskClick={openDeleteTaskModal}
        onAssignMembersClick={handleAssignMemberClick}
        onMoveTaskClick={handleMoveTaskClick}
      />
      <AssignMembersModal
        {...assignMembersModalData}
        isOpen={assignMembersModalOpen}
        boardId={boardId}
        listId={listId}
        taskId={taskId}
        onMembersChanged={handleMembersChanged}
        onClose={closeAssignMembersModal}
      />
      <DeleteModal
        loading={deleteLoading}
        title={t('taskModal:deleteTaskTitle')}
        subtitle={t('taskModal:deleteTaskSubtitle')}
        isOpen={deleteTaskModalOpen}
        onClose={closeDeleteTaskModal}
        onDeleteClick={handleConfirmDeleteTaskClick}
      />
      <MoveTaskModal
        {...moveTaskModalData}
        isOpen={moveTaskModalOpen}
        orderNumber={orderNumber}
        listId={listId}
        taskId={taskId}
        taskVersion={task.version}
        boardId={boardId}
        onTaskMoved={handleTaskMoved}
        onClose={closeMoveTaskModal}
      />
      <TypesSettingsModal
        {...typesSettingsModalData}
        isOpen={typesSettingsModalOpen}
        boardId={boardId}
        taskTypes={taskTypes}
        onTaskTypeEdited={handleTaskTypeEdited}
        onTaskTypeRemoved={handleTaskTypeRemoved}
        onOpenTypePopover={openTypePopover}
        onClose={closeTypesSettingsModal}
      />
    </>
  );
};

export default React.memo(TaskModalContainer);
