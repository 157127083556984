import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Type.styles.scss';

const Type = ({ withDelete, checked, type, onTypeClick, onDeleteClick }) => (
  <div className={styles.container}>
    <div className={styles.typeWrapper} onClick={() => onTypeClick(type.id)}>
      <div className={styles.typeColor} style={{ backgroundColor: type.color }}>
        {checked && <Icon className={styles.checkedIcon} name="check" />}
      </div>
      <Typography className={styles.typeName} variant="body">
        {type.name}
      </Typography>
    </div>
    {withDelete && (
      <div
        className={styles.iconWrapper}
        onClick={() => onDeleteClick(type.id)}
      >
        <Icon className={styles.icon} name="close" />
      </div>
    )}
  </div>
);

export default React.memo(Type);
