import React from 'react';

import { Loading } from 'components/shared/Loading';

import styles from './RedirectPage.styles.scss';

const RedirectPage = ({ loading }) =>
  loading && <Loading className={styles.loading} size={30} color="#623CEA" />;

export default React.memo(RedirectPage);
