import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertDate } from 'helpers/convertDate';

import { Icon } from 'components/shared/Icon';
import { Modal } from 'components/shared/Modal';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { ActionsPanel } from '../ActionsPanel';

import styles from './AttachmentModal.styles.scss';

const AttachmentModal = ({
  isOpen,
  loading,
  attachment,
  preview,
  onClose,
  onAttachmentActionClick,
}) => {
  const { t } = useTranslation('taskModal');

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <Icon name="close" className={styles.closeIcon} onClick={onClose} />
      <div className={styles.previewWrapper}>
        {loading ? (
          <Loading className={styles.loading} />
        ) : (
          <img className={styles.preview} src={preview} />
        )}
      </div>

      {attachment && (
        <div className={styles.info}>
          <Typography variant="h4" weight="medium" className={styles.title}>
            {attachment.name}
          </Typography>
          <Typography variant="body" className={styles.text}>
            {t('addedLabelStart')}{' '}
            {convertDate(attachment.createdAt, 'dd.MM.yyyy ')}
            {t('addedLabelFinish')} {convertDate(attachment.createdAt, 'HH:mm')}
          </Typography>
          <ActionsPanel
            className={styles.actions}
            itemClassName={styles.actionsItem}
            iconClassName={styles.actionsIcon}
            textClassName={styles.actionsText}
            items={[
              {
                key: 'save',
                icon: 'download',
                text: t('saveLabel'),
                clickable: true,
              },
              {
                key: 'cover',
                icon: 'cover',
                text: attachment.isCover ? t('removeLabel') : t('setLabel'),
                clickable: true,
              },
              {
                key: 'delete',
                icon: 'bucket',
                text: t('deleteLabel'),
                clickable: true,
              },
            ]}
            onItemClick={(action) =>
              onAttachmentActionClick(action, attachment)
            }
          />
        </div>
      )}
    </Modal>
  );
};
export default React.memo(AttachmentModal);
