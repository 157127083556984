import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as api from 'api/methods';

import { systemMetaSelector } from 'store/system/selectors';

import { convertDataToApi, setFormError } from './RegisterPage.helpers';
import { getValidationSchema } from './RegisterPage.formConfig';

import RegisterPage from './RegisterPage';

const RegisterPageContainer = () => {
  const { t: t_validation } = useTranslation('validation');
  const { t: t_signUpEmailPage } = useTranslation('signUpEmailPage');

  const sliderRef = useRef();
  const meta = useSelector(systemMetaSelector);

  const [loading, setLoading] = useState(false);

  const { i18n } = useTranslation();

  const language = i18n.language;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleSubmit = (values, actions) => {
    setLoading(true);

    const data = convertDataToApi({ values, language, timeZone });

    api
      .registerUser(data)
      .then(() => {
        setLoading(false);

        sliderRef.current.slickNext();
      })
      .catch(({ status }) => {
        setFormError({
          status,
          setFieldError: actions.setFieldError,
          t: t_signUpEmailPage,
        });
        setLoading(false);
      });
  };

  const passwordPattern = meta ? meta.passwordPattern : '';
  const emailPattern = meta ? meta.emailPattern : '';

  const validationSchema =
    meta && getValidationSchema(passwordPattern, emailPattern, t_validation);

  return (
    <RegisterPage
      loading={loading}
      passwordConfig={passwordPattern}
      validationSchema={validationSchema}
      sliderRef={sliderRef}
      onSubmit={handleSubmit}
    />
  );
};

export default React.memo(RegisterPageContainer);
