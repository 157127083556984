export const convertDataToForm = (data) => ({
  ...data,
  description: data.description || '',
});

export const convertDataToApi = (data) => ({
  assignedUserIdList: data.memberList
    ? data.memberList.map((member) => member.id)
    : [],
  columnId: data.columnId,
  customerId: data.customer.id,
  description: data.description || null,
  dueDate: data.dueDate ? data.dueDate : null,
  tagIdList: data.tagList ? data.tagList.map((tag) => tag.id) : [],
  taskTypeId: data.type ? data.type.id : null,
  title: data.title,
  version: data.version,
});
