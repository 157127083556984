import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { userSelector } from 'store/auth/selectors';

import { OnboardingModal } from 'components/OnboardingModal';

import { showToastMessage } from 'helpers/showToastMessage';

import { useModal } from 'hooks/useModal';
import { useFetch } from 'hooks/useFetch';

import { CreateBoardModal } from './components/CreateBoardModal';

import { getFilterConfig } from './BoardPage.config';

import BoardsPage from './BoardsPage';

const BoardsPageContainer = ({ location }) => {
  const { t } = useTranslation(['boardsPage', 'toast']);
  const history = useHistory();

  const user = useSelector(userSelector);

  const [welcomePopoverOpen, setWelcomePopoverOpen] = useState(false);

  const [activeFilter, setActiveFilter] = useState(getFilterConfig(t)[0].value);

  const [boardModalOpen, openBoardModal, closeBoardModal] = useModal({});

  const [onboardingModalOpen, openOnboardingModal, closeOnboardingModal] =
    useModal({});

  useEffect(() => {
    if (user.fullName === null) {
      openOnboardingModal();
    }
  }, []);

  const {
    data: boards,
    loading,
    setState: setBoards,
  } = useFetch(
    {
      defaultData: [],
      fetcher: api.getBoards,
      immediately: true,
      onSuccess: (data) => {
        setBoards((...prevState) => ({
          data: data.boardResponseList.map((board) => ({
            ...board,
            memberList: board.memberList.filter((member) => !member.deleted),
          })),
        }));
      },
    },
    activeFilter,
    [activeFilter],
  );

  const handleOnboardingModalClose = () => {
    closeOnboardingModal();
    setWelcomePopoverOpen(true);
  };

  const handleWelcomePopoverClose = () => {
    setWelcomePopoverOpen(false);
  };

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const handleBoardCreated = (createdBoard) => {
    setBoards((prevState) => ({
      ...prevState,
      data: [createdBoard, ...boards],
    }));

    setTimeout(() => {
      history.push(`/boards/${createdBoard.id}`);
    }, 0);
  };

  const handleBoardDeleted = (boardId) => {
    const nextBoards = boards.filter((board) => board.id !== boardId);

    setBoards((prevState) => ({
      ...prevState,
      data: nextBoards,
    }));

    showToastMessage({
      variant: 'success',
      title: t('toast:boardDeleted'),
    });
  };

  const handleFavoriteClick = (event, boardId) => {
    event.stopPropagation();

    const selectedBoard = boards.find((board) => board.id === boardId);

    const nextBoards = boards.map((board) => {
      if (board.id === boardId) {
        return { ...board, favorite: !board.favorite };
      }

      return board;
    });

    setBoards((prevState) => ({
      ...prevState,
      data: nextBoards,
    }));

    api
      .setFavoriteBoard(boardId, {
        favorite: !selectedBoard.favorite,
      })
      .catch(({ status }) => {
        const prevBoards = boards.map((board) => {
          if (board.id === boardId) {
            return { ...board, favorite: board.favorite };
          }

          return board;
        });

        setBoards((prevState) => ({
          ...prevState,
          data: prevBoards,
        }));

        showToastMessage({ variant: 'error', status, t });
      });
  };

  return (
    <Fragment>
      <BoardsPage
        isWelcomePopoverOpen={welcomePopoverOpen}
        loading={loading}
        user={user}
        boards={boards}
        activeFilter={activeFilter}
        onCreateBoardClick={openBoardModal}
        onFilterChange={handleFilterChange}
        onBoardDeleted={handleBoardDeleted}
        onFavoriteClick={handleFavoriteClick}
        onWelcomePopoverClose={handleWelcomePopoverClose}
      />
      <CreateBoardModal
        isOpen={boardModalOpen}
        onClose={closeBoardModal}
        onBoardCreated={handleBoardCreated}
      />
      <OnboardingModal
        isOpen={onboardingModalOpen}
        onClose={handleOnboardingModalClose}
      />
    </Fragment>
  );
};

export default React.memo(BoardsPageContainer);
