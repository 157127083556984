import React from 'react';

import { Link } from 'components/shared/Link';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { FormikControl } from 'components/shared/FormikControl';

import styles from './EmailStep.styles.scss';
import { useTranslation } from 'react-i18next';

const EmailStep = ({ loading, onNextStepClick }) => {
  const { t } = useTranslation('resetPasswordEmailPage');

  return (
    <div className={styles.step}>
      <Typography className={styles.title} variant="h1">
        {t('title')}
      </Typography>
      <Typography className={styles.subtitle} variant="main">
        {t('subtitle')}
      </Typography>
      <FormikControl
        formClassName={styles.inputForm}
        component="input"
        label={t('emailLabel')}
        name="email"
        placeholder="example@company.com"
      />
      <Button
        className={styles.button}
        loading={loading}
        onClick={onNextStepClick}
      >
        {t('nextButton')}
      </Button>
      <Link className={styles.link} to="/login">
        {t('signInLink')}
      </Link>
    </div>
  );
};

export default React.memo(EmailStep);
