import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { FilterItem } from './components/FilterItem';

import { FILTER_CONFIG } from './Filter.config';

import styles from './Filter.styles.scss';

const Filter = ({ className }) => (
  <div className={cn(styles.filter, className)}>
    <Typography className={styles.title}>Filter</Typography>
    {FILTER_CONFIG.map((item) => (
      <FilterItem key={item.title} title={item.title} params={item.params} />
    ))}
  </div>
);

export default React.memo(Filter);
