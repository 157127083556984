import React from 'react';
import cn from 'classnames';
import format from 'date-fns/format';
import { Tooltip } from 'react-tippy';
import parseISO from 'date-fns/parseISO';
import { enUS, ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import { getStartPosition } from 'components/pages/TimelinePage/TimelinePage.helpers';

import { Typography } from 'components/shared/Typography';

import styles from './DueDate.styles.scss';

const DueDate = ({ dueDate, language, period, taskPosition, bottomArray }) => {
  const { t } = useTranslation('timelinePage');

  const VARIANTS = {
    DAY: styles.day,
    WEEK: styles.week,
    MONTH: styles.month,
  };

  return (
    <div
      className={cn(styles.dueDateWrapper, VARIANTS[period])}
      style={{
        left: getStartPosition({ startDate: dueDate, bottomArray, period }) + 1,
      }}
    >
      <Tooltip
        position="top"
        trigger="mouseenter"
        html={
          <div className={styles.tippy}>
            <Typography className={styles.tippyText} variant="subtitle">
              {t('dueDate')}{' '}
              {format(parseISO(dueDate), 'd MMM', {
                locale: language === 'en' ? enUS : ru,
              })}
            </Typography>
          </div>
        }
      >
        <div className={styles.dueDate} />
      </Tooltip>
    </div>
  );
};

export default React.memo(DueDate);
