import React from 'react';
import cn from 'classnames';
import Scrollbar from 'react-scrollbars-custom';

import styles from './ScrollWrapper.styles.scss';

const ScrollWrapper = ({
  className,
  wrapperClassName,
  contentClassName,
  thumbClassName,
  trackClassName,
  scrollbarRef,
  children,
  scrolling,
  scrollThumbSize = 6,
  onScroll = () => {},
  ...restProps
}) => (
  <Scrollbar
    ref={scrollbarRef}
    noDefaultStyles
    onScroll={onScroll}
    className={cn(styles.scrollWrapper, className)}
    wrapperProps={{
      renderer: ({ elementRef, ...otherProps }) => (
        <div
          {...otherProps}
          ref={elementRef}
          className={cn(styles.wrapper, wrapperClassName)}
        />
      ),
    }}
    contentProps={{
      renderer: ({ elementRef, ...otherProps }) => (
        <div
          {...otherProps}
          ref={elementRef}
          className={cn(styles.content, contentClassName)}
        />
      ),
    }}
    trackYProps={{
      renderer: ({ elementRef, ...otherProps }) => (
        <div
          {...otherProps}
          ref={elementRef}
          className={cn(
            styles.trackY,
            { [styles.trackScrolling]: scrolling },
            trackClassName,
          )}
        />
      ),
    }}
    trackXProps={{
      renderer: ({ elementRef, ...otherProps }) => (
        <div
          {...otherProps}
          ref={elementRef}
          className={cn(
            styles.trackX,
            { [styles.trackScrolling]: scrolling },
            trackClassName,
          )}
        />
      ),
    }}
    thumbYProps={{
      renderer: ({ elementRef, ...otherProps }) => (
        <div
          {...otherProps}
          ref={elementRef}
          className={cn(styles.thumbY, thumbClassName)}
          style={{ width: scrollThumbSize }}
        />
      ),
    }}
    thumbXProps={{
      renderer: ({ elementRef, ...otherProps }) => (
        <div
          {...otherProps}
          ref={elementRef}
          className={cn(styles.thumbX, thumbClassName)}
          style={{ height: scrollThumbSize }}
        />
      ),
    }}
    {...restProps}
  >
    {children}
  </Scrollbar>
);

export default React.memo(ScrollWrapper);
