import React from 'react';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import AssignMemberPopover from './AssignMemberPopover';

const AssignMemberPopoverContainer = ({
  open,
  boardId,
  currentMember,
  setOpen,
  onMemberChanged,
  ...restProps
}) => {
  const {
    loading,
    data: members,
    setState: setMembers,
  } = useFetch(
    {
      defaultData: [],
      fetcher: api.getBoardMembers,
      immediately: false,
      stopRequest: !open,
      onSuccess: (data) => {
        setMembers((...prevState) => ({
          data: data.filter((member) => member.id !== currentMember.id),
        }));
      },
    },

    boardId,
    [open],
  );

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleMemberClick = (member) => {
    setOpen(false);

    if (onMemberChanged) {
      onMemberChanged(member);
    }
  };

  return (
    <AssignMemberPopover
      {...restProps}
      loading={loading}
      open={open}
      currentMember={currentMember}
      members={members}
      setOpen={setOpen}
      onRequestClose={handleRequestClose}
      onMemberClick={handleMemberClick}
    />
  );
};

export default React.memo(AssignMemberPopoverContainer);
