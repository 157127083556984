import React from 'react';

import Header from './Header';

const HeaderContainer = ({
  modulesRef,
  welcomeRef,
  applicationRef,
  functionsRef,
  ...restProps
}) => {
  return (
    <Header
      modulesRef={modulesRef}
      welcomeRef={welcomeRef}
      functionsRef={functionsRef}
      applicationRef={applicationRef}
      {...restProps}
    />
  );
};

export default React.memo(HeaderContainer);
