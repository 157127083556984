import React, { useEffect, useRef, useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { useParams } from 'react-router-dom';

import { TASK_WIDTH, TIME_PERIOD } from './TimelinePage.constants';

import * as api from 'api/methods';

import { toPosionScroll } from './TimelinePage.helpers';

import { useFetch } from 'hooks/useFetch';
import { useModal } from 'hooks/useModal';

import { NotificationModal } from 'components/pages/AnalyticsPage/components/NotificationModal';

import TimelinePage from './TimelinePage';

const TimelinePageContainer = () => {
  const lineRef = useRef();
  const timelineRef = useRef();
  const tasksSectionRef = useRef();
  const lineContainerRef = useRef();
  const scrollPermissionRef = useRef(false);
  const timelineLastScrollTopRef = useRef(0);

  const [dragging, setDragging] = useState(null);
  const [hovering, setHovering] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(TIME_PERIOD.DAY);

  const { id: boardId } = useParams();

  const [notificationModalOpen, openNotificationModal, closeNotificationModal] =
    useModal({});

  const { data: analyticNotification } = useFetch(
    {
      defaultData: {},
      fetcher: api.getAnalyticNotification,
      immediately: true,
      onSuccess: (data) => {
        if (!data.timelineNotified) {
          openNotificationModal();
        }
      },
    },
    boardId,
    [],
  );

  const {
    data: tasksTimeline,
    setState: setTasksTimeline,
    loading,
  } = useFetch(
    {
      defaultData: [],
      fetcher: api.getTasksTimeline,
      immediately: true,
      onSuccess: (data) => {
        setTasksTimeline((...prevState) => ({
          data: data.map((task, index) => ({
            ...task,
            taskPosition: index * TASK_WIDTH,
          })),
        }));
      },
    },
    boardId,
    [],
  );

  useEffect(() => {
    if (timelineRef.current) {
      toPosionScroll({ selectedTimePeriod, timelineRef });
    }
  });

  const handleTaskScroll = (event) => {
    setHovering(null);

    if (scrollPermissionRef.current) {
      scrollPermissionRef.current = false;
      return;
    }

    scrollPermissionRef.current = true;
    timelineRef.current.scrollTop = event.target.scrollTop;
  };

  const handleTimelineScroll = (event) => {
    setHovering(null);

    if (scrollPermissionRef.current) {
      scrollPermissionRef.current = false;
      return;
    }

    if (event.scrollTop !== timelineLastScrollTopRef.current) {
      scrollPermissionRef.current = true;
      tasksSectionRef.current.scrollTop = event.scrollTop;
      timelineLastScrollTopRef.current = event.scrollTop;
    }
  };

  const handleSelectPeriod = (period) => {
    setSelectedTimePeriod(period);
  };

  const handleTaskAndLineHover = (state) => {
    if (!dragging) {
      setHovering(state);
    }
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setDragging(null);

    if (oldIndex !== newIndex) {
      setTasksTimeline((prevData) => ({
        ...prevData,
        data: arrayMove(prevData.data, oldIndex, newIndex).map(
          (task, index) => ({ ...task, taskPosition: index * TASK_WIDTH }),
        ),
      }));
    }
  };

  const handleSortStart = ({ index }, event) => {
    setHovering(null);
    const taskIndex = index > 0 ? index : true;

    setDragging(taskIndex);
  };

  return (
    <>
      <TimelinePage
        lineRef={lineRef}
        timelineRef={timelineRef}
        tasksSectionRef={tasksSectionRef}
        lineContainerRef={lineContainerRef}
        loading={loading}
        hovering={hovering}
        dragging={dragging}
        boardId={boardId}
        selectedTimePeriod={selectedTimePeriod}
        tasks={tasksTimeline}
        onSortEnd={handleSortEnd}
        onSortStart={handleSortStart}
        onTaskScroll={handleTaskScroll}
        onSelectPeriod={handleSelectPeriod}
        onTimelineScroll={handleTimelineScroll}
        onTaskAndLineHover={handleTaskAndLineHover}
      />
      <NotificationModal
        isOpen={notificationModalOpen}
        boardId={boardId}
        analyticNotification={analyticNotification}
        onClose={closeNotificationModal}
      />
    </>
  );
};

export default React.memo(TimelinePageContainer);
