import React from 'react';
import cn from 'classnames';
import Tippy from '@tippyjs/react/headless';

import { Typography } from 'components/shared/Typography';

import styles from './CustomTag.styles.scss';

const CustomTag = ({ color, description, className }) => (
  <Tippy
    render={() => {
      return (
        description && (
          <div className={styles.tippy}>
            <Typography variant="subtitle" className={styles.tippyText}>
              {description}
            </Typography>
          </div>
        )
      );
    }}
    placement="top"
  >
    <div
      className={cn(styles.tag, className)}
      style={{ backgroundColor: color }}
    />
  </Tippy>
);

export default React.memo(CustomTag);
