import React, { useState } from 'react';
import format from 'date-fns/format';

import { convertDate } from 'helpers/convertDate';

import * as api from 'api/methods';

import { usePopover } from '../../TaskModal.hooks';

import CheckListSection from './CheckListSection';

const CheckListSectionContainer = ({
  boardId,
  taskId,
  onCheckListItemCreated,
  onCheckListItemUpdated,
  onCheckListItemRemoved,
  onCheckListUpdated,
  onCheckListRemoved,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);

  const [editing, setEditing] = useState(null);
  const [creating, setCreating] = useState(null);
  const [showDetailsTooltip, setShowDetailsTooltip] = useState(null);
  const [openAssignMemberPopover, setOpenAssignMemberPopover] = useState(null);

  const { togglePopover, currentPopover, closePopover } = usePopover();

  const handleItemDueDateChanged = (
    dueDate,
    item,
    { values, setFieldValue },
  ) => {
    setFieldValue(
      'items',
      values.items.map((prevItem) => {
        if (prevItem.id === item.id) {
          return {
            ...prevItem,
            dueDate: dueDate
              ? format(dueDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
              : null,
          };
        }

        return prevItem;
      }),
    );

    api
      .updateCheckListItem(
        { boardId, taskId, checkListId: values.checkListId, itemId: item.id },
        {
          assigneeId: item.assignee ? item.assignee.id : null,
          name: item.name,
          dueDate: dueDate ? format(dueDate, "yyyy-MM-dd'T'HH:mm:ss'Z'") : null,
          completed: item.completed,
        },
      )
      .then((updatedItem) => {
        if (onCheckListItemUpdated) {
          onCheckListItemUpdated(updatedItem, values.checkListId);
        }
      })
      .catch(() => {});
  };

  const handleItemAssigneeChanged = (
    assignee,
    item,
    { values, setFieldValue },
  ) => {
    setFieldValue(
      'items',
      values.items.map((prevItem) => {
        if (prevItem.id === item.id) {
          return {
            ...prevItem,
            assignee,
          };
        }

        return prevItem;
      }),
    );

    api
      .updateCheckListItem(
        { boardId, taskId, checkListId: values.checkListId, itemId: item.id },
        {
          assigneeId: assignee ? assignee.id : null,
          name: item.name,
          dueDate: item.dueDate ? item.dueDate : null,
          completed: item.completed,
        },
      )
      .then((updatedItem) => {
        if (onCheckListItemUpdated) {
          onCheckListItemUpdated(updatedItem, values.checkListId);
        }
      })
      .catch(() => {});
  };

  const handleCompletedChange = (event, item, completed, { field, values }) => {
    api
      .updateCheckListItem(
        { boardId, taskId, checkListId: values.checkListId, itemId: item.id },
        {
          assigneeId: item.assignee ? item.assignee.id : null,
          name: item.name,
          dueDate: item.dueDate ? item.dueDate : null,
          completed: !completed,
        },
      )
      .then((updatedItem) => {
        if (onCheckListItemUpdated) {
          onCheckListItemUpdated(updatedItem, values.checkListId);
        }
      })
      .catch(() => {});

    field.onChange(event);
  };

  const handleTitleInputBlur = ({
    checkListId,
    title,
    currentTitle,
    setFieldValue,
  }) => {
    if (currentTitle === '') {
      setFieldValue(title);

      return;
    }

    if (title === currentTitle) {
      return;
    }

    api
      .updateCheckList({ boardId, taskId, checkListId }, { name: currentTitle })
      .then((updatedCheckList) => {
        if (onCheckListUpdated) {
          onCheckListUpdated(updatedCheckList);
        }
      })
      .catch(() => {});
  };

  const handleItemInputFocus = (event, item) => {
    setEditing(item.id);
  };

  const handleItemInputBlur = (event) => {
    if (loading) {
      return;
    }

    setEditing(null);
  };

  const handleCreatingItemInputBlur = () => {
    if (loading) {
      return;
    }

    setCreating(null);
  };

  const handleDeleteCheckListClick = (checkListId) => {
    api
      .removeCheckList({ boardId, taskId, checkListId })
      .then(({ taskVersion }) => {
        if (onCheckListRemoved) {
          onCheckListRemoved(checkListId, taskVersion);
        }
      })
      .catch(() => {});
  };

  const handleAddItemClick = (checkListId) => {
    setCreating(checkListId);
  };

  const handleDeleteItemClick = (itemId, values, { setFieldValue }) => {
    setFieldValue(
      'items',
      values.items.filter((item) => item.id !== itemId),
    );

    setShowDetailsTooltip(itemId);

    api
      .removeCheckListItem({
        boardId,
        taskId,
        checkListId: values.checkListId,
        itemId,
      })
      .then(() => {
        if (onCheckListItemRemoved) {
          onCheckListItemRemoved(itemId, values.checkListId);
        }
      })
      .catch(() => {});
  };

  const handleSaveItemClick = (values, { setFieldValue }) => {
    setLoading(true);

    api
      .createCheckListItem(
        { boardId, taskId, checkListId: values.checkListId },
        { name: values.creatingName },
      )
      .then((createdItem) => {
        setLoading(false);

        setCreating(null);
        setFieldValue('creatingName', '');
        setFieldValue('items', [...values.items, createdItem]);

        if (onCheckListItemCreated) {
          onCheckListItemCreated(createdItem, values.checkListId);
        }
      })
      .catch(() => {
        setLoading(false);
        setCreating(null);
        setFieldValue('creatingName', '');

        setFieldValue('items', values.items);
      });
  };

  const handleUpdateItemClick = (item, { values }) => {
    setLoading(true);
    setEditing(null);

    api
      .updateCheckListItem(
        { boardId, taskId, checkListId: values.checkListId, itemId: item.id },
        {
          assigneeId: item.assignee ? item.assignee.id : null,
          name: item.name,
          dueDate: item.dueDate
            ? convertDate(item.dueDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
            : null,
          completed: item.completed,
        },
      )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancelItemClick = (
    itemId,
    prevItems,
    { values, setFieldValue },
  ) => {
    const prevItem = prevItems.find((item) => item.id === itemId);

    setFieldValue(
      'items',
      values.items.map((item) => {
        if (item.id === prevItem.id) {
          return {
            ...item,
            name: prevItem.name,
          };
        }

        return item;
      }),
    );
  };

  const handleCancelCreatingItemClick = ({ setFieldValue }) => {
    setFieldValue('creatingName', '');
  };

  const handleContextItemClick = (
    { key, open, openPopover },
    { itemId, values, setFieldValue },
  ) => {
    switch (key) {
      case 'assignee': {
        openPopover(false);
        setOpenAssignMemberPopover(itemId);

        break;
      }

      case 'dueDate': {
        openPopover(false);
        togglePopover(itemId);

        break;
      }

      case 'delete': {
        handleDeleteItemClick(itemId, values, { setFieldValue });

        break;
      }
    }
  };

  const handleCheckListItemClick = (action, item) => {
    switch (action.key) {
      case 'person': {
        setOpenAssignMemberPopover(item.id);

        break;
      }

      case 'dueDate': {
        togglePopover(item.id);

        break;
      }
    }
  };

  return (
    <CheckListSection
      {...restProps}
      openAssignMemberPopover={openAssignMemberPopover}
      showDetailsTooltip={showDetailsTooltip}
      creating={creating}
      editing={editing}
      boardId={boardId}
      currentPopover={currentPopover}
      setOpenAssignMemberPopover={setOpenAssignMemberPopover}
      closeDatePopover={closePopover}
      onCompletedChange={handleCompletedChange}
      onTitleInputBlur={handleTitleInputBlur}
      onItemInputFocus={handleItemInputFocus}
      onItemInputBlur={handleItemInputBlur}
      onCreatingItemInputBlur={handleCreatingItemInputBlur}
      onCheckListItemClick={handleCheckListItemClick}
      onItemDueDateChanged={handleItemDueDateChanged}
      onItemAssigneeChanged={handleItemAssigneeChanged}
      onAddItemClick={handleAddItemClick}
      onSaveItemClick={handleSaveItemClick}
      onDeleteItemClick={handleDeleteItemClick}
      onUpdateItemClick={handleUpdateItemClick}
      onCancelItemClick={handleCancelItemClick}
      onCancelCreatingItemClick={handleCancelCreatingItemClick}
      onDeleteCheckListClick={handleDeleteCheckListClick}
      onItemClick={handleContextItemClick}
    />
  );
};

export default React.memo(CheckListSectionContainer);
