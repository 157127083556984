import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './IndicatorsContainer.styles.scss';

const VARIANTS = {
  searchable: styles.searchable,
  default: styles.default,
  error: styles.error,
};

const IndicatorsContainer = ({ variant = 'default', className, menuOpen }) => (
  <div
    className={cn(
      styles.indicator,
      VARIANTS[variant],
      { [styles.indicatorOpen]: menuOpen },
      className,
    )}
  >
    <Icon name="arrowDown" className={cn(styles.icon, VARIANTS[variant])} />
  </div>
);

export default React.memo(IndicatorsContainer);
