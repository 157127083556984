import React from 'react';
import cn from 'classnames';
import { AVATAR_COLORS } from 'constants/common';
import { getInitials } from 'helpers/getInitials';
import { Typography } from 'components/shared/Typography';
import styles from './Avatar.styles.scss';

const VARIANTS = {
  extraSmall: styles.extraSmall,
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

const Avatar = ({
  className,
  variant = 'medium',
  fullName,
  color,
  onClick,
}) => {
  const avatarColor = AVATAR_COLORS.find((clr) => clr.value === color) || {};
  const initials = getInitials(fullName);

  return (
    <div
      style={{
        background: `linear-gradient(134.47deg, ${avatarColor.startColor} 15.6%, ${avatarColor.finishColor} 83.73%)`,
      }}
      className={cn(styles.avatar, VARIANTS[variant], className)}
      onClick={onClick}
    >
      <Typography
        className={cn(styles.text, styles.unselectable, VARIANTS[variant])}
        weight="light"
      >
        {initials}
      </Typography>
    </div>
  );
};

export default React.memo(Avatar);
