import React, { useState } from 'react';
import { useModal } from 'hooks/useModal';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { DeleteModal } from 'components/DeleteModal';

import { showToastMessage } from 'helpers/showToastMessage';

import BoardCard from './BoardCard';

const BoardCardContainer = ({ id, onBoardDeleted, members, ...restProps }) => {
  const { t } = useTranslation(['toast', 'boardPage']);

  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [deleteBoardModalOpen, openDeleteBoardModal, closeDeleteBoardModal] =
    useModal({});

  const handleContextMenuClick = (event) => {
    event.preventDefault();

    setIsOpen(!isOpen);
  };

  const handleOuterAction = () => {
    setIsOpen(false);
  };

  const handleBoardClick = (boardId) => {
    history.push(`/boards/${boardId}`);
  };

  const handleConformDeleteBoardClick = () => {
    setLoading(true);

    api
      .deleteBoard(id)
      .then((response) => {
        setLoading(false);

        closeDeleteBoardModal();

        if (onBoardDeleted) {
          onBoardDeleted(id);
        }
      })
      .catch(({ status }) => {
        setLoading(false);

        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleDeleteBoardClick = () => {
    openDeleteBoardModal();

    setIsOpen(false);
  };

  const nextMembers = members.filter((user) => !user.deleted);

  return (
    <>
      <BoardCard
        {...restProps}
        id={id}
        isOpen={isOpen}
        members={nextMembers}
        onContextMenuClick={handleContextMenuClick}
        onOuterAction={handleOuterAction}
        onDeleteBoardClick={handleDeleteBoardClick}
        onBoardClick={handleBoardClick}
      />
      <DeleteModal
        loading={loading}
        title={t('boardPage:deleteBoardTitle')}
        subtitle={t('boardPage:deleteBoardSubtitle')}
        isOpen={deleteBoardModalOpen}
        onClose={closeDeleteBoardModal}
        onDeleteClick={handleConformDeleteBoardClick}
      />
    </>
  );
};

export default React.memo(BoardCardContainer);
