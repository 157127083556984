import React from 'react';
import { useTranslation } from 'react-i18next';

import { PARTS_TYPE } from './GuideModal.constants';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import ColumnTypeImg from 'assets/img/columns-types1.svg';
import TypeImg from 'assets/img/columns-types2.svg';
import TimelineImg from 'assets/img/columns-types3.svg';

import { Info } from './components/Info';
import { Controls } from './components/Controls';

import styles from './GuideModal.styles.scss';

const GuideModal = ({ isOpen, visibleChapter, onControlClick, onClose }) => {
  const { t, i18n } = useTranslation('guideModal');

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <Typography
        className={styles.title}
        variant="h2"
        weight={i18n.language === 'ru' ? 'regular' : 'medium'}
      >
        {t('title')}
      </Typography>

      <div className={styles.controls}>
        <Controls
          name={t('configure')}
          active={visibleChapter === PARTS_TYPE.CONFIGURE}
          onClick={() => onControlClick(PARTS_TYPE.CONFIGURE)}
        />
        <Controls
          name={t('instruction')}
          active={visibleChapter === PARTS_TYPE.INSTRUCTION}
          onClick={() => onControlClick(PARTS_TYPE.INSTRUCTION)}
        />
      </div>

      {visibleChapter === PARTS_TYPE.CONFIGURE && (
        <ScrollWrapper
          className={styles.scroll}
          trackClassName={styles.trackClassName}
          scrollThumbSize={4}
        >
          <Info
            part="1"
            image={ColumnTypeImg}
            text={
              <Typography variant="body" weight="regular">
                {t('configureFirstPartText')}
              </Typography>
            }
          />

          <Info
            part="2"
            image={TypeImg}
            text={
              <>
                <Typography
                  className={styles.text}
                  variant="body"
                  weight="regular"
                >
                  {t('configureSecondPartText')}
                </Typography>

                <Typography
                  className={styles.text}
                  variant="body"
                  weight="regular"
                >
                  <span>
                    <span className={styles.queue}>{t('queue')}</span>
                    {t('queueText')}
                  </span>
                </Typography>

                <Typography
                  className={styles.text}
                  variant="body"
                  weight="regular"
                >
                  <span>
                    <span className={styles.inProgress}>{t('inProgress')}</span>
                    {t('inProgressText')}
                  </span>
                </Typography>

                <Typography
                  className={styles.text}
                  variant="body"
                  weight="regular"
                >
                  <span>
                    <span className={styles.done}>{t('done')}</span>
                    {t('doneText')}
                  </span>
                </Typography>
              </>
            }
          />
        </ScrollWrapper>
      )}

      {visibleChapter === PARTS_TYPE.INSTRUCTION && (
        <Info
          part="1"
          image={TimelineImg}
          text={
            <Typography variant="body" weight="regular">
              {t('instructionPartText')}
            </Typography>
          }
        />
      )}
    </Modal>
  );
};

export default React.memo(GuideModal);
