import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from 'api/methods';
import { useModal } from 'hooks/useModal';
import { usePopover } from '../TaskModal/TaskModal.hooks';
import { useBoard } from '../../../BoardPageContext/BoardPage.context';
import { convertDataToApi } from '../TaskModal/TaskModal.helpers';
import { showToastMessage } from 'helpers/showToastMessage';
import TaskCard from './TaskCard';
import { TypesSettingsModal } from 'components/pages/BoardPage/components/TypesSettingsModal';
import { POPOVER_TYPES } from '../TaskModal/TaskModal.constants';
import { BOARD_PROVIDER_TYPES } from '../../../BoardPageContext/BoardPageProvider.constants';

const TaskCardContainer = ({
  taskTypes,
  boardId,
  task = {},
  id: taskId,
  coverAttachmentId,
  ...restProps
}) => {
  const { t } = useTranslation('toast');
  const [loading, setLoading] = useState(false);
  const [coverPreview, setCoverPreview] = useState(null);

  const { dispatch } = useBoard();
  const popoverProps = usePopover();
  const [
    typesSettingsModalOpen,
    openTypesSettingsModal,
    closeTypesSettingsModal,
    typesSettingsModalData,
  ] = useModal({});

  const updateTask = async (data) => {
    try {
      const updatedTask = await api.updateTask(
        { boardId, listId: task.columnId, taskId },
        convertDataToApi({ ...task, ...data }),
      );
      dispatch({
        type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
        payload: { task: updatedTask },
      });
    } catch ({ status }) {
      showToastMessage({ variant: 'error', status, t });
    }
  };

  useEffect(() => {
    if (coverAttachmentId) {
      const fetchCoverPreview = async () => {
        setLoading(true);
        try {
          const coverBlob = await api.getTaskCover({
            boardId,
            taskId,
            attachmentId: coverAttachmentId,
          });

          if (typeof window !== 'undefined' && window.FileReader) {
            const reader = new window.FileReader();
            reader.readAsDataURL(coverBlob);
            reader.onload = () => {
              setCoverPreview(reader.result);
              setLoading(false);
            };
          } else {
            setLoading(false);
          }
        } catch {
          setLoading(false);
        }
      };
      fetchCoverPreview();
    } else {
      setCoverPreview(null);
    }
  }, [coverAttachmentId, boardId, taskId]);

  const handleAddTaskTypeClick = (typeId) => {
    popoverProps.togglePopover();
    updateTask({ type: { id: task.type?.id === typeId ? null : typeId } });
  };

  const handleTypeCardClick = (event) => {
    event.stopPropagation();
    popoverProps.togglePopover(POPOVER_TYPES.TYPE_CARD);
  };

  const handleTypesSettingsCardClick = (showSettings) => {
    openTypesSettingsModal({ showSettings });
  };

  const openTypePopover = () => {
    if (typesSettingsModalOpen) {
      closeTypesSettingsModal();
    }
    popoverProps.togglePopover(POPOVER_TYPES.TYPE_CARD);
  };

  return (
    <>
      <TaskCard
        {...restProps}
        {...popoverProps}
        loading={loading}
        coverPreview={coverPreview}
        boardId={boardId}
        taskTypes={taskTypes}
        type={task.type}
        onTypeCardClick={handleTypeCardClick}
        onAddTaskTypeClick={handleAddTaskTypeClick}
        onTypesSettingsCardClick={handleTypesSettingsCardClick}
      />
      <TypesSettingsModal
        {...typesSettingsModalData}
        isOpen={typesSettingsModalOpen}
        boardId={boardId}
        taskTypes={taskTypes}
        onOpenTypePopover={openTypePopover}
        onClose={closeTypesSettingsModal}
      />
    </>
  );
};

export default React.memo(TaskCardContainer);
