import { useEffect } from 'react';

export const useResponsive = () => {
  useEffect(() => {
    const appElement = document.getElementById('x-core-app');

    if (appElement) {
      appElement.classList.add('responsive');
    }

    return () => {
      appElement.classList.remove('responsive');
    };
  }, []);
};
