import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './WeekDay.styles.scss';

const WeekDay = ({ weekday, locale, localeUtils }) => (
  <div className={styles.weekday}>
    <Typography component="span" variant="subtitle" className={styles.day}>
      {localeUtils.formatWeekdayShort(weekday, locale)}
    </Typography>
  </div>
);

export default React.memo(WeekDay);
