import React from 'react';
import cn from 'classnames';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/shared/Icon';
import { ContextMenuPopover } from 'components/ContextMenuPopover';

import { DatePopover } from '../../../DatePopover';

import { getContextMenuPopoverItems } from './EndAdornmentContent.helpers';

import { AssignMemberPopover } from '../AssignMemberPopover';

import styles from './EndAdornmentContent.styles.scss';

const EndAdornmentContent = ({
  currentPopover,
  openAssignMemberPopover,
  item,
  boardId,
  values,
  taskDueDate,
  closeDatePopover,
  setFieldValue,
  setOpenAssignMemberPopover,
  onItemClick,
  onItemDueDateChanged,
  onItemAssigneeChanged,
}) => {
  const { t } = useTranslation('taskModal');

  return (
    <>
      <DatePopover
        open={currentPopover === item.id}
        onRequestClose={() => closeDatePopover(item.id)}
        value={item.dueDate}
        disabledDays={
          taskDueDate
            ? { before: new Date(), after: parseISO(taskDueDate) }
            : { before: new Date() }
        }
        onChanged={(dueDate) =>
          onItemDueDateChanged(dueDate, item, {
            values,
            setFieldValue,
          })
        }
      >
        <span className={styles.popoverStartingPoint} />
      </DatePopover>

      <AssignMemberPopover
        open={openAssignMemberPopover === item.id}
        setOpen={setOpenAssignMemberPopover}
        boardId={boardId}
        title={t('assignedLabel')}
        currentMember={item.assignee || {}}
        onMemberChanged={(member) =>
          onItemAssigneeChanged(member, item, {
            values,
            setFieldValue,
          })
        }
      >
        <span className={styles.popoverStartingPoint} />
      </AssignMemberPopover>

      <ContextMenuPopover
        offset="10"
        position="bottom-start"
        items={getContextMenuPopoverItems(t)}
        onItemClick={(props) =>
          onItemClick(props, {
            itemId: item.id,
            values,
            setFieldValue,
          })
        }
      >
        {({ open, openPopover }) => (
          <span
            className={styles.itemInputIconWrapper}
            onClick={() => openPopover(!open)}
          >
            <Icon
              className={cn(styles.itemInputIcon, {
                [styles.itemInputIconActive]:
                  open || openAssignMemberPopover || currentPopover,
              })}
              name="details"
            />
          </span>
        )}
      </ContextMenuPopover>
    </>
  );
};

export default React.memo(EndAdornmentContent);
