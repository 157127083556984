import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import LandingLayout from './LandingLayout';

const LandingLayoutContainer = ({
  children,
  modulesRef,
  welcomeRef,
  applicationRef,
  functionsRef,
  onTryForFreeClick,
}) => {
  const { i18n } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    if (navigator.language.indexOf('en') > -1 && i18n.language !== 'en') {
      i18n.changeLanguage('en');
    }

    if (navigator.language.indexOf('ru') > -1 && i18n.language !== 'ru') {
      i18n.changeLanguage('ru');
    }
  }, []);

  const handleLanguageChange = (nextLanguage) => {
    i18n.changeLanguage(nextLanguage);
  };

  const handleSignInClick = () => {
    history.push({
      pathname: ROUTES.LOGIN,
      state: { goToSection: false },
    });
  };

  const handlePolicyClick = () => {
    history.push({
      pathname: ROUTES.POLICY,
      state: { goToSection: false },
    });
  };

  return (
    <LandingLayout
      modulesRef={modulesRef}
      welcomeRef={welcomeRef}
      applicationRef={applicationRef}
      functionsRef={functionsRef}
      currentLanguage={i18n.language}
      onLanguageChange={handleLanguageChange}
      onTryForFreeClick={onTryForFreeClick}
      onPolicyClick={handlePolicyClick}
      onSignInClick={handleSignInClick}
    >
      {children}
    </LandingLayout>
  );
};

export default React.memo(LandingLayoutContainer);
