import React, { useState } from 'react';

import PageLayout from './PageLayout';

const PageLayoutContainer = ({ tabs, ...props }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (nextTabIndex) => {
    setActiveTabIndex(nextTabIndex);
  };

  const currentTab = tabs
    ? tabs.find((tab, index) => index === activeTabIndex)
    : null;

  return (
    <PageLayout
      {...props}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleTabChange}
    />
  );
};

export default React.memo(PageLayoutContainer);
