import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import cn from 'classnames';
import styles from './Barcode.styles.scss';

const Barcode = ({
  className,
  value,
  size = 140,
  level = 'L',
  bgColor = '#ffffff',
  minVersion = 5,
  ...props
}) => {
  return (
    <div className={cn(styles.section, className)} {...props}>
      <QRCodeSVG
        value={value}
        size={size}
        level={level}
        bgColor={bgColor}
        minVersion={minVersion}
      />
    </div>
  );
};

export default React.memo(Barcode);
