import React from 'react';
import { useHistory } from 'react-router-dom';

import { useResponsive } from 'hooks/useResponsive';

import RestrictedPage from './RestrictedPage';

const RestrictedPageContainer = () => {
  useResponsive();

  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/');
  };

  return <RestrictedPage onButtonClick={handleButtonClick} />;
};

export default React.memo(RestrictedPageContainer);
