import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { BOARD_PROVIDER_TYPES } from '../BoardPageContext/BoardPageProvider.constants';

import * as api from 'api/methods';

import { showToastMessage } from 'helpers/showToastMessage';

import { useModal } from 'hooks/useModal';
import { useBoard } from '../BoardPageContext/BoardPage.context';

import { DeleteModal } from 'components/DeleteModal';

import { TypesSettingsModal } from '../TypesSettingsModal';

import SettingsModal from './SettingsModal';

const SettingsModalContainer = ({
  isOpen,
  board,
  openSettingsModal,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation(['toast', 'boardPage']);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { dispatch } = useBoard();

  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal({});

  const [
    typesSettingsModalOpen,
    openTypesSettingsModal,
    closeTypesSettingsModal,
    typesSettingsModalData,
  ] = useModal({});

  const handleTitleInputBlur = (title) => {
    if (title.length > 40 || title === '' || title === board.name) {
      return;
    }

    dispatch({
      type: BOARD_PROVIDER_TYPES.UPDATE_BOARD_DATA,
      payload: { name: title },
    });

    api
      .updateBoard(board.id, { color: board.color, name: title })
      .then(() => {
        showToastMessage({
          variant: 'success',
          title: t('boardUpdated'),
        });
      })
      .catch(({ status }) => {
        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleColorClick = (color) => {
    if (color === board.color) {
      return;
    }

    dispatch({
      type: BOARD_PROVIDER_TYPES.UPDATE_BOARD_DATA,
      payload: { color: color },
    });

    api
      .updateBoard(board.id, { color: color, name: board.title })
      .then(() => {
        showToastMessage({
          variant: 'success',
          title: t('boardUpdated'),
        });
      })
      .catch(({ status }) => {
        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleCloseDeleteModal = () => {
    closeDeleteModal();

    openSettingsModal({ board });
  };

  const handleDeleteBoard = () => {
    setLoading(true);

    api
      .deleteBoard(board.id)
      .then(() => {
        setLoading(false);

        closeDeleteModal();

        showToastMessage({
          variant: 'success',
          title: t('boardDeleted'),
        });

        history.push(ROUTES.BOARDS);
      })
      .catch(({ status }) => {
        setLoading(false);

        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleTypesSettingsClick = () => {
    openTypesSettingsModal({ showSettings: true });
  };

  const taskTypes = board.taskTypeList || [];
  const admin =
    board.memberList &&
    board.memberList.find((member) => member.role === 'ADMIN');

  return (
    <>
      <SettingsModal
        isOpen={isOpen}
        board={board}
        admin={admin}
        types={taskTypes}
        onClose={onClose}
        onTypesSettings={handleTypesSettingsClick}
        onTitleInputBlur={handleTitleInputBlur}
        onColorClick={handleColorClick}
        onDeleteBoardClick={openDeleteModal}
        {...restProps}
      />
      <DeleteModal
        loading={loading}
        isOpen={deleteModalOpen}
        title={t('boardPage:deleteBoardTitle')}
        subtitle={t('boardPage:deleteBoardSubtitle')}
        onClose={handleCloseDeleteModal}
        onDeleteClick={handleDeleteBoard}
      />
      <TypesSettingsModal
        {...typesSettingsModalData}
        isOpen={typesSettingsModalOpen}
        boardId={board.id}
        taskTypes={taskTypes}
        onClose={closeTypesSettingsModal}
      />
    </>
  );
};

export default React.memo(SettingsModalContainer);
