import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as api from 'api/methods';

import { systemMetaSelector } from 'store/system/selectors';
import { verifyUserEmail } from 'store/auth/actions';

import { setFormError } from './ChangeEmailModal.helpers';

import { userSelector } from 'store/auth/selectors';

import { useModal } from 'hooks/useModal';

import { CodeStep } from 'components/CodeStep';

import { getValidationSchema } from './ChangeEmailModal.formConfig';

import ChangeEmailModal from './ChangeEmailModal';

const ChangeEmailModalContainer = ({ onClose, ...restProps }) => {
  const { t } = useTranslation('validation');

  const user = useSelector(userSelector);
  const meta = useSelector(systemMetaSelector);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [
    codeStepModalOpen,
    openCodeStepModal,
    closeCodeStepModal,
    codeStepModalData,
  ] = useModal({
    defaultOpen: false,
  });

  const verifyRequest = (code, data) => dispatch(verifyUserEmail(code, data));
  const changeRequest = (data) => api.changeEmail(data);

  const handleConfirmClick = (values, actions) => {
    setLoading(true);

    const data = { newEmail: values.email };

    api
      .changeEmail(data)
      .then(() => {
        setLoading(false);

        onClose();

        if (!codeStepModalOpen) {
          openCodeStepModal({
            verifyRequestData: data,
            changeRequestData: data,
            email: values.email,
          });
        }
      })
      .catch(({ status }) => {
        setFormError({
          status,
          setFieldError: actions.setFieldError,
          t,
        });

        setLoading(false);
      });
  };

  const emailPattern = meta ? meta.emailPattern : '';

  const validationSchema = meta && getValidationSchema(emailPattern, t);

  return (
    <>
      <ChangeEmailModal
        {...restProps}
        validationSchema={validationSchema}
        user={user}
        onClose={onClose}
        onConfirmClick={handleConfirmClick}
      />
      <CodeStep
        {...codeStepModalData}
        loading={loading}
        variant="email"
        isOpen={codeStepModalOpen}
        verifyRequest={verifyRequest}
        changeRequest={changeRequest}
        onClose={closeCodeStepModal}
      />
    </>
  );
};

export default React.memo(ChangeEmailModalContainer);
