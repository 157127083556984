import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext, Field } from 'formik';
import cn from 'classnames';

import { Avatar } from 'components/shared/Avatar';

import { InputField, ColorRadioField } from 'components/FormikFields';

import styles from './EnterNameSection.styles.scss';

const EnterNameSection = ({
  className,
  variant,
  colors,
  size,
  onColorChange,
  onNameBlur,
}) => {
  const { t } = useTranslation('onboardingModal');
  const { values } = useFormikContext();

  const currentColor = colors.find((color) => color.value === values.color);

  return (
    <div className={cn(styles.section, className)}>
      <div className={styles.inner}>
        <Avatar
          variant="large"
          fullName={values.name}
          color={currentColor ? currentColor.value : ''}
        />
        <Field
          classNames={{
            formControl: styles.formControl,
          }}
          name="name"
          placeholder={t('placeholder')}
          component={InputField}
          onBlur={onNameBlur}
        />
      </div>
      <Field
        classNames={{
          icon: styles.icon,
          iconWrapper: styles.iconWrapper,
          formControl: styles.colorFormControl,
        }}
        name="color"
        variant="circle"
        colors={colors}
        size={size}
        component={ColorRadioField}
        onAfterChange={onColorChange}
      />
    </div>
  );
};

export default React.memo(EnterNameSection);
