import _orderBy from 'lodash/orderBy';

import { SOCKET_EVENT_TYPES } from 'constants/socketConstants';
import { BOARD_PROVIDER_TYPES } from '../BoardPageContext/BoardPageProvider.constants';

export const updateList = ({ listId, data, dispatch }) => {
  switch (data.state) {
    case SOCKET_EVENT_TYPES.BOARD_TASK_CREATED: {
      if (listId === data.changedColumns[0].id) {
        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_TASK_CREATED,
          payload: { listId, task: data.changedTasks[0] },
        });
      }

      break;
    }

    case SOCKET_EVENT_TYPES.BOARD_TASK_UPDATED: {
      if (listId === data.changedColumns[0].id) {
        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_TASK_UPDATE,
          payload: { listId, task: data.changedTasks[0] },
        });
      }

      break;
    }

    case SOCKET_EVENT_TYPES.BOARD_TASK_MOVED: {
      dispatch({
        type: BOARD_PROVIDER_TYPES.BOARD_TASK_MOVED_SOCKET,
        payload: { lists: data.changedColumns, tasks: data.changedTasks },
      });

      break;
    }

    case SOCKET_EVENT_TYPES.BOARD_TASK_DELETED: {
      if (listId === data.changedColumns[0].id) {
        dispatch({
          type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
          payload: {
            listId,
            tasks: _orderBy(data.changedTasks, ['orderNumber'], ['asc']),
          },
        });
      }

      break;
    }

    default: {
      console.log(data.state, 'not handled for now');

      break;
    }
  }
};
