import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { BOARD_COLORS } from 'constants/board/colors';

import formConfig from './SettingsModal.formConfig';
import { getValidationSchema } from './SettingsModal.formConfig';

import { Icon } from 'components/shared/Icon';
import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';

import { InputField } from 'components/FormikFields';
import { ColorRadioField } from 'components/FormikFields';

import styles from './SettingsModal.styles.scss';

const SettingsModal = ({
  isOpen,
  user,
  admin,
  board,
  types,
  onClose,
  onTitleInputBlur,
  onColorClick,
  onTypesSettings,
  onDeleteBoardClick,
}) => {
  const { t, i18n } = useTranslation('boardSettingsModal');
  const { t: t_validation } = useTranslation('validation');

  return (
    <Modal
      className={styles.modal}
      withClose
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
    >
      <Formik
        {...formConfig}
        validationSchema={getValidationSchema(t_validation)}
        initialValues={board}
        enableReinitialize
        validateOnMount
      >
        {({ values }) => (
          <Form className={styles.form}>
            <Typography
              className={styles.title}
              variant="h2"
              weight={i18n.language === 'ru' ? 'regular' : 'medium'}
            >
              {t('title')}
            </Typography>

            {admin && admin.id === user.id ? (
              <>
                <Field
                  label={t('titleLabel')}
                  name="name"
                  component={InputField}
                  onBlur={() => onTitleInputBlur(values.name)}
                />

                <div className={styles.colorsInputWrapper}>
                  <Field
                    label={t('colorLabel')}
                    name="color"
                    variant="circle"
                    colors={BOARD_COLORS}
                    component={ColorRadioField}
                    onClick={onColorClick}
                  />
                </div>
              </>
            ) : (
              <div className={styles.boardInfoWrapper}>
                <div
                  className={styles.boardColorWrapper}
                  style={{ borderColor: board.color }}
                >
                  <div
                    className={styles.boardColor}
                    style={{ backgroundColor: board.color }}
                  />
                </div>
                <div className={styles.boardInfoInner}>
                  <Typography variant="body" weight="medium">
                    {board.name}
                  </Typography>
                </div>
              </div>
            )}

            <div className={styles.typeWrapper}>
              <div className={styles.typeInner}>
                <Typography variant="body" weight="medium">
                  {t('typeLabel')}
                </Typography>
                <div>
                  <Typography
                    className={styles.typeSettingLabel}
                    variant="body"
                    onClick={onTypesSettings}
                  >
                    {t('typeSettingLabel')}
                  </Typography>
                </div>
              </div>

              {types.length ? (
                <div className={styles.types}>
                  {types.map((type) => (
                    <div
                      key={type.id}
                      className={styles.type}
                      style={{ backgroundColor: type.color }}
                    >
                      <Typography className={styles.typeName} variant="body">
                        {type.name}
                      </Typography>
                    </div>
                  ))}
                </div>
              ) : (
                <Typography className={styles.typeSubtitle} variant="body">
                  {t('typeSubtitle')}
                </Typography>
              )}
            </div>

            {admin && admin.id === user.id && (
              <div className={styles.control} onClick={onDeleteBoardClick}>
                <Icon className={styles.icon} name="bucket" />
                <Typography className={styles.deleteLabel} variant="body">
                  {t('deleteLabel')}
                </Typography>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default React.memo(SettingsModal);
