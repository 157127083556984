import React from 'react';
import cn from 'classnames';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { getPAGE_CONSTANTS } from '../../SettingsModal.constants';

import { Icon } from 'components/shared/Icon';
import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';

import { ModalControls } from 'components/ModalControls';
import { InputField } from 'components/FormikFields';

import formConfig from './ChangePasswordModal.formConfig';

import styles from './ChangePasswordModal.styles.scss';

const ChangePasswordModal = ({
  isOpen,
  showPassword,
  showConfirmPassword,
  validationSchema,
  user,
  passwordInfo,
  onClose,
  onEyeClick,
  onConfirmClick,
  onAfterPasswordChange,
}) => {
  const { t, i18n } = useTranslation(['password', 'changePasswordModal']);

  const passwordStatusStyle = {
    [styles.weak]: passwordInfo === getPAGE_CONSTANTS(t).WEAK_PASSWORD,
    [styles.normal]: passwordInfo === getPAGE_CONSTANTS(t).NORMAL_PASSWORD,
    [styles.strong]: passwordInfo === getPAGE_CONSTANTS(t).STRONG_PASSWORD,
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <Typography
        className={styles.title}
        variant="h2"
        weight={i18n.language === 'ru' ? 'regular' : 'medium'}
      >
        {t('changePasswordModal:title')}
      </Typography>
      <div className={styles.inner}>
        <Formik
          {...formConfig}
          validationSchema={validationSchema}
          validateOnMount
          enableReinitialize
          onSubmit={onConfirmClick}
        >
          {({ errors }) => (
            <Form>
              <Field
                inputClassName={cn(
                  { [styles.formControl]: !errors.password },
                  passwordStatusStyle,
                )}
                name="password"
                label={t('changePasswordModal:passwordLabel')}
                placeholder={t('changePasswordModal:passwordPlaceholder')}
                type={showPassword ? 'text' : 'password'}
                component={InputField}
                onAfterChange={onAfterPasswordChange}
                endAdornment={
                  <Icon
                    className={styles.showPasswordIcon}
                    name={showPassword ? 'showEye' : 'hideEye'}
                    onClick={() => {
                      onEyeClick('password');
                    }}
                  />
                }
              />
              <Typography
                className={cn(styles.passwordInfo, passwordStatusStyle)}
                variant="subtitle"
              >
                {!errors.password && passwordInfo}
              </Typography>
              <Field
                classNames={{
                  formControl: styles.formControl,
                }}
                name="confirmPassword"
                label={t('changePasswordModal:confirmPasswordLabel')}
                placeholder={t(
                  'changePasswordModal:confirmPasswordPlaceholder',
                )}
                type={showConfirmPassword ? 'text' : 'password'}
                component={InputField}
                endAdornment={
                  <Icon
                    className={styles.showPasswordIcon}
                    name={showConfirmPassword ? 'showEye' : 'hideEye'}
                    onClick={() => {
                      onEyeClick('confirmPassword');
                    }}
                  />
                }
              />
              <ModalControls
                className={styles.controls}
                confirmButtonClassName={styles.confirmButton}
                submitType="submit"
                submitTitle={t('changePasswordModal:confirmButton')}
                onCancelClick={onClose}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default React.memo(ChangePasswordModal);
