import React, { useState } from 'react';

import ColorRadio from './ColorRadio';

const ColorRadioContainer = ({ ...restProps }) => {
  const [hover, setHover] = useState(false);

  const onMouseOver = () => {
    setHover(true);
  };

  const onMouseOut = () => {
    setHover(false);
  };

  return (
    <ColorRadio
      {...restProps}
      hover={hover}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  );
};

export default React.memo(ColorRadioContainer);
