import React from 'react';
import { components } from 'react-select';

import { Typography } from 'components/shared/Typography';

import styles from './MenuList.styles.scss';

const MenuList = ({ children, ...props }) => (
  <components.MenuList {...props} className={styles.menuList}>
    <Typography component="div">{children}</Typography>
  </components.MenuList>
);

export default React.memo(MenuList);
