import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { AVATAR_COLORS } from 'constants/common';

import formConfig from './OnboardingModal.formConfig';
import { getValidationSchema } from './OnboardingModal.formConfig';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { EnterNameSection } from 'components/EnterNameSection';

import styles from './OnboardingModal.styles.scss';

const OnboardingModal = ({ loading, isOpen, onGetStartedClick }) => {
  const { t } = useTranslation(['validation', 'onboardingModal']);

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <Formik
        {...formConfig}
        validationSchema={getValidationSchema(t)}
        enableReinitialize
        validateOnMount
        onSubmit={onGetStartedClick}
      >
        {({ values }) => (
          <Form className={styles.form}>
            <div className={styles.inner}>
              <Typography className={styles.title}>
                {t('onboardingModal:title')}
              </Typography>
              <Typography className={styles.subtitle} variant="main">
                {t('onboardingModal:subtitle')}
              </Typography>
              <EnterNameSection
                colors={AVATAR_COLORS.filter((clr) => clr.value !== 'grey')}
                size={44}
              />
            </div>
            <Button className={styles.button} loading={loading} type="submit">
              {t('onboardingModal:startButton')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(OnboardingModal);
