import React from 'react';
import Slider from 'react-slick';

import { Formik, Form } from 'formik';

import formConfig from './RegisterPage.formConfig';
import { sliderSettings } from './RegisterPage.config';

import { CodeStep } from './components/CodeStep';
import { EmailStep } from './components/EmailStep';
import { PasswordStep } from './components/PasswordStep';

import styles from './RegisterPage.styles.scss';

const RegisterPage = ({
  loading,
  sliderRef,
  passwordConfig,
  validationSchema,
  onSubmit,
}) => (
  <div className={styles.page}>
    <Formik
      {...formConfig}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ errors }) => (
        <Form className={styles.form}>
          <Slider {...sliderSettings} ref={sliderRef}>
            <EmailStep sliderRef={sliderRef} />
            <PasswordStep
              loading={loading}
              error={errors.password}
              passwordConfig={passwordConfig}
            />
            <CodeStep errors={errors} />
          </Slider>
        </Form>
      )}
    </Formik>
  </div>
);

export default React.memo(RegisterPage);
