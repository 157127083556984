import React from 'react';

import { getBottomData, getTopData } from '../../TimelinePage.helpers';

import { Icon } from 'components/shared/Icon';

import TimelineInner from './components/TimelineInner/TimelineInner';

import styles from './Timeline.styles.scss';

const Timeline = ({
  lineRef,
  timelineRef,
  lineContainerRef,
  hovering,
  dragging,
  language,
  selectedTimePeriod,
  tasks,
  onSortEnd,
  onSortStart,
  onSyncScroll,
  onLeftScroll,
  onLeftScrollClick,
  onRightScrollClick,
  onTaskAndLineHover,
  onClearRightInterval,
  onClearLeftInterval,
  onLeftScrollInterval,
  onRightScrollInterval,
}) => (
  <div className={styles.timeline}>
    <div
      className={styles.leftGradient}
      onClick={onLeftScrollClick}
      onMouseDown={onLeftScrollInterval}
      onMouseUp={onClearLeftInterval}
    >
      <Icon className={styles.arrowLeft} name="arrowLeft" />
    </div>
    <div
      className={styles.rightGradient}
      onClick={onRightScrollClick}
      onMouseDown={onRightScrollInterval}
      onMouseUp={onClearRightInterval}
    >
      <Icon className={styles.arrowRight} name="arrowRight" />
    </div>

    <TimelineInner
      lineRef={lineRef}
      timelineRef={timelineRef}
      lineContainerRef={lineContainerRef}
      hovering={hovering}
      dragging={dragging}
      language={language}
      period={selectedTimePeriod}
      tasks={tasks}
      topArray={getTopData({ selectedTimePeriod, language })}
      bottomArray={getBottomData({ selectedTimePeriod, language })}
      onSyncScroll={onSyncScroll}
      onTaskAndLineHover={onTaskAndLineHover}
    />
  </div>
);

export default React.memo(Timeline);
