export const BOARD_PROVIDER_TYPES = {
  //A
  //B
  BOARD_LIST_MOVED: 'BOARD_LIST_MOVED',
  BOARD_LIST_CREATED: 'BOARD_LIST_CREATED',
  BOARD_LIST_DELETED: 'BOARD_LIST_DELETED',
  BOARD_LIST_UPDATED: 'BOARD_LIST_UPDATED',
  BOARD_TASKS_MOVE: 'BOARD_TASKS_MOVE',
  BOARD_TASKS_UPDATE: 'BOARD_TASKS_UPDATE',
  BOARD_TASK_CREATED: 'BOARD_TASK_CREATED',
  BOARD_TASK_UPDATE: 'BOARD_TASK_UPDATE',
  BOARD_TASK_DELETE: 'BOARD_TASK_DELETE',
  BOARD_TASK_MOVED_SOCKET: 'BOARD_TASK_MOVED_SOCKET',
  BOARD_TYPE_UPDATE: 'BOARD_TYPE_UPDATE',
  BOARD_TYPE_REMOVED: 'BOARD_TYPE_REMOVED',
  BOARD_TASKS_MOVED: 'BOARD_TASKS_MOVED',
  BOARD_TASKS_UPDATE_TAG: 'BOARD_TASKS_UPDATE_TAG',
  //C
  //D
  //E
  //F
  //G
  //H
  //I
  //J
  //K
  //L
  //M
  //N
  //O
  //P
  //Q
  //R
  //S
  SET_BOARD_DATA: 'SET_BOARD_DATA',
  SET_BOARD_LISTS: 'SET_BOARD_LISTS',
  SET_BOARD_LISTS_SOCKET: 'SET_BOARD_LISTS_SOCKET',
  SET_BOARD_TASKS: 'SET_BOARD_TASKS',
  SET_BOARD_TASK: 'SET_BOARD_TASK',
  SET_INVITED_MEMBER: 'SET_INVITED_MEMBER',
  //T
  TYPE_CREATED: 'TYPE_CREATED',
  TASKS_TYPE_EDITED: 'TASKS_TYPE_EDITED',
  //U
  UPDATE_BOARD_DATA: 'UPDATE_BOARD_DATA',
  //V
  //W
  //X
  //Y
  //Z
};
