import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Day.styles.scss';

const Day = ({ day, modifiers }) => {
  const dayText = day.getDate();

  return (
    <Typography
      weight="regular"
      variant="subtitle"
      className={cn({ ...modifiers }, styles.day)}
    >
      {dayText}
    </Typography>
  );
};

export default React.memo(Day);
