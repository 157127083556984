export const DAY_WIDTH = 30;
export const TASK_WIDTH = 36;
export const WEEK_WIDTH = 85;
export const MONTH_WIDTH = 70;

export const TIME_PERIOD = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const BLOB_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
