import { TYPES_COLORS } from 'constants/taskTypes/colors';

export const convertDataToApi = (values) => ({
  color: values.color,
  name: values.name.trim(),
});

export const checkUniqType = ({ taskTypes, typeId, value }) =>
  !taskTypes
    .filter((type) => type.id !== typeId)
    .some((type) => type.name === value);

export const getColors = (taskTypes) =>
  TYPES_COLORS.filter(
    ({ color }) => !taskTypes.some((type) => type.color === color),
  );

export const getUsedColors = (taskTypes) =>
  TYPES_COLORS.filter(({ color }) =>
    taskTypes.some((type) => type.color === color),
  );
