import React from 'react';

import Footer from './Footer';

const FooterContainer = ({
  modulesRef,
  welcomeRef,
  applicationRef,
  functionsRef,
  ...restProps
}) => {
  return (
    <Footer
      modulesRef={modulesRef}
      welcomeRef={welcomeRef}
      functionsRef={functionsRef}
      applicationRef={applicationRef}
      {...restProps}
    />
  );
};

export default React.memo(FooterContainer);
