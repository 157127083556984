export const getPAGE_CONSTANTS = (t) => {
  return {
    WEAK_PASSWORD: t('weakPassword'),
    NORMAL_PASSWORD: t('normalPassword'),
    STRONG_PASSWORD: t('strongPassword'),
  };
};

export const TABS = [
  {
    translationKey: 'settingsPage:sideMenuAccount',
    icon: 'person',
    text: 'Account',
  },
  // { translationKey: 'tabNotifications', icon: 'bell', text: 'Notifications' },
];
export const LANGUAGE_OPTIONS = [
  {
    translationKey: 'settingsPage:optionRussian',
    label: 'Russian',
    value: 'ru',
  },
  {
    translationKey: 'settingsPage:optionEnglish',
    label: 'English',
    value: 'en',
  },
];
