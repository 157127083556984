import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { Header } from './components/Header';
import { Footer } from './components/Footer';

import styles from './LandingLayout.styles.scss';

const LandingLayout = ({
  children,
  modulesRef,
  welcomeRef,
  applicationRef,
  functionsRef,
  currentLanguage,
  onLanguageChange,
  onPolicyClick,
  onTryForFreeClick,
  onSignInClick,
}) => (
  <Fragment>
    <Helmet>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no"
      />
      <title>X-CORE</title>
      <meta property="og:title" content="X-CORE" />
    </Helmet>
    <div className={styles.layout}>
      <Header
        modulesRef={modulesRef}
        welcomeRef={welcomeRef}
        applicationRef={applicationRef}
        functionsRef={functionsRef}
        currentLanguage={currentLanguage}
        onLanguageChange={onLanguageChange}
        onTryForFreeClick={onTryForFreeClick}
        onSignInClick={onSignInClick}
      />
      {children}
      <Footer
        modulesRef={modulesRef}
        welcomeRef={welcomeRef}
        applicationRef={applicationRef}
        functionsRef={functionsRef}
        currentLanguage={currentLanguage}
        onLanguageChange={onLanguageChange}
        onPolicyClick={onPolicyClick}
        onTryForFreeClick={onTryForFreeClick}
      />
    </div>
  </Fragment>
);
export default React.memo(LandingLayout);
