export const getUserRole = (role, t) => {
  switch (role) {
    case 'ADMIN': {
      return t('adminLabel');
    }

    case 'USER': {
      return t('userLabel');
    }

    default: {
      return t('userLabel');
    }
  }
};
