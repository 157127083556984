import React, { useState, useCallback } from 'react';
import { showToastMessage } from 'helpers/showToastMessage';
import { checkSpaceContainValue } from 'helpers/checkSpaceContainValue';
import CreatingInput from './CreatingInput';

const CreatingInputContainer = ({
  withSubmit = true,
  errorMessage,
  checkValueUniq,
  onItemCreate,
  onItemCreated,
  onItemError,
  onInputBlur,
  ...restProps
}) => {
  const [value, setValue] = useState('');
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createItem = useCallback(() => {
    if (!checkSpaceContainValue(value)) {
      setValue('');
      return;
    }

    setLoading(true);

    onItemCreate(value.trim())
      .then((item) => {
        if (onItemCreated) {
          onItemCreated(item);
        }
        setValue('');
        setLoading(false);
        setCreating(true);
      })
      .catch(({ status }) => {
        if (onItemError) {
          onItemError();
        }
        if (status) {
          showToastMessage({ variant: 'error', status });
        }
        setLoading(false);
        setCreating(false);
      });
  }, [value, onItemCreate, onItemCreated, onItemError]);

  const handleChange = useCallback(
    (event) => {
      const nextValue = event.target.value;
      setValue(nextValue);

      if (checkValueUniq && checkValueUniq(nextValue)) {
        setError(errorMessage);
      } else {
        setError(null);
      }
    },
    [checkValueUniq, errorMessage],
  );

  const handleBlur = useCallback(
    (event) => {
      if (!withSubmit && !error) {
        createItem();
      } else {
        setValue('');
      }

      if (onInputBlur) {
        onInputBlur(event);
      }

      setCreating(false);
    },
    [withSubmit, error, createItem, onInputBlur],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (!error && event.key === 'Enter' && value.length) {
        createItem();
      }
    },
    [error, value, createItem],
  );

  const handleConfirmClick = useCallback(() => {
    if (value.length && !error) {
      createItem();
    }
  }, [value, error, createItem]);

  const handleSetCreatingClick = useCallback(() => {
    setError(null);
    setCreating(true);
  }, []);

  return (
    <CreatingInput
      {...restProps}
      error={error}
      value={value}
      creating={creating}
      loading={loading}
      withSubmit={withSubmit}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onConfirmClick={handleConfirmClick}
      onSetCreatingClick={handleSetCreatingClick}
    />
  );
};

export default React.memo(CreatingInputContainer);
