import React from 'react';
import cn from 'classnames';

import styles from './Input.styles.scss';

const VARIANTS = {
  default: styles.default,
  error: styles.error,
};

const Input = ({
  className,
  error,
  endAdornment,
  inputRef = () => {},
  inputClassName,
  multiline,
  readOnly,
  onChange,
  onAfterChange,
  startAdornment,
  variant = 'default',
  wrapperClassName,
  ...restProps
}) => {
  const Component = multiline ? 'textarea' : 'input';

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }

    if (onAfterChange) {
      onAfterChange(event);
    }
  };

  return (
    <div className={cn(styles.inputWrapper, className)}>
      {startAdornment && (
        <div className={cn(styles.startAdornment)}>{startAdornment}</div>
      )}
      <Component
        {...restProps}
        readOnly={readOnly}
        ref={inputRef}
        className={cn(
          styles.input,
          { [styles.inputReadOnly]: readOnly },
          VARIANTS[variant],
          inputClassName,
        )}
        onChange={handleChange}
      />
      {endAdornment && (
        <div className={cn(styles.endAdornment)}>{endAdornment}</div>
      )}
    </div>
  );
};

export default React.memo(Input);
