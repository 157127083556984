import formatDistance from 'date-fns/formatDistance';
import { enUS, ru } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';

import { HISTORY_TYPE } from './TaskInfo.constants';

export const getTimeDistance = (history, language, t) => {
  const locale = language === 'en' ? { locale: enUS } : { locale: ru };

  switch (history.historyType) {
    case HISTORY_TYPE.CREATED: {
      return `${t('createdLabel')} ${formatDistance(
        parseISO(history.lastHistoryDate),
        new Date(),
        locale,
      )} ${t('agoLabel')}`;
    }

    case HISTORY_TYPE.MOVED: {
      return `${t('movedLabel')} ${formatDistance(
        parseISO(history.lastHistoryDate),
        new Date(),
        locale,
      )} ${t('agoLabel')}`;
    }

    default: {
      console.log();
    }
  }
};
