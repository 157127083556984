import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { TOKEN_KEY } from 'constants/common';

import { getUserInfo } from 'store/auth/actions';
import { getSystemMeta } from 'store/system/actions';

import SessionProvider from './SessionProvider';

const SessionProviderContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const inviteLink = window.location.pathname;

  const { i18n } = useTranslation();

  const token = localStorage.getItem(TOKEN_KEY);

  const [loading, setLoading] = useState(!!token);

  useEffect(() => {
    if (i18n.language === 'en') {
      document.body.classList.remove('ru');
      document.body.classList.add(i18n.language);
    } else {
      document.body.classList.remove('en');
      document.body.classList.add(i18n.language);
    }

    return () => {};
  }, [i18n.language]);

  const successCallback = () => {
    setLoading(false);

    if (
      location.pathname === ROUTES.LOGIN ||
      location.pathname === ROUTES.ROOT ||
      location.pathname === ROUTES.REGISTER ||
      location.pathname === ROUTES.RESET_PASSWORD
    ) {
      history.replace(ROUTES.BOARDS);
    }
  };

  const errorCallback = () => {
    setLoading(false);

    history.replace('/');
  };

  useEffect(() => {
    dispatch(getSystemMeta()).then(() => {
      if (token) {
        dispatch(getUserInfo())
          .then(({ value: user }) => {
            i18n.changeLanguage(user.language);

            successCallback();
          })
          .catch((err) => {
            console.log(err);
            errorCallback();
          });
      } else {
        if (inviteLink.startsWith('/invite')) {
          localStorage.setItem('inviteLink', inviteLink);
        }
      }
    });
  }, []);

  return <SessionProvider {...props} loading={loading} />;
};

export default React.memo(SessionProviderContainer);
