import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'react-tippy';

import { Typography } from 'components/shared/Typography';
import { Input } from 'components/shared/Input';
import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';

import styles from './TagsPopover.styles.scss';

const TagsPopover = ({
  children,
  open,
  loading,
  tags,
  onRequestClose,
  onNameBlur,
  onNameFocus,
  onNameChange,
  onTagClick,
}) => {
  const { t, i18n } = useTranslation('tagsPopover');

  return (
    <Tooltip
      trigger="click"
      interactive
      open={open}
      onRequestClose={onRequestClose}
      position="bottom"
      html={
        <div className={styles.popover}>
          <Icon
            className={styles.closeIcon}
            name="close"
            onClick={onRequestClose}
          />
          <Typography
            variant="h2"
            weight={i18n.language === 'ru' ? 'regular' : 'medium'}
            className={styles.title}
          >
            {t('title')}
          </Typography>
          <div className={styles.inner}>
            {loading ? (
              <Loading size={17} className={styles.loading} />
            ) : (
              tags.map((tag) => (
                <div key={tag.key} className={styles.tag}>
                  <div
                    className={styles.tagColor}
                    style={{ backgroundColor: tag.color }}
                    onMouseDown={(event) => onTagClick(event, tag)}
                  >
                    {tag.active && (
                      <Icon name="check" className={styles.icon} />
                    )}
                  </div>
                  <Input
                    placeholder={t('placeholder')}
                    className={styles.inputWrapper}
                    inputClassName={styles.input}
                    value={tag.name || ''}
                    onChange={(event) => onNameChange(event, tag)}
                    onFocus={onNameFocus}
                    onBlur={(event) => onNameBlur(event, tag)}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      }
    >
      <span className={styles.content}>{children}</span>
    </Tooltip>
  );
};

export default React.memo(TagsPopover);
