import React from 'react';
import cn from 'classnames';
import { components } from 'react-select';

import { Typography } from 'components/shared/Typography';

import styles from './Placeholder.styles.scss';

const Placeholder = ({ placeholder, variant, ...props }) => (
  <components.Placeholder {...props} className={styles.placeholder}>
    <Typography
      className={cn(styles.text, { [styles.error]: variant === 'error' })}
      variant="subtitle"
    >
      {placeholder}
    </Typography>
  </components.Placeholder>
);

export default React.memo(Placeholder);
