import { ERROR_TYPES } from 'constants/errorTypes';

export const setFormError = ({ status, setFieldError, t }) => {
  switch (status) {
    case ERROR_TYPES.USER_NOT_FOUND: {
      setFieldError('email', t('notFoundEmailError'));

      break;
    }

    case ERROR_TYPES.EMAIL_IS_NOT_VALID: {
      setFieldError('email', t('notValidEmailError'));

      break;
    }

    case ERROR_TYPES.ACCOUNT_DELETED: {
      setFieldError('email', t('deletedAccountError'));

      break;
    }

    default: {
      setFieldError('email', t('notValidEmailError'));
    }
  }
};
