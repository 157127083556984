import React from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { showToastMessage } from 'helpers/showToastMessage';

import { useFetch } from 'hooks/useFetch';

import ShareModal from './ShareModal';

const ShareModalContainer = ({ id, isOpen, ...restProps }) => {
  const { t } = useTranslation('toast');

  const { data, loading } = useFetch(
    {
      defaultData: {},
      fetcher: api.inviteUser,
      immediately: true,
      stopRequest: !isOpen,
    },
    id,
    [isOpen],
  );

  const handleShowToastClick = () => {
    link &&
      showToastMessage({
        title: t('copyLink'),
        variant: 'success',
      });
  };

  const link = !loading ? data.link : '';

  return (
    <ShareModal
      isOpen={isOpen}
      loading={loading}
      link={link}
      onShowToastClick={handleShowToastClick}
      {...restProps}
    />
  );
};

export default React.memo(ShareModalContainer);
