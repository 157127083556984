import { ERROR_TYPES } from 'constants/errorTypes';

export const setFormError = ({ status, setFieldError, t }) => {
  setFieldError('email', true);
  setFieldError('password', true);

  switch (status) {
    case ERROR_TYPES.EMAIL_IS_NOT_VALID:
    case ERROR_TYPES.PASSWORD_IS_INVALID: {
      setFieldError('form', t('incorrectValueError'));

      break;
    }
    case ERROR_TYPES.USER_DOES_NOT_EXIST: {
      setFieldError('form', t('notFoundEmailError'));

      break;
    }
    case ERROR_TYPES.ACCOUNT_DOES_NOT_VERIFIED: {
      setFieldError('form', t('notVerifiedAccountError'));

      break;
    }
    case ERROR_TYPES.ACCOUNT_DELETED: {
      setFieldError('form', t('deletedAccountError'));

      break;
    }

    default: {
      console.log(t('defaultError'));
    }
  }
};
