import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from 'constants/routes';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import { MainLayout } from 'components/Layout';

const PrivateRoute = ({ component: Component, exact, ...restProps }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return (
    <Route
      {...restProps}
      exact={exact}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        }

        return (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        );
      }}
    />
  );
};

export default React.memo(PrivateRoute);
