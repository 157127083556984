import * as Yup from 'yup';

import { emailValidation } from 'validation/emailValidation';

export const getValidationSchema = (emailPattern, t) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t('requiredEmailError'))
      .test('isEmail', t('invalidEmailError'), (value) =>
        emailValidation(value, emailPattern),
      ),
    password: Yup.string().required(t('requiredPasswordError')),
  });

const initialValues = {
  email: '',
  password: '',
};

export default {
  initialValues,
};
