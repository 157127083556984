import { TASK_TAG_COLORS } from 'constants/common';

export const formatTags = ({ currentTags, boardTags }) =>
  TASK_TAG_COLORS.map((taskTag) => {
    const boardTag = boardTags.find((tag) => tag.color === taskTag.key);
    const currentTag = currentTags
      ? currentTags.find((tag) => tag.color === taskTag.key)
      : null;

    if (!boardTag) {
      return {
        ...taskTag,
        active: !!currentTag,
      };
    }

    return {
      ...taskTag,
      active: !!currentTag,
      id: boardTag.id,
      name: boardTag.name,
    };
  });
