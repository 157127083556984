import React from 'react';
import MaskedInput from 'react-text-mask';
import createDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './TimeInput.styles.scss';

const timeMask = [/\d/, /\d/, ':', /\d/, /\d/];
const timePipe = createDatePipe('HH:MM');

const VARIANTS = {
  default: styles.default,
  error: styles.error,
};

const getConvertedValue = (e) => {
  const timeValue = e.target.value
    ? e.target.value.replace(/_/g, '0')
    : '00:00';
  const [hh, mm] = timeValue.split(':');
  const minutes = +hh * 60 + +mm;

  return { timeValue, minutes };
};

const valueToTimeValue = (value) => {
  if (!value) {
    return '';
  }

  if (typeof value === 'string') {
    return value;
  }

  // return minutesToTime(value, true);
  return value;
};

// value param can be string in time format or minutes with number type

const TimeInput = ({
  inputKey,
  value,
  onChange,
  onBlur,
  onFocus,
  variant,
  className,
  readOnly,
  lastSuccessTimestamp,
  isSlider,
  endAdornment,
  ...restProps
}) =>
  readOnly ? (
    <div className={styles.time}>
      <Typography variant="subtitle" component="div">
        {value ? valueToTimeValue(value) : '00:00'}
      </Typography>
    </div>
  ) : (
    <MaskedInput
      placeholder="00:00"
      mask={timeMask}
      pipe={timePipe}
      render={(ref, props) => (
        <span className={styles.root}>
          <input
            ref={ref}
            {...(inputKey && { key: inputKey })}
            className={cn(styles.input, VARIANTS[variant], className)}
            {...props}
          />
          {endAdornment}
        </span>
      )}
      onBlur={(e) => {
        const timeValue = e.target.value;

        e.target.value =
          timeValue === '1_:__'
            ? '01:00'
            : timeValue === '2_:__'
            ? '02:00'
            : timeValue.replace(/_/g, '0');

        const convertedValue = getConvertedValue(e);

        onChange && onChange(e, convertedValue);
        onBlur && onBlur(e, convertedValue);
      }}
      onFocus={(e) => {
        e.target.select();
        onFocus && onFocus(e, getConvertedValue(e));
      }}
      onChange={(e) => onChange && onChange(e, getConvertedValue(e))}
      defaultValue={valueToTimeValue(value)}
      {...restProps}
    />
  );

export default React.memo(TimeInput);
