import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'components/Layout';

import Diagram from 'assets/img/diagram.svg';
import TimeLine from 'assets/img/time-line.svg';

import { AnalyticsCard } from './components/AnalyticsCard';

import styles from './AnalyticsPage.styles.scss';

const AnalyticsPage = ({ boardId, onTimelineClick, onBackButtonClick }) => {
  const { t } = useTranslation('analyticsPage');

  return (
    <PageLayout
      title={t('pageTitle')}
      backButton
      onBackButtonClick={onBackButtonClick}
    >
      <div className={styles.container}>
        <AnalyticsCard name="Timeline" onCardClick={onTimelineClick}>
          <TimeLine className={styles.cover} />
        </AnalyticsCard>
        <AnalyticsCard name={t('diagram')} onCardClick={() => {}}>
          <Diagram className={styles.cover} />
        </AnalyticsCard>
      </div>
    </PageLayout>
  );
};
export default React.memo(AnalyticsPage);
