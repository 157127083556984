export const calculateProgress = (items) => {
  if (!items.length) {
    return 0;
  }

  return (
    (items.filter((item) => item.completeItem.completed).length * 100) /
    items.length
  ).toFixed(0);
};
