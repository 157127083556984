import React from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/auth/selectors';

import Timeline from './Timeline';

const TimelineContainer = ({
  timelineRef,
  onChangeTaskPosition,
  ...restProps
}) => {
  let rightScrollInterval;
  let leftScrollInterval;

  const { language } = useSelector(userSelector);

  const handleLeftScrollInterval = () => {
    leftScrollInterval = setInterval(() => {
      timelineRef.current.scrollLeft = timelineRef.current.scrollLeft - 30;
    }, 50);
  };

  const handleRightScrollInterval = () => {
    rightScrollInterval = setInterval(() => {
      timelineRef.current.scrollLeft = timelineRef.current.scrollLeft + 30;
    }, 50);
  };

  const handleRightScrollClick = () => {
    timelineRef.current.scrollLeft = timelineRef.current.scrollLeft + 30;
  };

  const handleLeftScrollClick = () => {
    timelineRef.current.scrollLeft = timelineRef.current.scrollLeft - 30;
  };

  const handleClearRightInterval = () => {
    clearInterval(rightScrollInterval);
  };

  const handleClearLeftInterval = () => {
    clearInterval(leftScrollInterval);
  };

  return (
    <Timeline
      {...restProps}
      timelineRef={timelineRef}
      language={language}
      onLeftScrollClick={handleLeftScrollClick}
      onLeftScrollInterval={handleLeftScrollInterval}
      onRightScrollClick={handleRightScrollClick}
      onClearRightInterval={handleClearRightInterval}
      onRightScrollInterval={handleRightScrollInterval}
      onClearLeftInterval={handleClearLeftInterval}
    />
  );
};

export default React.memo(TimelineContainer);
