import { ERROR_TYPES } from 'constants/errorTypes';

export const convertDataToApi = (values) => ({
  email: values.email,
  password: values.password,
  confirmPassword: values.confirmPassword,
});

export const setFormError = ({ status, setFieldError, t }) => {
  switch (status) {
    case ERROR_TYPES.EMAIL_IS_NOT_VALID:
    case ERROR_TYPES.PASSWORD_IS_INVALID: {
      setFieldError('password', t('incorrectValueError'));

      break;
    }
    case ERROR_TYPES.CONFIRMATION_CODE_IS_EMPTY: {
      setFieldError('password', t('emptyCodeError'));

      break;
    }
    case ERROR_TYPES.PASSWORDS_DO_NOT_MATCH: {
      setFieldError('password', t('notMatchPasswordError'));

      break;
    }
    case ERROR_TYPES.CONFIRMATION_NOT_VALID: {
      setFieldError('password', t('notMatchPasswordError'));

      break;
    }
    case ERROR_TYPES.USER_NOT_FOUND: {
      setFieldError('password', t('notFoundEmailError'));

      break;
    }

    default: {
      console.log(t('unknownError'));
    }
  }
};
