import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import { Member } from 'components/Member';

import styles from './MembersModal.styles.scss';

const MembersModal = ({
  isAdmin,
  isOpen,
  members,
  admin,
  scrollHeight,
  onClose,
  onDeleteMemberClick,
}) => {
  const { t, i18n } = useTranslation('membersModal');

  return (
    <Modal className={styles.modal} withClose isOpen={isOpen} onClose={onClose}>
      <ScrollWrapper style={{ height: scrollHeight }}>
        <Typography
          className={styles.title}
          variant="h2"
          weight={i18n.language === 'ru' ? 'regular' : 'medium'}
        >
          {t('title')}
        </Typography>
        <Typography className={styles.subtitle} variant="body">
          {t('adminLabel')}
        </Typography>
        {admin && (
          <Member {...admin} onDeleteMemberClick={onDeleteMemberClick} />
        )}
        <Typography className={styles.subtitle} variant="body">
          {t('usersLabel')}
        </Typography>
        {members.length ? (
          <div className={styles.members}>
            {members.map((member) => (
              <Member
                canRemove={member.role !== 'ADMIN' && isAdmin}
                key={member.id}
                {...member}
                onDeleteMemberClick={onDeleteMemberClick}
              />
            ))}
          </div>
        ) : (
          <Typography className={styles.text} variant="body">
            {t('emptyuserstext')}
          </Typography>
        )}
      </ScrollWrapper>
    </Modal>
  );
};
export default React.memo(MembersModal);
