import React from 'react';
import cn from 'classnames';
import { getIn } from 'formik';

import { ColorRadio } from 'components/shared/ColorRadio';
import { FormControl } from 'components/shared/FormControl';

import styles from './ColorRadioField.styles.scss';

const ColorRadioField = ({
  classNames = {},
  size,
  colors,
  label,
  field,
  form,
  onAfterChange,
  onClick,
  colorsWrapperClassName,
  formControlClassName,
  ...restProps
}) => {
  const error = getIn(form.errors, field.name);
  const touched = form.touched[field.name];

  const handleChange = (event) => {
    const value = event.target.value;

    form.setFieldValue(field.name, value);

    if (onAfterChange) {
      onAfterChange(value);
    }
  };

  return (
    <FormControl
      className={cn(
        styles.control,
        classNames.formControl,
        formControlClassName,
      )}
      label={label}
      error={error}
      touched={touched}
    >
      <div className={cn(styles.colorsWrapper, colorsWrapperClassName)}>
        {colors.map((item) => (
          <ColorRadio
            key={item.color}
            {...field}
            {...restProps}
            iconWrapperClassName={classNames.iconWrapper}
            iconClassName={classNames.icon}
            checked={item.value === field.value}
            color={item.color}
            activeColor={item.activeColor}
            value={item.value}
            size={size}
            onChange={handleChange}
            onClick={() => onClick?.(item.color)}
          />
        ))}
      </div>
    </FormControl>
  );
};

export default React.memo(ColorRadioField);
