import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { useTimer } from 'hooks/useTimer';

import { AUTH_CODE_LENGTH } from 'constants/common';

import * as api from 'api/methods';

import CodeStep from './CodeStep';
import { setFormError } from './CodeStep.helpers';

const CodeStepContainer = ({ sliderRef, onSaveConfirmationCode }) => {
  const { t } = useTranslation('resetPasswordCodeStep');
  const { values, setFieldError, errors } = useFormikContext();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeSubmitted, setCodeSubmitted] = useState(false);
  const [errorCounter, setErrorCounter] = useState(0);
  const [resetTrigger, setResetTrigger] = useState(new Date());
  const [timerSecondsLeft, { startTimer }] = useTimer({ defaultSeconds: 0 });

  const handleCodeChange = (code) => {
    if (code.filter((number) => !!number).length === AUTH_CODE_LENGTH) {
      setLoading(true);

      api
        .confirmForgotPassword({ email: values.email }, code.join(''))
        .then(() => {
          setLoading(false);
          setError(false);
          setCodeSubmitted(true);
          onSaveConfirmationCode(code);

          setTimeout(() => {
            sliderRef.current.slickNext();
          }, 1000);
        })
        .catch(({ status }) => {
          setLoading(false);
          setError(true);

          setFormError({ status, setFieldError, t });

          if (errorCounter < 2) {
            setErrorCounter(errorCounter + 1);

            setTimeout(() => {
              setResetTrigger(new Date());
              setError(false);
            }, 2000);
          }

          if (errorCounter > 1) {
            setErrorCounter(errorCounter + 1);

            api
              .forgotPassword({ email: values.email })
              .then(() => {
                setTimeout(() => {
                  setResetTrigger(new Date());
                  setErrorCounter(0);
                  setError(false);
                }, 3000);
              })
              .catch(({ status: forgotPasswordStatus }) => {
                setLoading(false);
                setError(true);
                setFormError({
                  status: forgotPasswordStatus,
                  setFieldError,
                  t,
                });

                setTimeout(() => {
                  setResetTrigger(new Date());
                  setErrorCounter(0);
                  setError(false);
                }, 2000);
              });
          }
        });
    }
  };

  const handleSendCodeAgainClick = () => {
    if (timerSecondsLeft > 0) {
      return;
    }

    startTimer(30);

    api
      .forgotPassword({ email: values.email })
      .then(() => {
        setErrorCounter(0);
        setError(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        setError(true);
        setFormError({ status, setFieldError, t });
        setTimeout(() => {
          setResetTrigger(new Date());
          setErrorCounter(0);
          setError(false);
        }, 2000);
      });
  };

  return (
    <CodeStep
      loading={loading}
      error={error}
      errors={errors}
      values={values}
      errorCounter={errorCounter}
      codeSubmitted={codeSubmitted}
      resetTrigger={resetTrigger}
      timerSecondsLeft={timerSecondsLeft}
      onCodeChange={handleCodeChange}
      onSendCodeAgainClick={handleSendCodeAgainClick}
    />
  );
};

export default React.memo(CodeStepContainer);
