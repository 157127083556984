import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import styles from './CreatingInput.styles.scss';

const CreatingInput = ({
  error = '',
  value = '',
  creating = false,
  loading = false,
  withSubmit = false,
  fontWeight,
  className = '',
  creatingClassName = '',
  maxLength = 100,
  placeholder = '',
  title = '',
  onChange,
  onBlur,
  onKeyDown,
  onConfirmClick,
  onSetCreatingClick,
}) => {
  return (
    <div className={cn(styles.input, className)}>
      {creating ? (
        <>
          <div className={styles.inputWrapper}>
            {error && (
              <Typography className={styles.errorText} variant="subtitle">
                {error}
              </Typography>
            )}
            <input
              className={cn(styles.field, {
                [styles.fieldWithSubmit]: withSubmit,
                [styles.error]: error,
              })}
              placeholder={placeholder}
              autoFocus
              value={value}
              maxLength={maxLength}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
            />
            {loading && !withSubmit && (
              <Loading className={styles.loading} size={16} />
            )}
          </div>
          {withSubmit && (
            <div
              className={cn(styles.button, {
                [styles.buttonLoading]: loading,
                [styles.buttonDisabled]: error,
              })}
              onMouseDown={onConfirmClick}
            >
              {loading ? (
                <Loading size={16} color="var(--color-green)" />
              ) : (
                <Icon className={styles.checkIcon} name="check" />
              )}
            </div>
          )}
        </>
      ) : (
        <div className={styles.addButton} onClick={onSetCreatingClick}>
          <Icon className={styles.addIcon} name="add" />
          <Typography
            className={cn(styles.creatingTitle, creatingClassName)}
            variant="subtitle"
            weight={fontWeight}
          >
            {title}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default React.memo(CreatingInput);
