import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared/Modal';

import { CodeStepInner } from '../CodeStepInner';

const CodeStep = ({ isOpen, codeSubmitted, onClose, ...restProps }) => {
  const { t } = useTranslation('codeStepInner');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CodeStepInner
        {...restProps}
        withCancel
        title={t('title')}
        codeSubmitted={codeSubmitted}
        onClose={onClose}
      />
    </Modal>
  );
};

export default React.memo(CodeStep);
