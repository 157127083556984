import { SPACE_REGEX, SYMBOLS_REGEX } from 'constants/passwordRegex';

import { getPASSWORD_CONSTANTS } from 'constants/passwordConstants';

import { getRegex } from 'helpers/getRegex';

export const checkPasswordValue = ({ value, config, t }) => {
  const LOWER_CASE = getRegex(config.lowerCase);
  const UPPER_CASE = getRegex(config.upperCase);
  const NUMBER = getRegex(config.number);

  if (
    value.length > Number(config.minLength) &&
    LOWER_CASE.test(value) &&
    UPPER_CASE.test(value) &&
    NUMBER.test(value) &&
    SYMBOLS_REGEX.test(value) &&
    !SPACE_REGEX.test(value)
  ) {
    return getPASSWORD_CONSTANTS(t).STRONG_PASSWORD;
  }

  if (
    value.length === Number(config.minLength) &&
    LOWER_CASE.test(value) &&
    UPPER_CASE.test(value) &&
    NUMBER.test(value) &&
    SYMBOLS_REGEX.test(value) &&
    !SPACE_REGEX.test(value)
  ) {
    return getPASSWORD_CONSTANTS(t).NORMAL_PASSWORD;
  }

  return getPASSWORD_CONSTANTS(t).WEAK_PASSWORD;
};
