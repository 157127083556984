import React from 'react';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';
import { useTranslation } from 'react-i18next';

import BackgroundIcon from 'assets/img/simple-board.svg';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Template.styles.scss';

const Template = ({ activeTemplate, templates, onTemplateClick }) => {
  const { t } = useTranslation('boardPage');

  return (
    <div className={styles.container}>
      <Typography variant="body" weight="medium">
        {t('titleTemplate')}
      </Typography>
      <div className={styles.wrapper}>
        {templates.map((template) => (
          <Tooltip
            key={template.type}
            trigger="mouseenter"
            position="top"
            html={
              <div className={styles.tippy}>
                <Typography className={styles.tippyText} variant="subtitle">
                  {template.description}
                </Typography>
              </div>
            }
          >
            <div
              className={cn(styles.item, {
                [styles.active]: activeTemplate === template.type,
              })}
              onClick={() => onTemplateClick(template.type)}
            >
              {template.background && (
                <BackgroundIcon className={styles.backgroundIcon} />
              )}
              <Icon className={styles.icon} name="check" />
              <Typography
                className={styles.title}
                variant="body"
                weight="regular"
              >
                {template.title}
              </Typography>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default React.memo(Template);
