export const getItems = (t) => [
  {
    icon: 'editList',
    title: t('renameListLabel'),
    variant: 'default',
    key: 'rename',
  },
  {
    icon: 'settings',
    title: t('setLimitLabel'),
    settingsTitle: t('settingsLimitLabel'),
    key: 'settingsLimit',
    variant: 'default',
  },
  {
    icon: 'bookmark',
    title: t('columnTypeLabel'),
    key: 'columnType',
    variant: 'default',
  },
  {
    icon: 'move',
    title: t('moveTaskLabel'),
    variant: 'default',
    key: 'move',
  },
  {
    icon: 'deleteTasks',
    title: t('deleteTasksLabel'),
    variant: 'alert',
    key: 'deleteTasks',
  },
  {
    icon: 'bucket',
    title: t('deleteButton'),
    variant: 'alert',
    key: 'deleteList',
  },
];
