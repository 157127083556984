import React from 'react';
import { getIn } from 'formik';

import { FormControl } from 'components/shared/FormControl';
import { Checkbox } from 'components/shared/Checkbox';

import styles from './CheckboxField.styles.scss';

const CheckboxField = ({ field, form, ...restProps }) => {
  const error = getIn(form.errors, field.name);
  const touched = form.touched[field.name];

  return (
    <FormControl className={styles.control} error={error} touched={touched}>
      <Checkbox {...field} {...restProps} />
    </FormControl>
  );
};

export default React.memo(CheckboxField);
