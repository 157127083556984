import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import { showToastMessage } from 'helpers/showToastMessage';

import { formatTags } from './TagsPopover.helpers';

import TagsPopover from './TagsPopover';

const TagsPopoverContainer = ({
  open,
  tags: taskTags,
  boardId,
  onTagsChanged,
  ...restProps
}) => {
  const { t } = useTranslation('toast');

  const [currentTags, setCurrentTags] = useState(
    formatTags({ currentTags: taskTags, boardTags: [] }),
  );
  const [creating, setCreating] = useState(false);

  const {
    loading,
    data: boardTags,
    setState: setBoardTags,
  } = useFetch(
    {
      defaultData: [],
      fetcher: api.getBoardTags,
      immediately: false,
      stopRequest: !open,
    },
    boardId,
    [open],
  );

  useEffect(() => {
    if (boardTags.length) {
      setCurrentTags(formatTags({ currentTags: taskTags, boardTags }));
    }
  }, [boardTags]);

  const updateTag = (tag) => {
    const nextTags = currentTags.map((currentTag) => {
      if (currentTag.key === tag.key) {
        return {
          ...currentTag,
          active: tag.active,
          id: tag.id,
        };
      }

      return currentTag;
    });

    setCurrentTags(nextTags);

    if (onTagsChanged) {
      onTagsChanged(
        nextTags
          .filter((nextTag) => nextTag.active)
          .map((nextTag) => ({
            id: nextTag.id,
            name: nextTag.name,
            color: nextTag.key,
          })),
      );
    }
  };

  const handleNameChange = (event, tag) => {
    if (event.target.value === ' ') {
      return;
    }

    setCurrentTags((prevTags) =>
      prevTags.map((prevTag) => {
        if (tag.key === prevTag.key) {
          return {
            ...prevTag,
            name: event.target.value,
          };
        }

        return prevTag;
      }),
    );
  };

  const handleNameFocus = () => {
    setCreating(true);
  };

  const handleNameBlur = (event, tag) => {
    if (
      boardTags.some(
        (boardTag) => boardTag.id === tag.id && boardTag.name === tag.name,
      )
    ) {
      return;
    }

    if (!tag.id) {
      api
        .createBoardTag({
          boardId,
          data: { name: tag.name || '', color: tag.key },
        })
        .then((createdTag) => {
          updateTag({ ...createdTag, ...tag });

          setCreating(false);

          setBoardTags((prevState) => ({
            ...prevState,
            data: [...prevState.data, createdTag],
          }));
        })
        .catch(({ status }) => {
          setCreating(false);

          showToastMessage({ variant: 'error', status, t });
        });
    } else {
      api
        .updateBoardTag({
          boardId,
          tagId: tag.id,
          data: { name: tag.name || '' },
        })
        .then((updatedTag) => {
          setCreating(false);

          const nextBoardTags = boardTags.map((boardTag) => {
            if (boardTag.id === updatedTag.id) {
              return updatedTag;
            }
            return boardTag;
          });

          setBoardTags((prevState) => ({
            ...prevState,
            data: nextBoardTags,
          }));

          updateTag({ ...updatedTag, ...tag });
        })
        .catch(({ status }) => {
          setCreating(false);

          showToastMessage({ variant: 'error', status, t });
        });
    }
  };

  const handleTagClick = (event, tag) => {
    if (creating) {
      return;
    }

    event.stopPropagation();

    if (!tag.id) {
      api
        .createBoardTag({
          boardId,
          data: { name: tag.name || '', color: tag.key },
        })
        .then((createdTag) => {
          updateTag({ ...createdTag, ...tag, active: !tag.active });
        })
        .catch(({ status }) => {
          showToastMessage({ variant: 'error', status, t });
        });

      return;
    }

    updateTag({ ...tag, active: !tag.active });
  };

  return (
    <TagsPopover
      {...restProps}
      open={open}
      loading={loading}
      tags={currentTags}
      onNameBlur={handleNameBlur}
      onNameFocus={handleNameFocus}
      onNameChange={handleNameChange}
      onTagClick={handleTagClick}
    />
  );
};

export default React.memo(TagsPopoverContainer);
