import React from 'react';

import { Tab } from 'components/shared/Tab';

import styles from './Tabs.styles.scss';

const Tabs = ({ tabs, activeTabIndex, onTabClick }) => (
  <div className={styles.tabs}>
    {tabs.map((tab, index) => (
      <Tab
        key={tab.label}
        active={activeTabIndex === index}
        label={tab.label}
        onClick={() => onTabClick(index)}
      />
    ))}
  </div>
);

export default React.memo(Tabs);
