import React from 'react';
import cn from 'classnames';

import styles from './Switch.styles.scss';

const Switch = ({ className, name, onChange, ...restProps }) => (
  <div className={cn(styles.switch, className)}>
    <input
      type="checkbox"
      name={name}
      className={styles.input}
      {...restProps}
      onChange={onChange}
    />
    <div className={styles.body} />
    <div className={styles.toggle} />
  </div>
);

export default React.memo(Switch);
