import React from 'react';
import { getIn } from 'formik';

import { Select } from 'components/shared/Select';
import { FormControl } from 'components/shared/FormControl';

const SelectField = ({
  label,
  field,
  form,
  formClassName,
  onChange,
  onAfterChange,
  ...restProps
}) => {
  const error = getIn(form.errors, field.name);
  const touched = form.touched[field.name];

  const hasError = touched && !!error;

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    } else {
      form.setFieldValue(field.name, value);
    }

    if (onAfterChange) {
      onAfterChange(value);
    }
  };

  return (
    <FormControl
      label={label}
      error={error}
      className={formClassName}
      touched={touched}
    >
      <Select
        {...field}
        {...restProps}
        variant={hasError && 'error'}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default React.memo(SelectField);
