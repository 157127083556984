import React from 'react';
import { components } from 'react-select';

import styles from './Input.styles.scss';

const Input = ({ children, ...props }) => (
  <components.Input {...props} className={styles.input}>
    {children}
  </components.Input>
);

export default React.memo(Input);
