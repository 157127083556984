import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import formConfig from './MoveTasksModal.formConfig';
import { getValidationSchema } from './MoveTasksModal.formConfig';

import { Modal } from 'components/shared/Modal';
import { SelectField } from 'components/FormikFields';
import { ModalControls } from 'components/ModalControls';
import { Typography } from 'components/shared/Typography';

import styles from './MoveTasksModal.styles.scss';

const MoveTasksModal = ({ loading, isOpen, options, onClose, onSubmit }) => {
  const { t, i18n } = useTranslation('moveTasksModal');
  const { t: t_validation } = useTranslation('validation');

  return (
    <Modal className={styles.modal} withClose isOpen={isOpen} onClose={onClose}>
      <Formik
        {...formConfig}
        validationSchema={getValidationSchema(t_validation)}
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}
      >
        {() => (
          <Form className={styles.form}>
            <Typography
              className={styles.title}
              variant="h2"
              weight={i18n.language === 'ru' ? 'regular' : 'medium'}
            >
              {t('tasksTitle')}
            </Typography>
            <Field
              label={t('listLabel')}
              name="list"
              placeholder={t('listMovePlaceholder')}
              options={options}
              className={styles.selectField}
              component={SelectField}
            />
            <div className={styles.controlsWrapper}>
              <ModalControls
                confirmButtonClassName={styles.confirmButton}
                submitType="submit"
                submitTitle={t('moveButton')}
                loading={loading}
                onCancelClick={onClose}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(MoveTasksModal);
