import React from 'react';
import Slider from 'react-slick';

import { Formik, Form } from 'formik';

import { sliderSettings } from './ResetPassword.config';

import formConfig from './ResetPassword.formConfig';

import { PasswordStep } from './components/PasswordStep';
import { CodeStep } from './components/CodeStep';
import { EmailStep } from './components/EmailStep';

import styles from './ResetPasswordPage.styles.scss';

const ResetPasswordPage = ({
  loading,
  sliderRef,
  passwordConfig,
  validationSchema,
  onSubmit,
  onSaveConfirmationCode,
}) => {
  return (
    <div className={styles.page}>
      <Formik
        {...formConfig}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ errors }) => (
          <Form>
            <Slider {...sliderSettings} ref={sliderRef}>
              <EmailStep sliderRef={sliderRef} errors={errors.email} />
              <CodeStep
                errors={errors.code}
                sliderRef={sliderRef}
                onSaveConfirmationCode={onSaveConfirmationCode}
              />
              <PasswordStep
                loading={loading}
                error={errors.password}
                passwordConfig={passwordConfig}
              />
            </Slider>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(ResetPasswordPage);
