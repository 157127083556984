import * as Yup from 'yup';

import { AVATAR_COLORS } from 'constants/common';

export const getValidationSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('requiredNameError'))
      .max(40, t('shortNameError')),
    color: Yup.string().required(t('requiredColorError')),
  });

const initialValues = {
  name: '',
  color: AVATAR_COLORS[0].value,
};

export default {
  initialValues,
};
