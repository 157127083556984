import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { BOARD_PROVIDER_TYPES } from 'components/pages/BoardPage/components/BoardPageContext/BoardPageProvider.constants';

import { showToastMessage } from 'helpers/showToastMessage';
import { convertDataToApi } from './ContextMenuPopover.helpers';

import { useBoard } from 'components/pages/BoardPage/components/BoardPageContext/BoardPage.context';

import ContextMenuPopover from './ContextMenuPopover';

import { getRadioItems } from './ContextMenuPopover.constants';

const ContextMenuPopoverContainer = ({
  type,
  listId,
  boardId,
  ...restProps
}) => {
  const { t } = useTranslation('boardPage');
  const { t: t_toast } = useTranslation('toast');

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(type);

  useEffect(() => {
    setChecked(type);
  }, [type]);

  const { dispatch } = useBoard();

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleCheckClick = (key) => {
    const data = convertDataToApi(key);

    api
      .setColumnType(boardId, listId, data)
      .then((updatedList) => {
        setChecked(key);

        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_LIST_UPDATED,
          payload: { listId: updatedList.id, data: { type: updatedList.type } },
        });
      })
      .catch(({ status }) => {
        showToastMessage({ variant: 'error', status, t_toast });
      });
  };

  return (
    <ContextMenuPopover
      {...restProps}
      open={open}
      checked={checked}
      setOpen={setOpen}
      radioItems={getRadioItems(t)}
      onRequestClose={handleRequestClose}
      onCheckClick={handleCheckClick}
    />
  );
};

export default React.memo(ContextMenuPopoverContainer);
