export const getContextMenuPopoverItems = (t) => [
  {
    icon: 'person',
    title: t('contextAssigneeLabel'),
    variant: 'default',
    key: 'assignee',
  },
  {
    icon: 'calendar',
    title: t('contextDueDateLabel'),
    variant: 'default',
    key: 'dueDate',
  },
  {
    icon: 'bucket',
    title: t('contextDeleteLabel'),
    variant: 'alert',
    key: 'delete',
  },
];
