export const BOARD_COLORS = [
  {
    color: '#F9EEE1',
    activeColor: 'rgba(239, 141, 107, 0.35)',
    value: '#F9EEE1',
  },
  {
    color: '#E4F4E8',
    activeColor: 'rgba(121, 198, 139, 0.35)',
    value: '#E4F4E8',
  },
  {
    color: '#F9EAEF',
    activeColor: 'rgba(225, 149, 175, 0.35)',
    value: '#F9EAEF',
  },
  {
    color: '#ECF0FC',
    activeColor: 'rgba(158, 181, 238, 0.35)',
    value: '#ECF0FC',
  },
];
