import React from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import { convertDate } from 'helpers/convertDate';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';
import { Loading } from 'components/shared/Loading';

import { ActionsPanel } from '../ActionsPanel';

import {
  getAttachmentFormat,
  getAttachmentFormatColor,
} from './AttachmentSection.helpers';

import styles from './AttachmentsSection.styles.scss';

const AttachmentsSection = ({
  className,
  attachments,
  attachmentPreviews,
  uploadingAttachment,
  percentage,
  getFileInputProps,
  getFileRootProps,
  openFileDialog,
  onAttachmentClick,
  onAttachmentActionClick,
  onCancelUploadClick,
}) => {
  const { t } = useTranslation('taskModal');

  return (
    <div className={styles.section}>
      <Typography
        variant="body"
        weight="medium"
        className={cn(styles.emptyTitle, {
          [styles.title]: uploadingAttachment || !!attachments.length,
        })}
      >
        {t('attachmentsTitle')}
      </Typography>
      <div className={styles.inner}>
        {!!attachments.length && (
          <div className={styles.attachments}>
            {attachments.map((attachment) => {
              const attachmentFormat = getAttachmentFormat(attachment);

              const attachmentFormatColor =
                getAttachmentFormatColor(attachment);

              const isAttachmentImage =
                attachmentFormat === 'JPEG' || attachmentFormat === 'PNG';

              const attachmentPreview = attachmentPreviews[attachment.id]
                ? attachmentPreviews[attachment.id]
                : { loading: false, preview: null };

              return (
                <div key={attachment.id} className={styles.attachment}>
                  {isAttachmentImage ? (
                    <div
                      className={styles.attachmentPreview}
                      onClick={() => onAttachmentClick(attachment)}
                    >
                      {attachmentPreview.loading ? (
                        <Loading size={17} />
                      ) : (
                        <img
                          src={attachmentPreview.preview}
                          className={styles.attachmentPreviewImg}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className={styles.attachmentPreview}
                      style={{ borderColor: attachmentFormatColor }}
                      onClick={() => onAttachmentClick(attachment)}
                    >
                      <Typography
                        variant="body"
                        className={styles.attachmentPreviewText}
                        style={{ color: attachmentFormatColor }}
                      >
                        {attachmentFormat}
                      </Typography>
                    </div>
                  )}

                  <div className={styles.attachmentInner}>
                    <Typography
                      variant="body"
                      weight="medium"
                      className={styles.attachmentTitle}
                    >
                      {attachment.name}
                    </Typography>
                    <Typography
                      variant="body"
                      className={styles.attachmentText}
                    >
                      {t('addedLabelStart')}{' '}
                      {convertDate(attachment.createdAt, 'dd.MM.yyyy')}{' '}
                      {t('addedLabelFinish')}{' '}
                      {convertDate(attachment.createdAt, 'HH:mm')}
                    </Typography>
                    <ActionsPanel
                      className={styles.attachmentActions}
                      items={[
                        {
                          key: 'save',
                          icon: 'download',
                          text: t('saveLabel'),
                          clickable: true,
                        },
                        ...(isAttachmentImage
                          ? [
                              {
                                key: 'cover',
                                icon: 'cover',
                                text: attachment.isCover
                                  ? t('removeLabel')
                                  : t('setLabel'),
                                clickable: true,
                              },
                            ]
                          : []),
                        {
                          key: 'delete',
                          icon: 'bucket',
                          text: t('deleteLabel'),
                          clickable: true,
                        },
                      ]}
                      onItemClick={(action) =>
                        onAttachmentActionClick(action, attachment)
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {uploadingAttachment && (
          <div className={styles.uploading}>
            <div className={styles.uploadingHeader}>
              <Typography variant="body" className={styles.uploadingText}>
                {t('uploadingLabel')} {uploadingAttachment.name}
              </Typography>
              <Typography
                variant="body"
                className={styles.uploadingProgressText}
              >
                {percentage}%
              </Typography>
            </div>
            <LinearProgressBar
              progress={percentage}
              className={styles.uploadingProgressBar}
            />
            <Button
              variant="outline"
              className={styles.uploadingButton}
              onClick={onCancelUploadClick}
            >
              {t('cancelButton')}
            </Button>
          </div>
        )}
      </div>
      <div
        className={cn(styles.buttonWrapper, {
          [styles.attachmentsButton]:
            !!attachments.length || uploadingAttachment,
        })}
        {...getFileRootProps()}
      >
        <input className={styles.buttonField} {...getFileInputProps()} />
        <Button
          size="small"
          variant="outline"
          endIcon={<Icon className={styles.icon} name="plus" />}
          className={styles.button}
          onClick={openFileDialog}
        >
          {t('addAttachmentButton')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(AttachmentsSection);
