import React, { useEffect, useState } from 'react';
import parseISO from 'date-fns/parseISO';

import DatePopover from './DatePopover';

const DatePopoverContainer = ({
  open,
  value,
  onChanged,
  onRequestClose,
  ...restProps
}) => {
  const [selectedDay, setSelectedDay] = useState(
    value ? parseISO(value) : null,
  );

  useEffect(() => {
    setSelectedDay(value ? parseISO(value) : null);
  }, [value]);

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const handleClearClick = () => {
    onRequestClose();

    setSelectedDay(null);

    if (onChanged) {
      onChanged(null);
    }
  };

  const handleConfirmClick = () => {
    onRequestClose();

    if (onChanged) {
      onChanged(selectedDay);
    }
  };

  return (
    <DatePopover
      {...restProps}
      open={open}
      selectedDay={selectedDay}
      value={value}
      onRequestClose={onRequestClose}
      onDayClick={handleDayClick}
      onClearClick={handleClearClick}
      onConfirmClick={handleConfirmClick}
    />
  );
};

export default React.memo(DatePopoverContainer);
