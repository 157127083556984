import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/shared/Modal';
import { Select } from 'components/shared/Select';
import { Typography } from 'components/shared/Typography';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import { Member } from 'components/Member';

import { convertMembersToOptions } from './AssignMembersModal.helpers';

import styles from './AssignMembersModal.styles.scss';

const AssignMembersModal = ({
  loading = false,
  saveLoading = false,
  isOpen,
  assignedMembers = [],
  membersToAssign = [],
  onClose,
  onMembersChange,
  onDeleteMemberClick,
  onSaveClick,
}) => {
  const { t, i18n } = useTranslation('assignMembersModal');

  return (
    <Modal
      withControls
      className={styles.modal}
      isOpen={isOpen}
      loading={saveLoading}
      onClose={onClose}
      onCancelClick={onClose}
      onConfirmClick={onSaveClick}
      confirmButtonText={t('saveButton')}
      cancelButtonText={t('сancelButton')}
    >
      <div className={styles.inner}>
        <Typography
          className={styles.title}
          variant="h2"
          weight={i18n.language === 'ru' ? 'regular' : 'medium'}
        >
          {t('title')}
        </Typography>
        <ScrollWrapper
          className={styles.scrollWrapper}
          trackClassName={styles.track}
        >
          {assignedMembers.length > 0 ? (
            <div className={styles.members}>
              {assignedMembers.map((member) => (
                <Member
                  key={member.id}
                  canRemove
                  {...member}
                  onDeleteMemberClick={onDeleteMemberClick}
                />
              ))}
            </div>
          ) : (
            <Typography className={styles.text} variant="body">
              {t('emptymemberstext')}
            </Typography>
          )}
          {membersToAssign.length > 0 ? (
            <Select
              className={styles.select}
              options={convertMembersToOptions(membersToAssign)}
              placeholder={t('placeholder')}
              value=""
              onChange={onMembersChange}
            />
          ) : (
            <Typography variant="body" className={styles.emptyText}>
              {t('addLabel')}
            </Typography>
          )}
        </ScrollWrapper>
      </div>
    </Modal>
  );
};

export default React.memo(AssignMembersModal);
