import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import _orderBy from 'lodash/orderBy';

import { convertDate } from 'helpers/convertDate';

import { TASK_ACTION_TYPES } from 'constants/common';

const _getTitleActionText = (type, title, t) => {
  switch (type) {
    case 'ADDED': {
      return `${t('addedTitleAction')} '${title}'`;
    }
    case 'UPDATED': {
      return `${t('updatedTitleAction')} '${title}'`;
    }
    case 'DELETED': {
      return t('removedTitleAction');
    }
  }
};

const _getDescriptionActionText = (type, description, t) => {
  switch (type) {
    case 'ADDED': {
      return t('addDescriptionAction');
    }
    case 'UPDATED': {
      return t('updateDescriptionAction');
    }
    case 'DELETED': {
      return t('deleteDescriptionAction');
    }
  }
};

const _getDueDateActionText = (type, dueDate, t) => {
  switch (type) {
    case 'ADDED': {
      return `${t('addDateAction')} '${format(
        parseISO(dueDate),
        'dd.MM.yyyy',
      )}'`;
    }
    case 'UPDATED': {
      return `${t('updateDate')} '${convertDate(dueDate, 'dd.MM.yyyy')}'`;
    }
    case 'DELETED': {
      return `${t('deleteDate')}`;
    }
  }
};

const _getMembersActionText = (type, members, t) => {
  switch (type) {
    case 'ADDED': {
      return members.map(
        (member) =>
          `${t('addMembersActionStart')} '${member.fullName}' ${t(
            'addMembersActionFinish',
          )}`,
      );
    }
    case 'DELETED': {
      return members.map(
        (member) =>
          `${t('deleteMembersActionStart')} '${member.fullName}' ${t(
            'deleteMembersActionFinish',
          )}`,
      );
    }
  }
};

const _getAttachmentActionText = (type, name, t) => {
  switch (type) {
    case 'ADDED': {
      return `${t('addAttachmentAction')} '${name}'`;
    }
    case 'UPDATED': {
      return `${t('updatedAttachmentAction')} '${name}'`;
    }
    case 'DELETED': {
      return `${t('deletedAttachmentAction')} '${name}'`;
    }
  }
};

const _getTagActionText = (type, color, title, t) => {
  switch (type) {
    case 'ADDED': {
      return `${t('addMembersActionStart')} '${t(color)}' ${t(
        'tagLabel',
      )} ${title}`;
    }
    case 'UPDATED': {
      return `${t('updatedTagAction')} '${t(color)}' ${t('tagLabel')} ${title}`;
    }
    case 'DELETED': {
      return `${t('deleteMembersActionStart')} '${t(color)}' ${t(
        'tagLabel',
      )} ${title}`;
    }
  }
};

const _getCheckActionText = (type, name, t) => {
  switch (type) {
    case 'ADDED': {
      return `${t('addListAction')} '${name}'`;
    }
    case 'UPDATED': {
      return `${t('updateListAction')} '${name}'`;
    }
    case 'DELETED': {
      return `${t('deletedListAction')} '${name}'`;
    }
  }
};

const _getColumnActionText = (type, column, t) => {
  switch (type) {
    case 'ADDED': {
      return `${t('columnAction')}'${column.name}'`;
    }
    case 'UPDATED': {
      return `${t('moveTaskAction')} '${column.name}'`;
    }
  }
};

const _getTypeActionText = (type, title, t) => {
  switch (type) {
    case 'ADDED': {
      return `${t('addTypeAction')} '${title}'`;
    }
    case 'UPDATED': {
      return `${t('changeTypeAction')} '${title}'`;
    }
    case 'DELETED': {
      return `${t('deleteTypeAction')}`;
    }
  }
};

export const formatActions = (data, t, language) => {
  const actions = [];

  const attachmentActions = data.attachmentHistoryList || [];
  const checkActions = data.checkListHistoryList || [];
  const columnActions = data.columnHistoryList || [];
  const descriptionActions = data.descriptionHistoryList || [];
  const dueDateActions = data.dueDateHistoryList || [];
  const membersActions = data.memberHistoryList || [];
  const titleActions = data.titleHistoryList || [];
  const tagActions = data.tagHistoryList || [];
  const typeActions = data.taskTypeHistoryList || [];

  actions.push(
    ...attachmentActions.map((attachmentAction) => ({
      actionType: TASK_ACTION_TYPES.ATTACHMENT,
      action: _getAttachmentActionText(
        attachmentAction.historyType,
        attachmentAction.taskAttachmentList.map((attachmentList) => {
          return attachmentList.name;
        }),
        t,
      ),
      author: attachmentAction.author,
      date: attachmentAction.updatedAt,
    })),
  );

  actions.push(
    ...checkActions.map((checkAction) => ({
      actionType: TASK_ACTION_TYPES.CHECK,
      action: _getCheckActionText(
        checkAction.historyType,
        checkAction.taskChecklists.map((checkList) => {
          return checkList.name;
        }),
        t,
      ),
      author: checkAction.author,
      date: checkAction.updatedAt,
    })),
  );

  actions.push(
    ...tagActions.map((tagAction) => ({
      actionType: TASK_ACTION_TYPES.TAG,
      action: _getTagActionText(
        tagAction.historyType,
        tagAction.taskTagList.map((tagList) => {
          return tagList.color;
        }),
        tagAction.taskTagList.map((tagList) => {
          return tagList.name ? tagList.name : '';
        }),
        t,
      ),
      author: tagAction.author,
      date: tagAction.updatedAt,
    })),
  );

  actions.push(
    ...typeActions.map((typeAction) => ({
      actionType: TASK_ACTION_TYPES.TYPE,
      action: _getTypeActionText(
        typeAction.historyType,
        typeAction.taskType ? typeAction.taskType.name : '',
        t,
      ),
      author: typeAction.author,
      date: typeAction.updatedAt,
    })),
  );

  actions.push(
    ...columnActions.map((columnAction) => ({
      actionType: TASK_ACTION_TYPES.COLUMN,
      action: _getColumnActionText(
        columnAction.historyType,
        columnAction.boardColumn,
        t,
      ),
      author: columnAction.author,
      date: columnAction.updatedAt,
    })),
  );

  actions.push(
    ...titleActions.map((titleAction) => ({
      actionType: TASK_ACTION_TYPES.TITLE,
      action: _getTitleActionText(
        titleAction.historyType,
        titleAction.title,
        t,
      ),
      author: titleAction.author,
      date: titleAction.updatedAt,
    })),
  );

  actions.push(
    ...descriptionActions.map((descriptionAction) => ({
      actionType: TASK_ACTION_TYPES.DESCRIPTION,
      action: _getDescriptionActionText(
        descriptionAction.historyType,
        descriptionAction.description,
        t,
      ),
      author: descriptionAction.author,
      date: descriptionAction.updatedAt,
    })),
  );

  actions.push(
    ...dueDateActions.map((dueDateAction) => ({
      actionType: TASK_ACTION_TYPES.DUE_DATE,
      action: _getDueDateActionText(
        dueDateAction.historyType,
        dueDateAction.dueDate,
        t,
      ),
      author: dueDateAction.author,
      date: dueDateAction.updatedAt,
    })),
  );

  actions.push(
    ...membersActions.map((membersAction) => ({
      actionType: TASK_ACTION_TYPES.MEMBERS,
      action: _getMembersActionText(
        membersAction.historyType,
        membersAction.memberList,
        t,
      ),
      author: membersAction.author,
      date: membersAction.updatedAt,
    })),
  );

  return _orderBy(actions, ['date'], ['desc']).map((action) => ({
    ...action,
    date: convertDate(action.date, 'dd.MM HH:mm'),
  }));
};
