import React from 'react';
import cn from 'classnames';
import { components } from 'react-select';

import styles from './Control.styles.scss';

const VARIANTS = {
  searchable: styles.searchable,
  default: styles.default,
  error: styles.error,
};

const Control = ({
  children,
  variant = 'default',
  error,
  controlClassName,
  ...props
}) => (
  <components.Control
    {...props}
    className={cn(styles.control, VARIANTS[variant], controlClassName)}
  >
    {children}
  </components.Control>
);

export default React.memo(Control);
