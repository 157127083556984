import { enUS, ru } from 'date-fns/locale';
import isToday from 'date-fns/isToday';
import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import isThisWeek from 'date-fns/isThisWeek';
import isSameMonth from 'date-fns/isSameMonth';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getHours from 'date-fns/getHours';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import startOfMonth from 'date-fns/startOfMonth';
import lastDayOfWeek from 'date-fns/lastDayOfWeek';
import endOfYear from 'date-fns/endOfYear';
import endOfMonth from 'date-fns/endOfMonth';

import {
  DAY_WIDTH,
  WEEK_WIDTH,
  MONTH_WIDTH,
  TIME_PERIOD,
} from './TimelinePage.constants';

export const toPosionScroll = ({ selectedTimePeriod, timelineRef }) => {
  switch (selectedTimePeriod) {
    case TIME_PERIOD.DAY: {
      const dayPosition = daysToDayDisplay.find((day) => day.today).position;

      const toDayPositionScroll = dayPosition - DAY_WIDTH * 25;

      timelineRef.current.scrollLeft = toDayPositionScroll;

      break;
    }

    case TIME_PERIOD.WEEK: {
      const weekPosition = weeksToWeekDisplay().find(
        (day) => day.today,
      ).position;

      timelineRef.current.scrollLeft = weekPosition - WEEK_WIDTH * 8;

      break;
    }

    case TIME_PERIOD.MONTH: {
      const monthPosition = monthsToMontsDisplay().find(
        (day) => day.today,
      ).position;

      timelineRef.current.scrollLeft = monthPosition - MONTH_WIDTH * 7;

      break;
    }

    default: {
      break;
    }
  }
};

export const getBottomData = ({ selectedTimePeriod, language }) => {
  const locale = language === 'en' ? { locale: enUS } : { locale: ru };

  switch (selectedTimePeriod) {
    case TIME_PERIOD.DAY: {
      return daysToDayDisplay;
    }

    case TIME_PERIOD.WEEK: {
      return weeksToWeekDisplay(locale);
    }

    case TIME_PERIOD.MONTH: {
      return monthsToMontsDisplay(locale);
    }

    default: {
      break;
    }
  }
};

export const getTopData = ({ selectedTimePeriod, language }) => {
  const locale = language === 'en' ? { locale: enUS } : { locale: ru };

  switch (selectedTimePeriod) {
    case TIME_PERIOD.DAY: {
      return monthsToDayDisplay(locale);
    }

    case TIME_PERIOD.WEEK: {
      return montsToWeekDisplay(locale);
    }

    case TIME_PERIOD.MONTH: {
      return yearsToMontsDisplay;
    }

    default: {
      break;
    }
  }
};

export const getStartPosition = ({ startDate, bottomArray, period }) => {
  switch (period) {
    case TIME_PERIOD.DAY: {
      return bottomArray.find((day) =>
        isSameDay(day.fullDate, parseISO(startDate)),
      ).position;
    }

    case TIME_PERIOD.WEEK: {
      const oneDayWidth = WEEK_WIDTH / 7;

      const week = bottomArray.find((day) =>
        isSameWeek(parseISO(startDate), day.startDayOfWeek),
      );

      const positionInWeek =
        differenceInDays(parseISO(startDate), week.startDayOfWeek) *
        oneDayWidth;

      return week.position + positionInWeek;
    }

    case TIME_PERIOD.MONTH: {
      const oneDayWidth = MONTH_WIDTH / getDaysInMonth(new Date());

      const dayPosition = bottomArray.find((day) =>
        isSameMonth(day.fullDate, parseISO(startDate)),
      ).position;

      return dayPosition + format(parseISO(startDate), 'd') * oneDayWidth;
    }

    default: {
      break;
    }
  }
};

export const getTaskPeriodWidth = ({ endDate, startDate, period }) => {
  switch (period) {
    case TIME_PERIOD.DAY: {
      const oneHourWidth = DAY_WIDTH / 24;
      const hour = getHours(new Date()) * oneHourWidth;

      return (
        differenceInCalendarDays(new Date(), parseISO(startDate)) * DAY_WIDTH +
        hour
      );
    }

    case TIME_PERIOD.WEEK: {
      const dayWidth = WEEK_WIDTH / 7;
      const oneHourWidth = dayWidth / 24;
      const hour = getHours(new Date()) * oneHourWidth;

      return (
        differenceInCalendarDays(new Date(), parseISO(startDate)) * dayWidth +
        hour
      );
    }

    case TIME_PERIOD.MONTH: {
      const amountDaysInMonth = getDaysInMonth(new Date());
      const oneDayWidth = MONTH_WIDTH / amountDaysInMonth;
      const oneHourWidth = oneDayWidth / 24;
      const hour = getHours(new Date()) * oneHourWidth;

      const taskWidth =
        differenceInCalendarDays(new Date(), parseISO(startDate)) *
          oneDayWidth +
        hour;

      return taskWidth ? taskWidth : oneDayWidth;
    }

    default: {
      break;
    }
  }
};

export const getTodayLinePosition = ({ period, tasks, startDayOfWeek }) => {
  switch (period) {
    case TIME_PERIOD.DAY: {
      const oneHourWidth = DAY_WIDTH / 24;
      const hour = getHours(new Date()) * oneHourWidth;

      return hour;
    }

    case TIME_PERIOD.WEEK: {
      const oneDayWidth = WEEK_WIDTH / 7;
      const oneHourWidth = oneDayWidth / 24;
      const hour = getHours(new Date()) * oneHourWidth;

      return (
        differenceInCalendarDays(new Date(), startDayOfWeek) * oneDayWidth +
        hour
      );
    }

    case TIME_PERIOD.MONTH: {
      const amountDaysInMonth = getDaysInMonth(new Date());
      const oneDayWidth = MONTH_WIDTH / amountDaysInMonth;
      const oneHourWidth = oneDayWidth / 24;
      const hour = getHours(new Date()) * oneHourWidth;

      return Number(format(new Date(), 'd')) * oneDayWidth + hour;
    }

    default: {
      break;
    }
  }
};

export const getLineWidth = (period) => {
  switch (period) {
    case TIME_PERIOD.DAY: {
      return daysToDayDisplay.length * DAY_WIDTH;
    }

    case TIME_PERIOD.WEEK: {
      return weeksToWeekDisplay().length * WEEK_WIDTH;
    }

    case TIME_PERIOD.MONTH: {
      return monthsToMontsDisplay().length * MONTH_WIDTH;
    }

    default: {
      break;
    }
  }
};

export const getMonthshWidth = (months) => {
  return months
    .map((month) => month.width)
    .reduce((resultWidth, monthMidth) => resultWidth + monthMidth, 0);
};

//FOR DAY DISPLAY
const getPeriodDayDisplay = (steps = 1) => {
  const dateArray = [];

  const startDate = startOfMonth(addMonths(new Date(), -6));
  const finishDate = endOfMonth(addMonths(new Date(), 6));
  let currentDate = startDate;

  while (currentDate < finishDate) {
    dateArray.push(currentDate);

    currentDate = addDays(currentDate, 1);
  }

  return dateArray;
};

const analyticsPeriod = getPeriodDayDisplay();

export const monthsToDayDisplay = (locale) => {
  const localeObj = locale || { locale: enUS };

  return analyticsPeriod.reduce((months, date) => {
    const monthText = format(date, 'MMMM yyyy', localeObj);

    if (!months.some((month) => month.text === monthText)) {
      months.push({
        text: monthText,
        width: getDaysInMonth(date) * DAY_WIDTH,
        fullDate: date,
      });
    }

    return months;
  }, []);
};

export const daysToDayDisplay = analyticsPeriod.map((date, index) => ({
  text: format(date, 'd'),
  position: index * DAY_WIDTH,
  today: isToday(date),
  fullDate: date,
  width: 30,
  withBorder: true,
}));

//FOR WEEK DISPLAY
const getPeriodWeekDisplay = (steps = 1) => {
  const dateArray = [];

  const startDate = startOfYear(addYears(new Date(), -3));
  const finishDate = endOfYear(addYears(new Date(), 3));
  let currentDate = startDate;

  while (currentDate < finishDate) {
    dateArray.push(currentDate);

    currentDate = addWeeks(currentDate, 1);
  }

  return dateArray;
};

export const weeksToWeekDisplay = (locale) => {
  const localeObj = locale || { locale: enUS };

  return getPeriodWeekDisplay().map((date, index) => ({
    text: `${format(date, 'wo', localeObj)}(${format(
      startOfWeek(date),
      'd',
    )}-${format(lastDayOfWeek(date), 'd')})`,
    position: index * WEEK_WIDTH,
    width: WEEK_WIDTH,
    fullDate: date,
    today: isThisWeek(date),
    startDayOfWeek: startOfWeek(date),
    lastDayOfWeek: lastDayOfWeek(date),
    withBorder: false,
  }));
};

export const montsToWeekDisplay = (locale) => {
  const localeObj = locale || { locale: enUS };

  return weeksToWeekDisplay()
    .reduce((months, week) => {
      const monthText = format(week.fullDate, 'MMM yyyy', localeObj);

      if (!months.some((month) => month.text === monthText)) {
        months.push({
          text: monthText,
          fullDate: week.fullDate,
        });
      }

      return months;
    }, [])
    .map((month, index) => {
      const dayWidth = WEEK_WIDTH / 7;
      const width = getDaysInMonth(month.fullDate) * dayWidth;

      if (index === 0) {
        //only needed for the first month of the array
        const extraWidth =
          differenceInDays(month.fullDate, startOfWeek(month.fullDate)) *
          dayWidth;

        return { ...month, width: width + extraWidth };
      }

      return { ...month, width: width };
    });
};

//FOR MONTH DISPLAY
const getPeriodMonthDisplay = (steps = 1) => {
  const dateArray = [];

  const startDate = startOfYear(addYears(new Date(), -5));
  const finishDate = endOfYear(addYears(new Date(), 5));
  let currentDate = startDate;

  while (currentDate < finishDate) {
    dateArray.push(currentDate);

    currentDate = addMonths(currentDate, 1);
  }

  return dateArray;
};

export const monthsToMontsDisplay = (locale) => {
  const localeObj = locale || { locale: enUS };

  return getPeriodMonthDisplay().map((date, index) => {
    if (isSameMonth(date, new Date())) {
      return {
        text: format(date, 'MMM', localeObj),
        position: index * MONTH_WIDTH,
        width: MONTH_WIDTH,
        monthNumber: format(date, 'M'),
        fullDate: new Date(),
        today: isToday(new Date()),
        withBorder: format(date, 'M') === '1',
      };
    }

    return {
      text: format(date, 'MMM', locale),
      position: index * MONTH_WIDTH,
      today: isToday(date),
      fullDate: date,
      width: MONTH_WIDTH,
      monthNumber: format(date, 'M'),
      withBorder: format(date, 'M') === '1',
    };
  });
};

export const yearsToMontsDisplay = monthsToMontsDisplay().reduce(
  (years, date) => {
    const yearsText = format(date.fullDate, 'yyyy');

    if (!years.some((year) => year.text === yearsText)) {
      years.push({
        text: yearsText,
        fullDate: date.fullDate,
        width: 12 * MONTH_WIDTH,
      });
    }

    return years;
  },
  [],
);
