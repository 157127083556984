import React from 'react';
import { ChromePicker } from 'react-color';

import styles from './ColorPicker.styles.scss';

const ColorPicker = (color, hidden, onColorChange, onOpenPallete) => (
  <div>
    {hidden && (
      <ChromePicker
        className={styles.colorPicker}
        color={color}
        onChange={onColorChange}
      />
    )}
  </div>
);

export default React.memo(ColorPicker);
