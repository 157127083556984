export const getScrollHeight = (members) => {
  const scrollHeight = 220 + members.length * 50;

  return scrollHeight > 510 ? 510 : scrollHeight;
};

export const getAdmin = (members) => {
  return members.find((user) => user.role === 'ADMIN');
};

export const getMembers = (members) => {
  return members.filter((user) => user.role !== 'ADMIN');
};
