import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './RestrictedPage.styles.scss';

const RestrictedPage = ({ onButtonClick }) => {
  const { t } = useTranslation('restrictedPage');

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <Typography className={styles.title} variant="h2" weight="medium">
            {t('title')}
          </Typography>
          <Typography className={styles.text} variant="body" weight="regular">
            {t('subtitleStart')}
            <span className={styles.textCaption}> X-CORE </span>
            {t('subtitleFinish')}
          </Typography>
          <Button
            className={styles.button}
            variant="contained"
            onClick={onButtonClick}
          >
            {t('titleButton')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RestrictedPage);
