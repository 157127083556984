import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './ActionsPanel.styles.scss';

const ActionsPanel = ({
  className,
  itemClassName,
  iconClassName,
  textClassName,
  items,
  onItemClick,
}) => (
  <div className={cn(styles.panel, className)}>
    {items.map((item) => {
      const Component = item.linkTo ? 'a' : 'div';

      return (
        <Component
          key={item.key}
          className={cn(
            styles.item,
            {
              [styles.itemClickable]: item.clickable,
            },
            itemClassName,
          )}
          onClick={() => item.clickable && onItemClick(item)}
          {...(item.linkTo
            ? { download: item.fileName, href: item.linkTo }
            : {})}
        >
          <Icon className={cn(styles.icon, iconClassName)} name={item.icon} />
          <Typography className={cn(styles.text, textClassName)} variant="body">
            {item.text}
          </Typography>
        </Component>
      );
    })}
  </div>
);

export default React.memo(ActionsPanel);
