import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from 'constants/routes';

import { isAuthenticatedSelector, userSelector } from 'store/auth/selectors';
import { userCompanySelector } from 'store/company/selectors';

import { AuthLayout } from 'components/Layout';

const AuthRoute = ({ component: Component, path, ...restProps }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const user = useSelector(userSelector);
  const company = useSelector(userCompanySelector);

  return (
    <Route
      {...restProps}
      path={path}
      render={(props) => {
        if (isAuthenticated && user.companyId && company) {
          return <Redirect to={ROUTES.DASHBOARD} />;
        }

        return (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        );
      }}
    />
  );
};

export default AuthRoute;
