export const getAttachmentLink = (attachmentPreview) => attachmentPreview;

export const getAttachmentFormat = (attachment) => {
  switch (attachment.contentType) {
    case 'image/jpeg': {
      return 'JPEG';
    }
    case 'image/png': {
      return 'PNG';
    }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      return 'XLSX';
    }
    case 'application/vnd.ms-excel': {
      return 'XLS';
    }
    case 'application/msword': {
      return 'DOC';
    }
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      return 'DOCX';
    }
    case 'application/pdf': {
      return 'PDF';
    }
    case 'image/gif': {
      return 'GIF';
    }
    case 'audio/mpeg': {
      return 'AUDIO';
    }
    case 'application/x-msdownload': {
      return 'EXE';
    }
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      return 'PPT';
    }
    case 'application/x-zip-compressed': {
      return 'ZIP';
    }
    case 'video/avi':
    case 'video/mp4':
    case 'video/quicktime':
    case 'video/x-matroska': {
      return 'VIDEO';
    }
    default: {
      return 'DOC';
    }
  }
};

export const getAttachmentFormatColor = (attachment) => {
  switch (attachment.contentType) {
    case 'image/jpeg': {
      return '#9EB5EE';
    }
    case 'image/png': {
      return '#9EB5EE';
    }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel': {
      return '#79C68B';
    }

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword': {
      return '#9EB5EE';
    }
    case 'application/pdf': {
      return '#E195AF';
    }
    case 'image/gif': {
      return '#B199F0';
    }
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      return '#EF8D6B';
    }
    default: {
      return '#6549D5';
    }
  }
};
