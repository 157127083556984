import { TASK_TAG_COLORS } from 'constants/common';

export const getTagColor = (colorKey) => {
  const tagColor = TASK_TAG_COLORS.find((color) => color.key === colorKey);

  if (!tagColor) {
    return null;
  }

  return tagColor.color;
};
