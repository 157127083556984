import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { convertTemplate } from './Template.helpers';

import Template from './Template';

const TemplateContainer = ({ templates }) => {
  const { t } = useTranslation('boardPage');

  const { setFieldValue } = useFormikContext();

  const [activeTemplate, setActiveTemplate] = useState(templates[0]);

  const convertedTemplates = templates.map((templete) =>
    convertTemplate(templete, t),
  );

  const handleTemplateClick = (type) => {
    setFieldValue('templateType', type);

    setActiveTemplate(type);
  };

  return (
    <Template
      activeTemplate={activeTemplate}
      templates={convertedTemplates}
      onTemplateClick={handleTemplateClick}
    />
  );
};

export default React.memo(TemplateContainer);
