import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { Modal } from 'components/shared/Modal';
import { InputField } from 'components/FormikFields';
import { ModalControls } from 'components/ModalControls';

import { convertDataToForm } from './SetLimitModal.helpers';

import styles from './SetLimitModal.styles.scss';

const SetLimitModal = ({
  loading,
  isOpen,
  wipLimit,
  onClose,
  onChange,
  onCreateWipLimit,
  onDeleteLimitClick,
}) => {
  const { t, i18n } = useTranslation('setLimitModal');

  return (
    <Modal className={styles.modal} withClose isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={convertDataToForm(wipLimit)}
        onSubmit={onCreateWipLimit}
      >
        {({ setFieldValue }) => (
          <Form className={styles.form}>
            <Typography
              className={styles.title}
              variant="h2"
              weight={i18n.language === 'ru' ? 'regular' : 'medium'}
            >
              {wipLimit ? t('settingsLimitTitle') : t('setLimitTitle')}
            </Typography>
            {!wipLimit && (
              <Typography className={styles.subtitle}>
                {t('subtitle')}
              </Typography>
            )}
            <Field
              label={t('label')}
              name="limit"
              placeholder={'0'}
              component={InputField}
              onChange={(event) =>
                onChange({ event, setFieldValue, name: 'limit' })
              }
            />
            {!!wipLimit && (
              <div
                className={styles.deleteWrapper}
                onClick={onDeleteLimitClick}
              >
                <Icon className={styles.icon} name="bucket" />
                <Typography className={styles.text}>
                  {t('deleteLabel')}
                </Typography>
              </div>
            )}
            <ModalControls
              className={styles.controls}
              submitType="submit"
              loading={loading}
              onCancelClick={onClose}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(SetLimitModal);
