import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';

import { PageLayout } from 'components/Layout';

import DashboardIcon from 'assets/img/dashboard.svg';

import styles from './DashboardPage.styles.scss';

const DashboardPage = () => {
  const { t } = useTranslation('analyticsPage');

  return (
    <PageLayout>
      <div className={styles.inner}>
        <Typography className={styles.title} variant="h3">
          {t('title')}
        </Typography>
        <Typography className={styles.text} variant="body">
          <span className={styles.textCaption}>{t('span')} </span>
          {t('subtitle')}
        </Typography>
        <DashboardIcon className={styles.icon} />
      </div>
    </PageLayout>
  );
};
export default React.memo(DashboardPage);
