import React from 'react';

import TypePopover from './TypePopover';

const TypePopoverContainer = ({
  open,
  boardId,
  taskTypes,
  togglePopover,
  onAddTaskTypeClick,
  onSettingsClick,
  ...restProps
}) => {
  const handleAddTypeToTaskClick = (id) => {
    onAddTaskTypeClick(id);
  };

  const handleAddNewTypeClick = () => {
    togglePopover();

    onSettingsClick({ showSettings: false });
  };

  const handleSettingsClick = () => {
    togglePopover();

    onSettingsClick({ showSettings: true });
  };

  return (
    <>
      <TypePopover
        {...restProps}
        open={open}
        taskTypes={taskTypes}
        onAddNewTypeClick={handleAddNewTypeClick}
        onAddTypeToTask={handleAddTypeToTaskClick}
        onSettingsClick={handleSettingsClick}
      />
    </>
  );
};

export default React.memo(TypePopoverContainer);
