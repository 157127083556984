import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';
import { TOKEN_KEY } from 'constants/common';

import { getUserInfo } from 'store/auth/actions';

import * as api from 'api/methods';

import { showToastMessage } from 'helpers/showToastMessage';

import UnsubscribePage from './UnsubscribePage';

const UnsubscribePageContainer = () => {
  const { t } = useTranslation('toast');
  const history = useHistory();
  const dispatch = useDispatch();
  const search = useLocation().search;

  const [loading, setLoading] = useState(false);

  const token = new URLSearchParams(search).get('authorizationToken');

  useEffect(() => {
    setLoading(true);

    localStorage.setItem(TOKEN_KEY, token);

    dispatch(getUserInfo())
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  const handleUnsubscribe = () => {
    setLoading(true);

    const data = { unsubscribe: true };

    api
      .unsubscribe(data)
      .then(() => {
        setLoading(false);

        history.replace(ROUTES.BOARDS);

        showToastMessage({
          variant: 'success',
          title: t('unsubscribe'),
        });
      })
      .catch(({ status }) => {
        showToastMessage({ variant: 'error', status, t });
        setLoading(false);
      });
  };

  return (
    <UnsubscribePage loading={loading} onUnsubscribe={handleUnsubscribe} />
  );
};

export default React.memo(UnsubscribePageContainer);
