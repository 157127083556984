import React from 'react';
import { Field, getIn, FieldArray } from 'formik';

import { FormControl } from 'components/shared/FormControl';
import { Input } from 'components/shared/Input';
import { CustomTag } from 'components/shared/CustomTag';
import { Select } from 'components/shared/Select';

const FormikControl = ({
  name,
  label,
  values,
  variant,
  component,
  hint,
  formClassName,
  ...restProps
}) => {
  switch (component) {
    case 'input': {
      return (
        <Field name={name}>
          {({ field, form }) => {
            const error = getIn(form.errors, field.name);
            const touched = form.touched[field.name];

            const hasError = touched && !!error;

            return (
              <FormControl
                label={label}
                className={formClassName}
                error={error}
                touched={touched}
              >
                <Input
                  {...field}
                  {...restProps}
                  variant={hasError ? 'error' : 'default'}
                />
              </FormControl>
            );
          }}
        </Field>
      );
    }
    case 'select': {
      return (
        <Field name={name}>
          {({ field, form, onChange }) => {
            const error = getIn(form.errors, field.name);
            const touched = form.touched[field.name];

            const hasError = touched && !!error;

            const handleChange = (value) => {
              if (onChange) {
                onChange(value);
              } else {
                form.setFieldValue(field.name, value);
              }
            };

            return (
              <FormControl
                label={label}
                error={error}
                className={formClassName}
                touched={touched}
              >
                <Select
                  {...field}
                  {...restProps}
                  variant={hasError && 'error'}
                  onChange={handleChange}
                />
              </FormControl>
            );
          }}
        </Field>
      );
    }
    case 'customTag': {
      return (
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <>
              {values[name] &&
                values[name].length > 0 &&
                values[name].map((item, index) => {
                  if (item.active) {
                    return (
                      <Field name={`${name}.${index}`}>
                        {({ field, form }) => {
                          return (
                            <FormControl className={formClassName}>
                              <CustomTag
                                color={item.color}
                                description={item.description}
                                {...field}
                                {...restProps}
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                    );
                  }
                })}
            </>
          )}
        />
      );
    }

    default: {
      return <div>default</div>;
    }
  }
};

export default React.memo(FormikControl);
