import React from 'react';
import cn from 'classnames';
import { components } from 'react-select';

import { Checkbox } from 'components/shared/Checkbox';
import { Typography } from 'components/shared/Typography';

import styles from './Option.styles.scss';

const Option = ({ withCheckbox, children, className, ...props }) => (
  <div className={styles.optionWrapper}>
    <components.Option
      {...props}
      className={cn(styles.option, className)}
      isSelected={false}
    >
      <Typography component="div">{children}</Typography>
      {withCheckbox && <Checkbox className={styles.checkbox} />}
    </components.Option>
  </div>
);

export default React.memo(Option);
