import React from 'react';
import { useTranslation } from 'react-i18next';

import { PAGE_CONSTANTS } from '../../RegisterPage.constants';

import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { ErrorMessage } from 'components/shared/ErrorMessage';

import { CodeInput } from 'components/CodeInput';

import styles from './CodeStep.styles.scss';

const infoRenderer = ({
  loading,
  t,
  error,
  errors,
  codeSubmitted,
  errorCounter,
}) => {
  switch (true) {
    case loading: {
      return (
        <div className={styles.loading}>
          <Loading color="#B9B9B9" />
          <Typography className={styles.text}>
            {t('codeCheckingLoading')}
          </Typography>
        </div>
      );
    }
    case error && errorCounter < PAGE_CONSTANTS.MAX_ERROR_COUNTER: {
      return (
        <div className={styles.info}>
          <Icon className={styles.errorIcon} name="close" />
          <Typography className={styles.errorText}>{`${t(
            'invalidCodeErrorStart',
          )} (${errorCounter} ${t('invalidCodeErrorFinish')})`}</Typography>
        </div>
      );
    }
    case error && errorCounter === PAGE_CONSTANTS.MAX_ERROR_COUNTER: {
      return (
        <Typography className={styles.errorText}>
          {t('codeCounterLimitError')}
        </Typography>
      );
    }
    case codeSubmitted: {
      return (
        <div className={styles.info}>
          <Icon className={styles.successIcon} name="success" />
          <Typography className={styles.successText}>
            {t('successText')}
          </Typography>
        </div>
      );
    }
    case !!errors.code: {
      return (
        <ErrorMessage className={styles.error}>{errors.code}</ErrorMessage>
      );
    }
    default: {
      break;
    }
  }
};

const CodeStep = ({
  loading,
  error,
  errors,
  codeSubmitted,
  errorCounter,
  currentEmail,
  timerSecondsLeft,
  resetTrigger,
  onCodeChange,
  onSendCodeAgainClick,
}) => {
  const { t } = useTranslation('signUpCodeStep');
  const { t: t_codeStepErrors } = useTranslation('codeStepErrors');

  return (
    <div className={styles.step}>
      <Typography className={styles.title} variant="h1">
        {t('title')}
      </Typography>
      <Typography className={styles.subtitle} variant="main">
        {t('subtitle')} {currentEmail}. {t('label')}
      </Typography>
      <CodeInput
        className={styles.codeInput}
        error={error}
        resetTrigger={resetTrigger}
        onChange={onCodeChange}
      />
      <div className={styles.infoWrapper}>
        {infoRenderer({
          t: t_codeStepErrors,
          loading,
          error,
          errors,
          errorCounter,
          codeSubmitted,
        })}
      </div>
      <div className={styles.linkWrapper}>
        <Link
          type={timerSecondsLeft > 0 ? 'disabled' : 'default'}
          className={styles.resendLink}
          onClick={onSendCodeAgainClick}
        >
          {t('sendAgaingLink')}
        </Link>
        {timerSecondsLeft > 0 && (
          <Typography className={styles.timer}>
            {`( ${timerSecondsLeft} )`}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default React.memo(CodeStep);
