import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactDayPicker, { LocaleUtils } from 'react-day-picker';

import { Typography } from 'components/shared/Typography';

import { Day } from './components/Day';
import { Navbar } from './components/Navbar';
import { WeekDay } from './components/WeekDay';

import { formatWeekdayShort, formatMonthTitle } from './DatePicker.helpers.js';

import styles from './DatePicker.styles.scss';

const DatePicker = ({
  className,
  selectedDays,
  disabledDays,
  value,
  onCancelClick,
  onClearClick,
  onConfirmClick,
  onDayClick,
}) => {
  const { t, i18n } = useTranslation('datePicker');

  const language = i18n.language;

  return (
    <div className={cn(styles.calendar, className)}>
      <ReactDayPicker
        classNames={styles}
        locale={language}
        enableOutsideDaysClick={false}
        showOutsideDays={true}
        selectedDays={selectedDays}
        disabledDays={disabledDays}
        localeUtils={{ ...LocaleUtils, formatWeekdayShort, formatMonthTitle }}
        weekdayElement={<WeekDay />}
        captionElement={() => null}
        navbarElement={<Navbar />}
        renderDay={(day, modifiers) => <Day day={day} modifiers={modifiers} />}
        onDayClick={onDayClick}
      />
      <div className={styles.controls}>
        <Typography className={styles.confirm} onClick={onConfirmClick}>
          {t('successButton')}
        </Typography>
        {value ? (
          <Typography className={styles.cancel} onClick={onClearClick}>
            {t('clearButton')}
          </Typography>
        ) : (
          <Typography className={styles.cancel} onClick={onCancelClick}>
            {t('cancelButton')}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default React.memo(DatePicker);
