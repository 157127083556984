import React, { useState } from 'react';
import format from 'date-fns/format';

import { DEFAULT_DATE_FORMAT } from 'constants/date';

import DateInput from './DateInput';

const DateInputContainer = ({ onDayClick, ...restProps }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleIconClick = () => setIsDatePickerOpen(true);
  const onClickOutside = () => setIsDatePickerOpen(false);

  const handleDayClick = (day) => {
    if (onDayClick) {
      onDayClick(format(day, DEFAULT_DATE_FORMAT));
    }

    setIsDatePickerOpen(false);
  };

  return (
    <DateInput
      isDatePickerOpen={isDatePickerOpen}
      onClickOutside={onClickOutside}
      onIconClick={handleIconClick}
      onDayClick={handleDayClick}
      {...restProps}
    />
  );
};

export default React.memo(DateInputContainer);
