import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useBoard } from 'components/pages/BoardPage/components/BoardPageContext/BoardPage.context';

import { SLIDE_TYPES } from './TypesSettingsModal.constants';
import { BOARD_PROVIDER_TYPES } from 'components/pages/BoardPage/components/BoardPageContext/BoardPageProvider.constants';

import * as api from 'api/methods';

import { showToastMessage } from 'helpers/showToastMessage';

import TypesSettingsModal from './TypesSettingsModal';

const TypesSettingsModalContainer = ({
  boardId,
  isOpen,
  taskTypes,
  onClose,
  showSettings = true,
  onTaskTypeRemoved,
  ...restProps
}) => {
  const { t } = useTranslation('toast');
  const sliderRef = useRef();
  const [slide, setSlide] = useState(null);
  const [editingType, setEditingType] = useState(null);
  const { dispatch } = useBoard();

  useEffect(() => {
    if (!showSettings) {
      setSlide(SLIDE_TYPES.ADD_NEW_TYPE);
    }
  }, [isOpen, showSettings]);

  const handleAddNewTypeClick = () => {
    setSlide(SLIDE_TYPES.ADD_NEW_TYPE);

    setTimeout(() => {
      sliderRef.current.slickNext();
    }, 200);
  };

  const handleEditTypeClick = (typeId) => {
    setEditingType(taskTypes.find((type) => type.id === typeId));
    setSlide(SLIDE_TYPES.EDIT_TYPE);

    setTimeout(() => {
      sliderRef.current.slickNext();
    }, 200);
  };

  const handleDeleteTypeClick = (typeId) => {
    api
      .removeTaskType({ boardId, typeId })
      .then((response) => {
        if (response.length && onTaskTypeRemoved) {
          onTaskTypeRemoved(response);
        }

        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_TYPE_REMOVED,
          payload: { typeId, updatedTasksVersion: response },
        });
      })
      .catch(({ status }) => {
        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handlePrevSlideClick = () => {
    if (showSettings) {
      setTimeout(() => {
        sliderRef.current.slickPrev();
      }, 200);
    } else {
      onClose();
    }
  };

  return (
    <TypesSettingsModal
      isOpen={isOpen}
      showSettings={showSettings}
      slide={slide}
      sliderRef={sliderRef}
      boardId={boardId}
      editingType={editingType}
      taskTypes={taskTypes}
      onClose={onClose}
      onAddNewTypeClick={handleAddNewTypeClick}
      onEditTypeClick={handleEditTypeClick}
      onDeleteTypeClick={handleDeleteTypeClick}
      onPrevSlideClick={handlePrevSlideClick}
      {...restProps}
    />
  );
};

export default React.memo(TypesSettingsModalContainer);
