import React from 'react';
import cn from 'classnames';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { AVATAR_COLORS } from 'constants/common';

import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { FormControl } from 'components/shared/FormControl';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import { EnterNameSection } from 'components/EnterNameSection';
import { InputField, SelectField } from 'components/FormikFields';

import { TABS, LANGUAGE_OPTIONS } from './SettingsModal.constants';

import { getValidationSchema } from './SettingsModal.formConfig';

import styles from './SettingsModal.styles.scss';

const SettingsModal = ({
  isOpen,
  initialValues,
  currentTab,
  onClose,
  onTabChange,
  onLanguageChange,
  onColorChange,
  onNameBlur,
  onChangeEmailClick,
  onChangePasswordClick,
}) => {
  const { t, i18n } = useTranslation('settingsPage');
  const { t: t_validation } = useTranslation('validation');

  return (
    <Modal className={styles.modal} withClose isOpen={isOpen} onClose={onClose}>
      <div className={styles.aside}>
        <Typography
          className={styles.asideTitle}
          variant="h2"
          weight={i18n.language === 'ru' ? 'regular' : 'medium'}
        >
          {t('title')}
        </Typography>
        <div className={styles.tabs}>
          {TABS.map((tab) => (
            <div
              className={cn(styles.tab, {
                [styles.tabActive]: currentTab === tab.text,
              })}
              key={tab.text}
              onClick={() => onTabChange(tab.text)}
            >
              <Icon className={styles.tabIcon} name={tab.icon} />
              <Typography className={styles.tabText} variant="main">
                {t(tab.translationKey)}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      {currentTab === 'Account' && (
        <>
          <ScrollWrapper
            wrapperClassName={styles.scrollWrapper}
            trackClassName={styles.track}
          >
            <div className={styles.main}>
              <Typography
                className={styles.title}
                variant="h2"
                weight={i18n.language === 'ru' ? 'regular' : 'medium'}
              >
                {t('sideMenuAccount')}
              </Typography>

              <Formik
                validationSchema={getValidationSchema(t_validation)}
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
              >
                {({ values }) => (
                  <Form className={styles.form}>
                    <Typography
                      className={styles.label}
                      variant="link"
                      weight="medium"
                    >
                      {t('nameLabel')}
                    </Typography>
                    <EnterNameSection
                      className={styles.enterNameSection}
                      variant="circle"
                      colors={AVATAR_COLORS.filter(
                        (clr) => clr.value !== 'grey',
                      )}
                      onNameBlur={(event, name) =>
                        onNameBlur(event, name, values)
                      }
                      onColorChange={(color) => onColorChange(color, values)}
                    />
                    <Field
                      readOnly
                      name="email"
                      label={t('emailLabel')}
                      component={InputField}
                      formControlProps={{
                        endAdornment: (
                          <Link onClick={onChangeEmailClick}>
                            {t('emailLink')}
                          </Link>
                        ),
                      }}
                      className={styles.emailField}
                    />
                    <FormControl label={t('passwordLabel')}>
                      <Link
                        className={styles.changePasswordLink}
                        onClick={onChangePasswordClick}
                      >
                        {t('passwordLink')}
                      </Link>
                    </FormControl>
                    <Field
                      className={styles.languageField}
                      name="language"
                      label={t('languageLabel')}
                      component={SelectField}
                      mode="portal"
                      options={LANGUAGE_OPTIONS.map((option) => ({
                        ...option,
                        label: t(option.translationKey),
                      }))}
                      onAfterChange={(nextLanguage) =>
                        onLanguageChange(nextLanguage, values)
                      }
                    />
                    {/* <div className={styles.delete}>
                      <Icon className={styles.deleteIcon} name="bucket" />
                      <Typography className={styles.deleteLabel} variant="body">
                        Delete account
                      </Typography>
                    </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </ScrollWrapper>
        </>
      )}
      {/* {currentTab === 'Notifications' && (
        <>
          <Typography className={styles.title} variant="h2" weight="medium">
            {t('tabNotifications')}
          </Typography>
        </>
      )} */}
    </Modal>
  );
};

export default React.memo(SettingsModal);
