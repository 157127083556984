import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './Footer.styles.scss';

const Footer = ({
  modulesRef,
  welcomeRef,
  functionsRef,
  applicationRef,
  currentLanguage,
  onModuleClick,
  onLogoClick,
  onFunctionClick,
  onTryForFreeClick,
  onApplicationClick,
  onLanguageChange,
  onSignInClick,
  onPolicyClick,
}) => {
  const { t } = useTranslation('homePage');

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.wrapper}>
            <Icon
              name="logo"
              className={styles.icon}
              onClick={() => {
                onLogoClick(welcomeRef);
              }}
            />
            <Button
              className={cn(styles.button, styles.mobile)}
              size="small"
              onClick={onTryForFreeClick}
            >
              {t('startButton')}
            </Button>
          </div>
          <div className={styles.menuContainer}>
            <div className={styles.menu}>
              <div className={styles.menuColumn}>
                <Typography
                  className={styles.menuItem}
                  onClick={() => {
                    onModuleClick(modulesRef);
                  }}
                >
                  {t('modulesLabel')}
                </Typography>
                <Typography
                  className={styles.menuItem}
                  onClick={() => {
                    onApplicationClick(applicationRef);
                  }}
                >
                  {t('caseLabel')}
                </Typography>
                <Typography
                  className={styles.menuItem}
                  onClick={() => {
                    onFunctionClick(functionsRef);
                  }}
                >
                  {t('functionsLabel')}
                </Typography>
              </div>
              <div className={styles.menuColumn}>
                <Typography className={styles.menuItem} onClick={onPolicyClick}>
                  {t('policyLabel')}
                </Typography>
                <Typography className={styles.menuItem}>
                  {t('termsLabel')}
                </Typography>
              </div>
            </div>
            <div className={styles.languageSelect}>
              <Typography
                className={cn(styles.languageSelectItem, {
                  [styles.languageSelectItemSelected]: currentLanguage === 'ru',
                })}
                variant="body"
                onClick={() => onLanguageChange('ru')}
              >
                ru
              </Typography>
              <Typography
                className={cn(styles.languageSelectItem, {
                  [styles.languageSelectItemSelected]: currentLanguage === 'en',
                })}
                variant="body"
                onClick={() => onLanguageChange('en')}
              >
                en
              </Typography>
            </div>
          </div>
          <Button
            className={cn(styles.button, styles.desktop)}
            onClick={onTryForFreeClick}
          >
            {t('startButton')}
          </Button>
        </div>
        <Typography className={styles.text}>
          {t('copyright')}. &copy; {new Date().getFullYear()}. AIM.SPACE
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(Footer);
