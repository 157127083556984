import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { BOARD_PROVIDER_TYPES } from '../../../BoardPageContext/BoardPageProvider.constants';

import { showToastMessage } from 'helpers/showToastMessage';
import { convertDataToApi } from './SetLimitModal.helpers';

import { useBoard } from 'components/pages/BoardPage/components/BoardPageContext/BoardPage.context';

import SetLimitModal from './SetLimitModal';

const SetLimitModalContainer = ({
  isOpen,
  boardId,
  listId,
  onDeleteList,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation('toast');

  const [loading, setLoading] = useState(false);

  const { dispatch } = useBoard();

  const handleCreateWipLimit = ({ limit }) => {
    setLoading(true);
    const data = convertDataToApi(limit);

    api
      .setWipLimit(boardId, listId, data)
      .then(() => {
        setLoading(false);

        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_LIST_UPDATED,
          payload: { listId, data: { wipLimit: parseInt(limit, 10) } },
        });

        onClose();
      })
      .catch(({ status }) => {
        setLoading(false);

        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleDeleteWipLimit = () => {
    handleCreateWipLimit({ limit: 0 });

    onClose();
  };

  const handleChange = ({ event, setFieldValue, name }) => {
    const value = event.target.value.replace(/[^0-9]+/g, '');

    setFieldValue(name, value);
  };

  return (
    <SetLimitModal
      loading={loading}
      isOpen={isOpen}
      onClose={onClose}
      onChange={handleChange}
      onCreateWipLimit={handleCreateWipLimit}
      onDeleteLimitClick={handleDeleteWipLimit}
      {...restProps}
    />
  );
};

export default React.memo(SetLimitModalContainer);
