import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { systemMetaSelector } from 'store/system/selectors';

import { ROUTES } from 'constants/routes';
import { ERROR_TYPES } from './LoginPage.constants';

import { loginUser } from 'store/auth/actions';
import { updateUserInfo } from 'store/auth/actions';

import { setFormError } from './LoginPage.helpers';
import { getValidationSchema } from './LoginPage.formConfig';

import LoginPage from './LoginPage';

const LoginPageContainer = () => {
  const { t: t_validation } = useTranslation('validation');
  const { t: t_signInPage } = useTranslation('signInPage');
  const { i18n } = useTranslation();

  const loginSliderRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const meta = useSelector(systemMetaSelector);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const checkUserTimeZone = (user) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timeZone === user.tz) {
      setLoading(false);
      history.push(ROUTES.BOARDS);
    } else {
      dispatch(
        updateUserInfo({
          fullName: user.fullName,
          avatarColor: user.avatarColor,
          language: user.language,
          shortName: user.shortName,
          state: 'ACTIVE',
          tz: timeZone,
        }),
      )
        .then(() => {
          setLoading(false);
          history.push(ROUTES.BOARDS);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = (values, actions) => {
    setLoading(true);

    dispatch(loginUser(values))
      .then(({ value: user }) => {
        i18n.changeLanguage(user.language);

        const inviteLink = localStorage.getItem('inviteLink');

        if (inviteLink) {
          localStorage.removeItem('inviteLink');

          setLoading(false);
          history.replace(inviteLink);
        } else {
          checkUserTimeZone(user);
        }
      })
      .catch(({ status }) => {
        if (
          status ===
          ERROR_TYPES.ACCOUNT_DOES_NOT_VERIFIED_CONFIRMATION_CODE_SENT
        ) {
          loginSliderRef.current.slickNext();
        }

        setFormError({
          status,
          setFieldError: actions.setFieldError,
          t: t_signInPage,
        });

        setLoading(false);
      });
  };

  const handleEyeClick = () => {
    setShowPassword(!showPassword);
  };

  const emailPattern = meta ? meta.emailPattern : '';

  const validationSchema =
    meta && getValidationSchema(emailPattern, t_validation);

  return (
    <LoginPage
      loading={loading}
      loginSliderRef={loginSliderRef}
      validationSchema={validationSchema}
      showPassword={showPassword}
      onSubmit={handleSubmit}
      onEyeClick={handleEyeClick}
    />
  );
};

export default React.memo(LoginPageContainer);
