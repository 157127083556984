import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import { LandingLayout } from 'components/Layout';

import PolicyPage from './PolicyPage';

const PolicyPageContainer = () => {
  const modulesRef = useRef();
  const functionsRef = useRef();
  const applicationRef = useRef();
  const welcomeRef = useRef();

  const history = useHistory();

  const handleTryForFreeClick = () => {
    history.push(ROUTES.REGISTER);
  };

  return (
    <LandingLayout
      modulesRef={modulesRef}
      welcomeRef={welcomeRef}
      applicationRef={applicationRef}
      functionsRef={functionsRef}
      onTryForFreeClick={handleTryForFreeClick}
    >
      <PolicyPage />
    </LandingLayout>
  );
};

export default React.memo(PolicyPageContainer);
