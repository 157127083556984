import React from 'react';
import { Tooltip } from 'react-tippy';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/shared/Typography';

import styles from './TaskInfo.styles.scss';

const TaskInfo = ({ identifier, timeDistance, onCopyLinkClick }) => {
  const { t } = useTranslation('taskInfo');

  return (
    <div className={styles.container}>
      <Tooltip
        trigger="mouseenter"
        position="bottom"
        html={
          <div className={styles.tippy}>
            <Typography className={styles.tippyText} variant="subtitle">
              {t('copyLabel')}
            </Typography>
          </div>
        }
      >
        <div className={styles.idWrapper}>
          <Typography
            className={styles.id}
            variant="body"
            onClick={onCopyLinkClick}
          >
            #{identifier}
          </Typography>
        </div>
      </Tooltip>

      <Typography className={styles.timeDistance} variant="body">
        , {timeDistance}
      </Typography>
    </div>
  );
};

export default React.memo(TaskInfo);
