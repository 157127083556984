import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import formConfig from './MoveTaskModal.formConfig';
import { getValidationSchema } from './MoveTaskModal.formConfig';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { SelectField } from 'components/FormikFields';
import { ModalControls } from 'components/ModalControls';

import styles from './MoveTaskModal.styles.scss';

const MoveTaskModal = ({ loading, isOpen, options, onClose, onSubmit }) => {
  const { t, i18n } = useTranslation(['validation', 'moveTasksModal']);

  return (
    <Modal className={styles.modal} withClose isOpen={isOpen} onClose={onClose}>
      <Formik
        {...formConfig}
        validationSchema={getValidationSchema(t)}
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}
      >
        {() => (
          <Form className={styles.form}>
            <Typography
              className={styles.title}
              variant="h2"
              weight={i18n.language === 'ru' ? 'regular' : 'medium'}
            >
              {t('moveTasksModal:taskTitle')}
            </Typography>
            <Field
              className={styles.selectField}
              label={t('moveTasksModal:listLabel')}
              name="list"
              placeholder={t('moveTasksModal:listMovePlaceholder')}
              options={options}
              component={SelectField}
            />
            <div className={styles.controlsWrapper}>
              <ModalControls
                confirmButtonClassName={styles.confirmButton}
                submitType="submit"
                submitTitle={t('moveTasksModal:moveButton')}
                loading={loading}
                onCancelClick={onClose}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default React.memo(MoveTaskModal);
