import React from 'react';
import { Tooltip } from 'react-tippy';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Loading } from 'components/shared/Loading';

import { Member } from 'components/Member';

import styles from './AssignMemberPopover.styles.scss';

const AssignMemberPopover = ({
  children,
  loading,
  open,
  title,
  currentMember,
  members,
  onRequestClose,
  onMemberClick,
}) => (
  <Tooltip
    trigger="click"
    open={open}
    onRequestClose={onRequestClose}
    interactive
    animation="shift"
    position="bottom-start"
    html={
      <div className={styles.popover}>
        <Icon name="close" className={styles.icon} onClick={onRequestClose} />
        <Typography variant="h2" weight="medium" className={styles.title}>
          {title}
        </Typography>
        <div className={styles.inner}>
          {loading ? (
            <Loading size={17} className={styles.loading} />
          ) : (
            <>
              {currentMember.id && (
                <div className={styles.member}>
                  <Member
                    {...currentMember}
                    className={styles.memberInner}
                    underline
                    canRemove
                    onDeleteMemberClick={() => onMemberClick(null)}
                  />
                </div>
              )}
              {members.map(
                (member) =>
                  !member.deleted && (
                    <div
                      key={member.id}
                      className={styles.member}
                      onClick={() => onMemberClick(member)}
                    >
                      <Member {...member} className={styles.memberInner} />
                    </div>
                  ),
              )}
            </>
          )}
        </div>
      </div>
    }
  >
    {children}
  </Tooltip>
);

export default React.memo(AssignMemberPopover);
