import React from 'react';
import { Tooltip } from 'react-tippy';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { ScrollWrapper } from 'components/shared/ScrollWrapper';

import { Type } from 'components/pages/BoardPage/components/TypesSettingsModal/components/Type';

import styles from './TypePopover.styles.scss';

const TypePopover = ({
  open,
  taskType,
  children,
  taskTypes,
  onSettingsClick,
  onAddTypeToTask,
  onAddNewTypeClick,
  onRequestClose,
}) => {
  const { t, i18n } = useTranslation('typePopover');

  return (
    <Tooltip
      interactive
      open={open}
      trigger="click"
      position="bottom"
      onRequestClose={onRequestClose}
      html={
        <div className={styles.popover}>
          <Icon
            className={styles.closeIcon}
            name="close"
            onClick={onRequestClose}
          />
          <Typography
            className={styles.title}
            variant="h2"
            weight={i18n.language === 'ru' ? 'regular' : 'medium'}
          >
            {t('title')}
          </Typography>
          {taskTypes.length ? (
            <>
              <ScrollWrapper
                className={styles.scrollWrapper}
                trackClassName={styles.track}
              >
                {taskTypes.map((type) => (
                  <Type
                    key={type.id}
                    checked={taskType && type.id === taskType.id}
                    type={type}
                    onTypeClick={onAddTypeToTask}
                  />
                ))}
              </ScrollWrapper>
              <div className={styles.settings} onClick={onSettingsClick}>
                <Icon className={styles.settingsIcon} name="settings" />
                <Typography className={styles.settingsTitle} variant="body">
                  {t('settingsButton')}
                </Typography>
              </div>
            </>
          ) : (
            <div>
              <Typography className={styles.subtitle} variant="body">
                {t('noTypesSubtitle')}
              </Typography>
              <Button
                className={styles.addButton}
                variant="outline"
                onClick={onAddNewTypeClick}
              >
                {t('addNewType')}
              </Button>
            </div>
          )}
        </div>
      }
    >
      <span className={styles.content}>{children}</span>
    </Tooltip>
  );
};

export default React.memo(TypePopover);
