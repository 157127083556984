import * as Yup from 'yup';

import { SPACE_REGEX, SYMBOLS_REGEX } from 'constants/passwordRegex';

import { getRegex } from 'helpers/getRegex';
import { convertSymbols } from 'helpers/convertSymbols';

export const getValidationSchema = (passwordPattern, t) =>
  Yup.object().shape({
    password: Yup.string()
      .min(Number(passwordPattern.minLength), t('shortPasswordError'))
      .required(t('requiredPasswordError'))
      .test('lowercase', t('passwordLowerCaseError'), (value) =>
        getRegex(passwordPattern.lowerCase).test(value),
      )
      .test('uppercase', t('passwordLeastUppercaseLetterError'), (value) =>
        getRegex(passwordPattern.upperCase).test(value),
      )
      .test('number', t('passwordLeastNumberError'), (value) =>
        getRegex(passwordPattern.number).test(value),
      )
      .test(
        'space',
        t('spaceContainError'),
        (value) => !SPACE_REGEX.test(value),
      )
      .test(
        'symbols',
        `${t('passwordLeastSpecialSymbolsError')} '${convertSymbols(
          SYMBOLS_REGEX,
        )}'`,
        (value) => SYMBOLS_REGEX.test(value),
      )
      .trim(),

    confirmPassword: Yup.string()
      .required(t('requiredPasswordError'))
      .oneOf([Yup.ref('password'), null], t('notMatchPasswordsError')),
  });

const initialValues = {
  password: '',
  confirmPassword: '',
};

export default {
  initialValues,
};
