import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useScroll } from 'hooks/useScroll';
import { useModal } from 'hooks/useModal';

import { logoutUser } from 'store/auth/actions';
import { userSelector } from 'store/auth/selectors';

import { SettingsModal } from 'components/SettingsModal';

import UserMenu from './UserMenu';

const UserMenuContainer = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const [isOpen, setIsOpen] = useState(false);

  const [settingsModalOpen, openSettingsModal, closeSettingsModal] = useModal({
    defaultOpen: false,
  });

  useScroll(isOpen, setIsOpen);

  const handleAvatarClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoutClick = () => {
    dispatch(logoutUser());
  };

  const handleOuterAction = () => {
    setIsOpen(false);
  };

  const handleSettingsClick = () => {
    setIsOpen(false);

    openSettingsModal();
  };

  return (
    <>
      <UserMenu
        {...props}
        isOpen={isOpen}
        user={user}
        onAvatarClick={handleAvatarClick}
        onSettingsClick={handleSettingsClick}
        onLogoutClick={handleLogoutClick}
        onOuterAction={handleOuterAction}
      />
      <SettingsModal isOpen={settingsModalOpen} onClose={closeSettingsModal} />
    </>
  );
};

export default React.memo(UserMenuContainer);
