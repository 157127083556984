import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tippy';
import { Formik, Form, Field } from 'formik';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { InputField } from 'components/FormikFields';
import { ModalControls } from 'components/ModalControls';

import formConfig from './CheckListPopover.formConfig';
import { getValidationSchema } from './CheckListPopover.formConfig';

import styles from './CheckListPopover.styles.scss';

const CheckListPopover = ({
  children,
  open,
  loading,
  resetFormRef,
  onRequestClose,
  onSaveClick,
}) => {
  const { t, i18n } = useTranslation(['validation', 'taskModal']);

  return (
    <Tooltip
      trigger="click"
      open={open}
      onRequestClose={onRequestClose}
      interactive
      animation="shift"
      position="bottom"
      html={
        <div className={styles.popover}>
          <Icon
            className={styles.closeIcon}
            name="close"
            onClick={onRequestClose}
          />
          <Formik
            {...formConfig}
            validationSchema={getValidationSchema(t)}
            enableReinitialize
            validateOnMount
            onSubmit={onSaveClick}
            innerRef={resetFormRef}
          >
            {({ resetForm }) => (
              <Form className={styles.form}>
                <Typography
                  className={styles.title}
                  variant="h2"
                  weight={i18n.language === 'ru' ? 'regular' : 'medium'}
                >
                  {t('taskModal:checkListTitle')}
                </Typography>
                <Field
                  label={t('taskModal:nameLabel')}
                  name="name"
                  placeholder={t('taskModal:checkListPlaceholder')}
                  component={InputField}
                />
                <ModalControls
                  className={styles.controls}
                  loading={loading}
                  submitType="submit"
                  submitTitle={t('taskModal:saveButton')}
                  onCancelClick={() => onRequestClose({ resetForm })}
                />
              </Form>
            )}
          </Formik>
        </div>
      }
    >
      <span className={styles.content}>{children}</span>
    </Tooltip>
  );
};

export default React.memo(CheckListPopover);
