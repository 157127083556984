import React, { useState } from 'react';

import Tabs from './Tabs';

const TabsContainer = ({ onTabChange, ...restProps }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (nextTabIndex) => {
    setActiveTabIndex(nextTabIndex);

    if (onTabChange) {
      onTabChange(nextTabIndex);
    }
  };

  return (
    <Tabs
      {...restProps}
      activeTabIndex={activeTabIndex}
      onTabClick={handleTabClick}
    />
  );
};

export default React.memo(TabsContainer);
