import React from 'react';
import { Tooltip } from 'react-tippy';

import { DatePicker } from 'components/shared/DatePicker';

import styles from './DatePopover.styles.scss';

const DatePopover = ({
  children,
  open,
  selectedDay,
  disabledDays,
  value,
  onRequestClose,
  onDayClick,
  onClearClick,
  onConfirmClick,
}) => (
  <Tooltip
    trigger="click"
    open={open}
    onRequestClose={onRequestClose}
    interactive
    animation="shift"
    position="bottom"
    html={
      <div className={styles.popover}>
        <DatePicker
          selectedDays={selectedDay}
          disabledDays={disabledDays}
          value={value}
          onDayClick={onDayClick}
          onCancelClick={onRequestClose}
          onClearClick={onClearClick}
          onConfirmClick={onConfirmClick}
        />
      </div>
    }
  >
    <span className={styles.content}>{children}</span>
  </Tooltip>
);

export default React.memo(DatePopover);
