import { ERROR_TYPES } from 'constants/errorTypes';

export const setFormError = ({ status, setFieldError, t }) => {
  switch (status) {
    case ERROR_TYPES.BOARD_NOT_FOUND:
    case ERROR_TYPES.BOARD_ID_IS_NULL: {
      setFieldError('list', t('existBoardError'));

      break;
    }

    case ERROR_TYPES.COLUMN_ID_IS_NULL:
    case ERROR_TYPES.COLUMN_IDS_ARE_EMPTY:
    case ERROR_TYPES.BOARD_COLUMN_NOT_FOUND:
    case ERROR_TYPES.MOVE_COLUMN_ID_IS_NULL:
    case ERROR_TYPES.MOVE_COLUMN_DOES_NOT_EXIST: {
      setFieldError('list', t('existColumnError'));

      break;
    }

    case ERROR_TYPES.FORBIDDEN_USER: {
      setFieldError('list', t('userIsForbiddenError'));

      break;
    }

    default: {
      setFieldError('list', t('unknownError'));
    }
  }
};
