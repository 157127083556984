import { convertDate } from 'helpers/convertDate';

export const getDoneText = ({ name, date, t }) =>
  `${t('done')} ${name} ${t('on')} ${convertDate(date, 'dd.MM.yyyy')} ${t(
    'at',
  )} ${convertDate(date, 'HH:mm')}`;

export const getActionsPanelItems = (item, t) => [
  ...(item.assignee && !item.completeItem.completed
    ? [
        {
          key: 'person',
          icon: 'person',
          text: item.assignee.fullName,
          clickable: true,
        },
      ]
    : []),
  ...[],

  ...(item.dueDate && !item.completeItem.completed
    ? [
        {
          key: 'dueDate',
          icon: 'calendar',
          text: convertDate(item.dueDate, 'dd.MM'),
          clickable: true,
        },
      ]
    : []),
  ...[],

  ...(item.completeItem.completed
    ? [
        {
          key: 'completed',
          icon: 'check',
          text: getDoneText({
            name: item.completeItem.completeBy.fullName,
            date: item.completeItem.completeDate,
            t: t,
          }),
          clickable: false,
        },
      ]
    : []),

  ...[],
];
