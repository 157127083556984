import { WEEKDAYS_SHORT, MONTHS } from './DatePicker.constants';

export const formatWeekdayShort = (weekdayNumber, locale) => {
  return WEEKDAYS_SHORT[locale][weekdayNumber];
};

export const formatMonthTitle = (monthNumber, locale) => {
  return `${
    MONTHS[locale][monthNumber.getMonth()]
  } ${monthNumber.getFullYear()}`;
};

export const convertSelectedDays = (selectedDays) => {
  if (!selectedDays || selectedDays === '') {
    return undefined;
  }

  return selectedDays.map((selectedDay) => selectedDay);
};
