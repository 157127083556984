import React from 'react';
import ReactSelect from 'react-select';
import cn from 'classnames';

import { Control } from './components/Control';
import { ValueContainer } from './components/ValueContainer';
import { IndicatorsContainer } from './components/IndicatorsContainer';
import { Option } from './components/Option';

import { SingleValue } from './components/common/SingleValue';
import { MenuList } from './components/common/MenuList';
import { Placeholder } from './components/common/Placeholder';
import { Input } from './components/common/Input';

import styles from './Select.styles.scss';

const VARIANTS = {
  searchable: styles.searchable,
  default: styles.default,
  error: styles.error,
};

const customStyles = {
  control: (style, state) => ({
    ...style,
    boxShadow: state.isFocused ? 0 : 0,
    minHeight: '35px',
  }),
  option: (style) => ({
    ...style,
    display: 'flex',
  }),
};

const portalStyles = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) };

const Select = ({
  variant = 'default',
  optionVariant,
  placeholder,
  mode = 'regular',
  options,
  className,
  controlClassName,
  withCheckbox,
  ...restProps
}) => {
  const commonProps = {
    isSearchable: variant === 'searchable',
    className: cn(styles.select, VARIANTS[variant], className),
    options,
    styles: { ...customStyles, ...portalStyles },
    closeMenuOnSelect: true,
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: 'transparent',
        primary50: 'transparent',
      },
      cursor: 'pointer',
    }),
    components: {
      SingleValue,
      MenuList,
      Input,
      Option: (props) => (
        <Option {...props} withCheckbox={withCheckbox} variant={variant} />
      ),
      Placeholder: (props) => (
        <Placeholder {...props} placeholder={placeholder} variant={variant} />
      ),
      Control: (props) => (
        <Control
          {...props}
          controlClassName={controlClassName}
          variant={variant}
        />
      ),
      ValueContainer: (props) => (
        <ValueContainer {...props} variant={variant} />
      ),
      IndicatorsContainer: (props) => (
        <IndicatorsContainer
          {...props}
          variant={variant}
          menuOpen={props.selectProps.menuIsOpen}
        />
      ),
    },
  };

  if (mode === 'portal') {
    return (
      <ReactSelect
        menuPortalTarget={document.body}
        menuPosition="fixed"
        {...commonProps}
        {...restProps}
      />
    );
  }

  return <ReactSelect {...commonProps} {...restProps} />;
};

export default React.memo(Select);
