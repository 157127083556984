import { SOCKET_EVENT_TYPES } from 'constants/socketConstants';
import { BOARD_PROVIDER_TYPES } from './components/BoardPageContext/BoardPageProvider.constants';

export const updateBoard = ({ id, data, dispatch }) => {
  switch (data.state) {
    case SOCKET_EVENT_TYPES.BOARD_UPDATED: {
      dispatch({
        type: BOARD_PROVIDER_TYPES.UPDATE_BOARD_DATA,
        payload: data.board,
      });

      break;
    }

    case SOCKET_EVENT_TYPES.BOARD_COLUMN_CREATED: {
      dispatch({
        type: BOARD_PROVIDER_TYPES.BOARD_LIST_CREATED,
        payload: data.changedColumns[0],
      });

      break;
    }

    case SOCKET_EVENT_TYPES.BOARD_COLUMN_UPDATED: {
      dispatch({
        type: BOARD_PROVIDER_TYPES.BOARD_LIST_UPDATED,
        payload: {
          listId: data.changedColumns[0].id,
          data: data.changedColumns[0],
        },
      });

      break;
    }

    case SOCKET_EVENT_TYPES.BOARD_COLUMN_MOVED: {
      dispatch({
        type: BOARD_PROVIDER_TYPES.SET_BOARD_LISTS_SOCKET,
        payload: data.changedColumns,
      });

      break;
    }

    case SOCKET_EVENT_TYPES.BOARD_COLUMN_DELETED: {
      dispatch({
        type: BOARD_PROVIDER_TYPES.SET_BOARD_LISTS_SOCKET,
        payload: data.changedColumns,
      });

      break;
    }

    case SOCKET_EVENT_TYPES.BOARD_TASKS_UPDATED: {
      dispatch({
        type: BOARD_PROVIDER_TYPES.BOARD_TASKS_UPDATE,
        payload: { changedTasks: data.changedTasks },
      });

      break;
    }

    default: {
      console.log(data.state, 'not handled for now');

      break;
    }
  }
};
