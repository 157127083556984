import React from 'react';
import ReactDOM from 'react-dom/client'; // Использование нового API createRoot

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';

import store from 'configs/store';
import { createAxiosDefaults, setupAxiosInterceptors } from 'configs/axios';
import i18n from 'configs/i18n';

import * as serviceWorker from './serviceWorker';

import 'assets/styles/styles.scss';
import 'slick-carousel/slick/slick.css';

import App from './App';

createAxiosDefaults();
setupAxiosInterceptors(store, i18n.t);

Modal.setAppElement('#x-core-app');

const root = ReactDOM.createRoot(document.getElementById('x-core-app'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);

serviceWorker.unregister();
