import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Navbar.styles.scss';

const Navbar = ({
  month,
  locale,
  localeUtils,
  onPreviousClick,
  onNextClick,
  showPreviousButton,
  showNextButton,
}) => (
  <div className={styles.navbar}>
    <div className={styles.arrowWrapper}>
      <Icon
        name="arrowLeftDayPicker"
        className={cn(styles.arrow, { [styles.hidden]: !showPreviousButton })}
        onClick={() => onPreviousClick()}
      />
    </div>

    <Typography variant="subtitle" className={styles.text} weight="medium">
      {localeUtils.formatMonthTitle(month, locale)}
    </Typography>

    <div className={styles.arrowWrapper}>
      <Icon
        name="arrowRightDayPicker"
        className={cn(styles.arrow, { [styles.hidden]: !showNextButton })}
        onClick={() => onNextClick()}
      />
    </div>
  </div>
);

export default React.memo(Navbar);
