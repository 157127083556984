import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import styles from './TabButton.styles.scss';

const TabButton = ({
  rootRef,
  loading,
  checked,
  index,
  name,
  startIconName,
  onClick,
}) => (
  <div
    className={cn(styles.tab, {
      [styles.checked]: checked,
      [styles.margin]: index > 0,
    })}
    ref={rootRef}
    onClick={onClick}
  >
    {loading ? (
      <Loading />
    ) : (
      <>
        {startIconName && (
          <div className={styles.startIcon}>
            <Icon className={styles.icon} name={startIconName} />
          </div>
        )}
        <Typography className={styles.title} variant="body" weight="light">
          {name}
        </Typography>
      </>
    )}
  </div>
);

export default React.memo(TabButton);
