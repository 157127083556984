import * as Yup from 'yup';

export const getValidationSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('requiredTitleError'))
      .max(40, t('shortTitleError')),
    color: Yup.string().required(t('requiredColorError')),
    templateType: Yup.string(),
  });
