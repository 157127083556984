import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Emitter from 'services/eventEmitter';

import * as api from 'api/methods';

import { EMITTER_TYPES } from 'constants/emitterTypes';
import { BOARD_PROVIDER_TYPES } from '../BoardPageContext/BoardPageProvider.constants';

import { updateList } from './List.helpers';
import { showToastMessage } from 'helpers/showToastMessage';
import { checkSpaceContainValue } from 'helpers/checkSpaceContainValue';

import { useModal } from 'hooks/useModal';
import { useBoard } from '../BoardPageContext/BoardPage.context';

import { DeleteModal } from 'components/DeleteModal';

import { TaskModal } from './components/TaskModal';
import { SetLimitModal } from './components/SetLimitModal';
import { MoveTasksModal } from './components/MoveTasksModal';
import { DeleteListModal } from './components/DeleteListModal';

import List from './List';

const ListContainer = ({
  movingDisabledTo,
  movingDisabledFrom,
  id: listId,
  name,
  reserveTaskData,
  tasks,
  taskIdUrl,
  checkListTitleUniq,
  getCurrentList,
  setMovingDisabledFrom,
  setMovingDisabledTo,
  setMovingTask,
  onSaveReserveTaskData,
  ...restProps
}) => {
  const { id: boardId } = useParams();

  const { t } = useTranslation('boardPage');
  const { t: t_toast } = useTranslation('toast');

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const [error, setError] = useState(null);

  const [value, setValue] = useState(name || '');

  const { board, dispatch } = useBoard();

  const [deleteTasksModalOpen, openDeleteTasksModal, closeDeleteTasksModal] =
    useModal({ defaultOpen: false });

  const [deleteListModalOpen, openDeleteListModal, closeDeleteListModal] =
    useModal({ defaultOpen: false });

  const [setLimitModalOpen, openSetLimitModal, closeSetLimitModal] = useModal({
    defaultOpen: false,
  });

  const [
    taskModalOpen,
    openTaskModal,
    closeTaskModal,
    taskModalData,
    setTaskModalData,
  ] = useModal({
    defaultOpen: false,
  });

  const [
    moveTasksModalOpen,
    openMoveTasksModal,
    closeMoveTasksModal,
    moveTasksModalData,
  ] = useModal({
    defaultOpen: false,
  });

  useEffect(() => {
    if (name !== value) {
      setValue(name);
    }
  }, [name]);

  useEffect(() => {
    if (taskIdUrl && tasks.some((task) => task.id === taskIdUrl)) {
      openTaskModal({
        orderNumber: 6,
        listName: name,
        listId,
        taskId: taskIdUrl,
      });
    }
  }, [taskIdUrl]);

  useEffect(() => {
    Emitter.on(EMITTER_TYPES.UPDATE, (data) => {
      if (boardId === data.board.id) {
        updateList({ listId, data, dispatch });
      }
    });

    return () => {
      Emitter.removeListener(EMITTER_TYPES.UPDATE);
    };
  }, []);

  const getTaskCardPayload = (index) => {
    return tasks[index];
  };

  const renameList = () => {
    setLoading(true);

    api
      .updateBoardList(boardId, listId, {
        name: value.trim(),
      })
      .then((response) => {
        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_LIST_UPDATED,
          payload: { listId, data: { name: response.name } },
        });

        setLoading(false);
        setEditing(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        setEditing(false);

        showToastMessage({ variant: 'error', status, t: t_toast });
      });
  };

  const handleDeleteList = () => {
    setLoading(true);

    api
      .deleteBoardList(boardId, listId)
      .then(() => {
        dispatch({
          type: BOARD_PROVIDER_TYPES.BOARD_LIST_DELETED,
          payload: { listId },
        });

        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);

        dispatch({
          type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
          payload: { listId, tasks },
        });

        showToastMessage({ variant: 'error', status, t: t_toast });
      });
  };

  const handleTaskCreate = (taskTitle) =>
    api.createTask(boardId, listId, { title: taskTitle });

  const openRenameList = (open, openPopover) => {
    setError(null);

    setEditing(true);

    if (open) {
      openPopover(false);
    }
  };

  const handleTaskCreated = (task) => {
    if (!task) {
      return;
    }

    dispatch({
      type: BOARD_PROVIDER_TYPES.BOARD_TASK_CREATED,
      payload: { listId, task },
    });
  };

  const handleInputListChange = (event) => {
    const nextValue = event.target.value;

    if (checkListTitleUniq(nextValue, listId)) {
      setError(t('uniqueError'));
    } else {
      setError(null);
    }

    setValue(nextValue);
  };

  const handleInputListKeyDown = (event) => {
    const nextValue = event.target.value;

    if (
      !error &&
      value.length &&
      nextValue !== name &&
      event.key === 'Enter' &&
      checkSpaceContainValue(nextValue)
    ) {
      setValue(nextValue);

      renameList();
    }
  };

  const handleInputListBlur = (event) => {
    const nextValue = event.target.value;

    if (
      !error &&
      value.length &&
      nextValue !== name &&
      checkSpaceContainValue(nextValue)
    ) {
      setValue(nextValue);

      renameList();
    } else {
      setEditing(false);

      setValue(name);
    }
  };

  const handleMouseUp = () => {
    setError(null);

    setEditing(true);
  };

  const handleListTaskMoved = (toListId) => {
    const currentList = getCurrentList(toListId);

    setTaskModalData((prevData) => ({
      ...prevData,
      listId: currentList.id,
      listName: currentList.name,
    }));
  };

  const handleTaskDrop = ({ addedIndex, payload, removedIndex }) => {
    //moving tasks inside list

    if (
      !movingDisabledFrom &&
      !movingDisabledTo &&
      removedIndex !== null &&
      addedIndex !== null
    ) {
      setMovingDisabledFrom(true);
      setMovingDisabledTo(true);
      setMovingTask(true);

      const nextTasks = [...tasks];

      nextTasks.splice(addedIndex, 0, nextTasks.splice(removedIndex, 1)[0]);

      dispatch({
        type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
        payload: { listId, tasks: nextTasks },
      });

      api
        .moveTask(boardId, payload.id, {
          fromColumnId: payload.columnId,
          orderNumber: addedIndex + 1,
          toColumnId: payload.columnId,
          version: reserveTaskData.current.version,
        })
        .then(({ toColumnTaskList }) => {
          dispatch({
            type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
            payload: { listId, tasks: toColumnTaskList },
          });

          const updatedReservedTaskData = toColumnTaskList.find(
            (task) => task.id === reserveTaskData.current.id,
          );

          if (updatedReservedTaskData) {
            onSaveReserveTaskData({ payload: updatedReservedTaskData });
          }

          setMovingTask(false);
        })
        .catch(({ status }) => {
          dispatch({
            type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
            payload: { listId, tasks },
          });

          setMovingTask(false);

          showToastMessage({ variant: 'error', status, t: t_toast });
        });
    }

    //removing tasks that moving to next list
    if (!movingDisabledFrom && addedIndex === null && removedIndex !== null) {
      setMovingDisabledFrom(true);

      const nextTasks = [...tasks];

      nextTasks.splice(removedIndex, 1)[0];

      dispatch({
        type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
        payload: { listId, tasks: nextTasks },
      });
    }

    //moving tasks to next list
    if (!movingDisabledTo && addedIndex !== null && removedIndex === null) {
      setMovingDisabledTo(true);

      const nextTasks = [...tasks];

      nextTasks.splice(addedIndex, 0, payload);

      setMovingTask(true);

      dispatch({
        type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
        payload: { listId, tasks: nextTasks },
      });

      api
        .moveTask(boardId, payload.id, {
          fromColumnId: payload.columnId,
          orderNumber: addedIndex + 1,
          toColumnId: listId,
          version: reserveTaskData.current.version,
        })
        .then(
          ({
            fromColumnId,
            toColumnId,
            fromColumnTaskList,
            toColumnTaskList,
          }) => {
            dispatch({
              type: BOARD_PROVIDER_TYPES.BOARD_TASKS_MOVED,
              payload: {
                fromColumnId,
                toColumnId,
                fromColumnTaskList,
                toColumnTaskList,
              },
            });

            const updatedReservedTaskData = [
              ...toColumnTaskList,
              ...fromColumnTaskList,
            ].find((task) => task.id === reserveTaskData.current.id);

            if (updatedReservedTaskData) {
              onSaveReserveTaskData({ payload: updatedReservedTaskData });
            }

            setMovingTask(false);
          },
        )
        .catch(({ status }) => {
          dispatch({
            type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
            payload: { listId, tasks },
          });

          setMovingTask(false);

          showToastMessage({ variant: 'error', status, t: t_toast });
        });
    }
  };

  const handleDeleteTasksClick = () => {
    if (tasks.length === 0) {
      closeDeleteTasksModal();
      return;
    }

    api
      .deleteTasks(boardId, listId)

      .then(() => {
        setLoading(false);

        dispatch({
          type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
          payload: { listId, tasks: [] },
        });

        closeDeleteTasksModal();
      })
      .catch(({ status }) => {
        setLoading(false);

        showToastMessage({ variant: 'error', status, t: t_toast });
      });
  };

  const handleDeleteListWithTasks = () => {
    if (tasks.length === 0) {
      closeDeleteTasksModal();
      return;
    }

    api
      .deleteTasks(boardId, listId)

      .then(() => {
        setLoading(false);

        handleDeleteList();

        dispatch({
          type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
          payload: { listId, tasks: [] },
        });

        closeDeleteTasksModal();

        closeDeleteListModal();
      })
      .catch(({ status }) => {
        setLoading(false);

        dispatch({
          type: BOARD_PROVIDER_TYPES.SET_BOARD_TASKS,
          payload: { listId, tasks },
        });

        showToastMessage({ variant: 'error', status, t: t_toast });
      });
  };

  const confirmDeleteTasks = (openPopover) => {
    openDeleteTasksModal();

    openPopover(false);
  };

  const handleTaskClick = (taskId, orderNumber) => {
    window.history.pushState(null, '', `/boards/${boardId}/task/${taskId}`);

    openTaskModal({
      orderNumber,
      listName: name,
      listId,
      taskId,
    });
  };

  const handleTaskModalClose = () => {
    window.history.pushState(null, '', `/boards/${boardId}`);
    closeTaskModal();
  };

  const handleMoveTasksWithDeleteListClick = () => {
    openMoveTasksModal({
      tasks,
      withDeleteList: true,
      onDeleteList: handleDeleteList,
    });
  };

  const deleteList = (open, openPopover) => {
    if (tasks.length !== 0) {
      if (open) {
        openPopover(false);
      }

      openDeleteListModal();
    } else {
      handleDeleteList();
    }
  };

  const moveTasks = (open, openPopover) => {
    if (open) {
      openPopover(false);
    }

    openMoveTasksModal({ tasks });
  };

  const setLimit = (open, openPopover) => {
    if (open) {
      openPopover(false);
    }

    openSetLimitModal();
  };

  const onMoreItemClick = ({ key, open, openPopover }) => {
    switch (key) {
      case 'rename': {
        openRenameList(open, openPopover);

        break;
      }

      case 'settingsLimit': {
        setLimit(open, openPopover);

        break;
      }

      case 'move': {
        moveTasks(open, openPopover);

        break;
      }

      case 'deleteTasks': {
        confirmDeleteTasks(openPopover);

        break;
      }

      case 'deleteList': {
        deleteList(open, openPopover);

        break;
      }
    }
  };

  return (
    <>
      <List
        {...restProps}
        onSaveReserveTaskData={onSaveReserveTaskData}
        error={error}
        editing={editing}
        loading={loading}
        tasksLoading={!tasks}
        listId={listId}
        boardId={boardId}
        value={value}
        taskTypes={board.taskTypeList}
        tasks={tasks}
        getTaskCardPayload={getTaskCardPayload}
        onTaskDrop={handleTaskDrop}
        onMouseUp={handleMouseUp}
        onInputListChange={handleInputListChange}
        onInputListBlur={handleInputListBlur}
        onInputListKeyDown={handleInputListKeyDown}
        onTaskCreate={handleTaskCreate}
        onTaskCreated={handleTaskCreated}
        onTaskClick={handleTaskClick}
        onMoreItemClick={onMoreItemClick}
      />
      <TaskModal
        {...taskModalData}
        isOpen={taskModalOpen}
        taskTypes={board.taskTypeList}
        onClose={handleTaskModalClose}
        onTaskMoved={handleListTaskMoved}
      />
      <SetLimitModal
        {...restProps}
        loading={loading}
        isOpen={setLimitModalOpen}
        listId={listId}
        onClose={closeSetLimitModal}
      />
      <DeleteModal
        loading={loading}
        isOpen={deleteTasksModalOpen}
        title={t('deleteTasksTitle')}
        subtitle={t('deleteTasksSubtitle')}
        onClose={closeDeleteTasksModal}
        onDeleteClick={handleDeleteTasksClick}
      />
      <DeleteListModal
        isOpen={deleteListModalOpen}
        onClose={closeDeleteListModal}
        onDelete={handleDeleteListWithTasks}
        onMove={handleMoveTasksWithDeleteListClick}
      />
      <MoveTasksModal
        {...moveTasksModalData}
        isOpen={moveTasksModalOpen}
        listId={listId}
        boardId={boardId}
        onClose={closeMoveTasksModal}
        getCurrentList={getCurrentList}
      />
    </>
  );
};

export default React.memo(ListContainer);
