import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';
import { TOKEN_KEY } from 'constants/common';

import { getUserInfo } from 'store/auth/actions';

import RedirectPage from './RedirectPage';

const RedirectPageContainer = () => {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;

  const [loading, setLoading] = useState(false);

  const taskId = new URLSearchParams(search).get('taskId');
  const boardId = new URLSearchParams(search).get('boardId');
  const token = new URLSearchParams(search).get('authorizationToken');

  useEffect(() => {
    setLoading(true);

    localStorage.setItem(TOKEN_KEY, token);

    dispatch(getUserInfo())
      .then(({ value: user }) => {
        i18n.changeLanguage(user.language);

        if (taskId) {
          history.replace(`/boards/${boardId}/task/${taskId}`);
        }
        if (boardId && !taskId) {
          history.replace(`/boards/${boardId}`);
        }
        if (!boardId && !taskId) {
          history.replace(ROUTES.BOARDS);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return <RedirectPage loading={loading} />;
};

export default React.memo(RedirectPageContainer);
