import React, { useState } from 'react';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import AssignOwnerPopover from './AssignOwnerPopover';

const AssignOwnerPopoverContainer = ({ boardId, onChanged, ...restProps }) => {
  const [open, setOpen] = useState(false);

  const { loading, data: members } = useFetch(
    {
      defaultData: [],
      fetcher: api.getBoardMembers,
      immediately: false,
      stopRequest: !open,
    },
    boardId,
    [open],
  );

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleMemberClick = (member) => {
    if (onChanged) {
      onChanged(member);
    }

    setOpen(false);
  };

  return (
    <AssignOwnerPopover
      {...restProps}
      loading={loading}
      open={open}
      members={members}
      setOpen={setOpen}
      onRequestClose={handleRequestClose}
      onMemberClick={handleMemberClick}
    />
  );
};

export default React.memo(AssignOwnerPopoverContainer);
