import React from 'react';
import cn from 'classnames';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

import { Button } from 'components/shared/Button';

import styles from './CreatingInput.styles.scss';

const CreatingInput = ({
  className,
  controlsClassName,
  buttonClassName,
  editing = false,
  autoFocus = false,
  actionsContent,
  name,
  placeholder,
  confirmText = 'Save',
  cancelText = 'Cancel',
  endAdornment,
  onInputFocus,
  onInputBlur,
  onConfirmClick,
  onCancelClick,
}) => (
  <div
    className={cn(styles.input, { [styles.inputEditing]: editing }, className)}
  >
    <Field name={name}>
      {({ field }) => (
        <div className={styles.fieldWrapper}>
          <TextareaAutosize
            {...field}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={styles.field}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
          {endAdornment && (
            <div className={styles.endAdornment}>{endAdornment}</div>
          )}
        </div>
      )}
    </Field>
    {editing && (
      <div className={cn(styles.controls, controlsClassName)}>
        <Button
          className={cn(styles.control, buttonClassName)}
          onMouseDown={onConfirmClick}
        >
          {confirmText}
        </Button>
        <Button
          variant="outline"
          className={cn(styles.control, buttonClassName)}
          onMouseDown={onCancelClick}
        >
          {cancelText}
        </Button>
      </div>
    )}
    {!editing && actionsContent && actionsContent}
  </div>
);

export default React.memo(CreatingInput);
