import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/methods';

import { useBoard } from 'components/pages/BoardPage/components/BoardPageContext/BoardPage.context';

import { BOARD_PROVIDER_TYPES } from 'components/pages/BoardPage/components/BoardPageContext/BoardPageProvider.constants';

import { convertDataToApi } from './AddNewType.helpers';
import { showToastMessage } from 'helpers/showToastMessage';

import AddNewType from './AddNewType';

const AddNewTypeContainer = ({
  boardId,
  taskTypes,
  popoverType,
  onOpenTypePopover,
  onClose,
}) => {
  const { t } = useTranslation('toast');

  const newTypeFormRef = useRef();

  const [loading, setLoading] = useState(false);

  const { dispatch } = useBoard();

  const handleSubmit = (values) => {
    setLoading(true);

    const data = convertDataToApi(values);

    api
      .createTaskType({ boardId, data })
      .then((createdTaskType) => {
        setLoading(false);

        newTypeFormRef.current.resetForm();

        dispatch({
          type: BOARD_PROVIDER_TYPES.TYPE_CREATED,
          payload: { createdTaskType },
        });

        onClose();

        if (onOpenTypePopover) {
          onOpenTypePopover(popoverType);
        }
      })
      .catch(({ status }) => {
        setLoading(false);

        showToastMessage({ variant: 'error', status, t });
      });
  };

  const handleCancelClick = () => {
    newTypeFormRef.current.resetForm();

    onClose();
  };

  return (
    <AddNewType
      loading={loading}
      newTypeFormRef={newTypeFormRef}
      taskTypes={taskTypes}
      onSubmit={handleSubmit}
      onCancelClick={handleCancelClick}
    />
  );
};

export default React.memo(AddNewTypeContainer);
