import React from 'react';
import { useTranslation } from 'react-i18next';

import { TASK_ACTION_TYPES } from 'constants/common';
import { Link } from 'components/shared/Link';
import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';
import { Loading } from 'components/shared/Loading';
import { Avatar } from 'components/shared/Avatar';

import styles from './ActionsSection.styles.scss';

const ActionsSection = ({ open, loading, actions = [], onToggleClick }) => {
  const { t } = useTranslation('taskModal');

  const renderAction = (action, actionIndex) => {
    if (action.actionType === TASK_ACTION_TYPES.MEMBERS) {
      return action.action.map((memberAction, index) => (
        <div key={index} className={styles.action}>
          <Avatar
            variant="medium"
            fullName={action.author.fullName}
            color={action.author.avatarColor}
          />
          <div className={styles.actionInner}>
            <Typography variant="body" className={styles.actionText}>
              <Typography
                variant="body"
                weight="medium"
                component="span"
                className={styles.actionTextAuthor}
              >
                {action.author.fullName}{' '}
              </Typography>
              {memberAction}
            </Typography>
            <Typography variant="body" className={styles.actionDate}>
              {action.date}
            </Typography>
          </div>
        </div>
      ));
    }

    return (
      <div key={actionIndex} className={styles.action}>
        <Avatar
          variant="medium"
          fullName={action.author.fullName}
          color={action.author.avatarColor}
        />
        <div className={styles.actionInner}>
          <Typography variant="body" className={styles.actionText}>
            <Typography
              variant="body"
              weight="medium"
              component="span"
              className={styles.actionTextAuthor}
            >
              {action.author.fullName}{' '}
            </Typography>
            {action.action}
          </Typography>
          <Typography variant="body" className={styles.actionDate}>
            {action.date}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.section}>
      <Collapsible
        open={open}
        transitionTime={150}
        headerComponent={
          <div className={styles.header}>
            <Typography variant="body" weight="medium" className={styles.title}>
              {t('actionsLabel')}
            </Typography>
            <Link onClick={onToggleClick}>
              {open ? t('hideTrigger') : t('showTrigger')}
            </Link>
          </div>
        }
        innerComponent={
          <div className={styles.inner}>
            {loading ? (
              <Loading size={17} className={styles.loading} />
            ) : actions.length ? (
              actions.map(renderAction)
            ) : (
              <Typography variant="body" className={styles.emptyText}>
                {t('empty')}
              </Typography>
            )}
          </div>
        }
      />
    </div>
  );
};

export default React.memo(ActionsSection);
