import { ERROR_TYPES } from 'constants/errorTypes';

export const setFormError = ({ status, setFieldError, t }) => {
  switch (status) {
    case ERROR_TYPES.REGISTER_USER_ALREADY_EXISTS: {
      setFieldError('email', t('existEmailError'));

      break;
    }
    case ERROR_TYPES.EMAIL_IS_NOT_VALID: {
      setFieldError('email', t('notValidEmailError'));

      break;
    }
    default: {
      setFieldError('email', t('notValidEmailError'));
    }
  }
};
