import { ERROR_TYPES } from 'constants/errorTypes';

export const setFormError = ({ status, setFieldError, t }) => {
  switch (status) {
    case ERROR_TYPES.ACCOUNT_VERIFIED: {
      setFieldError('code', t('verifyAccountError'));

      break;
    }
    case ERROR_TYPES.ACCOUNT_DELETED: {
      setFieldError('code', t('deletedAccountError'));

      break;
    }
    case ERROR_TYPES.EMAIL_IS_NOT_VALID: {
      setFieldError('code', t('notValidEmailError'));

      break;
    }

    default: {
      setFieldError('email', t('unknownError'));
    }
  }
};
