import { ERROR_TYPES } from 'constants/errorTypes';

export const convertDataToApi = ({ values, language, timeZone }) => ({
  email: values.email,
  password: values.password,
  confirmPassword: values.confirmPassword,
  tz: timeZone,
  language,
});

export const setFormError = ({ status, setFieldError, t }) => {
  switch (status) {
    case ERROR_TYPES.EMAIL_IS_NOT_VALID:
    case ERROR_TYPES.PASSWORD_IS_INVALID: {
      setFieldError('password', t('incorrectValueError'));

      break;
    }
    case ERROR_TYPES.REGISTER_USER_ALREADY_EXISTS: {
      setFieldError('password', t('existUserError'));

      break;
    }
    case ERROR_TYPES.PASSWORDS_DO_NOT_MATCH: {
      setFieldError('password', t('notMatchPasswordError'));

      break;
    }

    default: {
      console.log(t('defaultError'));
    }
  }
};
