import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserInfo } from 'store/auth/actions';
import { userSelector } from 'store/auth/selectors';

import { useModal } from 'hooks/useModal';

import { showToastMessage } from 'helpers/showToastMessage';

import { ChangeEmailModal } from './components/ChangeEmailModal';
import { ChangePasswordModal } from './components/ChangePasswordModal';

import { TABS } from './SettingsModal.constants';

import { convertDataToForm } from './SettingsModal.helpers';

import SettingsModal from './SettingsModal';

const SettingsModalContainer = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const [currentTab, setCurrentTab] = useState(TABS[0].text);

  const { t, i18n } = useTranslation(['toast', 'settingsPage']);

  const [changeEmailModalOpen, openChangeEmailModal, closeChangeEmailModal] =
    useModal({ defaultOpen: false });

  const [
    changePasswordModalOpen,
    openChangePasswordModal,
    closeChangePasswordModal,
  ] = useModal({ defaultOpen: false });

  const updateUser = (data) => {
    dispatch(updateUserInfo(data)).catch(({ status }) => {
      showToastMessage({ variant: 'error', status, t });
    });
  };

  const handleTabChange = (nextTab) => {
    setCurrentTab(nextTab);
  };

  const handleLanguageChange = (nextLanguage, values) => {
    updateUser({
      language: nextLanguage.value,
      fullName: values.name,
      avatarColor: values.color,
      state: values.state,
    });

    i18n.changeLanguage(nextLanguage.value);
  };

  const handleColorChange = (nextColor, values) => {
    updateUser({
      fullName: values.name,
      avatarColor: nextColor,
      state: values.state,
      language: values.language.value,
    });
  };

  const handleNameBlur = (_, nextName, values) => {
    if (nextName === user.fullName) {
      return;
    }

    updateUser({
      fullName: nextName,
      avatarColor: values.color,
      state: values.state,
      language: values.language.value,
    });
  };

  return (
    <>
      <SettingsModal
        {...props}
        initialValues={convertDataToForm(user, t)}
        currentTab={currentTab}
        onTabChange={handleTabChange}
        onLanguageChange={handleLanguageChange}
        onColorChange={handleColorChange}
        onNameBlur={handleNameBlur}
        onChangeEmailClick={openChangeEmailModal}
        onChangePasswordClick={openChangePasswordModal}
      />
      <ChangeEmailModal
        isOpen={changeEmailModalOpen}
        onClose={closeChangeEmailModal}
      />
      <ChangePasswordModal
        isOpen={changePasswordModalOpen}
        onClose={closeChangePasswordModal}
      />
    </>
  );
};

export default React.memo(SettingsModalContainer);
