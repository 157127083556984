import { TEMPLATE_TYPE } from './Template.constants';

export const convertTemplate = (templete, t) => {
  switch (templete) {
    case TEMPLATE_TYPE.NO_TEMPLATE: {
      return {
        title: `${t('noTemplateButton')}`,
        description: `${t('emptyLabel')}`,
        type: TEMPLATE_TYPE.NO_TEMPLATE,
      };
    }

    case TEMPLATE_TYPE.SIMPLE_BOARD: {
      return {
        background: true,
        title: `${t('simpleButton')}`,
        description: `${t('simpleLabel')}`,
        type: TEMPLATE_TYPE.SIMPLE_BOARD,
      };
    }

    case TEMPLATE_TYPE.AIRLINE_BOARD: {
      return {
        background: true,
        title: `${t('airlineButton')}`,
        description: `${t('simpleLabel')}`,
        type: TEMPLATE_TYPE.AIRLINE_BOARD,
      };
    }

    default: {
      console.log('DEBUG MESSEGE');
    }
  }
};
