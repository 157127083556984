import React, { useState, useRef, createRef } from 'react';
import { useTranslation } from 'react-i18next';

import { BLOB_TYPE } from '../../TimelinePage.constants';

import * as api from 'api/methods';

import { getTabButton } from './Header.helpers';

import Header from './Header';

const HeaderContainer = ({ selectedTimePeriod, boardId, onSelectPeriod }) => {
  const { t } = useTranslation('timelinePage');

  const tabButtons = getTabButton(t);

  const tabsRef = useRef(
    Array.from(Array(tabButtons.length)).map(() => createRef('')),
  );

  const tabBarRef = useRef();

  const [loading, setLoading] = useState(false);
  const [checkedButtonPosition, setCheckedButtonPosition] = useState(142);

  const handleDownloadXLS = () => {
    setLoading(true);

    api.getExelFile(boardId).then((xlsFile) => {
      const blob = new window.Blob([xlsFile], {
        type: BLOB_TYPE,
      });

      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);

      link.click();

      setLoading(false);
    });
  };

  const handleTabClick = (tab) => {
    const selectedButtonPosition = tabsRef.current.find(
      (tabEvent) => tabEvent.current.textContent === tab.name,
    );

    const left = selectedButtonPosition.current.offsetLeft - 4;

    setCheckedButtonPosition(left);
    onSelectPeriod(tab.type);
  };

  return (
    <Header
      tabsRef={tabsRef}
      tabBarRef={tabBarRef}
      loading={loading}
      checkedButtonPosition={checkedButtonPosition}
      selectedTimePeriod={selectedTimePeriod}
      tabButtons={tabButtons}
      onTabClick={handleTabClick}
      onDownloadXLS={handleDownloadXLS}
    />
  );
};

export default React.memo(HeaderContainer);
