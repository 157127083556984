import React from 'react';

import { Loading } from 'components/shared/Loading';

import styles from './AcceptInvitePage.styles.scss';

const AcceptInvitePage = ({ loading }) =>
  loading && <Loading className={styles.loading} size={30} color="#623CEA" />;

export default React.memo(AcceptInvitePage);
